#cryptowalletbusiness {
    padding: 0px 6.5% 0px 5%;
    .containertp {
        margin-top: 33px;
    }
    .trasactiontitle {
        color: var(--textdark);
        font-weight: 600;
        font-size: 18px;
        text-align: left;
        padding: 20px 20px 20px 0px;
    }
    .trouter {
        height: 155px;
        width: 100%;
        background: var(--bgwhite);
        border-radius: 2px;
        display: flex;
        align-items: center;
    }
    .mybalancesheadwrapper{
        margin-left: 8px;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .title{
        font-size: 32px;
        font-weight: 600;
        display: inline-block;
        margin-bottom: 0px;
        line-height: 60px;
    }
    .bcButtonwrapper{
        display: flex;
        .optionholder{
            margin: 0px 13px;
        }
    }
    .contentwrapper{
        background: var(--bgwhite);
        border-top-width: 0px;
        text-align: left;
    }
    .filterwrapper{
        border: 1px solid var(--borderlight);
        padding: 15px;
        border-radius: 6px;
    }
    .filtersectionheading{
        font-size: 12px;
        color: var(--textdarker);
        margin-bottom: 8px;
    }
    .searchinputwrapper{
        display: flex;
        height: 30px;
        align-items: center;
        justify-content: space-between;
        border: 1px solid var(--borderlight);
        border-radius: 4px;
        padding: 5px 8px;
        cursor: pointer;
        input{
            border: none;
            outline: none;
            font-size: 12px;
            background-color: transparent;
            cursor: pointer;
            color: var(--textdark);
            &::placeholder {
                color: var(--textlight);
                opacity: 1; /* Firefox */
              }
              
              &:-ms-input-placeholder { /* Internet Explorer 10-11 */
               color: var(--textlight);
              }
              
              &::-ms-input-placeholder { /* Microsoft Edge */
               color: var(--textlight);
              }
        }
    }
    // #dropdown-basic{
    //     color: var(--textdark);
    // }
    .searchicon{
        font-size: 12px;
        cursor: pointer;
        path{
            stroke: var(--textlight);
            opacity: .5;
        }
    }
    .dropdownwrapper{
        height: 30px;
        padding: 5px 8px;
        border: 1px solid var(--borderlight);
        border-radius: 5px;
        font-size: 12px;
        // color: var(--textlight);
    }
    .dropdown-menu{
        right: 0px !important;
        border: 1px solid var(--borderlight);
        color: var(--textdark);
        background-color: var(--bgwhite);
    }
    .toogleicon{
        float: right;
        font-size: 12px;
        color: var(--textlight);
        opacity: .5;
    }
    .hidetextwrapper{
        padding-top: 3px;
        font-size: 12px;
        color: var(--textlight);
        cursor: pointer;
        font-weight: 500;
        display: flex;
        align-items: center;
    }
    .hidetextwrapper.active{
        font-size: 12px;
        color: var(--primary);
        font-weight: 600;
        text-shadow: 0px 1px 3px var(--borderlight);
    }
    .hidetextwrapper.active .eyeIcon{
        height: 16px;
        width: 23px;
        path{
            fill: var(--primary);
            stroke: var(--primary)
        };
    }
    .noresults{
        padding-top: 20px;
        color: var(--textlight);
        font-size: 14px;
    }
    .hideText{
        margin-left: 5px;
        font-weight: 600;
    }
    .eyeIcon{
        path{
            fill: var(--textlight)
        };
    }
    .buttonWrapper{
        margin-top: 25px;
        display: flex;
        align-items: center;
        width: 100%;
    }
    .topbuttons {
        display: inline-block;
        .buttonmain {
          width: 95px;
          height: 32px;
          margin: 0px;
          margin-right: 25px;
          border-radius: 4px;
          font-weight: 600;
          font-size: 12px;
          box-shadow: 0px 2px 4px var(--borderlight);
        }
        .nonfilledbtn{
            width: 95px;
            height: 32px;
            margin: 0px;
            border-radius: 4px;
            font-size: 12px;
            padding: 0px;
        }
    }
    .topbuttons {
        .buttonmain:hover {
          box-shadow: 0px 2px 4px var(--borderdarker);
        }
    }
    @media(max-width: 992px){
        .trouter{
            height: 45px;
            margin-bottom: 15px;
        }
        .filterwrapper{
            padding: 0px;
            border: none;
        }
        .trasactiontitle{
            padding: 0px;
        }
        .mybalancesheadwrapper{
            margin: 0px;
        }
        .title{
            padding: 0px;
            font-size: 16px;
            line-height: 24px;
        }
        .hidetextwrapper{
            justify-content: flex-end;
        }
    }
}
@media (max-width: 556px) {
    #cryptowalletbusiness {
      padding: 0px 10px;
    }
  }