.profile {
  display: flex;
  align-items: center;
  justify-content: center;
  .profilecircle {
    height: 45px;
    width: 45px;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    box-sizing: border-box;
    border-radius: 100px;
  }
  .profileimg {
    vertical-align: middle;
    width: 45px;
    height: 45px;
  }
  .realprofileimg2 {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
    background-color: var(--textwhite);
  }
  .profilearea {
    margin-left: 8px;
    max-width: 300px;
    display: inline-block;
    p {
      margin-bottom: 0px;
    }
    .usernametext {
      font-size: 10px;
      font-weight: 400;
      color: var(--textlight);
      line-height: 10px;
    }
    .user-name {
      font-size: 12px;
      font-weight: 600;
    }
    .verified-or-not {
      display: none;
      // font-size: 10px;
      // display: flex;
      // font-weight: 400;
      // align-items: center;
      // &.verified {
      //   color: var(--green);
      // }
      // &.unverified {
      //   color: var(--red);
      // }
    }
    // .tickclass {
    //   margin-top: 2px;
    //   path {
    //     fill: var(--textwhite);
    //   }
    // }
  }
  // .verifiedornotcircle {
  //   width: 12px;
  //   height: 12px;
  //   border-radius: 50%;
  //   margin-right: 3px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   &.green {
  //     background-color: var(--green);
  //   }
  //   &.red {
  //     background-color: var(--red);
  //   }
  // }
  .profilename {
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    display: block;
    line-height: 40px;
    margin-top: 3px;
    color: var(--textdark);
    text-transform: capitalize;
  }
  .profilename p {
    line-height: 17px;
    width: 150px;
    // overflow: hidden;
    white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    white-space: pre-wrap; /* css-3 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    white-space: -webkit-pre-wrap; /* Newer versions of Chrome/Safari*/
    word-break: break-all;
    white-space: normal;
    text-overflow: ellipsis;
  }
  .profiletag {
    font-size: 9px;
    font-weight: 400;
    background-color: var(--primary);
    padding: 0px 6px;
    border-radius: 4px;
    height: 10px;
    width: 35px;
    line-height: 10px;
    margin-top: 1px;
    color: var(--textwhite);
  }
  .verifiedonmobile {
    display: none;
  }
  // @media (max-width: 992px) {
  //   .profileimg {
  //     vertical-align: middle;
  //     width: 32px;
  //     height: 32px;
  //   }
  //   .profilecircle {
  //     height: 32px;
  //     width: 32px;
  //   }
  // }
  @media (max-width: 768px) {
    .profilecircle {
      position: relative;
    }
    // .verifiedonmobile {
    //   display: flex;
    //   position: absolute;

    //   top: 4px;
    //   right: 2px;

    //   border: 1px solid var(--textwhite);
    // }
  }
}
