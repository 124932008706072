.createpayment {
  .outerlayout {
    background-color: var(--bgwhite);
    padding: 30px;
    border: 1px solid var(--borderlight);
    overflow: hidden;
    border-radius: 10px;
  }
  .topspace {
    margin-top: 15px;
    width: 100%;
    background: var(--bgwhite);
    border: 0.5px solid var(--borderlight);
    box-sizing: border-box;
    border-radius: 6px 6px 0px 0px;
  }
  .outerbox {
    height: 85px;
    width: 100%;
    border-bottom: 0.5px solid var(--borderlight);
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  .innerbox {
    padding: 50px 70px;
  }
  .hrline {
    width: 100%;
    color: var(--borderdarker);
    margin-top: 0;
  }
  .dropdownlink {
    max-width: 263px;
    height: 32px;
    margin-top: 15px;
    border-radius: 4px;
    padding-top: 2px;
  }
  .dropdown-menu{
    max-width: 263px;
  }
  .search{
    top: 2px;
    left: 1px;
    height: 24px;
  }
  .topmargin{
    margin-top: 16px;
  }
  .maintitle {
    font-size: 32px;
    font-weight: 600;
    color: var(--textdarker);
    text-align: left;

    margin-left: 30px;
  }
  .heading {
    width: 250px;
    font-size: 15px;
    font-weight: 600;
    color: var(--textdark);
    opacity: 0.87;
    text-align: left;
  }
  .allign{
    width: 225px
  }
  .inlineblock {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
  .inlineflex {
    display: inline-flex;
  }
  .input.form-control.input {
    max-width: 263px;
    height: 32px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 4px;
    padding: 0px 14px;
  }
  .linktxt {
    font-size: 16px;
    font-weight: 600;
    color: var(--primary);
  }
  .detail {
    text-align: left;
    margin-bottom: 15px;
    border-bottom: 1px solid var(--borderdark);
    width: 100%;
    max-width: 235px;
    margin-top: 8px;
  }
  .detail1 {
    text-align: left;
    margin-bottom: 15px;
    width: 100%;
    max-width: 235px;
    margin-top: 8px;
    border-bottom: 1px solid var(--borderdark);
  }
  .detailspace {
    margin-top: 20px;
    margin-bottom: 50px;
    width: 100%;
  }
  .label {
    display: inline-block;
  }
  .labeltext {
    color: var(--textdarker);
    font-weight: 400;
    font-size: 16px;
  }
  .labeltextblue {
    color: var(--primary);
    font-size: 16px;
    font-weight: 600;
  }
  .value {
    display: inline-block;
    float: right;
  }
  .valuetext {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: var(--textdarker);
  }
  .valuetextblue {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: var(--primary);
  }
  .detailtopspace {
    margin-top: 37px;
  }
  .boxtop {
    margin-top: 30px;
  }
  .box {
    width: 100%;
    max-width: 870px;
    margin: 0 auto;
    min-height: 220px;
    background: var(--bgwhite);
    box-shadow: 0px 3px 20px var(--borderlight);
    box-sizing: border-box;
    border-radius: 6px;
    padding: 30px 30px 30px 30px;
  }
  .link2 {
    margin-bottom: 6px;
  }
  .currencytoconvert {
    margin-bottom: 0px;
  }
  .buttonmain {
    width: 271px;
    height: 60px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 4px;
  }

  .textholder {
    text-align: left;
  }
  .text {
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;
    color: var(--textdark);
  }

  .link {
    text-align: center;
  }
  .link1 {
    text-align: left;
    margin-bottom: 30px;
  }

  .commonsection {
    text-align: center;
    display: inline-block;
    margin-left: 40px;
    margin-right: 40px;
  }
  .btntop {
    margin-top: 30px;
  }
  .textline1 {
    font-size: 14px;
    font-weight: 600;
    color: var(--textdark);
  }
  .textline2 {
    font-size: 12px;
    font-weight: 400;
    color: var(--textlight);
    display: block;
    margin-bottom: 10px;
  }
  .toptxt {
    font-size: 16px;
    font-weight: 600;
    color: var(--textdark);
    text-align: left;
  }
  .InputField {
    height: 48px;
  }

  .arrowclass {
    path {
      fill: var(--primary);
      stroke: var(--primary);
    }
  }
  .arrowclass {
    width: 9px;
    height: 9px;
  }
  .DropdownSearch .cointext {
    margin-left: 0px;
    line-height: 0px;
    vertical-align: middle;
  }
  // .DropdownSearch {
  //   margin-bottom: 40px;
  // }
  .coinicon {
    margin-right: 10px;
    line-height: 0px;
  }
  .display {
    display: inline-block;
  }
  .toogleicon{
    line-height: 25px;
  }
  @media screen and (min-width: 1199px) and (max-width: 1300px) {
    .heading {
      font-size: 14px;
    }
    .allign{
      width: 190px;
    }
    .commonsection {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  @media (max-width: 1100px){
    .innerbox{
      padding: 50px 40px;
    }
  }
  @media screen and (max-width: 991px) {
    .maintitle {
      font-size: 16px;
      margin-left: 0px;
    }
    .topspace {
      margin-top: 0px !important;
      border: none;
    }
    .outerbox {
      height: auto;
      border: none;
      margin-bottom: 30px;
    }
    .box {
      width: 261px;
      height: 100%;
      padding: 9px 30px 10px 30px
    }
    .toptxt {
      display: none;
    }
    .dropdownlink {
      // min-width: 352px;
      min-width: 100%;
      // margin-bottom: 40px;
      font-size: 12px;
    }
    .dropdown-menu{
      min-width: 100%;
    }
    .DropdownSearch .cointext{
      line-height: 25px;
    }
    .DropdownSearch {
      margin-bottom: 30px;
      // .selecteditem{
      //   display: flex;
      //   align-items: center;
      // }
      // .toogleicon{
      //   svg{
      //     vertical-align: text-top;
      //   }
      // }
    }
    .input.form-control.input {
      min-width: 100%;
      margin-bottom: 30px;
    }
    .linktxt {
      font-size: 12px;
    }
    .innerbox {
      padding: 0px 0px;
    }
    .container {
      padding-right: 0px;
      padding-left: 5px;
      margin-right: 5px;
      margin-left: 0px;
    }
    .buttonmain {
      height: 40px;
    }
    .btntop {
      margin-top: 41px;
    }
    .containerallignm {
      margin-top: 0px;
    }
    // .InputField {
    //   height: 71px;
    // }
    .boxtop{
      margin-top: 20px;
    }
    .detailspace{
      margin-bottom: 0px;
      margin-top: 37px
    }
    .link1{
      margin-bottom: 0px;
    }
    
    .labeltextblue{
      color: var(--primary);
    }
    .valuetextblue{
      color: var(--primary);
    }
    .heading{
      font-size: 14px;
      margin-top: 13px;
    }
    .allign{
      width: 190px;
    }
  }
  @media screen and (max-width: 768px) {
    .heading{
      font-size: 14px;
      margin-top: 0px;
    }
    .allign{
      width: 100%;
    }
    .inlineflex {
      display: block;
    }
    .inlineblock {
      display: inline-block;
      padding-left: 0px;
      padding-right: 0px;
      margin: 0 auto;
    }
  }
}