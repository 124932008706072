#AdminFiatWithdrawRequest {
  .disabledBtn {
    &.buttonmain {
      background-color: var(--borderdark) !important;
    }
  }
  .approved {
    color: var(--green);
    margin-bottom: 2px;
  }
  .rejected {
    color: var(--red);
    margin-bottom: 2px;
  }
  .transfertbl {
    position: relative;
    width: 100%;
    background: var(--bgwhite);
    border: var(--borderdark);
    border-radius: 2px;
  }
  .innerTdforprofile {
    padding: 9px;
    border-radius: 0.1px;
    border: 1px solid var(--borderlight);
    border-left: none;
    border-right: none;
    height: 70px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
  }
  .tableindark{
    .innerTdforprofile, .innerTd, tbody tr td:first-child .innerTd, tbody tr td:last-child .innerTd{
      border-color: var(--borderdarkblue);
    }

  }
  .colwidth {
    width: 280px;
  }
  thead tr {
    border-bottom: 2px solid var(--borderlight);
    background-color: var(--primarylight);
  }
  tr {
    border-color: var(--borderlight);
  }
  // tr:hover {
  //   background-color: var(--bglightgrey);
  // }
  table {
    border-color: var(--borderdark);
  }
  td {
    img {
      margin-top: 3px;
    }
  }
  .imgstatus {
    width: 33px;
    height: 33px;
  }
  td,
  th {
    border: none;
  }

  //   .table-responsive-sm {
  //   overflow-x: auto;
  // }
  // .table-responsive-sm {
  //   overflow-x: auto;
  //   border: 1px solid var(--borderlight);
  // }
  .containertp {
    margin-top: 33px;
    padding: 0 70px;
  }
  .transactiontitle {
    text-align: left;
   
  }
  // .transfertbl {
  //   position: relative;
  //   width: 100%;
  //   background: var(--bgwhite);
  //   border: var(--borderdark);
  //   border-radius: 2px;
  // }
  // thead tr {
  //   border-bottom: 2px solid var(--borderlight);
  //   background-color: var(--primarylight);
  // }
  // tr {
  //   border-color: var(--borderlight);
  // }
  // tr:hover {
  //   background-color: var(--bglightgrey);
  // }
  // table {
  //   border-color: var(--borderdark);
  // }
  // td {
  //   img {
  //     margin-top: 3px;
  //   }
  // }
  // td,
  // th {
  //   border: none;
  // }
  // .transactiontitle {
  //   text-align: left;
  //   padding-left: 25px;
  // }
  .trouter {
    height: 65px;
    margin-bottom: 20px;
    width: 100%;
    background: var(--bgwhite);
    border: var(--borderdark);
    border-radius: 2px;
  }
  .title {
    color: var(--textdark);
    opacity: 0.84;
    font-weight: 600;
    font-size: 32px;
    line-height: 67px;
  }
  .actions {
    width: 100%;
    height: auto;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    // margin-bottom: 20px;
  }
  .btmmargin {
    display: inline-block;
    margin-bottom: 20px;
  }
  // .trtbleheading {
  //   color: var(--textdark);
  //   font-weight: 400;
  //   font-size: 12px;
  //   line-height: 22px;
  //   padding-top: 15px;
  //   padding-bottom: 15px;
  // }
   .trtbleheading {
    color: var(--shadow);
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    vertical-align: middle;
    height: 68px;
    white-space: nowrap;
    text-align: center;
}
  
  .filtersection {
    display: inline-block;
    float: right;
    position: relative;
    margin-top: 12px;
    margin-right: 6px;
  }
  .buttoncontainer {
    width: 230px;
    height: 33px;
    margin-top: 4px;
  }
  .buttonmain {
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    height: 33px;
    margin: 0px;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    border-radius: 2px;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    color: var(--textwhite);
    background-color: var(--primary);
  }
  .buttoncontainersm {
    display: inline-block;
    margin-left: 0px;
    margin-right: 22px;
    // margin-top: 11px;
    // margin-bottom: 20px;
  }
  .leftsection {
    display: inline-block;
    margin-bottom: 20px;
  }
  .rightsection {
    text-align: right;
    width: 100%;
    margin-bottom: 10px;
  }
  .thethreebutton {
    text-align: right;
    margin-right: 0px;
  }
  .mr-0 {
    margin-right: 0px;
  }
  .status {
    font-weight: 600;
    font-size: 12px;
    line-height: 40px;
    padding-right: 20px;
    text-transform: uppercase;
  }
  .red {
    color: var(--red);
  }
  .green {
    color: var(--green);
  }
  .yellow {
    color: var(--yellow);
  }
  .amt {
    font-weight: 600;
    font-size: 16px;
    vertical-align: middle;
    margin-top: -2px;
    color: var(--textdark);
    opacity: 0.87;
  }
  .amount {
    font-weight: 600;
    font-size: 12px;
    line-height: 40px;
    color: var(--textdark);
  }
  .coinlistarea {
    line-height: 32px;
    font-size: 12px;
    font-weight: 400;
  }
  .coinlist img.coinimg {
    margin-top: -8px;
  }
  .coinlist {
    margin-top: 4px;
    width: 90px;
    margin-left: 20px;
    text-align: left;
  }
  .coinimg {
    height: 10px;
  }
  .coincircle {
    height: 18px;
    width: 18px;
    margin-top: 7px;
  }
  .pagination {
    justify-content: flex-end;
  }
  .arrowiconclass {
    path {
      fill: var(--textdark);
      stroke: var(--textdark);
    }
  }
  .arrowiconclass {
    //margin-top: 22px;
    width: 19px;
    height: 19px;
  }
  .statusr {
    display: inline-block;
    width: calc(100% - 20px);
    vertical-align: top;
  }
  .vertical {
    display: inline-block;
  }
  .threedot {
    background-color: transparent;
    padding: 0px;
    border: none;
    outline: none;
  }
  .btnwidth {
    width: 202px;
    margin: 0 auto;
    text-align: center;
  }
  .menu {
    border-radius: 2px;
    background-color: var(--bgwhite);
    border: 1px solid var(--borderlight);
  }
  .threedot.dropdown-toggle::after {
    display: none;
  }
  .dropdown.show {
    display: inline-block;
  }
  .dropdown-item:focus,
  .dropdown-item:hover {
    text-decoration: none;
    cursor: pointer;
  }
  .mleft {
    margin-left: 3px;
  }
  .iconcolor {
    path {
      fill: var(--textdark);
    }
  }
  .iconCopy {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .paginationicon {
    path {
      fill: var(--textdark);
    }
  }
  .link {
    width: calc(100% - 30px);
    display: inline-block;
  }
  .btnwithimg {
    width: 72px;
    height: 33px;
  }
  .linktxt {
    display: inline-block;
    color: var(--primary);
    text-decoration: none;
    width: 100%;
    max-width: 270px;
    word-wrap: break-word;
    text-align: justify;
  }
  .threedotspace {
    margin-right: 25px;
  }
  .approve {
    display: inline-block;
    width: 85px;
    margin:0px 8px 0px auto;
    margin-right: 8px;
    text-align: center;
  }
  .reject {
    margin-right: 8px;
    display: inline-block;
    width: 85px;
    text-align: center;
    margin-left: 16px;
  }
  .thwidth {
    width: 200px;
  }
  .buttonmain {
    font-weight: 500;
    font-size: 14px;
    width: 102px;
    height: 40px;
    margin: 20px 0;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    border-radius: 9px;
    color: var(--textwhite);
    background-color: var(--primary);
    box-shadow: 0px 2px 4px var(--borderdark);
  }
  .img {
    width: 17px;
    vertical-align: top;
    height: 38px;
    padding-left: 3px;
    display: inline-block;
  }

  .vertical-align {
    vertical-align: top;
    width: 17px;
    height: 24px;
    padding-left: 3px;
    display: inline-block;
  }
  .table {
    width: 100%;
    background: var(--bgwhite);
    border: var(--borderdark);
    border-radius: 6px;
    border-collapse: separate;
    border-spacing: 0px;
  }
  tbody:before {
    content: "-";
    display: block;
    line-height: 7px;
    color: transparent;
  }
  .innerTd {
    padding: 9px;
    border-radius: 0.1px;
    border: 1px solid var(--borderlight);
    border-left: none;
    border-right: none;
    height: 70px;
    text-align: left;
    display: flex;
    align-items: unset;
    justify-content: center;
    flex-direction: column;
  }
  tbody tr td:first-child .innerTd {
    padding-left: 15px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-left: 1px solid var(--borderlight);
  }
  tbody tr td:last-child .innerTd {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-right: 1px solid var(--borderlight);
  }
  .colspace.actions {
    line-height: 39px;
  }
  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: var(--borderlight);
  }
  thead tr th {
    border: 0.5px solid var(--borderlight);
    border-left: none;
    border-right: none;
    border-top-right-radius: 0.1px;
    border-top-left-radius: 0.1px;
    background-color: var(--bglightgrey);
  }
  thead tr th:first-child {
    padding-left: 45px;
    border-left: 1px solid var(--borderlight);
    border-top-left-radius: 6px;
  }
  thead tr th:last-child {
    border-right: 1px solid var(--borderlight);
    border-top-right-radius: 6px;
  }
  // tr {
  //   border-color: var(--borderlight);
  // }
  table {
    border-color: var(--borderdark);
  }
  td {
    border: none;
    padding-left: 0px;
    padding-right: 0px;
  }
  th {
    border: none;
    text-align: left;
  }
  .table-responsive-sm {
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
    // border: 1px solid var(--borderlight);
    min-height: 450px;
  }
  .arrow {
    margin-right: 10px;
    path {
      fill: var(--textdark);
    }
  }
  p{
    margin-bottom: 0px;
  }
  .id{
    text-align: center;
  }
  @media screen and (min-width: 992px) {
    .hidden-for-desktop {
      display: none;
    }
    .filtersection {
      display: inline-block;
      float: right;
      position: relative;
      margin-top: 3px;
      margin-right: -56px;
    }
    
  }
  
  @media (max-width: 991px) {
    .title {
      font-size: 16px;
  }
  .id{
    text-align: left;
  }
    .containertp{
      padding: 0 20px;
    }
    
  .filtersection{
  .filter .btn-primary {
    margin-top: 1px;
    height: 33px;
    width: 62px;
    margin-bottom: 10px;
    padding-left: 11px;
    padding-right: 11px;
    border: none;
    border-color: unset;
    box-shadow: 0px 1px 4px var(--borderlight);
    border-radius: 4px;
  }
  .filtericon {
    margin-top: -3px;
    width: auto;
  }
  .filtertext {
    display: none;
  }
  }
  .amt {
    font-size: 12px;
  }
  .date{
    opacity: 0.54;
  }
  }
 
  @media (max-width: 768px) {
    .buttoncontainer {
      width: 200px;
    }
    .leftsection {
      float: unset;
      margin-top: 20px;
      margin-left: 2px;
    }
    .thethreebutton {
      text-align: left;
      margin-top: 12px;
      margin-left: -7px;
    }
    .buttoncontainersm {
      display: inline-block;
      margin-left: 11px;
      margin-right: 11px;
      margin-top: 11px;
      margin-bottom: 20px;
      width: auto;
    }
  }
  @media screen and (max-width: 991px) {
    .hidden-for-mobile {
      display: none;
    }
  }
  @media (max-width: 480px) {
    .CurrencyTab .nav-link {
      font-size: 14px;
    }

    .CurrencyTab .nav-link.active {
      font-size: 14px;
    }
    .CurrencyTab .nav-item {
      width: 110px;
    }
  }
  
}
