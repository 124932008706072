#cryptowallet {
  padding: 0px 5%;
  .searcharea .searchbox{
    max-width: unset;
    text-align: left;
    margin-left: 0px;
    input{
      margin-bottom: 0px;
      margin-left: 1px;
    }
    .pointer{
      width: 20px;
      height: 20px;
    }
  }
  .qrswrappingborder{
    border: 0.5px solid var(--borderlight);
    padding: 8px 20px;
    border-radius: 6px;
    background-color: var(--bgfaintwhite);
  }
  .smallcardwrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // height: 100%;
  }
  .innersmallcard{
    height: calc(50% - 10px);
  }
  .modernBgWrap{
    margin-top: 45px;
  }
  .currWrap{
    margin-top: 45px;
    margin-bottom: 91px;
  }
  .CoinListBalance {
    .coinimgwrapper {
      width: 42px;
      height: 42px;
    }
    .coinSymbolbelowname {
      margin: 0px 13px;
    }
    .coinname {
       margin: 0px 13px;
    }
  }
  .innerTd{
    height: 70px;
    display: flex;
    align-items: center;
    // padding-left: 33px;
  }
  .coinlist{
    display: flex !important;
    align-items: center;
    .coincircle{
      margin-top: 0px;
    }
  }
  .tokentagwrapper {
    padding-top: 1px !important;
}
  .cointable .table > :not(caption) > * > * {
    padding-top: 10px;
    padding-bottom: 0px;
  }
  .tableheading {
    height: 37px;
    padding-left: 33px;
  }
  .cointable .tokentagwrapper {
    top: 1px;
  }
 
  @media (max-width: 991px){
    .coinlist .coinlistarea{
      width: calc(100% - 36px);
      p{
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .mobilescrbal{
      max-width: unset;
    }
    .cointable .price .contentinnertdstyle {
      flex: 1;
      white-space: nowrap;
    }
    .modernBgWrap{
        margin-top: 6px;
    }
    .currWrap{
      margin-top: 26px;
    }
    .qrswrappingborder{
      border: none;
      padding: 0px;
      margin-top: 40px;
    }
    .outermost{
      width: 100%;
    }
    .whtBtn{
      width: 100%;
      .CustomButton{
          flex: 1;
          margin: 0px 0px;
          .nonfilledbtn{
            max-width: 157px;
            height: 52px;
            border-radius: 10px;
          }
      }
  }
  .blkBtn{
      .CustomButton{
          width: 100%;
          margin: 0px 0px;
          .buttonmain{
              height: 52px;
              max-width: 157px;
              border-radius: 10px;
          }
      } 
  }
  }
  @media (max-width: 767px){
    .mobilescrbal{
      max-width: 60px;
    }
  }
  @media (max-width: 388px){
    .coinlist .coinlistarea{
      width: calc(100% - 36px);
      p{
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
@media (max-width: 556px) {
  #cryptowallet {
    padding: 0px 20px;
  }
}