.CustomButton {
  .buttonmain {
    font-weight: 600;
    font-size: 12px;
    width: 100%;
    height: 40px;
    margin: 20px 0;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    border-radius: 4px;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    color: var(--textwhite);
    background-color: var(--primary);
    &:disabled {
      cursor: not-allowed;
    }
  }

  .download-btn{
    color: var(--primary) !important;
    background-color: var(--textwhite) !important;

  }

  .reject-btn{
    color: #d74338 !important;
    background-color: var(--textwhite) !important;
  }
  .buttonmain:disabled {
    background: var(--primary);
    cursor:not-allowed
  }
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .nonfilledbtn {
    font-weight: 600;
    font-size: 12px;
    line-height: 6px;
    padding: 8px;
    width: 100%;
    height: 32px;
    background-color: var(--bgwhite);
    border: 0.2px solid var(--borderlight);
    color: var( --primary);
    box-shadow: 0px 2px 4px var( --borderlight);
    opacity: 0.7;
    border-radius: 5px;
  }
  .nonfilledbtn:hover {
    box-shadow: 0px 1px 2px var(--borderdark);
    opacity: 1;
    cursor: pointer;
  }
  .nonfilledbtn:disabled {
    background: var(--primarydark);
    cursor:not-allowed
  }
  .buttontext {
    vertical-align: middle;
    line-height: 14px;
  }
  .btnwithimg {
    border-radius: 2px;

    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    padding: 8px 10px;
    width: 100%;
    max-width: 77px;
    height: 40px;
    background-color: var(--bgwhite);
    border: none;
    color: var(--textdark);
    box-shadow: 0px 2px 4px var(--borderdark);
    opacity: 0.7;
  }
  .btnwithimg:hover {
    box-shadow: 0px 4px 6px var(--borderdark);
    opacity: 1;
  }
  .btnwithlefticon {
    padding-right: 5px;
    
  
    font-weight: 500;
    font-size: 13px;
    width: 180px;
    margin: 20px 0;
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    border: none;
    background-size: 300% 100%;
    border-radius: 2px;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    color: var(--textwhite);
    background-color: var(--primary);
    border-radius: 4px;
    box-shadow: 0px 3px 6px var(--borderdark);
  }
  .btnwithlefticon:hover {
    box-shadow: 0px 4px 6px var(--borderdark);
  }
  .lefticonimg {
    height: 32px;
    width: 32px;
    margin: 12px 10px;
  }
  .buttontext2 {
    text-align: left;
    line-height: normal;
  }
  .lessfontweight {
    font-weight: 400;
  }
  .roundedbordered {
    border-radius: 20px;

    font-weight: 600;
    font-size: 14px;
    line-height: 10px;
    padding: 8px 18px;
    width: 100%;
    height: 40px;
    background-color: transparent;
    border: 1px solid var(--primary);
    color: var(--textdarker);
    opacity: 0.7;
  }

  .paymentreportcustombutton {
    width: 190px;
    height: 32px;
    border-radius: 4px;
    box-shadow: 0px 2px 4px var(--borderdarker);
  }
  @media (max-width: 975px) {
    .btnwithlefticon {
      width: 150px;
    }
    .lefticonimg {
      height: 22px;
      width: 22px;
      margin: 13px 3px;
    }
    .buttontext2 {
      font-size: 11px;
    }
  }
  @media (max-width: 475px) {
    .btnwithlefticon {
      width: 130px;
    }
    .lefticonimg {
      height: 19px;
      width: 19px;
      margin: 11px 3px;
    }
    .buttontext2 {
      font-size: 10px;
    }
  }
  @media (max-width: 375px) {
    .btnwithlefticon {
      width: 120px;
    }
    .lefticonimg {
      height: 19px;
      width: 19px;
      margin: 11px 3px;
    }
    .buttontext2 {
      font-size: 9px;
    }
  }
}
