.videocard{
    border: 0.5px solid var(--borderlight);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 15px;
    .imgholder{
    width:100%;
    height: 244px;
    }

    .img{
        width: 100%;
        height: 100%;
    }
}