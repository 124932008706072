.currencypair {
  width: 100%;
  height: 100%;
  .tokensection {
    width: 100%;
    height: 100%;
    background-color: var(--bgfaintwhite);
    border: 1px solid var(--borderlight);
    border-radius: 6px;
    padding-bottom: 30px;
    padding-top: 5px;
    background-color: var(--bgfaintwhite);
    padding-left: 10px;
    padding-right: 10px;
  }
  .hrline {
    width: 100%;
    color: var(--borderdarker);
    margin-top: 0;
  }
  .toptitle {
    font-size: 32px;
    font-weight: 600;
    color: var(--textdark);
    text-align: left;
    margin-left: 56px;
    margin-top: 10px;
  }
  .toptitle1 {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 18px;
    margin-left: 56px;
  }
  .txt1 {
    color: var(--textdark);
    opacity: 0.87;
  }
  .txt2 {
    color: var(--primary);
  }
  .currpaircontent {
    padding-left: 56px;
    padding-right: 38px;
    padding-top: 20px;
  }
  .middlesection {
    text-align: left;
  }

  .thetextpart {
    display: inline-block;
  }

  .firstdropdown {
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
    .dropdown{
      background-color: transparent;
    }
  }
  .seconddropdown {
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    .dropdown{
      background-color: transparent;
    }
    
  }
  .crypto-dropdown{
      .dropdown-menu{
        transform: translate3d(0px, 37.5px, 0px) !important;
      }
  }
  .fiat-dropdown{
    .dropdown-menu{
      transform: translate3d(0px, 49.5px, 0px) !important;
    }
  }
  .thetextpart {
    text-align: left;
  }

  .lineone {
    font-size: 20px;
    line-height: 27px;
    font-weight: 500;
    color: var(--textdarker);
    display: block;
  }

  .linetwo {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: var(--textdarker);
  }

  .statictextsection {
    text-align: left;
    padding-top: 50px;
    display: flex;
  }

  .statictextone {
    text-align: left;
    display: inline-block;
    width: calc(100% - 550px);
  }

  .staticlineone {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: var(--textdarker);
    display: block;
  }

  .staticlinepartone {
    text-align: left;
    display: inline-block;
    width: calc(100% - 473px);
  }

  .statictextthree {
    text-align: left;
    display: inline-block;
    width: 100%;
    max-width: 156px;
  }

  .statictextfour {
    text-align: left;
    display: inline-block;
    width: 100%;
    max-width: 156px;
  }

  .statictextfive {
    text-align: left;
    display: inline-block;
    width: 100%;
    max-width: 158px;
  }

  .staticlinetwo {
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    color: var(--textdarker);
  }

  .linetwo1 {
    font-size: 20px;
  }

  .statictexttwo {
    text-align: left;
    display: inline-block;
    width: calc(100% - 513px);
  }

  .statictextthree {
    text-align: left;
    display: inline-block;
  }

  .statictextfour {
    text-align: left;
    display: inline-block;
  }

  .statictextfive {
    text-align: left;
    display: inline-block;
  }

  .aligntxt {
    text-align: left;
  }
  .alligntxt-one {
    text-align: left;
  }
  .volume {
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    color: var(--textdarker);
  }
  .value {
    display: inline-block;
    vertical-align: middle;
  }
  .valuetext {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: var(--textdarker);
  }

  .uparrow {
    display: inline-block;
    margin-left: 5px;
  }

  .updown {
    height: 15px;
    width: 15px;
  }

  .greentext {
    display: inline-block;
    font-weight: 600;
    font-size: 20px;
    vertical-align: middle;
    color: var(--green);
    opacity: 0.87;
  }
  .redtext {
    display: inline-block;
    font-weight: 600;
    font-size: 20px;
    vertical-align: middle;
    color: var(--red);
    opacity: 0.87;
  }

  .grapharea {
    width: 100%;
    height: 286px;
    margin-top: 80px;
    text-align: left;
    color: #000;

    svg g text{
      fill: var(--textdark) !important;
    }
  }

  .imggrapg {
    width: 100%;
    height: 100%;
  }

  .rightside {
    text-align: right;
    display: inline-block;
    vertical-align: top;
    width: calc(100%);
  }

  .calenderIcon {
    height: 16px;
    width: 16px;
    path {
      fill: var(--textlight);
    }
  }
  .infoIcon {
    height: 16px;
    width: 16px;
    path {
      stroke: var(--textdark);
      stroke-width: 2;
    }
  }

  .filterbtns .nonfilledbtn {
    padding: 4px 2px !important;
    width: 49px !important;
    height: 33px !important;
    border-radius: 4px !important;
    margin-left: 8px !important;
    font-size: 10px !important;
    color: var(--textdark) !important;
    opacity: 1 !important;
  }
  .daterangepicker:after {
    border: 0px;
  }
  @media (max-width: 1400px) {
    .statictextone {
      text-align: left;
      display: inline-block;
      width: calc(100% - 471px);
    }

    .statictexttwo {
      text-align: left;
      display: inline-block;
      width: calc(100% - 422px);
    }

    .leftcard {
      display: inline-block;
      margin-right: 32px;
      vertical-align: top;
      width: calc(100% - 440px);
    }

    .rightcard {
      display: inline-block;
      width: calc(100% - 427px);
    }
  }

  @media (max-width: 1200px) {
    .statictextone {
      text-align: left;
      display: inline-block;
      width: calc(100% - 389px);
    }

    .statictexttwo {
      text-align: left;
      display: inline-block;
      width: calc(100% - 357px);
    }
  }

  @media (max-width: 991px) {
    .mobileCurrDropdown{
      .filterfiatcurrency .dropdown{
        padding: 4px 2px;
        width: 49px;
        height: 33px;
        border-radius: 4px;
        margin-left: 8px;
        font-size: 10px;
        color: var(--textdark);
        opacity: 1;
        border: none;
        box-shadow: 0px 2px 4px var(--borderlight);
        border: 0.2px solid var(--borderlight);
        .filtertext{
          font-size: 10px;
          margin: 0px;
        }
        .drpicon{
          margin-left: 2px !important;
          path{
            fill: var(--textlight);
          }
        }
      }
      .dropdown-menu{
        transform: translate3d(0px, 32px, 0px) !important;
      }
    }
    .mobileDateDropdown{
      .dropdown{
        padding: 4px 2px;
        width: 49px;
        height: 33px;
        border-radius: 4px;
        margin-left: 8px;
        font-size: 10px;
        color: var(--textdark);
        opacity: 1;
        border: none;
        box-shadow: 0px 2px 4px var(--borderlight);
        border: 0.2px solid var(--borderlight);
      }
      .filtericon{
        width: unset;
        margin: 0px !important;
      }
      .calendericon{
        width: 15px;
        path{
          fill: var(--textlight);
        }
      }
      .drpicon{
        margin-left: 3px !important;
        path{
          fill: var(--textlight);
        }
      }
    }
    .rightside {
      text-align: left;
      display: inline-block;
      vertical-align: top;
      width: 100%;
      margin-top: 20px;
    }
    .tokensection {
      border: none;
    }
    .hrline {
      display: none;
    }
    .statictextsection {
      display: block;
    }
    .currpaircontent {
      padding-left: 2px;
      padding-right: 2px;
    }
    .seconddropdown {
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
    }

    .statictextone {
      text-align: left;
      display: inline-block;
      width: 100%;
      height: 30px;
    }

    .statictexttwo {
      text-align: left;
      display: inline-block;
      width: 100%;
      margin-top: 0px;
      height: 30px;
    }

    .statictextthree {
      text-align: left;
      display: inline-block;
    }

    .staticlinepartone {
      text-align: left;
      display: inline-block;
      width: 100%;
    }
    .grapharea {
      margin-top: 0px;
    }
    // ***********************
    .tokensection {
      border: none;
    }
    .toptitle {
      margin-left: 0px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .currpaircontent {
      padding: 0px;
    }
    .statictextsection {
      padding-top: 23px;
    }
    .staticlineone {
      display: inline-block;
      font-weight: 400;
      font-size: 12px;
      line-height: 16.34px;
      color: var(--textlight);
    }
    .staticlinetwo {
      font-size: 12px;
      font-weight: 600;
    }
    .percenttextmob {
      margin-left: 10px;
      font-size: 10px;
      font-weight: 600;
    }
 
  }
}
