@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
:root {
  //important: opacities will be only used when color is given to text.
  //for background and border  opacities will not work and never use opacity value less than 50%.
  //if no color matching without that then we need to ad that color

  //primary color- used in selective random places with diffrent opacities
  /*primary is brand color.It is used in multiple specific places
  like active link color, button color, textcolor and backgrounds> the color is used in
  different opacities for text 40%, 80% and so on*/
  --primary: #8800c7; //in dark theme #8800c7

  //this primary dark is for some buttons on hover or other onhover effects
  --primarydark: #C69BCD; //in dark theme #C69BCD
  --primarylight2: #941bcc;

  --primarylight: #f6e4ff; //in dark theme it will be black #000000

  //light grey is used for backgrounds in most places and another is pure white
  --bglightgrey: #f5f5f4; //in dark #0F0F0F
  --bgwhite: #ffffff; //in dark #0D0D0D

  //As border color darkness cannot bechanged using opacity there are 3 colors for it
  //borderlight- used in 90% places for all borders of boxes and containers or such divs.
  --borderlight: #dedede; //in dark #383838

  //litle darker than light used in hr tags which separates things, txtbox borders and such borders of smaller elements
  --borderdark: #c1c1c1; //in dark #C1C1C1

  //almost equal to black and used toshow active textbox borders orsuch things where dark black color needed
  --borderdarker: #383838; //in dark #DEDEDE
  //Textcolors are only 2 as these can be made faint or dark using opacity
  //textdarker is raarely used in plaform maybe 1 or 2 places. if figma is not showing same hex value don't use this color
  --textdarker: #000000; //in dark #ffffff

  //textdark is used in almost all places with different opacities. any other colors which looks like black use this  color there with matching opacity
  --textdark: #212121; //#E8E8E8

  //while matching above color if you go below 50% opacity then use below color.
  --textlight: #7a7a7a; //#A4A4A4

  //only for white text which is on top of colored background like purple tab or purple button
  --textwhite: #ffffff;

  --borderdarkblue: #ffffff; //in dark #8800c7;

  --bgfaintwhite: #ffffff; //in dark mode  #151515
  --bgdarkblack: #212121; //in dark mode #212121
  --tbbackground: #f5f5f5; // in dark #202020
  --shadow: #212121; //n dark #FFFFFF

  //below colors are constant and will not be in database
  --togglebgcolor: #565656;
  --green: #3ab67a;
  --red: #e22222;
  --yellow: #f7ce34;
  --blakish: #212121;
  --darkgrey: #383838;
  --white: #ffffff;
  --black: #000000;

  --piechartblue: #066eff;
  --piechartpurpledark: #511290;
  --piechartpurplelight: #8975d0;
  --piechartgreen: #88c652;
  --piechartprimary: #017fda;
  --piechartgreenlight: #05ba9d;
  --piechartyellow: #dac401;
  --piechartred: #d31335;
}

html {
  height: auto;
  width: 100%;
}
body,
#root {
  margin: 0;
  padding: 0;
  background-color: var(--bgwhite);
  font-family: Open Sans;
  width: 100%;
  height: auto;
  // padding-bottom: 20px;
  text-align: center;
  box-sizing: border-box;
  color: var(--textdark);
}
.react-tel-input .form-control {
  position: relative;
  font-size: 12px !important;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  height: 45px !important;
  margin-left: 0;
  background: var(--bgwhite) !important;
  mix-blend-mode: normal;
  border-radius: 0px !important;
  line-height: 25px;
  height: 35px;
  width: 100% !important;
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  border-left: unset !important;
  border-top: unset !important;
  border-right: unset !important;
  background: var(--bgwhite);
  border-bottom: 1px solid var(--borderdarker);
  color: var(--textdark);
}
.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: var(--bgwhite) !important;
  margin: 3px !important;
  border: 2px solid --var(--bgwhite) !important;
  border-right: 0px --var(--bgwhite) !important;
  text-align: left;
  color: var(--textdark) !important;
}
.react-tel-input .form-control:focus {
  border-color: var(--borderdarker);
  box-shadow: none;
  margin: none;
}
.form-control:focus {
  color: unset;
  background-color: unset;
  border-color: unset;
  outline: none;
  box-shadow: none;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: var(--borderdark);
}
.App {
  text-align: center;
}
.pointer {
  cursor: pointer;
}
.cm {
  color: var(--textdark);
}
.dropdown-item {
  color: var(--textdark);
  font-size: 13px;
}
.dropdown-item.active,
.dropdown-item:active {
  color: var(--textdark);
  background-color: var(--bglightgrey);
  text-decoration: none;
}
.dropdown-item.hover,
.dropdown-item:hover {
  color: var(--textdark);
  background-color: var(--bglightgrey);
  text-decoration: none;
}
.dropdown-menu {
  background-color: var(--bgwhite);
}

.paginationsection {
  margin-top: 20px;
  text-align: left;
}
.paginationtxtholer {
  display: inline-block;
}
.noofpagetxt {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: var(--textdarker);
  opacity: 0.84;
}
.paginationallignment {
  display: inline-block;
  float: right;
}
.pagination > li {
  display: inline;
  margin-left: 6px;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  margin-left: -1px;
  font-size: 16px;
  font-weight: 600;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border: 1px solid var(--borderlight);
  box-sizing: border-box;
  border-radius: 4px;
  color: var(--textdarker);
  font-weight: 900;
  margin-right: 50px;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  svg {
    path {
      fill: var(--textdarker) !important;
    }
  }
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border: 1px solid var(--borderlight);
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 800;
  color: var(--textdark);
  margin-left: 50px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  svg {
    path {
      fill: var(--textdarker) !important;
    }
  }
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 3;
  background: var(--bgwhite);
  border: 1px solid var(--borderlight);
  box-sizing: border-box;
  box-shadow: 0px 1px 10px var(--borderlight);
  border-radius: 4px;
  cursor: pointer;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  border: 1px solid var(--borderlight);
  box-sizing: border-box;
  border-radius: 4px;
  cursor: not-allowed;
}
.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 20px;
  line-height: 1.3333333;
}
.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}
.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}
.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.5;
}
.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.paginationicon {
  path {
    fill: var(--textdark);
  }
}
.table {
  margin-bottom: 0px;
}
.closebtn {
  position: absolute;
  right: 15px;
  top: 21px;
  cursor: pointer;
  width: 14px;
  height: 14px;
  path {
    fill: var(--textdark);
    opacity: 0.87;
  }
}

.react-tel-input .selected-flag .flag .arrow {
  color: var(--textdark);
  border-top: 4px solid var(--textdark);
}
.react-tel-input .flag-dropdown.open,
.react-tel-input .flag-dropdown.open .selected-flag,
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background: var(--bglightgrey) !important;
}

.react-tel-input .country-list .country:hover {
  background-color: var(--bglightgrey);
}
.react-tel-input .country-list,
.react-tel-input .country-list .country.highlight,
.react-tel-input .country-list .search,
.react-tel-input .country-list .search-box {
  background-color: var(--bglightgrey) !important;
}

.react-tel-input .country-list {
  box-shadow: 1px 2px 5px var(--borderdark) !important;
}

.react-tel-input .country-list .country:hover {
  background-color: var(--bgwhite) !important;
  .country-name {
    color: var(--textdark) !important;
  }
}

.react-tel-input .selected-flag .flag .arrow.up {
  border-top: none;
  border-bottom: 4px solid var(--textdark);
}

.react-tel-input .country-list .search {
  top: -2px !important;
}
.react-tel-input .country-list .search-box {
  color: var(--textdark) !important;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus,
.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  color: unset;
  background-color: unset;
  border-color: unset;
  box-shadow: unset;
  border: unset;
}
#button-tooltip{
  z-index: 1000;
}
// .Toastify__progress-bar {
//   display: none;
// }

@media (max-width: 556px) {
  .pagination > li:first-child > a,
  .pagination > li:first-child > span {
    margin-right: 20px;
  }
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    margin-left: 20px;
  }
}
@media (max-width: 390px) {
  .pagination > li:first-child > a,
  .pagination > li:first-child > span {
    margin-right: 10px;
  }
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    margin-left: 10px;
  }
  .pagination > li > a,
  .pagination > li > span {
    padding: 3px 10px;
  }
}

@media (max-width: 992px) {
  .paginationsection {
    display: none;
  }
}

.daterangepicker {
  color: var(--textdark);
  background-color: var(--bgwhite);
}
.daterangepicker .calendar-table table {
  color: var(--textdark);
  background-color: var(--bgfaintwhite);
}
.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background-color: transparent;
  border-color: transparent;
  color: #999;
}
.daterangepicker td.in-range {
  color: var(--textdark);
  background-color: var(--bgfaintwhite);
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--primary);
}
.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: var(--primary);
  border-color: transparent;
  color: var(--white);
}
.daterangepicker:after {
  border-bottom: 6px solid var(--textlight);
}
.daterangepicker .drp-buttons .btn {
  background-color: transparent;
  border: 1px solid var(--primary);
  border-color: var(--primary);
  color: var(--texrdark);
  border-radius: 4px;
}
.daterangepicker .calendar-table {
  background-color: var(--bgwhite);
}
.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  border: solid var(--textdarker);
  color: #fff;

  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}
.daterangepicker .drp-calendar.left .calendar-table {
  border: 1px solid var(--borderdark);
}
.daterangepicker .drp-calendar.right .calendar-table {
  border: 1px solid var(--borderdark);
}
@media (min-width: 564px) {
  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: 1px solid var(--borderdark);
  }
  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: 1px solid var(--borderdark);
  }
}
@media (max-width: 564px) {
  .daterangepicker .drp-selected {
    padding-right: 0px;
  }
  .daterangepicker .drp-calendar.right {
    padding: 0px 0px 8px 8px;
  }
}
.downarrow {
  margin-top: 8px;
  margin-left: -12px;
  cursor: pointer;
}
.uparrow {
  margin-top: -10px;
  margin-left: 8px;
  cursor: pointer;
}
.sorticon {
  margin-left: 0px;
}

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}


.daterangepicker.opensright:after {
  display: none;
}