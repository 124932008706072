.exchangeconfirmationpopup {
  .modal-body {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }
  .exchangetext {
   
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    opacity: 0.54;
   
  }
  .exchangeamt {
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    opacity: 0.54;
    margin-top: 5px;
    margin-bottom: 20px;
  }
  .exchangetitle {
    font-size: 20px;
    font-weight: 600;
    margin-left: 12px;
    margin-top: 0px;
    margin-bottom: 25px;
  }
  .confirm {
    .buttonmain {
      height: 39px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 16px;
      width: 215px;
      margin: 0 auto;
    }
  }
  .cancel {
    .buttonmain {
      background-color: var(--bglightgrey);
      border: 1px solid var(--borderlight);
      color: var(--textdark);
      height: 39px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 16px;
      width: 215px;
      margin: 0 auto;
    }
  }
  @media (max-width: 992px) {
    .modal-dialog {
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
    }

    .modal-content {
      width: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
    .confirm {
      .buttonmain {
        width: 154px;
      }
    }
    .cancel {
      .buttonmain {
        width: 154px;
      }
    }
    .hideclose {
      display: none;
    }
    .rightnav {
    //   height: 56px;
    //   width: 41px;
    //   background-color: var(--primary);
    //   border-top-left-radius: 10px;
    //   border-bottom-left-radius: 10px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   position: absolute;
    //   right: 0px;
    //   top: 45px;
     }

     .closebtn{
       margin-top: 20px;
       margin-right: 10px;
     }
    .exchangetitle {
      //margin-top: 10px;
    }
  }

  @media (max-width: 500px) {
    .confirm {
      .buttonmain {
        width: 140px;
      }
    }
    .cancel {
      .buttonmain {
        width: 140px;
      }
    }
  }
  @media (min-width: 992px) {
.topright{
  display: none;
}
  }
}
