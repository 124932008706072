.MainDashboard {
  .align-left {
    text-align: left;
    margin-top: 30px;
  }
  .topspace {
    margin-top: 25px;
    position : relative;
  }
  hr {
    width: 110%;
    margin-left: -30px;
    color: var(--borderdarker);
    margin-top: 0px;
  }
  .outercointainer {
    width: 100%;
    display: inline-block;
    position: relative;
  }
  .buttontopspace {
    position: absolute;
    right: 0%;
    top: 0%;
  }
  .searchbox {
    max-width: 374px !important;
    margin-left: 2px;
    padding: 8px 10px;
  }
  .searcharea .searchinside .pointer {
    margin-top: 5px;
  }
  .searchMobileFloatright {
    float: right;
    position: absolute;
    right: 0px;
    margin-top: 30px;
    width: 35%;
    .searchbox {
     height: 34px;
     padding: 4px 10px;
    opacity: none;
    }
    
  }
  .searchicon {
    margin-top: 7px;
    height: 20px;
    width: 20px;
    path {
      stroke: var(--textlight);
    }
  }
  .CustomButton {
    .nonfilledbtn {
      width: 153px;
      height: 34px;
      border-radius: 4px;
    }
  }
  .mobileaddbtn {
    display: flex;
    align-items: center;
    margin-top: 10px;
    .CustomButton {
      position: relative;
      .nonfilledbtn {
        position: absolute;
        border-radius: 4px;
        width: 150px;
        height: 32px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  @media (max-width: 992px) {
    .mobileaddbtn {
      margin-bottom: 88px;
      .CustomButton {
        .nonfilledbtn {
          width: 102px;
          height: fit-content;
          line-height: 14px;
        }
      }
    }

    @media (max-width: 768px){
      .CurrencyTab .nav-item {
      width: 80px;
      }
    }
   
 
  }
  @media (max-width: 500px) {
    .buttontopspace {
      position: relative;
      margin-top: 20px;
      width: 100px;
    }
    .cointable .innerTd.rotate180deg {
      padding-right: 10px;
    }


  }
  @media (max-width: 410px) {
    .mobileaddbtn {
      //margin-top: 50px;
      //margin-bottom: 120px;
    }
    .searchMobileFloatright {
      width: 40%;
    .searchbox {
      width: 135px;
      margin-left: -8px;
      
      
    }
  }
  }
}
