.idobuypopup {
  .modal-dialog {
    max-width: 600px;
  }
  .modal-body {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 120px;
    padding-right: 120px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 5px;
  }
  .modal-header {
    box-shadow: 0 4px 6px -6px var(--borderlight);
  }
  .icons {
    width: 20px;
    height: 25px;
    margin-right: 10px;
  }
  .buytitle {
    font-size: 16px;
    font-weight: 600;
    color: var(--textdarker);
    margin-left: 25px;
  }
  .btext {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: var(--textdark);
    margin-top: 20px;
  }
  .buy {
    .buttonmain {
      height: 40px;
      width: 154px;
      border-radius: 2px;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .bottomtext {
    font-size: 10px;
    line-height: 15px;
    font-weight: 400;
    color: var(--textlight);
    margin-top: 8px;
  }
  .buytext {
    font-size: 12px;
    font-weight: 400;
    color: var(--textdark);
    text-align: left;
  }
  .dropdownlink {
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid var(--borderdark);
    padding: 5px;
  }
  .input.form-control.input {
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid var(--borderdark);
  }
  .txtmargin {
    margin-top: 20px;
  }
  .buytxtalign {
    text-align: left;
    margin-top: 10px;
    margin-left: 5px;
  }

  .modalPrimaryHeader {
    height: 29px;
    background-color: var(--primary);
  }
  .errorstyles {
    margin-bottom: 0px;
    color: var(--red);
  }

  .dropdown-menu {
    overflow-y: scroll;
    width: 100%;
    margin-top: -4px;
    border-top: none;
    background-color: var(--bgwhite);
    border: 1px solid var(--borderdark);
  }
  .ok-btn {
    margin: 0 auto;
    max-width: 40%;
  }
  @media (max-width: 700px) {
    .modal-body {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  @media screen and (max-width: 992px) {
    .ok-btn {
      margin: 0 auto;
    }
    .mbspace {
      margin-top: 30px;
    }
    .back {
      height: 56px;
      width: 97px;
      background-color: var(--primary);
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0px;
      top: 15px;
    }
    .buytext {
      display: none;
    }
    .closebtn {
      display: none;
    }
    .bottomtext {
      font-size: 15px;
    }
    .modal-dialog {
      max-width: 100%;
      width: 100%;
      height: 100%;
      margin: 0 auto;
    }
    .modalhead {
      box-shadow: none;
      border: none;
    }
    .modal-title {
      display: none;
    }
    .modal-content {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
    .topspace {
      margin-top: 62px;
    }
    .subtitle {
      font-size: 12px;
      font-weight: 400;
      color: var(--textdark);
      opacity: 0.87;
      text-align: left;
    }
    .maintitle {
      font-size: 16px;
      font-weight: 600;
      color: var(--textdark);
      opacity: 0.95;
      text-align: left;
      margin-top: 0px;
    }
  }

  @media screen and (min-width: 992px) {
    .hidden-for-desktop {
      display: none;
    }
    .modalPrimaryHeader {
      max-width: 100%;
      margin-bottom: 5%;
      margin-top: -1px;
    }
  }
}
