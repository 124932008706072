#marketrates {
  padding: 0px 5%;
  text-align: left;
  .containertp {
    margin-top: 33px;
    padding: 70px;
  }
  .trasactiontitle {
    color: var(--textdarker);
    font-weight: 700;
    font-size: 21px;
    line-height: 25px;
    text-align: left;
  }
  .trouter {
    width: 100%;
    background: var(--bgwhite);
    border-radius: 2px;
    padding-bottom: 30px;
    margin-bottom: 20px;
  }
  .marketratesheadwrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .title {
    font-size: 32px;
    display: inline-block;
    font-weight: 600;
  }
  .roundedbordered {
    border-radius: 4px;
    height: 34px;
    width: max-content;
    color: var(--primary);
    font-size: 16px;
    font-weight: 600;
    box-shadow: 2px 0px 3px var(--borderlight);
    border: 0.5px solid var(--borderlight);
  }
  .topspacese {
    // display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 52px;
  }
  .searchbox {
    max-width: 18rem;
    margin-left: 0px;
    height: 35px;
  }
  .innerinputfield {
    margin-left: 1px;
    margin-bottom: 3px;
  }

  .searcharea .innerinputfield {
    font-size: 15px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    opacity: 1;
    //color: var(--textlight);
    border: none;
    background: none;
    position: absolute;
    color: var(--textdark);
    &::placeholder {
      color: var(--textdark);
      opacity: 0.12;
    }
  }
  .modalPrimaryHeader {
    max-width: 100%;
    margin-bottom: 5%;
    margin-top: -1px;
  }
  .topspace {
    margin-top: 10px;
  }
  .coinlistscrollable {
    overflow-x: hidden;
    overflow-y: visible;
    max-height: 550px;
    height: 600px;
  }
  .innerinputfield {
    margin-left: 1px;
  }
  .searchbox {
    max-width: 415px;
    border-radius: 4px;
    padding: 5px 15px;
  }
  @media (max-width: 992px) {
    .trouter {
      padding-bottom: 0px;
      margin-bottom: 15px;
    }
    .roundedbordered {
      box-shadow: 0px 1px 4px var(--borderlight);
    }
    .topspacese {
      margin-bottom: 30px;
    }
    .searchbox {
      width: 227px;
      max-width: 227px;
      max-height: 33px;
      border-radius: 4px;
      padding-left: 10px;
      .searchinside svg {
        path {
          fill: var(--textdark);
          opacity: 0.12;
        }
      }
    }
     .innerinputfield {
      margin-left: -1px;
      max-width: 690px;
      font-size: 14px;
      font-weight: 600;
      margin-top: -3px;
      opacity: 1;

      &::placeholder {
        color: var(--textdark);
        opacity: 0.12;
      }
    }
    .title {
      // margin-top:-18%;
      padding-left: 0px;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
    .searcharea .innerinputfield  {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      width: 70%;
    }
    .add-btn {
      line-height: 25px;
    }
    .roundedbordered {
      border-radius: 4px;
      height: 34px;
      width: max-content;
      color: var(--primary);
      font-size: 16px;
      font-weight: 600;
    }
    .lessfontweight {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
  @media (max-width: 400px) {
    .searchbox {
      width: 180px;
    }
    .searcharea .innerinputfield{
      width: 66%;
    }
  }
}
@media (max-width: 556px) {
  #marketrates {
    padding: 0px 10px;
  }
}
