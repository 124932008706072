#bityscreen {
  padding: 0px 5%;
  .containerallignm {
    margin-top: 33px;
  }
  .topspace {
    margin-top: 30px;
  }
  .headtext{
    color: var(--textdark);
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
    text-align: left;
    margin-right: 20px;
  }
  .innerinputfield {
    font-size: 15px;
    width: 100%;
    max-width: 357px;
    border: none;
    background: none;
    margin-left: 0;
    margin-bottom: 0;
    color: var(--textdark);
    opacity: 0.6;
  }
  .form-check-input:not(:checked) {
    background-color: var(--bgwhite);
  }
  @media screen and (max-width: 400px) {
  .innerinputfield {
    margin-left: -2px;
  }
}
}
@media (max-width: 556px){
  #DashboardScreen{
    padding: 0px 10px;
  }
}
