#AdminCryptoCards {

    .table > :not(caption) > * > * {
      padding-top: 4px;
      padding-bottom: 4px;
    }
    tbody:before {
      content: "-";
      display: block;
      line-height: 7px;
      color: transparent;
    }
    .table-responsive-sm {
      overflow-x: auto;
      // border: 1px solid var(--borderlight);
      min-height: 450px;
      padding-top: 30px;
    }
    .arrow {
      margin-right: 10px;
      path {
        fill: var(--textdark);
      }
    }
    .align-coin {
      margin-top: 15px;
    }
    .merchant {
      margin-top: 15px;
    }
    .btnwithimg {
      border-radius: 4px;
    }
    .containertp {
      margin: 33px auto;
      padding: 0 70px;
    }
    .maintdwrapper {
      position: relative;
    }
    .transfertbl {
      position: relative;
      width: 100%;
      background: var(--bgwhite);
      border: var(--borderdark);
      border-radius: 2px;
    }
    .innerTdforprofile {
      padding: 9px;
      border-radius: 0.1px;
      border: 1px solid var(--borderlight);
      border-left: none;
      border-right: none;
      height: 70px;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
    }
    .tableindark {
      .innerTdforprofile,
      .innerTd,
      tbody tr td:first-child .innerTd,
      tbody tr td:last-child .innerTd {
        border-color: var(--borderdarkblue);
      }
    }
    .colwidth {
      width: 280px;
    }
    thead tr {
      border-bottom: 2px solid var(--borderlight);
      background-color: var(--primarylight);
    }
    tr {
      border-color: var(--borderlight);
    }
    // tr:hover {
    //   background-color: var(--bglightgrey);
    // }
    table {
      border-color: var(--borderdark);
    }
    td {
      img {
        margin-top: 3px;
      }
    }
    .imgstatus {
      width: 33px;
      height: 33px;
    }
    td,
    th {
      border: none;
    }
    .pageTitle {
      text-align: left;
      padding-left: 3px;
    }
    .trouter {
      height: 65px;
      width: 100%;
      background: var(--bgwhite);
      border: var(--borderdark);
      border-radius: 2px;
    }
    .title {
      color: var(--textdarker);
      font-weight: 600;
      font-size: 32px;
      line-height: 67px;
      float: left;
    }
    .coinlistarea {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
    }
    .rightsection {
      text-align: left;
      width: 100%;
      margin-bottom: 40px;
    }
    .actions {
      //   width: 80%;
      height: auto;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      // margin-bottom: 20px;
    }
  
    .trtbleheading {
      color: var(--shadow);
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      vertical-align: middle;
      height: 68px;
      white-space: nowrap;
    }
    .heading {
      opacity: 0.54;
    }
 
  
    .mr-0 {
      margin-right: 0px;
    }
   
    .amt {
      font-weight: 600;
      font-size: 16px;
      color: var(--textdark);
      opacity: 0.87;
    }
   
    .id {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0em;
    }
    .amount {
      font-weight: 600;
      font-size: 12px;
      line-height: 40px;
      color: var(--textdark);
    }
  
    .coinlist img.coinimg {
      margin-top: -8px;
    }
   
    .pagination {
      justify-content: flex-end;
    }
    .arrowiconclass {
      path {
        fill: var(--textdark);
        stroke: var(--textdark);
      }
    }
    
    .paginationicon {
      path {
        fill: var(--textdark);
      }
    }
    
    .table {
      width: 100%;
      background: var(--bgwhite);
      border: var(--borderdark);
      border-radius: 6px;
      border-collapse: separate;
      border-spacing: 0px;
    }
    .innerTd {
      padding: 9px;
      border-radius: 0.1px;
      border: 1px solid var(--borderlight);
      border-left: none;
      border-right: none;
      height: 70px;
      text-align: center;
      display: flex;
      align-items: unset;
      justify-content: center;
      flex-direction: column;
    }
    tbody tr td:first-child .innerTd {
      padding-left: 15px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      border-left: 1px solid var(--borderlight);
    }
    tbody tr td:last-child .innerTd {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border-right: 1px solid var(--borderlight);
    }
    .colspace.actions {
      line-height: 39px;
    }
    .table > :not(:last-child) > :last-child > * {
      border-bottom-color: var(--borderlight);
    }
    thead tr th {
      border: 0.5px solid var(--borderlight);
      border-left: none;
      border-right: none;
      border-top-right-radius: 0.1px;
      border-top-left-radius: 0.1px;
      background-color: var(--bglightgrey);
    }
    thead tr th:first-child {
      padding-left: 15px;
      border-left: 1px solid var(--borderlight);
      border-top-left-radius: 6px;
    }
    thead tr th:last-child {
      border-right: 1px solid var(--borderlight);
      border-top-right-radius: 6px;
    }
  
    table {
      border-color: var(--borderdark);
    }
    td {
      border: none;
      padding-left: 0px;
      padding-right: 0px;
    }
    th {
      border: none;
      text-align: center;
    }
    .table-responsive-sm {
      overflow-y: hidden;
      overflow-x: auto;
      width: 100%;
      // border: 1px solid var(--borderlight);
    }
  
    @media (max-width: 450px) {
     
      .table-responsive-sm {
        // overflow-x: auto;
        width: 100%;
      }
  }
    @media (max-width: 600px) {
      .innerTd {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 10px;
      }
    }
    
    @media screen and (max-width: 991px) {
      .containertp{
        padding: 0 20px;
      }
      .hidden-for-mobile {
        display: none;
      }
      
      .align-coin {
        margin-top: 0px;
      }
      .trouter{
        height: unset;
        line-height: 28px;
      }
      .title {
        font-size: 16px;
        line-height: 28px;
      }
     
      .amttxt {
        margin-right: 0px;
        margin-top: 20px;
      }
     
      .coin-for-mobile {
        margin-left: -3px;
      }
  
      .id {
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
      }
  
      .numbers {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        opacity: 0.87;
      }
      tbody tr td:first-child .innerTd {
        padding-left: 10px;
        padding-right: 5px;
      }
      .innerTd {
        padding-top: 10px;
      }
      .amt {
        font-size: 12px;
      }
      .date{
        opacity: 0.54;
        text-align: left;
        margin-bottom: 0px;
      }
    }
    @media screen and (min-width: 992px) {
      .hidden-for-desktop {
        display: none;
      }
    }
    @media screen and (min-width: 992px) and (max-width: 2000px) {
      .idtr {
        min-width: 138px;
      }
    }
  }
  