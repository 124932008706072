.aboutcybor {
  height: 100%;
  background-color: var(--bgwhite);
  border: 0.5px solid var(--borderlight);
  border-radius: 6px;
  .iconwithtextinabout {
    text-align: left;
    padding-left: 85px;
    width: 100%;
    height: 82px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--borderlight);
  }
  .logotexttochange{
    fill: var(--textdarker);
  }
  .graph {
    width: 100%;
    height: 100%;
  }
  .idobtns a{
    display: contents;
  }
  .abouttext {
    display: inline-block;
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;
    color: var(--textdarker);
    opacity: 0.8;
  }

  .aboutcyborcontent {
    padding: 30px 20px;
  }

  .imagearea {
    width: 240px;
    height: 85px;
    text-align: center;
    margin: 0 auto;
  }

  .exclamation {
    width: 100%;
    height: 100%;
  }
  .textarea {
    text-align: center;
    padding-top: 30px;
  }

  .title {
    font-weight: 400;
    font-size: 13px;
    line-height: 16.19px;
    color: var(--textdark);
  }

  .para {
    font-weight: 400;
    font-size: 11px;
    text-align: justify;
    padding-top: 20px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 20px;
    line-height: 16.55px;
    text-transform: capitalize;
    color: var(--textdark);
    opacity: 0.54;
  }

  .idoWhiteBtns .CustomButton .nonfilledbtn {
    max-width: 271px;
    height: 60px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    line-height: 14px;
    box-shadow: -3px 4px 4px var(--borderlight);
    border: 1px solid var(--borderdarkblue);
  }
  .idoWhiteBtns {
    width: 100%;
    .CustomButton {
      flex: 1;
      margin: 0px 5px;
    }
  }
  .idoBlueBtns {
    .CustomButton {
      width: 100%;
      margin: 0px 5px;
      .buttonmain {
        line-height: 16px;
        height: 60px;
        max-width: 271px;
        height: 60px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  @media (max-width: 992px) {
    .aboutcybor {
      width: 100%;
      height: 100%;
      background-color: var(--bgwhite);
      border: 1px solid var(--borderlight);
    }
    .title {
      font-weight: 500;
      padding: 10px;
      font-size: 16px;
      line-height: 124.5%;
      text-transform: capitalize;
      color: var(--textdarker);
    }
    .para{
      padding-left: 0px;
      padding-right: 0px;
    }
    .iconwithtextinabout{
      padding-left: 20px !important;
      height: 60px !important;
    }
    .idoWhiteBtns .CustomButton .nonfilledbtn{
      font-size: 12px;
    }
    .idoBlueBtns .CustomButton .buttonmain {
      font-size: 12px;
    }
  }
}
