  .themeswitchwrapper{
    margin-right: 20px;
    margin-top: 3px;
  .uncheckedHandleIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 20px;
    margin-left: 10px;
    .darkmode path{
      fill: var(--textwhite);
      fill-opacity: 1 !important;
    }
    .lightmode path{
      fill: var(--textwhite);
      fill-opacity: 1;
    }
  }
  .checkedHandleIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    // margin-left: 10px;
    transform: translateX(5px);
    font-size: 23px;
    position: absolute;
    left: 4px;
    .darkmode path{
      fill: var(--textwhite);
      fill-opacity: 1 !important;
    }
    .lightmode path{
      fill: var(--textwhite);
      fill-opacity: 1;
    }
  }
  .checkedIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 20px;
    
  }
  // .darkmode path{
  //   fill: var(--textwhite);
  //   fill-opacity: 1 !important;
  // }
  .uncheckedIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 20px;
    path{
      fill: var(--white)
    }
  }
  // .lightmode path{
  //   fill: var(--textwhite);
  //   fill-opacity: 1;
  // }
  .react-switch-handle{
    width: 35px !important;
    border-radius: 7px !important;
    background-color: var(--primary) !important;
    border-radius: 6px !important;
  }
  .react-switch-bg{
    background: var(--borderlight) !important;
    opacity: 0.8;
  }
  .toogleon .react-switch-handle{
    transform: translateX(31px) !important;
  }
}