#CryptoCard {
    padding: 0px 5%;
    margin-bottom: 30px;

    .top-btn {
        text-align: left;

        .buttonmain {
            font-weight: 600;
            font-size: 16px;
            width: 208px;
            height: 34px;
            margin: 0px;
            text-align: center;
            border: none;
            border-radius: 4px;
            color: var(--textwhite);
            background-color: var(--primary);
        }
    }
}