.cointable {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  text-align: left;
  .completed {
    color: var(--green);
  }
  .cancel {
    color: var(--red);
  }
  .zero {
    color: white;
  }
  .rotate180deg {
    transform: rotate(180deg) rotateY(180deg);
  }
  .tableheading {
    color: var(--textdark);
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    padding: 5px;
    opacity: 0.54;
  }
  td span {
    line-height: 50px;
  }
  .coincol {
    width: 215px;
  }
  .coingraphsection {
    flex: 1 1 30%;
    min-width: 100%;
    height: 50px;
    width: 90px;
  }
  img.coinimg {
    margin-top: 8px;
    margin-left: -1px;
  }
  .redgraph svg g path {
    stroke: var(--red);
    stroke-width: 1;
  }
  .redgraph svg g g path {
    stroke: none;
    stroke-width: 0;
    fill: var(--red);
    opacity: 0.3;
  }
  .greengraph svg g path {
    stroke: var(--green);
    stroke-width: 1;
  }
  .greengraph svg g g path {
    stroke: none;
    stroke-width: 0;
    fill: var(--green);
    opacity: 0.3;
  }
  .tablestyle {
    width: 100%;
    background: var(--bgwhite);
    border: var(--borderdark);
    border-radius: 6px;
    border-collapse: separate;
    border-spacing: 0px;
  }
  .balance {
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    color: var(--textdark);
    line-height: 50px;
    // margin-top: 2px;
  }
  .maintdwrapper {
    position: relative;
  }
  .tokentagwrapper {
    position: absolute;
    top: -5px;
    left: 4px;
  }
  .tokentag {
    font-weight: 600;
    font-size: 9px;
    text-align: center;
    color: var(--textdark);
    background: var(--bgwhite);
    box-shadow: 0px 0px 2px var(--borderdark), 0px 1px 2px var(--borderdark);
    border-radius: 2px;
    height: 13px;
    padding: 0px 3px;
  }
  .price {
    font-weight: 600;
    color: var(--textdark);
    font-size: 16px;
    line-height: 50px;
    text-align: left;
  }
  .change {
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    line-height: 50px;
  }
  .colspace {
    padding: 0px;
    padding-top: 13px;
  }
  .colspace1 {
    padding: 0px;
    padding-top: 13px;
    width: 80px;
    line-height: 40px;
  }
  .innerTd {
    padding: 9px;
    border-radius: 0.1px;
    border: 1px solid var(--borderlight);
    border-left: none;
    border-right: none;
    height: 70px;
  }
  tbody:before {
    content: "-";
    display: block;
    line-height: 7px;
    color: transparent;
  }
  tbody tr td:first-child .innerTd {
    padding-left: 45px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-left: 1px solid var(--borderlight);
  }
  tbody tr td:last-child .innerTd {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-right: 1px solid var(--borderlight);
  }
  .colspace.actions {
    line-height: 39px;
  }
  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: var(--borderlight);
  }
  thead tr th {
    border: 0.5px solid var(--borderlight);
    border-left: none;
    border-right: none;
    border-top-right-radius: 0.1px;
    border-top-left-radius: 0.1px;
    background-color: var(--bglightgrey);
  }
  .table > :not(caption) > * > * {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  thead tr th:first-child {
    padding-left: 45px;
    border-left: 1px solid var(--borderlight);
    border-top-left-radius: 6px;
  }

  thead tr th:last-child {
    border-right: 1px solid var(--borderlight);
    border-top-right-radius: 6px;
  }
  tr {
    border-color: var(--borderlight);
  }
  table {
    border-color: var(--borderdark);
  }
  td {
    border: none;
  }
  th {
    border: none;
    text-align: left;
  }
  .coinlist {
    // min-width: 130px;
    display: inline-block;
  }
  .cointag {
    font-size: 8px;
    font-weight: 600;
    line-height: 16px;
    color: var(--textdark);
    display: inline-block;
    box-shadow: 0 2px 4px 0 var(--borderlight), 0 2px 4px 0 var(--borderlight);
    padding-right: 2px;
    padding-left: 2px;
    border-radius: 2px;
    vertical-align: middle;
    border-radius: 2px;

    text-transform: uppercase;
    margin-left: 10px;
  }
  .coincircle {
    vertical-align: middle;
  }
  .carea {
    width: 180px;
  }
  .nonfilledbtn {
    width: 95px;
  }
  .smallbuttons {
    display: flex;
    .holdercss {
      width: 39px;

      height: 39px;
    }

    .optionholder {
      margin: 5px 10px 0px 0px;
    }
    .arrowclass {
      margin-top: -5px;
    }
  }
  .activetr {
    box-shadow: 0px 3px 5px var(--borderlight);
    border-radius: 10px;
  }
  .contentinnertdstyle {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis !important;
  }
  @media (max-width: 992px) {
    .coinlist {
      display: flex;
      align-items: center;
      .coincircle {
        height: 30px;
        width: 30px;
        margin-top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .coinimg {
        margin: 0px;
        height: 20px;
      }
    }
    .colspace1.actions .innerTd {
      justify-content: flex-start;
    }
    .innerTd {
      display: flex;
      align-items: center;
    }
    thead {
      display: none;
    }
    tbody tr td:first-child .innerTd {
      padding-left: 10px;
    }
    .price .contentinnertdstyle {
      line-height: 20px;
      font-size: 14px;
      font-weight: 600;
    }
    .carea {
      width: 125px;
    }
    .chartcol {
      // width: 180px;
      img {
        width: 63px;
      }
    }
    .mobilescrbal {
      p {
        line-height: 1.4;
        margin: 0px;
        font-size: 14px;
        font-weight: 600;
        max-width: 10ch;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .mainbal {
        font-weight: 600;
        color: var(--textdark);
      }
      .mainsym {
        font-weight: 400;
        color: var(--textlight);
      }
    }
  }
  @media (max-width: 457px) {
    .coinlist .coinlistarea {
      .nameclass {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .symbolclass {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .mobilescrbal {
      p {
        max-width: 8ch;
      }
    }
    .carea {
      width: 110px;
    }
    .innerTd {
      padding-left: 3px;
      padding-right: 3px;
    }
    .coingraphsection {
      width: 60px;
    }
    .innerTd.rotate180deg {
      padding-left: 0px;
    }
  }

  @media screen and (min-width: 1300px) {
    .smallbuttons {
      width: 50%;
    }
  }

  @media (max-width: 390px) {
    .mobilescrbal {
      p {
        max-width: 7ch;
      }
    }
    .price .contentinnertdstyle {
      max-width: 7ch;
      white-space: nowrap;
    }
  }
}
