#bankreq {
  .table > :not(caption) > * > * {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  tbody:before {
    content: "-";
    display: block;
    line-height: 7px;
    color: transparent;
  }
  .table-responsive-sm {
    overflow-x: auto;
    // border: 1px solid var(--borderlight);
    min-height: 450px;
    padding-top: 30px;
  }
  .arrow {
    margin-right: 10px;
    path {
      fill: var(--textdark);
    }
  }
  .align-coin {
    margin-top: 15px;
  }
  .merchant {
    margin-top: 15px;
  }
  .btnwithimg {
    border-radius: 4px;
  }
  .containertp {
    margin-top: 33px;
    padding: 0 70px;
  }
  .maintdwrapper {
    position: relative;
  }
  .transfertbl {
    position: relative;
    width: 100%;
    background: var(--bgwhite);
    border: var(--borderdark);
    border-radius: 2px;
  }
  .innerTdforprofile {
    padding: 9px;
    border-radius: 0.1px;
    border: 1px solid var(--borderlight);
    border-left: none;
    border-right: none;
    height: 70px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
  }
  .tableindark {
    .innerTdforprofile,
    .innerTd,
    tbody tr td:first-child .innerTd,
    tbody tr td:last-child .innerTd {
      border-color: var(--borderdarkblue);
    }
  }
  .colwidth {
    width: 280px;
  }
  thead tr {
    border-bottom: 2px solid var(--borderlight);
    background-color: var(--primarylight);
  }
  tr {
    border-color: var(--borderlight);
  }
  // tr:hover {
  //   background-color: var(--bglightgrey);
  // }
  table {
    border-color: var(--borderdark);
  }
  td {
    img {
      margin-top: 3px;
    }
  }
  .imgstatus {
    width: 33px;
    height: 33px;
  }
  td,
  th {
    border: none;
  }
  .transactiontitle {
    text-align: left;
    padding-left: 3px;
  }
  .trouter {
    height: 65px;
    margin-bottom: 20px;
    width: 100%;
    background: var(--bgwhite);
    border: var(--borderdark);
    border-radius: 2px;
  }
  .title {
    color: var(--textdarker);
    font-weight: 600;
    font-size: 32px;
    line-height: 67px;
    float: left;
  }
  .coinlistarea {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
  .rightsection {
    text-align: left;
    width: 100%;
    margin-bottom: 40px;
  }
  .actions {
    //   width: 80%;
    height: auto;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    // margin-bottom: 20px;
  }

  .trtbleheading {
    color: var(--shadow);
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    vertical-align: middle;
    height: 68px;
    white-space: nowrap;
  }
  .heading {
    opacity: 0.54;
  }
  .filtersection {
    display: inline-block;
    float: right;
    position: relative;
    margin-top: 12px;
  }
  .buttoncontainer {
    width: 230px;
    height: 33px;
    margin-top: 4px;
  }
  .buttonmain {
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    height: 33px;
    margin: 0px;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    border-radius: 2px;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    color: var(--textwhite);
    background-color: var(--primary);
  }
  .buttoncontainersm {
    display: inline-block;
    margin-left: 0px;
    margin-right: 8px;
    width: auto;
  }
  .leftsection {
    display: inline-block;
    margin-bottom: 20px;
  }

  .thethreebutton {
    text-align: left;
    margin-left: 0px;
  }

  .mr-0 {
    margin-right: 0px;
  }
  .statustext{
    font-weight: 600;
    font-size: 16px;
    vertical-align: middle;
    color: var(--textdark);
    text-align: center;
    opacity: 0.87;
  }
  .status {
    font-weight: 600;
    font-size: 12px;
    line-height: 40px;
    padding-right: 0px;
    text-transform: uppercase;
  }
  .red {
    color: var(--red);
  }
  .green {
    color: var(--green);
  }
  .yellow {
    color: var(--yellow);
  }
  .amt {
    font-weight: 600;
    font-size: 16px;
    // vertical-align: middle;
    color: var(--textdark);
    // text-transform: capitalize;
    opacity: 0.87;
  }
  // .coinlistarea {
  //   line-height: 32px;
  //   font-size: 12px;
  //   font-weight: 400;
  // }
  .id {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
  }
  .amount {
    font-weight: 600;
    font-size: 12px;
    line-height: 40px;
    color: var(--textdark);
  }

  .coinlist img.coinimg {
    margin-top: -8px;
  }
  .coinlist {
    margin-top: 0px;
    margin-right: 16px;
    width: 115px;
    // margin-left: 20px;
    text-align: left;
  }
  .coinimg {
    height: 10px;
  }
  .coincircle {
    height: 18px;
    width: 18px;
    margin-top: 2px;
  }
  .pagination {
    justify-content: flex-end;
  }
  .arrowiconclass {
    path {
      fill: var(--textdark);
      stroke: var(--textdark);
    }
  }
  .arrowiconclass {
    width: 19px;
    height: 19px;
  }
  .statusr {
    display: inline-block;
    width: 100%;
    vertical-align: top;
  }
  .vertical {
    display: inline-block;
  }
  .threedot {
    background-color: transparent;
    padding: 0px;
    border: none;
    outline: none;
  }
  .menu {
    border-radius: 4px;
    background-color: var(--bgwhite);
    border: 1px solid var(--borderlight);
  }
  .threedot.dropdown-toggle::after {
    display: none;
  }
  .dropdown.show {
    display: inline-block;
  }
  .dropdown-item:focus,
  .dropdown-item:hover {
    text-decoration: none;
    cursor: pointer;
  }
  .mleft {
    margin-left: 3px;
  }
  .iconcolor {
    path {
      fill: var(--textdark);
    }
  }
  .iconCopy {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .paginationicon {
    path {
      fill: var(--textdark);
    }
  }
  .link {
    width: calc(100% - -3px);
    display: inline-block;
  }
  .btnwithimg {
    width: max-content;
    max-width: unset;
    height: 33px;
    line-height: 15px;
  }
  .linktxt {
    display: inline-block;
    color: var(--primary);
    text-decoration: none;
    width: 100%;
    max-width: 350px;
    word-wrap: break-word;
    text-align: justify;
  }
  .img {
    width: 17px;
    vertical-align: top;
    height: 38px;
    padding-left: 3px;
    display: inline-block;
  }

  .vertical-align {
    vertical-align: top;
    width: 17px;
    height: 24px;
    padding-left: 3px;
    display: inline-block;
  }
  .table {
    width: 100%;
    background: var(--bgwhite);
    border: var(--borderdark);
    border-radius: 6px;
    border-collapse: separate;
    border-spacing: 0px;
  }
  .innerTd {
    padding: 9px;
    border-radius: 0.1px;
    border: 1px solid var(--borderlight);
    border-left: none;
    border-right: none;
    height: 70px;
    text-align: center;
    display: flex;
    align-items: unset;
    justify-content: center;
    flex-direction: column;
  }
  tbody tr td:first-child .innerTd {
    padding-left: 15px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-left: 1px solid var(--borderlight);
  }
  tbody tr td:last-child .innerTd {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-right: 1px solid var(--borderlight);
  }
  .colspace.actions {
    line-height: 39px;
  }
  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: var(--borderlight);
  }
  thead tr th {
    border: 0.5px solid var(--borderlight);
    border-left: none;
    border-right: none;
    border-top-right-radius: 0.1px;
    border-top-left-radius: 0.1px;
    background-color: var(--bglightgrey);
  }
  thead tr th:first-child {
    padding-left: 45px;
    border-left: 1px solid var(--borderlight);
    border-top-left-radius: 6px;
  }
  thead tr th:last-child {
    border-right: 1px solid var(--borderlight);
    border-top-right-radius: 6px;
  }
  // tr {
  //   border-color: var(--borderlight);
  // }
  table {
    border-color: var(--borderdark);
  }
  td {
    border: none;
    padding-left: 0px;
    padding-right: 0px;
  }
  th {
    border: none;
    text-align: center;
  }
  .table-responsive-sm {
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
    // border: 1px solid var(--borderlight);
  }

  @media (max-width: 450px) {
    .filtersection {
      margin-bottom: 12px;
    }
    .table-responsive-sm {
      // overflow-x: auto;
      width: 100%;
    }
    .buttoncontainersm {
      display: inline-block;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
  .holderforbutton{
    margin:0 auto;
    width:202px;
  }
  .approve{
    display: inline-block;
    width: 85px;
    margin:0px 8px 0px auto;
    margin-right: 8px;
    //margin: 0 auto;
    text-align: center;
}

.reject{
  margin-right: 8px;
  display: inline-block;
  width: 85px;
  text-align: center;
  margin-left: 16px;
    
}
  @media (max-width: 600px) {
    .innerTd {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 10px;
    }
  }
  
  @media screen and (max-width: 991px) {
    .title {
      font-size: 16px;
  }
    .containertp{
      padding: 0 20px;
    }
    .hidden-for-mobile {
      display: none;
    }
    
    .align-coin {
      margin-top: 0px;
    }
    .title {
      font-size: 16px;
    }
    .cointag {
      margin-left: 30px;
    }
    .btnwithimg {
      width: max-content;
      max-width: unset;
    }
    .amttxt {
      margin-right: 0px;
      margin-top: 20px;
    }
    .idtxt {
      margin-right: 48px;
    }
   
    .coin-for-mobile {
      margin-left: -3px;
    }
    .filtersection{
      .filter .btn-primary {
        margin-top: 10px;
        height: 33px;
        width: 62px;
        margin-bottom: 10px;
        padding-left: 11px;
        padding-right: 11px;
        border: none;
        border-color: unset;
        box-shadow: 0px 1px 4px var(--borderlight);
        border-radius: 4px;
      }
      .filtericon {
        margin-top: -3px;
        width: auto;
      }
      .filtertext {
        display: none;
      }
      }
    
    .filteric {
      margin-top: -4px;
    }
    .thethreebutton {
      margin-top: 0px;
    }
    .rightsection {
      margin-bottom: 0px;
    }
    
    .coinlist {
      width: 61px;

      text-align: left;
      vertical-align: -webkit-baseline-middle;
    }

    .coinlist img.coinimg {
      margin-top: -8px;
      vertical-align: -webkit-baseline-middle;
    }
    .coinlistarea {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: center;
    }
    .arrow {
      margin-right: 10px;
      path {
        fill: var(--textdark);
      }
    }

    .id {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
    }

    .numbers {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      opacity: 0.87;
    }
    .filter .filtertext {
      display: none;
    }
    .datewidth {
      width: 130px;
    }

    .specialTd {
      padding: 0px;
    }
    .date {
      width: 130px;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      opacity: 0.54;
      margin-bottom: 0px;
    }

    tbody tr td:first-child .innerTd {
      padding-left: 10px;
      padding-right: 5px;
    }
    .innerTd {
      padding-top: 10px;
    }
    .amt {
      font-size: 12px;
    }
    .date{
      opacity: 0.54;
    }
  }
  // @media screen and (max-width: 410px) {
  //   .actions{
  //     width:80%;
  //   }
  // }
  @media (max-width: 480px) {
    .CurrencyTab .nav-link {
      font-size: 14px;
    }

    .CurrencyTab .nav-link.active {
      font-size: 14px;
    }
    .CurrencyTab .nav-item {
      width: 110px;
    }
  }
  @media screen and (min-width: 992px) {
    .hidden-for-desktop {
      display: none;
    }
  }
  @media screen and (min-width: 992px) and (max-width: 2000px) {
    .datewidth {
      width: 200px;
    }
    .idtr {
      min-width: 138px;
    }
  }

  @media (max-width: 768px) {
    .buttoncontainer {
      width: 200px;
    }
    .leftsection {
      float: unset;
      margin-top: 20px;
      margin-left: 2px;
    }
    // .thethreebutton {
    //   text-align: left;
    //   margin-top: 12px;
    //   margin-left: -7px;
    // }
    // .buttoncontainersm {
    //   display: inline-block;
    //   margin-left: 11px;
    //   margin-right: 11px;
    //   margin-top: 11px;
    //   margin-bottom: 20px;
    //   width: auto;
    // }
  }
}
