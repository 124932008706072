.SmallDropdown{
    display: inline-block;
    .smalltoggletext{
        font-size: 15px;
        font-weight: 600;
        cursor: pointer;
    }
    .dropdown-menu{
      border: 1px solid var(--borderlight);
      top: 40px;
      border-radius: 6px;
    }
    .toogleicon {
        margin-left: 10px;
        width: 12px;
        height: 8px;
        line-height: 32px;
      }
      .dropdownarrow {
        path {
          fill: var(--textdark);
        }
      }
      @media (max-width: 998px){
        .dropdown-menu{
          position: absolute !important;
        }
      }
}
