#claimido {
  .table-responsive-sm {
    overflow-x: auto;
    border: 1px solid var(--borderlight);
  }
  .containertp {
    margin-top: 33px;
    padding: 0 5%;
  }
  .transfertbl {
    width: 100%;
    background: var(--bgwhite);
    border: var(--borderdark);
    border-radius: 2px;
  }
  thead tr {
    border-bottom: 2px solid var(--borderlight);
    background-color: var(--primarylight);
  }
  tr:hover {
    background-color: var(--bglightgrey);
  }
  table {
    border-color: var(--borderdark);
  }
  td {
    img {
      margin-top: 3px;
    }
  }
  td,
  th {
    border: none;
    text-align: center;
  }

  .trasactiontitle {
    color: var(--textdark);
    opacity: 0.84;
    font-weight: 600;
    font-size: 32px;
    //line-height: 67px;
    text-align: left;
  }
  .trouter {
    //height: 65px;
    margin-bottom: 20px;
    width: 100%;
    background: var(--bgwhite);
    border: var(--borderdark);
    border-radius: 2px;
  }
  .title {
    
  }
  .trtbleheading {
    color: var(--textdark);
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .status {
    font-weight: 600;
    font-size: 12px;
    line-height: 40px;
    padding-right: 20px;
    text-transform: uppercase;
  }
  .completed {
    color: var(--green);
  }
  .cancel {
    color: var(--red);
  }
  .pending {
    color: var(--textlight);
  }
  .amt {
    font-weight: 400;
    font-size: 12px;
    vertical-align: middle;
    color: var(--textdark);
  }
  .pagination {
    justify-content: flex-end;
  }
  .colwidth {
    width: 150px;
  }
  .claimbtn {
    .buttonmain {
      opacity: 0.7;
      height: 40px;
      border-radius: 3px;
      margin-top: -2px;
    }
  }
  .claimbtnfalse {
    .buttonmain {
      opacity: 0.7;
      background-color: var(--bglightgrey);
      border: 1px solid var(--borderlight);
      color: var(--textdark);
      height: 40px;
      border-radius: 3px;
      margin-top: -2px;
    }
  }
  @media screen and (max-width:992px) {
    .trasactiontitle{
      font-size: 16px;
    }
  }
}
