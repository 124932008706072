#mybalances {
  padding: 0px 6.5% 0px 5%;
  .trasactiontitle {
    color: var(--textdark);
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 18px;
    line-height: 67px;
    text-align: left;
    // padding-left: 20px;
  }
  .trouter {
    display: flex;
    align-items: center;
  }
  .CoinListBalance.darkcoinlist{
    background: var(--bgfaintwhite);
  }
  .title {
    // margin-left: 8px;
    margin-top: 3px;
    font-size: 32px;
    font-weight: 600;
    display: inline-block;
  }
  .mybalancesheadwrapper {
    // margin-left: 8px;
  }

  .balancecontentrowwrapper {
    position: relative;
  }
  .mybalancescontent {
    border-top: none;
    .coinlistwrapper {
      padding: 0px 0px;
      margin-top: 15px;
      .coinlistitemss {
        margin: 14px;
        margin-top: 0px;
      }
    }
  }
  .coinlistmainwrapper {
    padding: 0px;
  }

  .coindetailswrapper {
    padding: 0px;
    position: relative;
    // box-shadow: 0px 3px 20px rgba();
    box-shadow: 0px 3px 20px var(--borderlight);
    border-radius: 6px;
    margin-top: 15px;
  }
  .coindetailsnav {
    display: none;
  }
  .carrencytabwrapper {
    text-align: left;
    margin-bottom: 30px;
  }
  @media (max-width: 991px) {
    .trasactiontitle {
      padding-left: 0px;
      margin-bottom: 0px;
    }
    .title {
      padding-left: 0px;
      margin-left: 0px;
    }
    .coindetailswrapper {
      display: none;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      background-color: var(--bgwhite);
    }
    .formobilewidth {
      display: block;
    }
    .trasactiontitle {
      line-height: 1;
    }
    .trouter {
      height: 80px;
    }
    .title {
      font-size: 25px;
    }
  }
  .CurrencyTab .nav {
    margin-bottom: 25px;
  }
  @media (min-width: 1385px) {
    .CoinListBalance .coinnameandsymbol {
      flex: 1 1 70%;
      max-width: 28% !important;
      padding-right: 0px;
    }
  }
  @media (min-width: 1199px) {
    .coinlistmainwrapper {
      .coinSymbolbelowname {
        white-space: nowrap;
      }
      .CoinListBalance .coinNameSymbolTag {
        flex: 1 1 60%;
      }
      .CoinListBalance .coinnameandsymbol {
        flex: 1 1 70%;
        max-width: 21%;
        padding-right: 0px;
      }
    }
  }
}
@media (max-width: 575px) {
  #mybalances {
    padding: 0px 20px;
    .title {
      font-size: 16px;
      padding-top: 0px;
    }
    .trouter {
      height: 50px;
    }
    .CurrencyTab .nav {
      width: 200px;
      .nav-item {
        width: 100px;
      }
    }
  }
}
@media (max-width: 556px) {
  #mybalances {
    padding: 0px 10px;
  }
}
