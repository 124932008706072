.CoindetailCard {
  width: 100%;
  border: 1px solid var(--borderlight);
  border-radius: 4px;
  padding: 5px;
  height: 78px;
  overflow: hidden;
  margin-bottom: 10px;
  .cardarea {
    display: inline-block;
    text-align: left;
    width: calc(100% - 90px);
    height: auto;
    vertical-align: middle;
    overflow: hidden;
  }
  .cardcircle {
    display: inline-block;
    background-color: var(--bgcardblue);
    width: 60px;
    height: 100%;
    border-radius: 4px;
    vertical-align: top;
  }
  .cardarea {
    span {
      display: block;
    }
    .coinnm {
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      text-transform: uppercase;
      color: var(--textdark);
      display: inline-block;
    }
    .cointype {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: var(--textdark);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    }
    .cointag {
      font-size: 10px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 18px;
      color: var(--textdark);
      display: inline-block;
      box-shadow: 0 2px 4px 0 var(--borderlight), 0 2px 4px 0 var(--borderlight);
      padding-left: 2px;
      padding-right: 2px;
      border-radius: 2px;
      margin-right: 2px;
      float: right;
      margin-top: 2px;
    }
    .amount {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: var(--textdark);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      cursor: pointer;
    }
  }
  .cardcircle {
    float: right;
    img {
      height: 40px;
      width: auto;
      margin-top: 55%;
      transform: translate(0, -50%);
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .cardarea{
      width: calc(100% - 70px);
    }
  }
  @media only screen and (min-width: 991px) and (max-width: 1200px) {
    .cardarea{
      width: calc(100% - 60px);

      .coinnm {
        font-size: 12px;
      }
      .cointag {
        font-size: 9px;
        margin-top: 3px;
      }
      .amount {
        font-size: 14px;
      }
    }
   
  }
  .tooltip-inner {
    max-width: 200px;
    border: 1px solid var(--borderlight);
    color: var(--textdarker);
    font-weight: 600;
    text-align: center;
    background-color: var(--bglightgrey);
  }
  
  .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before,
  .bs-tooltip-top .tooltip-arrow::before {
    top: -1px;
    border-top-color: var(--borderlight);
  }
}
