.CreatePaylinkpopup {
  .modal-dialog {
    max-width: 999px;
    width: 100%;
  }
  .modal-body {
    padding: 8px 15px 15px 8px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }
  // .topheadershadow{
  //     box-shadow: 0px 4px 15px var(--borderlight);
  // }
  .text {
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;
    color: var(--textdark);
  }
  .exchangetitle {
    font-size: 16px;
    font-weight: 700;
    margin-left: 12px;
  }
  .box {
    margin-top: 28px;
    height: auto;
    background: var(--bgwhite);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 8px;
    box-shadow: 0px 3px 20px var(--borderlight);
  }
  .valuetext1 {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
  .valuetext2 {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
  }
  .link {
    text-align: center;
    padding-bottom: 8px;
  }
  .linktxt {
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    color: var(--primary);
  }
  .label-color {
    color: var(--textdark);
    opacity: 0.87;
    font-weight: 600;
    font-size: 16px;
  }
  .textline1 {
    display: block;
    font-size: 11px;
    font-weight: 600;
    color: var(--textdark);
  }

  .textline2 {
    display: block;
    font-size: 11px;
    font-weight: 600;
    color: var(--textlight);
  }

  .status {
    text-align: center;
  }
  .textofstatus {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: var(--textdarker);
  }
  .compete-section {
    padding-bottom: 15px;
  }

  .greenicon,
  .completed {
    display: inline-block;
  }

  .dotclass {
    margin-top: 13px;
    width: 17px;
    height: 17px;
    vertical-align: text-bottom;
    path {
      fill: var(--green);
      stroke: var(--green);
    }
  }
  .completed {
    text-align: center;
    margin-left: 8px;
  }

  .textofcomplete {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--green);
    text-transform: uppercase;
  }
  /*only use if png*/
  .qrcode-section {
    width: 310px;
    height: 305px;
    margin: 0 auto;
    text-align: center;
  }
  .transectionid {
    display: inline-block;
    width: 100%;
    max-width: 160px;
    word-wrap: break-word;
  }
  .detail {
   // display: flex;
    text-align: left;
    margin-bottom: 15px;
    border-bottom: 1px solid var(--borderdark);
    width: 100%;
    max-width: 342px;
    margin: 0 auto;
    margin-top: 30px;
  }
  .allignicon{
    white-space: nowrap;
  }
  .label {
    display: inline-block;
    vertical-align: top;
  }
  .labeltext {
    color: var(--textdarker);
    font-weight: 400;
    font-size: 16px;
  }

  .valuetext {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: var(--textlight);
  }

  .longvalue {
    display: inline-block;
    width: calc(100% - 40px);
    overflow: hidden;
    vertical-align: bottom;
    word-wrap: break-word;
    text-align: right;
  }

  .currencytoconvert {
    margin-bottom: 20px;
    width: 100%;
    max-width: 342px;
    margin: 0 auto;
    margin-top: 30px;
  }
  .arrowclass11-1 {
    width: 17px;
    height: 17px;
  }
  .arrowclass11-1 {
    path {
      fill: var(--primary);
    }
  }

  .arrowclass111 {
    width: 25px;
    height: 25px;
  }
  .arrowclass111 {
    path {
      fill: var(--bgwhite);
    }
  }

  .detail2 {
    //display: flex;
    max-width: 250px;
    text-align: left;
    margin-bottom: 15px;
    border-bottom: 1px solid var(--borderdark);
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
  }

  .textcolor2 {
    color: var(--textdarker);
    width: 100%;
    max-width: 105px;
    font-weight: 600;
    word-wrap: break-word;
  }

  .line1 {
    text-align: right;
    display: inline-block;
    width: calc(100% - 0px);
  }
  .margin {
    margin-top: 27px;
  }
  .line2 {
    text-align: right;
    display: inline-block;
    width: calc(100% - 0px);
  }

  .line3 {
    text-align: right;
    display: inline-block;
    width: calc(100% - 27px);
  }
  .line4 {
    text-align: right;
    display: inline-block;
    width: calc(100% - 85px);
  }

  .classtoholdlongtext {
    text-align: right;
    display: inline-block;
    width: calc(100% - 90px);
  }

  //    .qrcode-section  {
  //         width: 228px;
  //         height: 224px;
  //         text-align: center;
  //         margin: 0 auto;
  //         margin-top: 20px;
  //       }
  .transectionid {
    display: inline-block;
    width: 100%;
    //max-width: 260px;
    word-wrap: break-word;
  }
  // .detail {
  //   text-align: left;
  //   margin-bottom: 15px;
  //   border-bottom: 1px solid var(--borderdark);
  //   width: 100%;
  //   max-width: 400px;
  //   margin: 0 auto;
  //   margin-top: 30px;
  // }
  .label {
    display: inline-block;
  }
  .labeltext {
    color: var(--textdarker);
    font-weight: 400;
    font-size: 12px;
  }
  .labeltext1 {
    color: var(--textdarker);
    font-weight: 400;
    font-size: 16px;
  }
  .value {
    display: inline-block;
    float: right;
    margin-left: 17%;
  }

  .justifytext {
    text-align: justify;
  }
  .value-copy {
    display: inline-block;
    width: calc(100% - 71px);
    text-align: right;
  }
  .valuetext {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding-right: 2px;
    color: var(--textlight);
  }
  .tcolor {
    color: var(--primary);
    font-size: 12px;
    font-weight: 400;
  }

  .arrowclass11 {
    width: 25px;
    height: 25px;
  }
  .imgcontainer {
    width: 17px;
    height: 17px;
    vertical-align: top;
    display: inline-block;
    margin-left: 8px;
  }
  .copy {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .detail1 {
    display: flex;
    text-align: left;
    margin-bottom: 15px;
    width: 100%;
    max-width: 345px;
    margin: 0 auto;
    margin-top: 30px;
    justify-content: space-between;
  }
  .textclass {
    display: inline-block;
    width: calc(100% - 68px);
  }
  .socialicon {
    display: inline-block;
    margin-left: 9px;
  }
  .buttonsection {
    margin-top: 30px;
  }
  .buttoncontainer {
    display: inline-block;
    padding: 0px 25px 13px 0px;
  }

  .pl {
    padding-left: 0px;
  }

  .pr {
    padding-right: 0px;
  }

  .imgsection {
    width: 100;
    height: 100%;
  }

  .down {
    path {
      fill: var(--primary);
      stroke: var(--primary);
    }
  }
  .classtoholdlongtext-agarths {
    text-align: right;
    display: inline-block;
    width: calc(100% - 0px);
  }
  .buttonmain {
    border-radius: 9px;
    // box-shadow: 0 7px 6px -6px var(--borderdarker);
    font-size: 16px;
    font-weight: 600;
    height: 39px;
    min-width: 160px;
  }
  @media (max-width: 400px) {
    .value {
      margin-left: 8%;
    }
  }
  @media (max-width: 520px) {
    .buttoncontainer {
      width: 115px;
      margin-right: 55px;
    }
    .buttonsection {
      width: 104%;
    }
    .buttonmain {
      width: max-content;
    }
  }
  @media screen and (min-width:993px) {
    .qrcode-section-mobile {
      display: none;
    }
  }
  @media (max-width: 992px) {
    .line4 {
      width: calc(100% - 99px);
    }
    .currencytoconvert {
      width: 261px;
    }
    .detail2 {
      max-width: 342px;
    }
    .textofcomplete {
      font-weight: 600;
    }
    .labeltext {
      font-size: 14px;
    }
    .labeltext1 {
      font-size: 14px;
    }
    .textcolor2 {
      font-size: 14px;
      font-weight: 400;
      color: var(--textlight);
    }
    .modal-dialog {
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
      // max-height: 150px;
    }
    .modal-header {
      display: inline;
    }
    .modal-content {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
    .copy {
      width: 20px;
      height: 24px;
    }
    .tcolor {
      font-size: 14px;
    }
    .textline1 {
      font-size: 14px;
    }
    .qrcode-section {
      display: none;
    }
    .qrcode-section-mobile {
      margin-bottom: 35px;
    }
    .classtoholdlongtext {
      width: calc(100% - 77px);
    }
    .longvalue {
      width: calc(100% - 30px);
    }
    .valuetext2{
      font-weight: 400;
      font-size: 14px;
      color: var(--textlight);
    }
  }
}
