.cancelpay {
  .modal-body {
    padding: 50px 40px 40px 46px;
  }
  .modal-dialog {
    max-width: 500px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }
  .exchangetext {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 20px;
  }
  .confirm {
    .buttonmain {
      height: 39px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .cancel {
    .buttonmain {
      background-color: var(--bglightgrey);
      border: 1px solid var(--borderlight);
      color: var(--textdark);
      height: 39px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 16px;
    }
  }
}
