#AdminAllBanks{
    padding: 0px 5%;
    .containertp {
        margin-top: 33px;
      }
      .trouter {
        height: 65px;
        margin-bottom: 28px;
        width: 100%;
        background: var(--bgwhite);
        border: var(--borderdark);
        border-radius: 2px;
      }
      .transactiontitle {
        text-align: left;
      }
      .title {
    
        opacity: 0.84;
        color: var(--textdarker);
        font-weight: 600;
        font-size: 32px;
        line-height: 67px;
        float: left;
        display: inline-block;
      }
      .filtersection {
        display: inline-block;
        float: right;
        position: relative;
        margin-top: 12px;
      }
      .buttoncontainersm {
        display: inline-block;
        margin-left: 0px;
        margin-right: 8px;
        width: auto;
      }
      .mleft {
        margin-left: 3px;
      }
      .iconcolor {
        path {
          fill: var(--textdark);
        }
      }
      .rightsection {
        text-align: left;
        width: 100%;
        margin-bottom: 40px;
      }
      .thethreebutton {
        text-align: left;
        margin-left: 0px;
      }
    
      .actions {
        //   width: 80%;
        height: auto;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        // margin-bottom: 20px;
      }

      .transfertbl {
        position: relative;
        width: 100%;
        background: var(--bgwhite);
        border: var(--borderdark);
        border-radius: 2px;
      }
      .BankListItem {
        width: 100%;
        border: none;
        border-radius: 9px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 60px;
        font-weight: 600;
        font-size: 14px;
        padding: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
        line-height: 40px;
        text-align: left;
        margin-bottom: 0px;
        .bankimgs{
          max-width: 75px;
          max-height: 40px;
          margin-right: 5px;
          margin-top: 0px;
        }
        .bankname {
          float: right;
          overflow: hidden;
          height: 100%;
        }
        .bankimg {
          height: 100%;
          width: auto;
          margin-top: -6px;
        }
      }
    //   .currencydropdown{
    
    //     position: relative;
    //     width: 118px;
    //     height: 34px;
    //     left: 75%;
    //     bottom: 56px;
    //     border: 1px solid var(--borderdark);
    //     border-radius: 10px;
    //     box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.12);
    //     .dropdownlink{
    //       height: 32px;
    //       padding: 8px;
    //     }
    //     .toogleicon{
    //       line-height: 16px;
    //     }
    //     .dropdown-menu{
    //       width: 100%;
    //       min-width: 118px;
    //       margin-top: 1px;
    //     }
    //     .search{
    //       height: 32px;
    //       padding-left: 5px;
    //       margin-left: 10px;
    //     }
    //    .dropdown-menu a{
    //      height: 34px;
    //      padding-left: 12px;
    //    }
    //   }
    //   .currencydropdown{
    //     .DropdownSearch {
    //       .cointext {
    //       overflow: unset;
    //       width: 54%;
    //       margin-left: 18px;
    //     }
    //     .coinicon{
    //       width: 8px;
    //     }
    //      .selecteditem{
    //       position: relative;
    //       bottom: 10px;
    //     }
    //   }
    // }
      .trtbleheading {
        color: var(--textlight);
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        vertical-align: middle;
        height: 68px;
        white-space: nowrap;
        .buttonwrapper {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          .editbutton {
            height: 30px;
            width: 30px;
            margin-right: 11px;
            border-radius: 9px;
            cursor: pointer;
            opacity: 0.6;
            rect{
              fill: var(--primary);
            }
          }
        }
      }
      thead tr {
        //border-bottom: 2px solid var(--borderlight);
        background-color: var(--primarylight);
      }
      tr {
        border-color: var(--borderlight);
      }
      table {
        border-color: var(--borderdark);
      }
      td {
        img {
          margin-top: 3px;
        }
      }
      td,
      th {
        border: none;
        text-align: left;
      }

      .maintdwrapper {
        position: relative;
      }
      .innerTd {
        padding: 9px;
        padding-left: 10px;
        border-radius: 0.1px;
        border: 1px solid var(--borderlight);
        border-left: none;
        border-right: none;
        height: 70px;
        text-align: left;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        color: var(--textdark);
        //opacity: 0.87;
        font-size: 16px;
        font-weight: 600;
      }
      tbody tr td:first-child .innerTd {
        padding-left: 15px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-left: 1px solid var(--borderlight);
    }
    tbody tr td:last-child .innerTd {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-right: 1px solid var(--borderlight);
  }
  .colspace.actions {
    line-height: 39px;
  }
  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: var(--borderlight);
  }
  thead tr th {
    //border: 0.5px solid var(--borderlight);
    border-left: none;
    border-right: none;
    border-top-right-radius: 0.1px;
    border-top-left-radius: 0.1px;
    background-color: var(--bglightgrey);
  }
  thead tr th:first-child {
    padding-left: 45px;
    //border-left: 1px solid var(--borderlight);
    border-top-left-radius: 10px;
  }
  thead tr th:last-child {
    //border-right: 1px solid var(--borderlight);
    border-top-right-radius: 10px;
  }
  // tr {
  //   border-color: var(--borderlight);
  // }
  table {
    border-color: var(--borderdark);
  }
  td {
    border: none;
    padding-left: 0px;
    padding-right: 0px;
  }
  th {
    border: none;
    text-align: left;
  }
  .table-responsive-sm {
    overflow-y: visible;
    overflow-x: auto;
    width: 100%;
    // border: 1px solid var(--borderlight);
    min-height: 450px;
  }
  .tableindark {
    .innerTd,
    .innerTdforprofile,
    tbody tr td:first-child .innerTd,
    tbody tr td:last-child .innerTd {
      border-color: var(--borderdarkblue);
    }
  }
      .text{
        opacity: 0.87;
      }
      .innercontent {
        font-weight: 400;
        font-size: 12px;
        vertical-align: middle;
        color: var(--textdark);
      }
      .btnwithimg {
        border-radius: 4px;
        line-height: 15px;
        height: 33px;
        width: max-content;
        max-width: unset;
        box-shadow: 0 1px 4px var(--borderlight);
    }
    .fiatcurrencydropdown{
      width: 116px;
    .toogleicon{
      line-height: 28px;
    }
    
    .DropdownSearch {
      .cointext{
        opacity: 0.87;
        margin-left: 13px;
    }
    .search{
      top: 0px;
      height: 32px;
      margin-left: 5px;
      width: 79px;
    }
    .coinicon{
      width: 8px;
      margin-bottom: 6px;
    }
    .dropdownlink{
      width: 116px;
      height: 36px;
      border-radius: 10px;
    }
    .dropdown-menu{
      width: 100%;
      min-width: 116px;
      margin-top: 1px;
    }
    .selecteditem{
      position: relative;
      bottom: 3px;
    }
    .coinicon img {
      height: 16px;
      width: 16px;
      margin-bottom: 6px;
  }
    .dropdown-menu a .coinicon img {
      height: 16px;
      width: 16px;
      margin-bottom: 4px;
  }
    
  }
}
  .addbank{
    color: var(--textdark);
    background-color: var(--bgwhite);
    border-color: var(--primary);
    border: 1px solid var(--primary);
    border-radius: 4px;
    height: 44px;
    min-width: 187px;
    width: max-content;
    max-width: unset;
    margin-left: 11px;
    padding: 0 14px;
    cursor: pointer;
  }
  .textfield{
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
  }
  .infotext{
    line-height: 29px;
    opacity: 0.84;
    font-size: 14px;
    font-weight: 600;
    vertical-align: middle;
    //text-align: center;
  }
  
  .addicon{
    width: 18px;

  }
  .logoholder {
    //margin-top: 2px;
    margin-left: 8px;
    cursor: pointer;
}
.filtercurrency{
  .btn-primary{
    color: var(--textdark);
    background-color: var(--bgwhite);
    border-color: var(--primary);
    border: 1px solid var(--primary);
    border-radius: 4px;
    height: 44px;
    min-width: 187px;
    width: max-content;
    max-width: unset;
    padding: 0 14px;
  }
  .btn-check:active + .btn-primary,
  .btn-check:checked + .btn-primary,
  .btn-primary.active,
  .btn-primary:active,
  .show > .btn-primary.dropdown-toggle {
    color: var(--textdark);
    background-color: var(--bgwhite);
    border-color: var(--primary);
    border: 1px solid var(--primary);
  }
  .btn-check:focus + .btn-primary,
  .btn-primary:focus,
  .btn-check:active + .btn-primary:focus,
  .btn-check:checked + .btn-primary:focus,
  .btn-primary.active:focus,
  .btn-primary:active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    color: var(--textdark);
    background-color: var(--bgwhite);
    border-color: var(--primary);
    border: 1px solid var(--primary);
  }
  .dropdown-menu.show{
    width: 187px;
    //transform: translate3d(0px, 47px, 0px);
  }
}

.filtericon {
  path {
    fill: var(--textdark);
  }
  vertical-align: top;
  width: 25px;
  display: inline-block;
  float: left;
}
.currencyfiltertext {
  display: inline-block;
  line-height: 29px;
  opacity: 0.84;
  font-size: 14px;
  font-weight: 600;
  vertical-align: top;
  text-align: left;
}
.dropdown-toggle::after{
  position: relative;
  float: right;
  top: 12px;
}
@media screen and (max-width: 992px) {
  .hidden-for-mobile {
    display: none;
  }
  .filtersection  {
     .btn-primary, .addbank{
      margin-top: 10px;
      height: 33px;
      width: 62px;
      min-width: unset;
      margin-bottom: 10px;
      padding-left: 11px;
      padding-right: 11px;
      border: none;
      border-color: unset;
      box-shadow: 0px 1px 4px var(--borderlight);
      border-radius: 4px;
    }

    .dropdown-toggle::after {
      margin-top: -3px;
      width: auto;
    }

    .currencyfiltertext , .infotext{
      display: none;
    }
    .textfield{
      justify-content: center;
      align-items: center;
    }
    .logoholder{
      margin: 0;
      margin-top: 3px;
    }
    .filtericon{
      width: 20px;
    }
    
  }
  .title{
    font-size: 16px;
  }
}
@media (max-width: 480px) {
  .CurrencyTab .nav-link {
    font-size: 14px;
  }

  .CurrencyTab .nav-link.active {
    font-size: 14px;
  }
  .CurrencyTab .nav-item {
    width: 110px;
  }
}

}