.withdrawformwithtoggle {
  .topspaceformodal1 {
    margin-bottom: 15px;
    position: relative;
    text-align: right;
  }
  @media (max-width: 991px) {
    .topspaceformodal1 {
      margin-bottom: 15px;
      position: relative;
      left: 0%;
      text-align: left;
      margin-top: 15px;
    }
    .hidetgl{
      display: none;
    }
  }
}
