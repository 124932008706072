.dropdowndownmobiledepositmodal{
    .dropdownlink {
        width: 70%;
        height: 45px;
        border: 1px solid var(--borderdark);
        box-sizing: border-box;
        border-radius: 4px;
        position: relative;
        text-align: left;
        padding: 12px;
        padding-top: 4px;
        padding-bottom: 4px;
        cursor: pointer;
        color: var(--textdark);
        margin-left:15%;
        margin-top: 10%;
      }
      .customDropDrownPlaceholder{
        font-size: 12px;
        font-weight: 500;
        color: var(--primary);
      }
      .dropdown-menu a {
        height: 40px;
      }
      .search {
        position: absolute;
        top: 0;
        left: 0;
        outline: none;
        border: none;
        display: none;
        width: calc(100% - 30px);
        z-index: 20;
        background-color: var(--bgwhite);
      }
      .dropdown-menu {
        // height: 210px;
       
        width: 70%;
        margin-top: -1px;
        border-top: none;
        background-color: var(--bgwhite);
        border: 1px solid var(--borderdark);
        margin-left: 15%;
      }
      .selecteditem {
        height: 100%;
        width: calc(100% - 30px);
      }
      ul {
        margin-top: 0;
        margin-bottom: -4px;
      }
      .coinicon {
        height: 80%;
        width: 25px;
        display: inline-block;
        line-height: 33px;
      }
      .coinbalance{
        float: right;
        height: 93%;
        /* width: 95px; */
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        /* margin-right: 10px; */
        /* margin-left: 41px; */
        margin-top: 10px;
      }
      .coinicon img {
        height: 100%;
        width: auto;
      }
      .cointext {
        margin-left: 10px;
        display: inline-block;
      }
      .toogleicon {
        float: right;
        height: 8px;
        line-height: 32px;
      }
      .cointag {
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 18px;
        color: var(--textdark);
        display: inline-block;
        box-shadow: 0 2px 4px 0 var(--borderlight), 0 2px 4px 0 var(--borderlight);
        padding-left: 2px;
        padding-right: 2px;
        border-radius: 2px;
         margin-right: 2px;
        margin-top: 2px;
        margin-left: 14px;
      }
      .dropdownarrow {
        path {
          fill: var(--textdark);
        }
      }
      .dropdown.show {
        border-bottom: none;
        .dropdownarrow {
          transform: rotate(180deg);
        }
        .selecteditem {
          display: none;
        }
        .search {
          display: initial;
          cursor: pointer;
        }
      }
}