.BankListItem {
  width: 100%;
  border: 0.99px solid var(--borderlight);
  border-radius: 6px;
  box-sizing: border-box;
  height: 60px;
  font-weight: 600;
  font-size: 14px;
  padding: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 40px;
  text-align: left;
  margin-bottom: 12px;
  .bankname {
    float: right;
    overflow: hidden;
    height: 100%;
  }
  .bankimg {
    height: 100%;
    width: auto;
    margin-top: -6px;
  }
}
@media (max-width: 992px) {
  .BankListItem {
    width: auto;
  }
}
