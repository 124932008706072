.filterfiatcurrency {
  .btn-primary {
    color: var(--textdark);
    background-color: var(--bgwhite);
    border-color: var(--primary);
    border-radius: 10px;
    border: 1px solid var(--primary);
  }
  .dropdown {
    color: var(--textdark);
    background-color: var(--bgwhite);
    border-color: var(--primary);
    border-radius: 4px;
    border: 1px solid var(--primary);
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .drpicon {
    path {
      fill: var(--textdark);
    }
  }
  .dropdown-menu {
    max-height: 209px;
    overflow-y: scroll;
    width: 100%;
    margin-top: 5px;
    margin-left: 20px;
    border-top: none;
    background-color: var(--bgwhite);
    border: 1px solid var(--borderdark);
  }
  .filtericon {
    path {
      fill: var(--textdark);
    }
    vertical-align: top;
    height: 80%;
    width: 25px;
    display: inline-block;
  }
  .filtertext {
    margin-left: 18px;
    margin-right: 18px;
    display: inline-block;
    line-height: 29px;
    font-size: 14px;
  }

  .dropdownarrow {
    path {
      fill: var(--textdark);
    }
  }
  .dropdown.show {
    .dropdownarrow {
      transform: rotate(180deg);
    }
  }
}
