#IdoNew {
  padding: 0px 6%;
  .smallcardwrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // height: 100%;
  }
  .innersmallcard {
    height: calc(50% - 10px);
  }

  @media screen and  (max-width: 992px) {
    .smallcardwrapper{
      flex-direction: column-reverse;
    }
    .hide-for-mobile {
      display: none;
    }
    .iconwithtextinabout{
      padding-left: 70px;
    }
    .buyTextStyle{
      font-size: 16px
      ;
    }
    .abouttext{
      font-size: 16px;
    }
    .para{
      font-size: 12px;

    }
    .title{
      font-size: 14px;
    }
    .colspace{
      margin-bottom: 30px;
    }
  }
}
@media (max-width: 556px) {
  #mybalances {
    padding: 0px 10px;
  }
}
