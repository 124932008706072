#personalsettings {
  padding: 0px 5%;
  .containerallignm {
    margin-top: 33px;
    
  }
  .outercontainer {
    background-color: var(--bgwhite);
    padding: 0px 0px 25px 0px;
    overflow: hidden;
  }
  .settingtitle {
    color: var(--textdarker);
    opacity: 0.84;
    font-weight: 600;
    font-size: 32px;
    line-height: 67px;
    text-align: left;

    margin-left: 35px;
  }
  .align-left {
    text-align: left;
    margin-left: 17px;
  }
  .topspace {
    margin-top: 20px;
  }
 

  @media screen and (max-width: 991px) {
    .settingtitle {
      display: none;
    }
  }
  @media screen and (max-width: 767px) {
    .marginlft {
      margin-left: 0px;
    }

    @media screen and (max-width: 600px) {
    }
    @media screen and (max-width: 520px) {
    }
  }
}
@media (max-width: 556px) {
  #personalsettings {
    padding: 0px 10px;
  }
}
