.footercommon {
  margin-top: 20px;
  border-top: 1px solid var(--borderlight);
  .leftalign {
    text-align: left;
  }
  .contentbelowbigline{
    padding: 0px 5%;
  }
  .applink {
    color: var(--primary);
  }
  .maintxt {
    margin-top: 15px;
    font-size: 9px;
    font-weight: 400;
    color: var(--textdark);
    // margin-left: 40px;
  }
  .links {
    margin-left: 6px;
    margin-right: 6px;
    color: var(--primary);
  }
  .linktxt {
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
  }
  .bigblueline{
    height: 29px;
    width: 100%;
    background-color: var(--primary);
  }
  a{
    text-decoration: none;
  }
  @media (max-width: 991px) {
    .maintxt{
      margin-left: 0px;
    }
    .linktxt{
      text-align: left;
      margin-top: 25px;
      span{
        display: block;
        margin-top: 10px;
        margin-left: 0px;
        white-space: nowrap;
      }
    }
  }
  @media (max-width: 556px) {
    .contentbelowbigline{
      padding: 0px 10px;
    }
  }
}
@media (max-width: 991px) {
  .footercommon {
    border-top: none;
    margin-top: 30px;
    
  }
}
