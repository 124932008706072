.addcoin {
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 40px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 40px;
    margin-left: 40px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    // background: var(--borderlight);
    border-radius: 10px;
  }

  /* Handle on hover */
  // ::-webkit-scrollbar-thumb:hover {
  //   background: #b30000;
  // }
  .modal{
    background-color: var(--bgwhite);
  }
  .modal-dialog {
    max-width: 600px;
  }
  .modal-body {
    padding: 45px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }
  .logoSize {
    height: 100%;
    width: auto;
  }
  .searchbox {
    max-width: 100%;
    height: 42px;
    margin-left: 0;
  }
  .searchinside {
    top: 48%;
  }
  .addcoin{
    margin-left: 40px;
    font-size: 18px;
    font-weight: 600;
    color: var(--textdark);
  }
  .innerinputfield {
    max-width: 100%;
    margin-left: 10px;
    margin-top: 3px;
  }
  .outerbox {
    width: 100%;
    border: 0.99px solid var(--borderlight);
    border-radius: 6px;
    box-sizing: border-box;
    height: 70px;
    font-weight: 600;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 20px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: 12px;
  }
  .addcoinscroll {
    overflow-x: auto;
    height: 700px;
  }
  .crossic {
    width: 21px;
    margin-left: 20px;
  }
  .outerflex {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .coinimg {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  .rightalign {
    text-align: right;
  }
  .coinname {
    flex: 1 1 40%;
    min-width: 50px;
  }
  .nametxt {
    font-size: 12px;
    font-weight: 600;
    color: var(--textdark);
    opacity: 0.54;
    margin: 0px 8px 0px 0px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .nametxt1 {
    font-size: 12px;
    font-weight: 600;
    color: var(--textdark);
    opacity: 0.87;
    margin: 0px 8px 0px 0px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .cointag {
    font-size: 7px;
    font-weight: 600;
    color: var(--textdark);
    opacity: 0.9;
    padding: 1px 3px;
    border: 1px solid var(--borderlight);
    border-radius: 2px;
    box-shadow: 0px 1px 2px var(--borderlight);
  }
  .plusicon {
    cursor: pointer;
    path {
      fill: var(--textdark);
      opacity: 0.8;
    }
  }
  .buybtn {
    .buttonmain {
      width: 140px;
      margin-top: 50px;
      font-size: 16px;
    }
  }
  @media screen and (min-width: 992px) {
    .hide {
      display: none;
    }
    
  }

  @media screen and (max-width: 450px) {
    .outerbox {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  @media screen and (max-width: 991px) {
    .modal-dialog {
      max-width: 100%;
      width: 100%;
      min-height: 100%;
      height: 150vh;
      margin: 0 auto;
    }
    .modal-header{
      display: none;
    }
    .modal-body {
      padding: 45px 18px;
    }
    .innerinputfield{
      margin-top: 0px;
    }
    .deletetitle {
      font-size: 16px;
      font-weight: 600;
      margin-left: 12px;
      color: var(--textdark);
      opacity: 0.87;
    }
    .modal-content {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
    .rightnav {
      height: 56px;
      width: 97px;
      background-color: var(--primary);
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0px;
      top: 40px;
    }
    .modalPrimaryHeader {
      height: 29px;
      background-color: var(--primary);
    }
    .closebtn {
      display: none;
    }
    .topspace {
      margin-top: 62px;
    }
    .addcointxt {
      font-size: 16px;
      font-weight: 600;
      text-align: left;
      margin-left: 10px;
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 420px) {
    .innerinputfield {
      margin-top: 2px;
    }
  }
  @media screen and (min-width: 768px) {
    .searchbox{
      padding: 1%;
    }
  }

  
}
