.Detailedex {
    .modal-dialog {
      max-width: 650px;
    }
    .modal-body {
      padding: 45px;
    }
    .modal-content {
      background-color: var(--bgwhite);
      border-radius: 10px;
    }
    .transectiontype {
      text-align: left;
      margin-bottom: 0px;
      margin-left: 40px;
    }
    .transectiontext {
      font-size: 20px;
      color: var(--textdark);
      font-weight: 600;
    }
    .detail {
      text-align: left;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
    }
    .label {
      display: inline-block;
      vertical-align: top;
    }
    .labeltext {
      color: var(--textdark);
      opacity: 0.87;
      font-weight: 600;
      font-size: 16px;
      white-space: nowrap;
    }
    .value {
      text-align: right;
    }
  
    .valuetransid {
      display: inline-block;
      text-align: right;
      width: calc(100% - 100px);
    }
  
    .valueamout {
      display: inline-block;
      text-align: right;
      width: calc(100% - 65px);
    }
    .longvalue {
      // display: inline-block;
      width: calc(100% - 40px);
      overflow: hidden;
      vertical-align: bottom;
      word-wrap: break-word;
    }
    .valuetext {
      color: var(--textdark);
      font-weight: 600;
      font-size: 16px;
      opacity: 0.54;
    }
    .linktag {
      text-align: left;
    }
    a {
      color: var(--primarydark);
      font-weight: 600;
      font-size: 14px;
    }
    .uppercase {
      text-transform: capitalize;
    }
    .transectionid {
      display: inline-block;
      width: 100%;
      max-width: 270px;
      word-wrap: break-word;
    }
    .options {
      margin: 0 auto;
      width: 225px;
      margin-bottom: 10px;
      margin-top: 50px;
    }
    .optiontxtholder1 {
      display: inline-block;
      text-align: center;
      margin-left: 8px;
      margin-right: 12px;
      word-wrap: break-word;
    }
    .confirm {
      .buttonmain {
        width: 153px;
        height: 34px;
        font-weight: 600;
        font-size: 16px;
        margin-top: 30px;
      }
    }
    .height {
      height: 80px;
    }
    .modalPrimaryHeader {
      max-width: 100%;
      margin-bottom: 0px;
      margin-top: -1px;
      height: 29px;
      background-color: var(--primary);
    }
    @media screen and (min-width: 993px) {
      .hidden-for-desktop {
        display: none;
      }
    }
    
    
    @media screen and (max-width: 992px) {
      .modal-header{
        padding: 40px 15px 39px;
      }
      .modal-body {
        padding: 30px 15px;
        height: 100vh;
      }
      .modal-dialog {
        max-width: 100%;
  
        height: 100%;
        margin: 0 auto;
      }
      .comtxt1{
        width:60%;
        margin-left: 15%;
      }
      .modal-content {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        border-radius: 0px;
        border: none;
      }
      .closebtn{
        display: none;
      }
      
       .back {
        float: right;
        // width: 9%;
        position: absolute;
        height: 56px;
        width: 96px;
        background-color: var(--primary);
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    
        right: 0px;
        top: 50px;
        // margin-top: 19px;
      }
      .confirm{
        display: none;
      }
    }
  }
  