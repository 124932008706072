.QrcryptoWallet {
  .modal-dialog {
    max-width: 400px;
  }
  .modal-body {
    padding: 20px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }

  .receivetxt {
    text-align: center;
  }

  .rcctxt {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--textdarker);
  }

  .imagesection {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    height: 100%;
    text-align: center;
    margin: 0 auto;
  }

  .qrimg {
    width: 100%;
    height: 100%;
  }

  .bottomtext {
    text-align: center;
  }

  .lineone {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    font-weight: 400;
    color: var(--textdarker);
  }

  .address {
    display: inline-block;
    width: calc(100% );
  }

  .copy {
  text-align: center; 
  }

  .addcopy {
    margin: auto;
    width: fit-content;
    display: flex;

    
  }
  .addresstext {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    font-weight: 400;
    color: var(--textdarker);
    display: inline-block;
    width: calc(100%);
    overflow: hidden;
    word-wrap: break-word;
    text-align:center;
    vertical-align: top;
  }

  .copytext {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin-left: 5px;
    vertical-align: top;
    color: var(--primary);
  }
  .options {
    margin: 0 auto;
    width: 275px;
    margin-bottom: 10px;
    margin-top: 22px;
  }
  .optiontxtholder1 {
    display: inline-block;
    text-align: center;
  }
  .optiontxtholder1 img {
    cursor: pointer;
    margin: 0px 18px 0px 18px;
  }
  .comtxt {
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
  .comtxt1 {
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    opacity: 0.54;
  }
  .address{
    display: contents;
  }

  @media (max-width: 992px) {
  .modal-content{
    border-radius: 0px;
  }
  }
}
