.footer {
  position: relative;
  left: 0;
  bottom: 25px;
  width: 100%;
  .socialicon {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
  }
  .socialicon1 {
    display: inline-block;
    margin-left: 8px;
    margin-right: 8px;
  }
  .txt {
    font-size: 7px;
    font-weight: 600;
    color: var(--textlight);
  }
  .align{
      text-align: center;
      margin-top: 15px;
  }
  .applink{
      color: var(--primary);
  }
  .alignment{
      text-align: center;
  }
}
