.mainnavwrapper {
  .nav-item .nav-link {
    padding: 0px;
  }
  .nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar {
    border-radius: 0px;
    border-bottom: 1px solid var(--borderlight);
  }
  .formobilemenu {
    display: flex;
  }
  .apptitle {
    margin-bottom: 0px;
  }
  .apptitle img {
    width: 120px;
    height: 50px;
    cursor: pointer;
  }
  .mt-10 {
    margin-top: 10px;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    text-align: center;
  }
  .editmode {
    margin-right: 7px;
  }
  .buttonsearch {
    path {
      stroke: var(--textdark);
    }
  }
  .spacing {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: -5px;
    path {
      fill: var(--textdark);
    }
  }
  .helptxt1 {
    font-size: 10px;
    font-weight: 400;
    color: var(--textlight);
  }
  .helptxt2 {
    font-size: 10px;
    font-weight: 700;
    color: var(--primary);
  }
  .txtalign {
    text-align: center;
  }
  .txtalign1 {
    width: 100px;
    margin: 0 auto;
    line-height: 10px;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 12px;
  }
  .helpimg {
    margin-right: 5px;
    margin-left: 5px;
  }
  .navvl {
    margin-right: 12px;
    margin-left: 12px;
  }
  .dropdown-menu {
    position: absolute;
    right: -30px;
  }
  .navwhitebg {
    padding: 30px 20px;
    background-color: var(--bgwhite);
  }
  .menucolour {
    color: var(--textdark);
    font-weight: 500;
  }
  .navline {
    display: inline-block;
    height: 15px;
    opacity: 0.7;
    border-left: 2px solid var(--borderdarker);
    vertical-align: middle;
  }
  .contentatend {
    justify-content: end;
    margin-left: auto;
  }
  .vertcallycenter {
    line-height: 50px;
    cursor: pointer;
  }
  .dropdown-toggle::after {
    display: none;
    margin-left: none;
    vertical-align: none;
    content: "";
    border-top: none;
    border-right: none;
    border-bottom: 0;
    border-left: none;
  }
  .widthofimgmoon {
    vertical-align: middle;
    height: 58px;
    width: 17px;
    cursor: pointer;
  }
  .widthofimgnotify {
    vertical-align: middle;
    height: 58px;
    width: 22px;
  }
  .widthofimgbell {
    height: 23px;
    width: 23px;
    margin-top: -2px;
    path {
      fill: var(--textdarker);
    }
  }
  .widthofimghelp {
    height: 20px;
    width: 20px;
    margin-right: 6px;
    path {
      fill: var(--textdarker);
    }
    .svgcircle {
      stroke: var(--textdarker);
      fill: none;
      stroke-width: 1.5;
    }
  }
  .mobilemenuiconstyle {
    .widthofimgbell {
      width: 24px;
      height: 20px;
      margin-top: -6px;
    }
    .yellowcircle {
      top: 0px;
      right: -3px;
      width: 15px;
      height: 15px;
    }
    .languagewrapper {
      margin-left: 10px;
      margin-right: 0px;
    }
    .helpwrapper {
      margin-left: 10px;
      margin-right: 0px;
    }
    .widthofimghelp {
      height: 23px;
      width: 19px;
      margin-top: -5px;
      margin-right: 6px;
    }
  }
  .helpDrpArrow {
    display: inline-block;
    margin-left: 5px;
    .dropdownarrow path {
      fill: var(--textdarker);
    }
  }
  .notifycircle {
    position: relative;
  }
  .yellowcircle {
    position: absolute;
    right: -3px;
    top: 1px;
    width: 15px;
    font-size: 8px;
    height: 100%;
    max-height: 15px;
    font-weight: 600;
    border-radius: 50%;
    border: 1px solid var(--textwhite);
    background-color: var(--yellow);
  }
  .count {
    line-height: 12px;
  }
  .navbar-nav {
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    flex-direction: row;
    list-style: none;
  }
  .pname {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: var(--textdark);
    text-transform: capitalize;
  }
  .helpwrapper {
    margin-right: 74px;
    .dropdown-menu {
      left: -60px;
      top: 63px;
      background-color: var(--bgwhite);
      border-radius: 6px;
      box-shadow: 0 2px 5px var(--borderlight);
      border: none;
      border-top: 8px solid var(--primary);
    }
  }
  .languagewrapper {
    margin-right: 74px;
    .dropdown-menu {
      left: -60px;
      top: 63px;
      background-color: var(--bgwhite);
      border-radius: 6px;
      box-shadow: 0 2px 5px var(--borderlight);
      border: none;
      border-top: 8px solid var(--primary);
    }
  }
  .notification {
    padding: 5%;
    width: 100%;
    overflow-y: scroll;
    height: 220px;
  }
  .notification::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 1 2px #f5f5f5;
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  .notification::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }

  .notification::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 1 2px rgba(255, 255, 255, 0.3);
    background-color: var(--primary);
    opacity: 0.54;
  }
  .details {
    margin-bottom: 12px;
  }
  .img {
    width: 9px;
    height: 9px;
    margin-top: 2px;
    margin-right: 5px;
  }
  .line1 {
    display: flex;
  }
  .line2 {
    display: flex;
  }
  .line3 {
    display: flex;
  }
  .txt {
    font-size: 9px;
    font-weight: 400;
    color: var(--textlight);
    margin-right: 1px;
  }
  .bold {
    font-size: 9px;
    font-weight: 700;
    color: var(--textlight);
    margin-left: 2px;
  }
  .amount {
    font-size: 9px;
    font-weight: 700;
    color: var(--primary);
    margin-right: 5px;
    margin-left: 5px;
  }
  .datentime {
    font-size: 10px;
    font-weight: 400;
    color: var(--textdark);
    margin-right: 1px;
  }
  .languagewrapper {
    .dropdownarrow {
      display: none;
    }
  }
  @media (max-width: 768px) {
    .navwhitebg {
      padding: 20px 0px;
    }
    &.darknavwrapper .navbar {
      box-shadow: none;
      background-color: var(--borderlight);
    }
    .navbar {
      box-shadow: 0px 4px 4px rgba(1, 127, 218, 0.12);
      border-radius: 2px;
      border: none;
    }
    .navbarcontainer {
      max-width: 100%;
      padding-left: 0px;
      padding-right: 0px;
    }
    .navbar {
      padding-left: 31px;
      padding-right: 31px;
    }
    .helpwrapper {
      margin-right: 10px;
    }
    .pname .verified-or-not {
      display: none !important;
    }
    .profilecircle {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .profilearea .user-name {
      text-align: left;
      white-space: break-spaces;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .profilearea {
      margin-left: 4px;
    }
    .toogleicon svg {
      margin-top: -4px;
      margin-left: 2px;
    }
  }
  @media (max-width: 589px) {
    .spacing {
      margin-left: 8px;
      margin-right: 8px;
    }
  }
  @media (max-width: 400px) {
    .pname .user-name {
      width: 54px;
      line-height: 11px;
      font-size: 10px;
    }
  }

  @media (max-width: 991px) {
    .navbarprofile .profileimg {
      vertical-align: middle;
      width: 32px;
      height: 32px;
    }
    .navbarprofile .profilecircle {
      height: 45px;
      width: 45px;
    }
    .helpwrapper {
      .dropdown-menu {
        top: 53px;
      }
    }
    .languagewrapper {
      .dropdown-menu {
        top: 53px;
      }
    }
  }

  @media only screen and (min-width: 360px) and (max-width: 425px) {
    .tempdisable {
      display: none;
    }
  }
  @media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute;
      right: 0;
    }
  }
  .languagewrapper {
    margin-right: 15px;
  }
  .switchwrapper {
    margin-right: 20px;
    margin-top: 3px;
  }
  .uncheckedHandleIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 20px;
    margin-left: 10px;
    .darkmode path {
      fill: var(--textwhite);
      fill-opacity: 1 !important;
    }
    .lightmode path {
      fill: var(--textwhite);
      fill-opacity: 1;
    }
  }
  .checkedHandleIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    // margin-left: 10px;
    transform: translateX(5px);
    font-size: 23px;
    .darkmode path {
      fill: var(--textwhite);
      fill-opacity: 1 !important;
    }
    .lightmode path {
      fill: var(--textwhite);
      fill-opacity: 1;
    }
  }
  .checkedIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 20px;
  }
  .uncheckedIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 20px;
  }
  .react-switch-handle {
    width: 35px !important;
    border-radius: 7px !important;
  }
  .react-switch-bg {
    background: var(--borderlight) !important;
    opacity: 0.8;
  }
  .toogleon .react-switch-handle {
    transform: translateX(31px) !important;
  }
  @media only screen and (max-width: 992px) {
    .mainnavwrapper .navbar {
      display: none;
    }
  }
}
