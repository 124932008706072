.qrtoggle {
  .topspaceformodal {
    margin-bottom: 15px;
    text-align: right;
    margin-right: 13px;
  }
  @media (max-width: 991px) {
    .topspaceformodal {
      margin-bottom: 15px;
      text-align: left;
      margin-top: 30px;
    }
    .hide{
      display: none;
    }
  }
}
