.switchwrapper {
  margin-right: 20px;
  margin-top: 3px;

  .uncheckedHandleIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 14px;
    color: var(--textwhite);
  }
  .checkedHandleIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 14px;
    color: var(--textwhite);
  }
  .checkedIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 14px;
    color: var(--textwhite);
  }
  .uncheckedIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 14px;
    color: var(--textwhite);
  }
  &.personalbusinessswitch {
    .lightreactswitch {
      .react-switch-handle {
        background-color: var(--white) !important;
        box-shadow: 2px 0px 3px var(--borderlight) !important;
      }
      .uncheckedIcon.lighttoogle {
        color: var(--borderdark);
        font-weight: 600;
      }
      .checkedIcon.lighttoogle {
        color: var(--borderdark);
        font-weight: 600;
      }
      .checkedHandleIcon.lighttoogle {
        color: var(--primary);
        font-weight: 600;
      }
      .uncheckedHandleIcon.lighttoogle {
        color: var(--primary);
        font-weight: 600;
      }
    }
    @media (min-width: 769px) {
      .react-switch-handle {
        width: 154px !important;
        border-radius: 6px !important;
        height: 33px !important;
        top: 0px !important;
        border: 0.5px solid var(--borderlight) !important;
        background-color: var(--primary) !important;
        div {
          height: 33px !important;
          width: 154px !important;
        }
      }
      .react-switch-bg {
        background: var(--tbbackground) !important;
        // opacity: 0.8;
        div {
          height: 33px !important;
          width: 154px !important;
        }
      }
      .toogleon .react-switch-handle {
        transform: translateX(154px) !important;
      }
    }
  }
  @media (max-width: 768px) {
    .react-switch-bg {
      width: 206px !important;
    }
    .react-switch-handle {
      width: 103px !important;
      border-radius: 5px !important;
      height: 33px !important;
      top: 0px !important;
      border: 0.5px solid var(--borderlight) !important;
      div {
        height: 33px !important;
        width: 103px !important;
      }
    }
    .react-switch-bg {
      background: var(--tbbackground) !important;
      border-radius: 5px !important;
      // opacity: 0.8;
      div {
        height: 33px !important;
        width: 103px !important;
      }
    }
    .toogleon .react-switch-handle {
      transform: translateX(103px) !important;
      background: var(--primary)!important;
    }
    .switchwrapper {
      margin-right: 0px;
      margin-top: 0px;
    }
  }
  .toogleoff .react-switch-handle {
    transform: translateX(0px) !important;
    background: var(--primary)!important;
  }
}
