.branchsuccess {
  .modal-body {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }

  .deletetext {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
  }

  .confirm {
    .buttonmain {
      height: 39px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 16px;
      width: 150px;
    }
  }

  @media screen and (max-width:768px) {
    .modal-dialog {
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
    }

    .modal-content {
      width: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
  }
}
