.payment {
  .modal-dialog {
    max-width: 650px;
  }
  .modal-body {
    padding: 45px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 20px;
  }
  .currencytoconvert {
    margin-bottom: 20px;
  }
  .textholder {
    text-align: left;
  }
  .text {
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;
    color: var(--textdark);
  }
  .box {
    margin-top: 28px;
    height: auto;
    background: var(--bglightgrey);
    border: 1px solid var(--borderlight);
    box-sizing: border-box;
    border-radius: 2px;
    padding: 20px;
  }
  .link {
    text-align: center;
  }
  .linktxt {
    font-size: 14px;
    line-height: 15px;
    color: var(--primary);
  }
  .commonsection {
    text-align: center;
    margin-top: 15px;
  }
  .textline1 {
    font-size: 14px;
    font-weight: 600;
    color: var(--textdark);
  }
  .textline2 {
    font-size: 14px;
    font-weight: 400;
    color: var(--textlight);
    display: block;
  }
  .arrowclass {
    path {
      fill: var(--primary);
      stroke: var(--primary);
    }
  }
  .arrowclass {
    margin-top: 13px;
    width: 17px;
    height: 17px;
  }
  .DropdownSearch .cointext {
    margin-left: 0px;
    line-height: 35px;
  }
  .coinicon {
    margin-right: 10px;
  }
}
