#BranchManagement {
  padding: 0px 5%;
  .table-responsive-sm {
    overflow-x: auto;
    min-height: 450px;
    // border: 1px solid var(--borderlight);
  }
  .smallwidth{
    min-width: 110px;
  }
  .passive{
    width: 160px;
  }
  .tablestyle {
    width: 100%;
    background: var(--bgwhite);
    border: var(--borderdark);
    border-radius: 2px;
    border-collapse: separate;
    border-spacing: 0px;
  }
  tbody tr td:first-child .innerTd {
    padding-left: 34px !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-left: 1px solid var(--borderlight);
  }
  tbody tr td:last-child .innerTd {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: 1px solid var(--borderlight);
  }
  .colspace.actions {
    line-height: 39px;
  }
  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: var(--borderlight);
  }
  thead tr th {
    border: 0.5px solid var(--borderlight);
    border-left: none;
    border-right: none;
    border-top-right-radius: 0.1px;
    border-top-left-radius: 0.1px;
    background-color: var(--bglightgrey);
  }
  thead tr th:first-child {
    padding-left: 34px;
    border-left: 1px solid var(--borderlight);
    border-top-left-radius: 6px;
  }
  thead tr th:last-child {
    border-right: 1px solid var(--borderlight);
    border-top-right-radius: 6px;
  }
  tr {
    border-color: var(--borderlight);
  }
  table {
    border-color: var(--borderdark);
  }
  td {
    border: none;
    padding-left: 0px;
    padding-right: 0px;
  }
  th {
    border: none;
    text-align: left;
  }
  .maintdwrapper {
    position: relative;
  }
  .innerTd {
    padding: 9px;
    padding-left: 10px;
    border-radius: 0.1px;
    border: 1px solid var(--borderlight);
    border-left: none;
    border-right: none;
    height: 70px;
    text-align: left;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    color: var(--textdark);
    opacity: 0.87;
    font-size: 16px;
    font-weight: 600;
  }
  .id {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    color: var(--textdarker);
    // text-align: right;
  }
  tbody tr td:first-child .innerTd {
    padding-left: 15px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-left: 1px solid var(--borderlight);
  }
  tbody tr td:last-child .innerTd {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: 1px solid var(--borderlight);
  }
  .colspace.actions {
    line-height: 39px;
  }
  .amt {
    font-weight: 400;
    font-size: 12px;
    vertical-align: middle;
    color: var(--textdark);
    text-transform: capitalize;
    opacity: 0.87;
  }
  .containertp {
    margin-top: 33px;
    
  }
  .transfertbl {
    position: relative;
    width: 100%;
    background: var(--bgwhite);
    border: var(--borderdark);
    border-radius: 2px;
  }
  thead tr {
    border-bottom: 2px solid var(--borderlight);
    background-color: var(--primarylight);
  }
  tr {
    border-color: var(--borderlight);
  }
  table {
    border-color: var(--borderdark);
  }
  td {
    img {
      margin-top: 3px;
    }
  }
  td,
  th {
    border: none;
    text-align: left;
  }
  .transactiontitle {
    text-align: left;
  }
  .trouter {
    height: 65px;
    margin-bottom: 28px;
    width: 100%;
    background: var(--bgwhite);
    border: var(--borderdark);
    border-radius: 2px;
  }
  .title {
    
    opacity: 0.84;
    color: var(--textdarker);
    font-weight: 600;
    font-size: 32px;
    line-height: 67px;
    float: left;
    display: inline-block;
  }
  .actions {
    width: 100%;
    height: auto;
    text-align: left;
    margin-bottom: 20px;
  }
  .btmmargin {
    display: inline-block;
    width: 100%;
    max-width: 264px;
    margin-left: 6px;
    margin-bottom: 15px;
  }
  .searcharea .innerinputfield{
    margin-bottom: 0px;
  }
  .searchbox {
    background: var(--bgwhite);
    // height: 34px;
    // border-bottom: none;
    width: 208px;
    height: 31px;
    margin-left: -5px;
    display: flex;
    align-items: center;
  }
  .searchinside {
    position: absolute;
    right: 0%;
    top: 44%;
  }
  .innerinputfield {
    margin-left: 5px;
    font-size: 12px;
  }
  .trtbleheading {
    color: var(--textlight);
    font-weight: 400;
    font-size: 18px;
    line-height: 16.34px;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 68px;
    white-space: nowrap;
    .buttonwrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .editbutton {
        height: 30px;
        width: 30px;
        margin-right: 11px;
        border-radius: 9px;
        cursor: pointer;
      }
    }
  }
    .filtersection {
      display: inline-block;
      float: right;
      position: relative;
      margin-top: 12px;
    }
  .buttoncontainer {
    width: 208px;
    height: 34px;
    font-weight: 400;
    font-size: 16px;
  }

  .buttonmain {
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    height: 34px;
    margin: 0px;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    border-radius: 4px;
    color: var(--textwhite);
    background-color: var(--primary);
  }
  .buttoncontainersm {
    display: inline-block;
    margin-top: 11px;
    margin-bottom: 20px;
    position: relative;
    left: 24%;
  }
  .innercontent {
    font-weight: 400;
    font-size: 12px;
    vertical-align: middle;
    color: var(--textdark);
  }
  .addr {
    font-weight: 400;
    font-size: 12px;
    vertical-align: middle;
    color: var(--textdark);
    text-transform: capitalize;
  }
  .searchinside {
    margin-right: 5px;
  }
  .leftsection {
    float: right;
    display: inline-block;
  }
  .thethreebutton {
    text-align: right;
    margin-right: 1px;
  }

  .amt {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    vertical-align: middle;
    margin-top: -2px;
    color: var(--textdark);
    opacity: 0.87;
  }
  .amount {
    font-weight: 600;
    font-size: 12px;
    line-height: 40px;
    color: var(--textdark);
  }
  .form-switch .form-check-input {
    width: 50px;
    height: 26px;
    box-shadow: 0px 1px 3px 0px var(--borderdark) inset;
  }
  .form-check-input:checked {
    background-color: var(--bgwhite);
    border-color: var(--borderlight);
  }
  .form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url("../../images/switch.svg");
    cursor: pointer;
  }
  .pagination {
    justify-content: flex-end;
  }
  .leftright {
    margin-left: 8px;
    margin-right: 8px;
}
  .mleft {
    margin-left: 3px;
  }
  .iconcolor {
    path {
      fill: var(--textdark);
    }
  }
  .iconCopy {
    width: 100%;
    height: 100%;
  }
  .paginationicon {
    path {
      fill: var(--textdark);
    }
  }

  .edit {
    display: inline-block;
    cursor: pointer;
  }

  .del {
    display: inline-block;
    padding-left: 5px;
    cursor: pointer;
  }
  .paddingleft {
    padding-left: 28px;
  }

  @media screen and (max-width: 991px) {
    .filtersection{
      margin-top: 0px;
    }
    .hidden-for-mobile{
      display: none
    }
    .innerTd{
      height: 70px;
    }

    .actions{
      margin-top: 20px;
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .leftsection{
      float: unset;
      margin-top:0px;
    }
      .trouter{
        margin-bottom: 10px;
        height: auto;
      }
     
    .buttonmain{
      width: 164px;
      height: 34px;
      font-size: 12px;
    }
      .hidden-for-mobile {
        display: none;
      }
      .title {
        font-size: 16px ;
        opacity: 1;
        line-height: 24px;
        font-weight: 600;
        float: unset;
        padding: 20px;
        padding-left: 0px;
      }
    .hidden-for-mobile {
      display: none;
    }
    .filter .btn-primary{
      opacity: 0.54;
    }
    .cointag {
      margin-left: 30px;
    }
    .btnwithimg {
      width: 70px;
    }
    .amttxt {
      margin-right: 70px;
      margin-top: 20px;
    }
    .idtxt {
      margin-right: 96px;
    }
    .mobUpperline{
      margin-bottom: 0px;
      line-height: 16px;
      font-weight: 600;
      font-size: 12px;
      opacity: 0.87;
      color: ar(--textdark);
    }
    .mobLowerline{
      margin-bottom: 0px;
      line-height: 16px;
      font-weight: 600;
      font-size: 12px;
      opacity: 0.55;
      color: ar(--textdark);
    }
    .filtericon {
      margin-top: -3px;
      width: auto;
      svg{
        width: 16px;
        height: 16px;
      }
    }
    .filter .btn-primary {
      height: 33px;
      width: 46px;
      border: none;
      border-color: unset;
      box-shadow: 0px 1px 4px var(--borderdark);
      border-radius: 4px;
    }
    .filter .dropdown-toggle::after {
      vertical-align: 0.18em;
    }
    .filteric {
      margin-top: -4px;
    }
    .trasactiontitle {
      font-size: 16px;
    }

    .trouter {
      padding-left: 0px;
    }
    .coinlist {
      width: 61px;

      text-align: left;
      vertical-align: -webkit-baseline-middle;
    }
    .thethreebutton {
      text-align: left;
      // margin-right: 0px;
      margin-left: 0px;
    }
    .coinlist img.coinimg {
      margin-top: -8px;
      vertical-align: -webkit-baseline-middle;
    }
    .coinlistarea {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: center;
    }
    .arrow {
      margin-right: 10px;
      path {
        fill: var(--textdark);
      }
    }
    .id {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
    }

    .numbers {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      opacity: 0.87;
    }
    .filter .filtertext {
      display: none;
    }
    .btmmargin {
      display: none;
    }
    .datewidth {
      width: 130px;
    }

    .specialTd {
      padding: 0px;
    }
    .date {
      width: 130px;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      opacity: 0.54;
      margin-bottom: 0px;
    }
    .rightarrow{
      path{
        fill: var(--textlight);
      }
    }
    .id {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      opacity: 0.87;
    }

    tbody tr td:first-child .innerTd {
      padding-left: 15px !important;
      padding-right: 5px;
    }
  }
  @media screen and (min-width: 992px) {
    .hidden-for-desktop {
      display: none;
    }
  }
}
