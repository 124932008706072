#BusinessSignUp {
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 40px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 40px;
    margin-left: 40px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    // background: var(--borderlight);
    border-radius: 10px;
  }

  /* Handle on hover */
  // ::-webkit-scrollbar-thumb:hover {
  //   background: #b30000;
  // }
  .modal-dialog {
    max-width: 850px;
  }
  .modal-body {
    padding: 10px 45px 30px 45px;
    max-height: 690px;
    overflow-y: scroll;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }
  .imgonsignup {
    width: 80px;
    //height: 50px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .inputphone {
    .react-tel-input {
      height: 31px;
    }
  }
  .react-tel-input .form-control {
    height: 31px !important;
    border-radius: 10px !important;
    border-left: 1px solid var(--borderlight) !important;
    border-top: 1px solid var(--borderlight) !important;
    border-right: 1px solid var(--borderlight) !important;
    border-bottom: 1px solid var(--borderlight);
  }
  .outerconforbtn {
    margin: 0 auto 0;
    width: 160px;
  }
  .outerconttxt {
    margin-top: 24px;
  }
  .txtclass {
    font-size: 14px;
  }
  .formfieldholder {
    margin-bottom: 15px;
  }
  .whitebg {
    background-color: var(--bgwhite);
  }
  .checktext {
    font-size: 11px;
    font-weight: 400;
    color: var(--textlight);
    text-align: left;
  }
  .form-check-input[type="checkbox"] {
    border-radius: 0px;
    margin: auto 0;
    height: 15px;
    cursor: pointer;
    width: 15px;
    margin-right: 10px;
    border: 1px solid var(--borderdarker);
    box-shadow: none;
  }
  .form-check-input:active {
    border: transparent;
  }
  .checkspace {
    padding-top: 15px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .invalid-feedback {
    text-align: left;
    margin-left: 10px;
  }
  .firsttext {
    padding-top: 10px;
    padding-bottom: 15px;
  }
  .firsttextlineone {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    max-width: 288px;
  }
  .lineonestyle {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--textdarker);
    opacity: 0.87;
  }
  .firsttextlinetwo {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    max-width: 280px;
  }
  .linetwostyle {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: var(--textdarker);
    opacity: 0.87;
  }

  .bottomtext {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .bottomtextfirstline {
    width: 100%;
    max-width: 147px;
    text-align: center;
    margin: 0 auto;
  }

  .bottomtextline1 {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--textdarker);
    opacity: 0.54;
  }

  .bottomtextsecondline {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    max-width: 220px;
  }

  .bottomtextline2 {
    font-weight: 600;
    font-size: 10px;
    line-height: 11px;
    color: var(--textdarker);
    opacity: 0.54;
  }

  .buttonmain {
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    height: 48px;
    margin: 20px 0;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    border-radius: 4px;
    transition: all 0.4s ease-in-out;
    color: var(--textwhite);
    background-color: var(--primary);
  }
  // .buttonmain:hover {
  //   box-shadow: 0 7px 6px -6px var(--borderdarker);
  // }

  .linktag {
    padding-bottom: 15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    text-decoration-line: none;
    color: var(--primary);
  }
  .text {
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    text-decoration-line: underline;
    color: var(--primary);
    padding-left: 10px;
  }
  .input.form-control.input {
    height: 31px;
    border-radius: 10px;
    padding: 0 10px;
  }
  @media screen and (max-width: 492px) {
    .outerbox {
      top: 65%;
    }
  }
  @media (max-width: 992px) {
    .modal-dialog {
      max-width: 100%;
      width: 100%;
      height: 150vh;
      margin: 0 auto;
    }

    .modal-content {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
    .modal-body {
      max-height: 100%;
      overflow-y: hidden;
    }
  }
}
