.addressdropdown{
    text-align: end;
    margin-top: 5px;
    .btn{
      padding: 0px;
    }
    .select-address {
        text-align: right;
        color: var(--textdark);
        opacity: 0.54;
        font-size: 9px;
        font-weight: 400;
        display: inline-block;
        margin-left: 5px;
      }
      .topblock {
        display: inline-block;
        vertical-align: bottom;
      }
      .selectaddresstext{
        width: 75px;
        display: inline-block;
        white-space: break-spaces;
        text-align: left;
      }
      .rightalign {
        float: right;
      }
      .btn-primary {
        color: unset;
        background-color: unset;
        border-color: unset;
        border: unset;
      }
      .btn-check:active + .btn-primary,
      .btn-check:checked + .btn-primary,
      .btn-primary.active,
      .btn-primary:active,
      .show > .btn-primary.dropdown-toggle {
        color: unset;
        background-color: unset;
        border-color: unset;
        border: unset;
      }
      .dropdown-toggle::after {
        display: none;
      }
      .dropdownimg {
        width: 21px;
        height: 21px;
        border-radius: 50px;
        margin-right: 10px;
      }
      .personicon {
        width: 26px;
        path {
          fill: var(--textdark);
          opacity: 0.5;
        }
      }
}