.Branch {
  background-color: var(--bgwhite);
  padding: 15px;
  overflow: hidden;
  .mybalinpie {
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    overflow: hidden;
  }

  .balance {
    color: var(--textdark);
    font-weight: 600;
    font-size: 32px;
    line-height: 24px;
    margin-top: 30px;
    margin-bottom: 42px;
    text-align: left;
  }

  .text {
    display: inline-block;
    padding: 6px;
  }
  .text1 {
    display: inline-block;
  }
  .piechartsection {
    width: 100%;
    max-width: 467px;
    height: 350px;
    display: inline-block;
    position: relative;
  }
  .pietxt {
    font-size: 38px;
    font-weight: 700;
    text-align: center;
    display: block;
    line-height: 38px;
    color: var(--textdark);
    width: calc(100% - 4px);
    overflow: hidden;
    vertical-align: bottom;
    word-wrap: break-word;
  }
  .pietxt1 {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: var(--textdark);
  }
  .legendarea {
    width: 100%;
    margin-top: -50px;
    text-align: left;
  }
  .legend {
    width: auto;
    height: auto;
    display: inline-block;
    margin-right: 20px;
  }
  .legendcolor {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 2px;
    vertical-align: middle;
  }
  .legendname {
    display: inline-block;
    font-size: 14px;
    margin-left: 4px;
    color: var(--textdark);
    vertical-align: middle;
    font-weight: 600;
  }
}
