.LoanRequestPopupDetail {
  .modal-dialog {
    max-width: 650px;
  }
  .modal-body {
    padding: 45px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }
  .transectiontype {
    text-align: left;
    margin-bottom: 0px;
    margin-left:38px;
  }
  .transectiontext {
    font-size: 20px;
    color: var(--textdark);
    font-weight: 600;
  }
  .detail {
    text-align: left;
    margin-bottom: 15px;
  }
  .label {
    display: inline-block;
    vertical-align: top;
  }
  .labeltext {
    color: var(--textlight);
    font-weight: 600;
    font-size: 16px;
  }
  .value {
    display: inline-block;
    float: right;
  }

  .valuetransid {
    text-align: right;
    max-width: 100%;
  }

  .valueamout {
    display: inline-block;
    text-align: right;
    width: calc(100% - 63px);
  }
  .longvalue {
    display: inline-block;
    margin-top: -20px;
    max-width: 50%;
    width: calc(100% - 40px);
    overflow: hidden;
    vertical-align: bottom;
    word-wrap: break-word;
  }
  .valuetext {
    color: var(--textdark);
    font-weight: 600;
    font-size: 16px;
  }
  .linktag {
    text-align: left;
  }
  a {
    color: var(--primarydark);
    font-weight: 600;
    font-size: 14px;
  }
  .uppercase {
    text-transform: capitalize;
  }
  .transectionid {
    display: inline-block;
    width: 100%;
    max-width: 270px;
    word-wrap: break-word;
  }
  .options {
    margin: 0 auto;
    width: 225px;
    margin-bottom: 10px;
    margin-top: 50px;
  }
  .optiontxtholder1 {
    display: inline-block;
    text-align: center;
    margin-left: 8px;
    margin-right: 12px;
    word-wrap: break-word;
  }
  .confirm {
    .buttonmain {
      width: 153px;
      height: 34px;
      font-weight: 600;
      font-size: 16px;
      margin-top: 30px;
    }
  }
  .height {
    height: 80px;
  }
  .modalPrimaryHeader {
    max-width: 100%;
    margin-bottom: 0;
    margin-top: -1px;
    height: 29px;
    background-color: var(--primary);
  }
  @media screen and (min-width: 992px) {
    .hidden-for-desktop {
      display: none;
    }
  }
  
  
  @media screen and (max-width: 992px) {
    .modal-header{
      padding: 40px 15px 39px;
    }
    .modal-body {
      padding: 30px 15px;
      height: 100vh;
    }
    .modal-dialog {
      max-width: 100%;

      height: 100%;
      margin: 0 auto;
    }
    
    .comtxt1{
      width:60%;
      margin-left: 15%;
    }
    .modal-content {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
    .closebtn{
      display: none;
    }
    
     .back {
      float: right;
      // width: 9%;
      position: absolute;
      height: 56px;
      width: 96px;
      background-color: var(--primary);
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
  
      right: 0px;
      top: 50px;
      // margin-top: 19px;
    }
    .confirm{
      display: none;
    }
  }
}
