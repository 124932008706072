.FiatBalance {
  background-color: var(--bgwhite);
  padding: 15px;
  overflow: hidden;
  .loaderWrap{
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .spincolor{
    background-color: var(--primary);
  }
  .hideTag {
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: var(--textlight);
    background: linear-gradient(
      92.43deg,
      var(--bgwhite) 0%,
      var(--bglightgrey) 100%
    );
    border: 1px solid var(--borderlight);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0px 5px;
    height: 32px;
    line-height: 1.8;
    align-items: center;
    vertical-align: text-bottom;
    white-space: pre;
    display: inline-block;
    margin-right: 15px;
  }
  .hideeye {
    width: 16px;
    height: 14px;
    margin: -2px 5px 0px 0px;
    path {
      stroke: var(--textlight);
    }
  }
  .mybalinpie {
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    overflow: hidden;
  }
  .allign {
    text-align: left;
  }

  .outerclass {
    padding-left: 25px;
    // padding-top: 17px;
  }
  .balance {
    color: var(--textdark);
    font-weight: 600;
    font-size: 32px;
    line-height: 34px;
    margin-bottom: 10px;
    text-align: left;
    margin-right: 30px;
    display: inline-block;
  }

  .options {
    width: auto;
    height: 100%;

    // display: inline-block;
  }
  .optionposition {
    // position: absolute;
    // right: 0;
    // top: 0;
  }
  .businessoptionposition {
    // right: 30px;
  }
  .mainbuttonswrapper {
    margin-left: auto;
    padding-right: 5px;
    display: flex;
    justify-content: flex-end;
  }

  .mainbuttonswrapper .optionholder {
    margin-left: 25px;
  }

  // .paddingtop {
  //   padding-top: 170px;
  // }

  .displayedcurrencies {
    text-align: right;
  }
  .btmmargin {
    margin-bottom: 15px;
  }

  .downbtn {
    width: 40px;
    height: 40px;
    border-radius: 2px;
    display: inline-block;
    padding: 6px;
    margin: 15px;
    margin-left: 25px;
    border: 1px solid var(--borderlight);
    text-align: center;
  }
  .downbtn:hover {
    box-shadow: 0px 1px 10px var(--borderlight);
    cursor: pointer;
  }
  .setting {
    box-shadow: var(--bglightgrey);
    display: inline-block;
    padding: 6px;
  }
  .textclass {
    display: inline-block;
    padding: 6px;
  }
  .piechartsection {
    width: 100%;
    max-width: 467px;
    height: 350px;
    display: inline-block;
    position: relative;
  }
  .pietxt {
    font-size: 38px;
    font-weight: 700;
    text-align: center;
    display: block;
    line-height: 38px;
    color: var(--textdark);
    width: calc(100% - 4px);
    overflow: hidden;
    vertical-align: bottom;
    word-wrap: break-word;
  }

  .pieandlable {
    text-align: left;
    position: relative;
    top: 4%;
  }

  .left {
    display: inline-block;
    width: 100%;
    max-width: 100%;
  }

  .right {
    display: inline-block;
    vertical-align: middle;
  }
  .searchicon {
    margin-top: 7px;
    height: 20px;
    width: 20px;
    margin-bottom: 20px;
    path {
      stroke: var(--textlight);
    }
  }
  .legendarea {
    width: 100%;
    text-align: left;
  }
  .legend {
    width: auto;
    height: auto;
  }
  .legendcolor {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 2px;
    vertical-align: middle;
  }
  .legendname {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    margin-left: 4px;
    color: var(--textdark);
    vertical-align: middle;
  }

  .displycurr {
    color: var(--textdark);
    opacity: 0.85;

    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .btn-primary {
    background-color: unset;
    border-color: unset;
    border: unset;
    margin-top: -6px;
    margin-left: -6px;
  }
  .btn-check:active + .btn-primary,
  .btn-check:checked + .btn-primary,
  .btn-primary.active,
  .btn-primary:active,
  .show > .btn-primary.dropdown-toggle {
    color: unset;
    background-color: unset;
    border-color: unset;
    border: unset;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .searcharea .searchbox {
    max-width: unset;
    text-align: left;
    
    input {
      margin-bottom: 0px;
      margin-left: 1px;
    }
  }
  @media (max-width: 1199px) {
    .right {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 50px;
    }

    .legend {
      width: auto;
      height: auto;
      display: inline-block;
      margin-right: 20px;
    }

    .legendarea {
      width: 100%;
      text-align: left;
      margin-left: 25px;
    }

    .pietxt {
      padding-top: 15px;
      font-size: 28px;
      line-height: 24px;
      font-weight: 400;
      display: inline-block;
      width: calc(100% + -1px);
      overflow: hidden;
      vertical-align: bottom;
      word-wrap: break-word;
      color: var(--textdarker);
    }
  }

  @media (max-width: 992px) {
    .left {
      display: inline-block;
      width: 100%;
      max-width: 100%;
    }

    .mybalinpie {
      text-align: center;
    }
    .allign {
      text-align: center;
    }
    .allignMobile {
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
    }
    .balance {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      margin: 0px;
    }
    .hideTag {
      vertical-align: baseline;
      //margin-right: 151px;
      font-size: 14px;
    }
    .options {
      position: relative;
      margin-top: 10px;
    }

    .outerclass {
      padding-left: 10px;
      padding-top: 0px;
    }

    .pieandlable {
      text-align: left;
      position: relative;
      top: 4%;
      right: 2%;
    }

    .paddingtop {
      padding-top: 0px;
    }
    .mainbuttonswrapper {
      margin: -12px auto;
      justify-content: center;
      .optionholder {
        margin: 0px 15px;
      }
    }
    .mainbuttonswrapper .holdercss {
      box-shadow: 0px 1px 4px var(--borderdark);
      border-radius: 4px;
      width: 61px;
    }
  }
}
@media (max-width: 992px) {
  .FiatBalance {
    padding: 0px;
  }
}
