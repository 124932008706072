.circularloader{
    .loader {
        z-index: 1000;
        position: fixed;
        top:0;
        left: 0;
        background-color:var(--bgwhite);
        opacity: 0.7;
        width: 100%;
        height: 100%;
       
      }
      .circular{
        position: absolute;
        top:50%;
        left: 50%;
        margin-top: -17.2px;
        margin-left: -42px;
      }
      .spincolor{
          color: var(--primary);
      }
}