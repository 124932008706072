.NFCCard {
    display: flex;
    flex-wrap: wrap;
    padding: 0px;

    .App-cards-list {
        margin: 30px 15px 0px;
        border-radius: 15px;
        box-shadow: 0px 3px 20px var(--borderlight);

        .rccs {
            margin: 0px;

            .rccs__number{
                font-size: 18px;
            }
        }

        .rccs__card--unknown > div {
            background: transparent;
        }

        .rccs__card__background, .rccs__card--front{
            background: transparent;
        }
    }

    .rccs__expiry,
    .rccs__issuer {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .NFCCard{
        justify-content: center;
    }
}