#mybalancesdetails {
  padding: 38px 30px;
  .imgwrapper {
    width: 88px;
    height: 88px;
    border-radius: 50%;
    padding: 15px;
    margin: 0px auto;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .coinname {
    margin-top: 10px;
    margin-bottom: 0px;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
  }
  .coinnamesymbol {
    font-weight: 400;
    text-align: center;
    color: var(--textlight);
    font-size: 14px;
  }
  .buttonsholder {
    margin: 23px 0px;
    display: flex;
    justify-content: center;
    .optionholder {
      margin: 0px 10px;
    }
  }
  .balancewrapper {
    text-align: left;
    padding: 0px 25px;
  }
  .singlebalanceunit {
    margin: 25px 0px;
    width: 100%;
    height: 80px;
    p {
      margin: 0px;
      line-height: 1.8;
    }
    .completed {
      color: var(--green);
    }
    .cancel {
      color: var(--red);
    }
  }
  .balanceunitheading {
    font-size: 14px;
    font-weight: 400;
    color: var(--textdark);
  }
  .balanceunitcontent {
    font-weight: 600;
    font-size: 21px;
  }
  .changecolor {
    color: var(--green);
  }
  .coinprices {
    position: absolute;
    top: 30px;
    left: 20px;
    text-align: left;
    p {
      margin-bottom: 3px;
    }
    .coinpricetitle {
      font-size: 12px;
      color: var(--textlight);
    }
    .coinpricemain {
      font-size: 14px;
      font-weight: 600;
      &.completed {
        color: var(--green);
      }
      &.cancel {
        color: var(--red);
      }
    }
  }
  .coinBalAndSmb {
    font-size: 24px;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .coinBalModal {
    font-size: 17px;
    font-weight: 600;
    color: var(--textdark);
    margin-top: 5px;
  }
  .notransactiontext {
    margin-top: 20px;
    color: var(--textlight);
    font-size: 16px;
  }
  @media (max-width: 992px) {
    .balancewrapper {
      text-align: center;
    }
    .singlebalanceunit {
      padding-left: 0%;
    }
  }

  .redgraph svg g path {
    stroke: var(--red);
    stroke-width: 1;
  }
  .redgraph svg g g path {
    stroke: none;
    stroke-width: 0;
    fill: var(--red);
    opacity: 0.3;
  }
  .greengraph svg g path {
    stroke: var(--green);
    stroke-width: 1;
  }
  .greengraph svg g g path {
    stroke: none;
    stroke-width: 0;
    fill: var(--green);
    opacity: 0.3;
  }
}
