.searcharea {
  .searchbox {
    border: 1px solid var(--borderlight);
    width: 100%;
    max-width: 400px;
    height: auto;
    position: relative;
    border-radius: 4px;
    padding: 2%;
   // margin-left: 2%;
  }
  .innerinputfield {
    font-size: 15px;
    width: 100%;
    max-width: 357px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--textdark);

    opacity: 0.34;
    border: none;
    background: none;
    margin-left: -20px;
    color: var(--textdark);
    margin-bottom: 20px;
  }
  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
  }
  .searchinside {
    position: absolute;
    right: 1%;
    top: 45%;
    transform: translateY(-50%);
    // width: 15px;
    height: 27px;
    .closeicon {
      height: 16px;
      width: 16px;
    }
    .pointer {
      cursor: pointer;
      margin-right: 10px;
      margin-top: 3px;
    }
  }
  .searchinside {
    path {
      stroke: var(--textdark);
      fill: var(--textdark);
      opacity: 0.25;
    }
  }
  ::placeholder {
    color: var(--textdarker);
    /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--textdarker);
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--textdarker);
  }
  @media (max-width: 410px) {
    .innerinputfield {
      margin-left: -1px;
    }
  }
}
