.reporttable {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  text-align: left;
  .tableheading {
    color: var(--textdark);
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    padding: 5px;
    opacity: 0.54;
    vertical-align: middle;
    white-space: nowrap;
  }
  .status {
    margin-right: 10px;
  }
  .minwidth {
    min-width: 128px;
  }
  .minwidth1 {
    min-width: 160px;
  }
  .minwidth2 {
    min-width: 195px;
  }
  .coinlist {
    margin-top: 0px;
    margin-right: 16px;
    width: 150px;
    text-align: left;
  }
  .coincircle {
    height: 18px;
    width: 18px;
    margin-top: 2px;
  }

  .coinlist img.coinimg {
    margin-top: -8px;
    height: 10px;
  }
  .coinlistarea {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
  .tablestyle {
    width: 100%;
    background: var(--bgwhite);
    border: var(--borderdark);
    border-radius: 2px;
    border-collapse: separate;
    border-spacing: 0px;
    min-height: 150px;
  }
  tbody:before {
    content: "-";
    display: block;
    line-height: 7px;
    color: transparent;
  }
  .colspace {
    padding: 0px;
    padding-top: 13px;
  }
  .innerTd {
    padding: 9px;
    border-radius: 0.1px;
    border: 1px solid var(--borderlight);
    border-left: none;
    border-right: none;
    height: 70px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: var(--textdark);
    opacity: 0.87;
    // max-width: 60px;
  }

  tbody tr td:first-child .innerTd {
    padding-left: 15px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-left: 1px solid var(--borderlight);
  }
  tbody tr td:last-child .innerTd {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: 1px solid var(--borderlight);
  }
  .idotaleindark {
    .innerTd,
    tbody tr td:first-child .innerTd,
    tbody tr td:last-child .innerTd {
      border-color: var(--borderdarkblue);
    }
  }
  .table > :not(caption) > * > * {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: var(--borderlight);
  }
  thead tr th {
    border: 0.5px solid var(--borderlight);
    border-left: none;
    border-right: none;
    border-top-right-radius: 0.1px;
    border-top-left-radius: 0.1px;
    background-color: var(--bglightgrey);
  }
  thead tr th:first-child {
    padding-left: 15px;
    border-left: 1px solid var(--borderlight);
    border-top-left-radius: 10px;
  }
  thead tr th:last-child {
    border-right: 1px solid var(--borderlight);
    border-top-right-radius: 10px;
  }
  tr {
    border-color: var(--borderlight);
    height: 68px;
  }
  table {
    border-color: var(--borderdark);
  }
  td {
    border: none;
  }
  th {
    border: none;
    text-align: left;
  }
  .moreInnerTd {
    white-space: nowrap;
  }
  .date {
    min-width: 100px;
    // max-width: 110px;
    white-space: unset;
  }
  .round {
    min-width: 117px;
    white-space: unset;
  }
  .exhCurr {
    //color: var(--textlight);
  }
  @media (max-width: 992px) {
    .innerTd {
      font-size: 12px;
    }
    .tableheading {
      font-size: 12px;
    }
  }
}
