.CoinListItem {
  width: 100%;
  border: 0.99px solid var(--borderlight);
  border-radius: 6px;
  box-sizing: border-box;
  height: 70px;
  font-weight: 600;
  font-size: 16px;
  padding: 15px;
  line-height: 40px;
  text-align: left;
  .coinname {
    margin-left: 20px;
    width: 50%;
    height: 100%;
    overflow: hidden;
  }
  .coinbalance {
    float: right;
    // width: 30%;
    // overflow: hidden;
    height: 100%;
    width: 95px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .logoSize {
    height: 100%;
    width: auto;
  }
  .cointag {
    font-size: 8px;
    font-weight: 600;
    line-height: 16px;
    color: var(--textdark);
    display: inline-block;
    box-shadow: 0 2px 4px 0 var(--borderlight), 0 2px 4px 0 var(--borderlight);
    padding-right: 2px;
    padding-left: 2px;
    border-radius: 2px;
    vertical-align: middle;
    border-radius: 2px;

    text-transform: uppercase;
    margin-left: 10px;
  }

  @media screen and (max-width: 992px) {
    .coinbalance {
      width: 100px;
    }
  }
  @media screen and (max-width: 556px) {
    .coinbalance {
      width: 145px;
    }
  }
  @media screen and (max-width: 510px) {
    .coinbalance {
      width: 110px;
    }
  }
  @media screen and (max-width: 469px) {
    .coinbalance {
      width: 90px;
    }
  }

}
