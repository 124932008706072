#VerifyLinkScreen {
  .outerbox {
    border-radius: 2px;
    padding: 25px 25px;
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 99;
  }
  .img {
    width: 153px;
    //height: 50px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .outerconforbtn {
    padding-top: 16px;
    margin: 0 auto 0;
    width: 115px;
  }
  .outerconttxt {
    margin-top: 24px;
  }
  .txtclass {
    font-size: 14px;
  }
  .formfieldholder {
    margin-bottom: 25px;
  }
  .whitebg {
    background-color: var(--bgwhite);
  }
  .spanmsg{
    line-height: 25px;
    font-size: 16px;
    font-weight: 500;
    color: var(--textdark);
  }

}
