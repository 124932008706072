#IdoScreens {
  .containertp {
    margin-top: 33px;
    padding: 70px 5%;
    
  }
  .idobackground {
    max-width: 904px;
    height: auto;
    background-color: var(--bgwhite);
  }
  .innerpadding {
    padding-left: 100px;
    padding-top: 50px;
    padding-right: 50px;
    padding-bottom: 30px;
    text-align: center;
  }
  .direction {
    flex-direction: row;
  }
  .cardholder {
    width: 100%;
    max-width: 240px;
    position: relative;
  }
  .cardholder1 {
    width: 100%;
    max-width: 248px;
  }
  .inner1 {
    position: relative;
    left: 21%;
  }
  .hrtag {
    margin-left: 100px;
    margin-right: 100px;
    height: 1px;
    color: var(--borderdarker);
  }
  @media (max-width: 782px) {
    .center {
      margin: 0 auto 30px;
      text-align: center;
    }
    .transferform .transferpane,
    .transferform .roundpane {
      width: 100%;
      // max-width: 0px;
      margin-top: 30px;
      padding-left: 70px;
      padding-right: 70px;
    }
    .innerpadding {
      padding-left: 50px;
      padding-top: 50px;
      padding-right: 50px;
      padding-bottom: 0px;
      text-align: center;
    }
  }
  @media (max-width: 782px){
   .containertp{
     padding: 0 5%;
   }
  }
  @media only screen and (max-width: 1200px) and (min-width: 784px) {
    .direction {
      flex-direction: column;
    }
    .center {
      margin: 0 auto 30px;
      text-align: center;
    }
    .innerpadding {
      padding-left: 50px;
      padding-top: 50px;
      padding-right: 50px;
      padding-bottom: 0px;
      text-align: center;
    }
  }
  @media only screen and (max-width: 1400px) and (min-width: 1200px) {
    .direction {
      flex-direction: row;
    }
    .center {
      margin: 0 auto 30px;
      text-align: center;
    }
    .innerpadding {
      padding-left: 50px;
      padding-top: 50px;
      padding-right: 50px;
      padding-bottom: 0px;
      text-align: center;
    }
  }
}
