
.BankRequestPopup {
    .modal-dialog {
      max-width: 650px;
    }
    .modal-body {
      padding: 45px;
    }
    .modal-content {
      background-color: var(--bgwhite);
      border-radius: 10px;
    }
    .transectiontype {
      text-align: left;
      margin-bottom: 20px;
      padding-left: 35px;
  }
    .transectiontext {
      font-size: 20px;
      color: var(--textdark);
      font-weight: 600;
    }
    .detail {
      text-align: left;
      margin-bottom: 15px;
    }
    .label {
      display: inline-block;
      vertical-align: top;
    }
    .labeltext {
      color: var(--textlight);
      font-weight: 600;
      font-size: 16px;
    }
    .value {
      display: inline-block;
      float: right;
    }
  
    .valuetransid {
      display: inline-block;
      text-align: right;
      width: calc(100% - 72px);
    }
  
    .valueamout {
      display: inline-block;
      text-align: right;
      width: calc(100% - 63px);
    }
    .width1{
      width:calc(100% - 90px);
    }
  
    .width2{
      width:calc(100% - 172px);
    }
  
    .width3{
      width:calc(100% - 92px);
    }
  
    .width4{
      width:calc(100% - 84px);
    }

    .width5{
        width:calc(100% - 34px);
      }
    .longvalue {
      display: inline-block;
      width: calc(100% - 193px);
      overflow: hidden;
      vertical-align: bottom;
      word-wrap: break-word;
    }
    .valuetext {
      color: var(--textdark);
      font-weight: 600;
      font-size: 16px;
    }
    .linktag {
      text-align: left;
    }
    a {
      color: var(--primarydark);
      font-weight: 600;
      font-size: 14px;
    }
    .uppercase {
      text-transform: capitalize;
    }
    .transectionid {
      display: inline-block;
      width: 100%;
      max-width: 270px;
      word-wrap: break-word;
    }
    .height {
      height: 80px;
    }
    .modalPrimaryHeader {
      max-width: 100%;
      margin-bottom: 0;
      margin-top: -1px;
      height: 29px;
      background-color: var(--primary);
    }
    .btnwidth {
      width: 202px;
      margin: 0 auto;
      text-align: center;
  }
  .buttonmain{
    width: 100px;
    height: 30px;
    margin: 0px;
  }
  .approve {
    display: inline-block;
    width: 85px;
    margin: 0px 8px 0px auto;
    margin-right: 8px;
    text-align: center;
}
.reject {
  margin-right: 8px;
  display: inline-block;
  width: 85px;
  text-align: center;
  margin-left: 16px;
}
@media screen and (max-width: 992px) {
  .modal-header{
    padding: 40px 15px 39px;
  }
  .modal-body {
    padding: 45px 8px;
    // background-color: #FFFFFF;
  }
  .modal-dialog {
    max-width: 100%;
    // height: 100%;
    margin: 0 auto;
  }

  .modal-content {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    border-radius: 0px;
    border: none;
  }
  .back {
    float: right;
    // width: 9%;
    position: absolute;
    height: 56px;
    width: 96px;
    background-color: var(--primary);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    right: 0px;
    top: 50px;
    // margin-top: 19px;
  }
  .hidden-for-mobile{
    display: none;
  }

.transectiontype {
  padding-left: 10px;
 
}
.valuetext {
  font-size: 14px;
}
.labeltext {
  font-size: 14px;
}
}
@media screen and (min-width: 993px) {
  .hidden-for-desktop {
    display: none;
  }
}
  }
  