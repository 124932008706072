.withdrawsmallpopup {
  .modal-dialog {
    max-width: 650px;
  }

  .modal-body {
    padding: 45px;
  }

  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }

  .transectiontype {
    text-align: left;
    padding-left: 35px;
  }

  .transectiontext {
    font-size: 20px;
    color: var(--textdark);
    font-weight: 600;
  }

  .detail {
    text-align: left;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
  }

  .label {
    display: inline-block;
    vertical-align: top;
  }

  .labeltext {
    color: var(--textlight);
    font-weight: 600;
    font-size: 16px;
    white-space: nowrap;
  }

  .value {
    text-align: right;
  }


  .valuetext {
    color: var(--textdark);
    font-weight: 600;
    font-size: 16px;
  }

  .addresstext {
    word-break: break-word;
    margin-left: 10px;
  }

  .confirm {
    .buttonmain {
      font-weight: 600;
      font-size: 16px;
      width: 150px;
    }
  }

  .cancel {
    .buttonmain {
      background-color: var(--bgwhite);
      border: 1px solid var(--primary);
      color: var(--primary);
      font-weight: 600;
      font-size: 16px;
      width: 150px;
    }
  }

  .rowspace {
    margin-top: 20px;
  }

  @media screen and (max-width: 992px) {

    .transectiontext {
      font-size: 20px;
      color: var(--textdark);
      font-weight: 600;
    }

    .modal-header {
      padding: 40px 15px 39px;
    }

    .modal-body {
      padding: 45px 25px;
      background-color: var(--bgwhite);
      //height: 100vh;
    }

    .modal-dialog {
      max-width: 100%;

      height: 100%;
      margin: 0 auto;
    }

    .modal-content {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }

    .back {
      float: right;
      // width: 9%;
      position: absolute;
      height: 56px;
      width: 96px;
      background-color: var(--primary);
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      right: 0px;
      top: 50px;
      // margin-top: 19px;
    }

    .hidden-for-mobile {
      display: none;
    }

    .transectiontype {
      padding-left: 10px;
      padding-top: 20px;

    }

    .modalPrimaryHeader {
      max-width: 100%;
      margin-bottom: 0;
      margin-top: -1px;
      height: 29px;
      background-color: var(--primary);
    }
  }


  @media screen and (max-width: 440px) {
    .modal-body {
      padding: 45px 5px;
    }
  }

  @media screen and (min-width: 993px) {
    .hidden-for-desktop {
      display: none;
    }
  }
}