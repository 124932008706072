#signin {
  position: relative;
  &.fordarkbg {
    background-color: var(--blakish);
    .onboardingmainwrapper {
      background-color: var(--blakish);
    }
  }
  &.forlightbg {
    background-color: var(--white);
    .onboardingmainwrapper {
      background-color: var(--white);
    }
  }
  // .contForHidingOverflow{
  //   overflow: hidden;
  // }
  .outerbox {
    border-radius: 2px;
    padding: 25px 25px;
    overflow: hidden;
    width: 80%;
  }
  .decoration {
    text-decoration: none;
  }
  .img {
    width: 120px;
    height: 50px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .banner-image {
    width: 477px;
    height: 100%;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .banner-image1 {
    width: 700px;
    height: 100%;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .imageCol {
    position: relative;
    height: 547px;
  }
  .formfieldholder {
    margin-bottom: 15px;
  }
  .input-field-title {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    color: var(--textdark);
  }
  .formfieldholder1 {
    margin-bottom: 18px;
  }
  .outerconforbtn {
    height: 40px;
    width: 115px;
  }
  .outerconforbtn1 {
    .btnwithlefticon {
      height: 80px;
      text-align: left;
      width: 310px;
      box-shadow: none;
      margin: 0;
    }
  }
  .CustomButton .lefticonimg {
    height: 31px;
    width: 31px;
    margin: 13px 10px;
  }
  .outerconttxt {
    margin-top: 24px;
  }
  .whitebg {
    background-color: var(--bgwhite);
  }
  .invalid-feedback {
    text-align: left;
  }
  .geetest_captcha .geetest_holder,
  .geetest_popup_wrap .geetest_holder {
    width: 100% !important;
  }

  .geetest_popup_wrap {
    background-color: var(--bgdark);
    opacity: 0.5;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    z-index: 99999;
  }

  .geetest_captcha .geetest_holder .geetest_content .geetest_tip_container,
  .geetest_popup_wrap .geetest_holder .geetest_content .geetest_tip_container {
    padding: 0 6% 0 3%;
  }

  .link-to-signup {
    font-size: 11px;
    font-weight: 400;
    text-align: left;
    color: var(--textdark);
  }
  .signup-register-link {
    font-size: 11px;
    font-weight: 400;
    text-align: left;
    color: var(--primary);
    margin-left: 15px;
  }
  .topheader {
    font-size: 22px;
    font-weight: 600;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 35px;
  }
  .topheader1 {
    color: var(--textdark);
  }
  .topheader2 {
    color: var(--primary);
    margin-left: 8px;
  }
  .heading {
    font-size: 18px;
    font-weight: 600;
    color: var(--textdark);
    text-align: left;
    opacity: 0.87;
    margin-bottom: -2px;
  }
  .input.form-control.input {
    padding: 0px 0px;
    opacity: 0.87;
    background-color: transparent;
  }
  .passwordIcon {
    margin-top: -29px;
    width: 14px;
    right: 10px;
  }
  .buttontext2 {
    font-size: 16px;
    font-weight: 600;
  }
  .security {
    color: var(--primary);
    // margin-left: 8px;
    font-size: 22px;
    font-weight: 600;
    text-align: left;
    margin-top: 40px;
  }
  .subsecurity {
    color: var(--textdark);
    opacity: 0.7;
    // margin-left: 8px;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .sign-in-header {
    font-size: 22px;
    font-weight: 600;
    text-align: left;
  }
  .displayblock {
    display: inline-block;
  }
  .signuptxt {
    font-size: 14px;
    font-weight: 700;
    color: var(--primary);
    margin-left: 20px;
  }
  .forgot-pass-link {
    font-size: 11px;
    font-weight: 400;
    text-align: left;
    color: var(--primary);
    text-decoration: none;
  }
  .forgotpasslink {
    margin-top: 32px;
    margin-bottom: 32px;
    text-align: left;
  }
  .centeralign {
    text-align: center;
  }
  .topspace {
    margin-top: 30px;
  }
  .signinfooter {
    margin: 145px 0px 0px 0px;
    padding-bottom: 20px;
  }
  // @media screen and (min-width: 1200px) and (max-width: 1400px) {
  //   .outerbox {
  //     margin-left: 10%;
  //   }
  // }
  @media screen and (min-width: 768px) {
    .hide {
      display: none;
    }
  }

  @media screen and (min-width: 992px) {
    .hidesignup {
      display: none;
    }
    .displayblock {
      display: block;
    }
  }
  @media screen and (min-width: 1400px) {
    .outerconforbtn1 {
      .btnwithlefticon {
        height: 80px;
        width: 350px;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .outerbox {
      overflow: visible;
    }
  }
  @media screen and (max-width: 992px) {
    .outerbox {
      overflow: hidden;
    }
    .CustomButton .lefticonimg {
      height: 31px;
      width: 31px;
      margin: 13px 10px;
    }
    .ban-img {
      width: 100%;
      height: 100%;
    }
    .security {
      text-align: center;
    }
    .subsecurity {
      text-align: center;
    }
    .outerbox {
      width: 100%;
    }
    .forgotpasslink {
      text-align: center;
    }
    .topheader {
      text-align: center;
    }
    .hidetop {
      display: none;
    }
    .outerconforbtn {
      width: 100px;
    }
    .topspace {
      margin-top: 0px;
    }
    .banner-image {
      width: 380px;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .banner-image1 {
      width: 520px;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .imageCol {
      position: relative;
      height: 574px;
    }
    .animatedBanner .inner-animatedBanner {
      margin-top: 65px;
    }
    .outerconforbtn1 {
      .btnwithlefticon {
        margin: 0 auto;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .topheader {
      font-size: 18px;
      margin-bottom: 25px;
    }
    .heading {
      font-size: 16px;
    }
    .banner-image {
      width: 298px;
      height: 100%;
      position: absolute;
      top: 48%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .banner-image1 {
      width: 410px;
      height: 100%;
      position: absolute;
      top: 48%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .imageCol {
      position: relative;
      height: 490px;
    }
    .contForHidingOverflow {
      overflow: hidden;
    }
  }
  @media screen and (max-width: 420px) {
    .outerconforbtn1 {
      .btnwithlefticon {
        width: 270px;
      }
    }
    .buttontext2 {
      font-size: 12px;
    }
  }
  @media screen and (max-width: 514px) {
    .banner-image {
      width: 220px;
      height: 100%;
      position: absolute;
      top: 48%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .banner-image1 {
      width: 360px;
      height: 100%;
      position: absolute;
      top: 48%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .imageCol {
      position: relative;
      height: 356px;
    }
    // .animatedBanner .inner-animatedBanner{
    //   margin-top: 35px;
    //   transform: scale(0.35);
    // }
  }
}
