.mybalancepopup {
    .modal-body {
      background-color: var(--bgwhite);
      padding: 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .modalPrimaryHeader{
      height: 29px;
      background-color: var(--primary);
    }
    .modalContent{
      position: relative;
    }
    .coindetailsnav {
      height: 56px;
      width: 41px;
      background-color: var(--primary);
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0px;
      top: 30px;
    }
    .balancewrapper{
      display: none;
    }
  
    @media screen and (max-width: 992px) {
      .modal-dialog {
        max-width: 100%;
        width: 100%;
        height: 100%;
        margin: 0 auto;
      }
  
      .modal-content {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        border-radius: 0px;
        border: none;
      }
    }
  }
  