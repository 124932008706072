.depositfiatmodal {
  .info {
    border: 1px solid var(--borderlight);
    text-align: left;
    padding: 15px;
    border-radius: 6px;
    margin-top: 20px;
    h4 {
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: center;
    }
    li {
      font-size: 14px;
      font-weight: 400;
      margin-top: 10px;
    }
  }
  .toptitle {
    font-size: 14px;
    font-weight: 700;
  }
  .toptitle1 {
    font-size: 14px;
    font-weight: 700;
    float: left;
    line-height: 44px;
  }
  .topspace {
    max-height: 500px;
    overflow-y: scroll;
    padding-right: 15px;
  }
  .searchbox {
    max-width: 440px;
  }
  .banklistlayout .innerinputfield {
    margin-left: -58px;}
  .alink {
    text-decoration: none;
    color: var(--primary);
    font-size: 12px;
    font-weight: 600;
  }
  .toptoggle {
    float: right;
    margin-right: 15px;
  }
  .toprowspace {
    padding-top: 0px;
    padding-left: 25px;
  }
  .close {
    .buttonmain {
      height: 35px;
      width: 130px;
      border-radius: 2px;
      font-weight: 500;
      font-size: 14px;
      opacity: 0.8;
      margin-top: 20px;
    }
  }
  .fiatformarea {
    padding-left: 0px;
  }
  .modalPrimaryHeader {
    max-width: 100%;
    margin-bottom: 5%;
    margin-top: -1px;
  }
  .banklistlayout {
    padding-right: 20px;
    padding-top: 20px;
  }
  .modal-dialog {
    max-width: 1100px;
  }
  .modal-body {
    padding: 45px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }
  .fiatdropdown{
    width: 80%;
    margin: 0px auto;
    .toogleicon{
      margin-top: -4px;
    }
    .customDropDrownPlaceholder{
      line-height: 37px;
    }
  }
  @media (min-width: 992px) {
    .hidden-for-desktop {
      display: none;
    }
  }
  @media (max-width: 991px) {
    .colborder {
      border-left: none;
    }
    .toptoggle {
      float: none;
    }
    .info {
      border: none;
    }
    .toprowspaceforsmall {
      padding-top: 20px;
      margin-top: 20px;
    }
    // .modal-body {
    //   padding: 45px 25px;
    // }

    .closebtn {
      display: none;
    }
    .modal-body {
      padding: 0px 0px;
    }
    .modal-dialog {
      max-width: 100%;
      width: 100%;
      min-height: 100vh;

      margin: 0 auto;
    }

    .modal-content {
      width: 100%;
      min-height: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
    .toptitle1 {
      display: none;
    }
    .modalPrimaryHeader {
      height: 29px;
      background-color: var(--primary);
    }
    .back {
      height: 56px;
      width: 41px;
      background-color: var(--primary);
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0px;
      top: 48px;
    }

    .fiatformarea {
      padding-left: 0px;
    }
    .toprowspace {
      padding-top: 20px;
      padding-left: 15px;
      padding-right: 15px;
    }
    @media screen and (max-width: 410px) {
      .fiatdropdown{
        width: 85%;
      }
    }
    @media screen and (min-width: 991px) {
      .hidden-for-mobile {
        display: none;
      }
    }
    .banklistlayout {
      display: none;
    }
  }
}
.mod-exchangebackdrop {
  opacity: 0 !important;
}
.depositfiatmodal.hidethisexchangemodal {
  display: none !important;
}

@media (min-width: 992px) and (max-width: 1200px){
  .searchbox {
    max-width: 96% !important;
  }
  .banklistlayout .innerinputfield {
    margin-left: -13px !important;
  }
  .alink {
    text-decoration: none;
    color: var(--primary);
    font-size: 12px;
    font-weight: 600;
  }
}