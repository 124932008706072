#CreateBranch {
  padding: 0px 5%;
  .containerallignm {
    margin-top: 33px;
  }
  .fileErrorCustom {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 12px;
    color: var(--red);
  }
  .outercontainer {
    background-color: var(--bgwhite);
    padding: 5px 30px 60px 40px;
    overflow: hidden;
  }
  .titles {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
    color: var(--textdark);
  }
  .toptitle {
    color: var(--textdarker);
    font-size: 32px;
    font-weight: 600;
    text-align: left;
  }
  .inputbox {
    .input {
      border-radius: 4px;
      width: 430px;
      height: 31px;
      margin-top: 20px;
      border-color: var(--borderlight);
    }
  }
  .inputphone {
    margin-top: 20px;
    .react-tel-input {
      width: 430px;
      height: 31px;
      border-radius: 10px;
    }
  }
  .titlepass {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
  }
  .inputboxpass {
    display: inline-block;
    .input {
      border-radius: 4px;
      width: 430px;
      height: 31px;
      margin-top: 20px;
      border-color: var(--borderlight);
    }
  }
  .outerpass {
    text-align: left;
  }
  .outerspan {
    display: inline-block;
  }
  .resetpass {
    color: var(--primary);
    font-size: 16px;
    font-weight: 400;
    margin-left: 20px;
  }

  .topbuttons {
    display: inline-block;
    .buttonmain {
      width: 153px;
      height: 34px;
      margin-right: 25px;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      box-shadow: 2px 0px 3px var(--borderlight);
    }
  }
  .bottombuttons {
    display: inline-block;
    vertical-align: top;
    .buttonmain {
      width: 153px;
      height: 34px;
      margin-right: 25px;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      color: var(--primary);
      background-color: var(--bgwhite);
      box-shadow: 2px 0px 3px var(--borderlight);
      border: 0.5px solid var(--borderlight);
    }
  }
  .btmbtnoutercontainer {
    text-align: left;
    margin-top: 20px;
  }
  .textcontainer {
    display: inline-block;
  }

  .react-tel-input .form-control {
    width: 430px !important;
    border-radius: 4px !important;
    height: 31px !important;
    border-left: 1px solid var(--borderlight) !important;
    border-top: 1px solid var(--borderlight) !important;
    border-right: 1px solid var(--borderlight) !important;
    border-bottom: 1px solid var(--borderlight);
  }
  .marginlft {
    margin-left: 30px;
  }
  .outercls {
    text-align: left;
    margin-top: 15px;
  }
  .spanwidth {
    width: 300px;
  }

  .imgsize {
    width: 100%;
    height: 100%;
    // border-radius: 10px;
  }

  .spanmsg {
    font-size: 12px;
    font-weight: 400;
    color: var(--textlight);
    opacity: 0.9;
  }

  .InputField .passwordIcon {
    position: absolute;
    right: 17px;
    width: 15px;
    cursor: pointer;
    margin-top: -23px;
  }
  .display {
    display: inline-block;
    vertical-align: middle;
  }
  .imgouter {
    // border: 1px solid var(--borderlight);
    height: 180px;
    width: 200px;
    .integratedimage {
      object-fit: cover;
    }
  }
  .btntop {
    margin-top: 50px;
  }
  .invalid-feedback {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
  }
  .inputfilebtn {
    width: 0px;
  }
  .inputbuttonmain {
    font-weight: 600;
    width: 153px;
    height: 34px;
    font-size: 16px;
    padding: 4px 14px;
    margin-right: 10px;
    margin: 20px 0;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 4px;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    color: var(--primary);
    background-color: var(--bgwhite);
    border: 0.5px solid var(--borderlight);
    box-shadow: 2px 0px 3px var(--borderlight);
    cursor: pointer;
    text-align: center;
  }
  .outerbtn {
    display: flex;
  }
  .addNewText {
    margin-bottom: 0px;
    text-align: left;
    font-weight: 700;
    font-size: 16px;
    color: var(--textdark);
  }
  .arrowicon {
    transform: rotate(180deg);
    margin-right: 10px;
    path {
      fill: var(--borderdarker);
    }
  }
  .mb-5{
    margin-bottom: 0px;
  }

  .error-msg{
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 0px;
    color: var(--textdark);
  }

  @media (min-width:992px){
    .hidefordesktop{
      display: none;
    }
  }
  @media (max-width:410px){
    .inputbuttonmain {
      width: 125px;
      height: 40px;
    }
    .btntop {
      .buttonmain {
        width: 125px;
        height: 40px;
      }
    }
    .outerbtn{
      margin-left: 4%;
    }
  }
  @media (max-width: 510px) {
    .react-tel-input .form-control {
      width: 305px !important;
    }
    .inputboxpass {
      .input {
        width: 305px;
      }
    }
    .inputbox {
      .input {
        width: 305px;
      }
    }
    .btmbtnoutercontainer {
      display: flex;
    }
    .topbuttons .buttonmain {
      width: 135px;
    }
    .bottombuttons .buttonmain {
      width: 135px;
    }
  }
  @media (max-width: 768px) {
    .marginlft {
      margin-left: 0px;
    }
  }
  @media (max-width: 992px) {
    .display{
      width: 450px;
    }
    .btntop{
      margin-top: 20px;
    }
    .marginlft{
      margin-left: 0px;
    }
    .react-tel-input .form-control {
      height: 40px !important;
    }
    .inputboxpass {
      .input {
        height: 40px;
      }
    }
    .inputbox {
      .input {
        height: 40px;
      }
    }
    .inputphone {
      .react-tel-input {
        height: 40px;
      }
    }
    .outercontainer {
      padding: 0px;
    }
    .toptitle {
      font-size: 16px;
      font-weight: 700;
    }
    .InputField .passwordIcon {
      margin-top: -27px;
    }
    .imgouter {
      width: 308px;
      height: 268px;
    }
    .spanmsg {
      display: none;
    }
    .outerbtn {
      display: inline-block;
    margin-top: 3px;
    
    margin-right: 15px;
    }
    .titles {
      margin-top: 28px;
      font-weight: 400;
    }
    .titlepass {
      max-width: 28px;
      font-weight: 400;
    }
    .btmbtnoutercontainer {
      margin-top: 69px;
    }
    .inputbox {
      margin-top: -15px;
    }
    .outerpass {
      margin-top: -15px;
    }
    .inputphone {
      margin-top: 5px;
    }
    .hideformobile{
      display: none;
    }
  }
}
@media (max-width: 375px) {
  #CreateBranch {
    padding: 0px 3%;
  }
}
