.InputField {
  height: 100%;
  position: relative;
  .area {
    height: 100%;
  }
  .text-area-field{
    padding: 10px 2px !important;
  }
  .input.form-control.input:focus {
    border-color: var(--borderdarker);
    box-shadow: none;
    margin: none;
  }
  .input.form-control.bottomborder {
    border-bottom: 1px solid var(--borderdarker);
    border-top: none;
    border-left: none;
    border-right: none;
  }
    .file-upload-field{
      height: 41px !important;
    }
    .file-upload-field::-webkit-file-upload-button{
      visibility: hidden;
    }
    .file-upload-field::before {
      content: 'Choose File';
      display: inline-block;
      font-weight: 400;
      font-size: 12px;
      text-align: center;
      border: none;
      background-size: 300% 100%;
      border-radius: 4px;
      transition: all 0.4s ease-in-out;
      color: var(--textwhite);
      background-color: var(--primary);
      padding: 13px 13px;
      border-radius: 0;
      cursor: pointer;
    }
    .input.form-control.file-upload-field{
      padding: 0 !important;
    }
    .file-upload-field:hover::before {
      background:  #8f1ee6 !important;
    }
   
  .input.form-control.input {
    width: 100%;
    height: 45px;
    padding: 0 5px;
    border-radius: 0px;
    box-sizing: border-box;
    outline: none;

    font-weight: 400;
    position: relative;
    font-size: 12px;
    line-height: 18px;
    mix-blend-mode: normal;
    background: var(--bgwhite);
    color: var(--textdark);
    border-color: var(--borderdark);
  }
  .input.form-control.roundedborderfield {
    border: 1px solid var(--borderlight);
    padding: 10px;
    height: 40px;
    border-radius: 2px;
  }
  .passwordIcon {
    position: absolute;
    right: 20px;
    margin-top: -35px;
    width: 20px;
    cursor: pointer;
  }
  .othersIcon {
    position: absolute;
    top: 50%;
    right: 6%;
    transform: translateY(-50%);
    width: 20px;
    z-index: 1;
    cursor: pointer;
  }
  .linktxt {
    position: absolute;
    top: 55%;
    transform: translate(0, -50%);
    right: 5px;
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
    color: var(--primary);

    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    background: none;
    border: none;
  }
  .form-control.input.inputwoanyborder {
    border: none;
    font-size: 14px;
    height: 37px;
    font-weight: 600;
  }

  .input.form-control.withoutleftborder {
    width: 100%;
    height: 100%;
    padding: 0 10px;
    border-radius: 4px;
    box-sizing: border-box;
    outline: none;

    font-weight: 500;
    position: relative;
    font-size: 12px;
    line-height: 18px;
    mix-blend-mode: normal;
    border: none;
    background: var(--bgwhite);
    color: var(--textdark);
  }
  .input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    position: absolute;
    z-index: 2;
    display: block;
    width: 40px;
    height: 20px;
    margin-left: 15px;
    right: 0px;
    border-width: thin;
    cursor: pointer;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
