.exchangepopup {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 10px;
  .outercontainerpopup {
    margin: 0 auto 0;
    width: 115px;
    margin-top: 25px;
  }
  .btext1 {
    font-weight: 400;
    font-size: 14px;
    color: var(--textdark);
    margin-top: 20px;
    text-align: left;
    margin-left: 15px;
  }
  .DropdownSearch .search {
    top: 3px;
    height: 25px;
  }
  .exgbtn {
    width: 60px;
    height: 45px;
    box-shadow: 0px 4px 7px var(--borderlight);
    border-radius: 4px;
    vertical-align: middle;
    margin: 0 auto;
  }
  .exgbtn:hover {
    cursor: pointer;
  }
  .exgimg {
    margin-top: 15px;
    path {
      fill: var(--textdark);
    }
  }
  .insufficientbal {
    color: var(--red);
    margin-left: -28px;
    font-size: 17px;
    text-align: center;
    width: 198px;
  }
  .formoutercontainer {
    width: 100%;
  }

  .from,
  .to {
    display: inline-block;
    width: calc(50% - 40px);
    vertical-align: top;
  }
  .exchange {
    width: 80px;
    display: inline-block;
    margin-top: 60px;
    height: auto;
  }

  .txt {
    text-align: left;
  }
  .spincolor {
    color: var(--primary);
  }
}

@media (max-width: 768px) {
  .exchangepopup {
    .from,
    .to {
      display: block;
      width: 100%;
      vertical-align: top;
    }
    .exchange {
      margin: 25px;
    }
  }
}
