.transferform {
  width: auto;
  display: inline-block;
  .tablecontainer {
    width: auto;
    height: auto;
    background: var(--bgwhite);
    box-shadow: 0px 1px 10px var(--borderlight);
    border-radius: 2px;
    margin-top: 40px;
    margin-bottom: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .text {

    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: var(--textlight);
  }
  .transferpane,
  .roundpane {
    width: 600px;
    max-width: 700px;
    margin-top: 30px;
    padding-left: 70px;
    padding-right: 70px;
  }
  .roundpane .RoundForm.list {
    margin-left: -35px;
    margin-right: -35px;
  }
  .tabholder {
    display: inline;
  }
  .fieldssection {
    position: relative;
  }
  .fieldssectionmargin {
    margin-top: 15px;
  }
  .logosection {
    width: 40px;
    height: 35px;
    display: inline-block;
    border: none;
    border-left: 1px solid var(--borderdark);
    text-align: center;
    vertical-align: middle;
  }
  .DropdownSearch .cointext {
    margin-left: 0px;
    line-height: 35px;
  }
  .formfield {
    margin: 0 auto;
    position: relative;
    border: 1px solid var(--borderdark);
    height: 45px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 2px;
  }
  .inputfieldholder {
    display: inline-block;
    width: calc(100% - 40px);
    height: 100%;
    vertical-align: top;
    .input {
      background: transparent;
      border: none;
      padding: 5px;
    }
  }
  .dropdondholder {
    margin: 0 auto;
  }
  .logoholder {
    width: 26px;
    height: 26px;
    margin: 0 auto;
    border-radius: 2px;
    overflow: hidden;
    margin-top: 4px;
  }
  .coinimg {
    width: 18px;
    height: 18px;
    object-fit: contain;
  }
  .label {
   
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--textlight);
  }
  .labeloffield {
    text-align: left;
    margin: 0 auto;
    padding-bottom: 5px;
  }
  .outerconforbtn {
    width: 196px;
    // height: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 29px;
    padding-bottom: 15px;
  }
  .separator {
    position: absolute;
    height: 35px;
    border-left: 1px solid var(--borderdark);
    top: 33px;
    right: 40px;
  }
  .logoholder.address {
    opacity: 0.5;
    background: var(--green);
    .tickmark {
      width: 18px;
      path {
        fill: var(--textwhite);
      }
    }
  }
  .logoholder.address.valid {
    opacity: 1;
  }
}
@media (max-width: 700px) {
  .transferform {
    .transferpane,
    .roundpane {
      width: 100%;
      margin-top: 30px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
