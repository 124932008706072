.notificationdropdown {
  .notification {
    padding: 5%;
    width: 100%;
    overflow-y: scroll;
    height: 220px;
    overflow-x: hidden;
  }
  .notification::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 1 2px #f5f5f5;
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  .notification::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }

  .notification::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 1 2px rgba(255, 255, 255, 0.3);
    background-color: rgba(1, 127, 218, 0.5);
    opacity: 0.54;
  }
  .details {
    margin-bottom: 12px;
  }

  .img {
    width: 9px;
    height: 9px;
    margin-top: 0px;
    margin-right: 5px;
  }
  .line1 {
    display: flex;
    align-items: center;
  }

  .line2 {
    display: flex;
  }
  .line3 {
    display: flex;
  }
  .txt {
    font-size: 9px;
    font-weight: 400;
    color: var(--textlight);
    margin-right: 1px;
  }
  .txt_bold {
    font-size: 9px;
    font-weight: bold;
    color: var(--textlight);
    margin-right: 1px;
  }
  .amount-text {
    font-size: 9px;
    font-weight: 700;
    color: var(--primary);
    margin-right: 5px;
    margin-left: 5px;
  }
  .infoicon {
    rect {
      fill: var(--primary);
    }
  }
}
