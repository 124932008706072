#cyborTokenomics {
  text-align: left;
  line-height: 24px;
  border: 1px solid var(--borderlight);
  border-radius: 6px 6px 6px 6px;
  .tokenomicswrapper {
    height: 82px;
    padding-left: 86px;
    border-bottom: 1px solid var(--borderlight);
    display: flex;
    align-items: center;
    p {
      margin-bottom: 0px;
      font-weight: 600;
      font-size: 32px;
      opacity: 0.87;
      color: var(--textdark);
    }
  }
  g text {
    fill: var(--white) !important;
  }
  g::before {
    content: "%";
  }
  .colorarea {
    width: 18px;
    height: 18px;
  }
  .chartnlogowrap {
    position: relative;
    height: 60%;
    width: 100%;
  }
  .labels {
    // position: absolute;
    padding-left: 20px;
    margin-top: -30px;
    p {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.3%;
    }
  }
  .distributionSection5 .labels {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .cyborlogoingraph {
    height: 72px;
    top: 43%;
    left: 48.5%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
  .cyborlogoingraph1 {
    height: 72px;
    top: 41%;
    left: 48.5%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
  .nivobarwrapper {
    height: 400px;
  }
  .tokencybor {
    g text {
      fill: var(--textlight) !important;
    }
    g g line {
      stroke: var(--borderlight) !important;
    }
    g g :last-of-type(line) {
      stroke: var(--textdarker) !important;
    }
  }
  .incybortoken {
    margin-top: 0px;
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    .singledata {
      margin: 0px 10px;
    }
    .colorarea {
      border-radius: 3px;
    }
    p {
      color: var(--textlight);
      opacity: 0.54;
    }
  }
  .tokenomicsContentWrap {
    padding: 47px;
  }
  .btnsinido a {
    display: contents;
  }
  .form-check-input[type="checkbox"] {
    border-radius: 2px;
    height: 20px;
    cursor: pointer;
    width: 20px;
    margin: auto 10px auto 0;
    border: 1px solid var(--borderlight);
    box-shadow: none;
  }
  input {
    width: 20px;
    height: 20px;
  }
  .listWrapeer {
    display: flex;
    align-items: center;
  }
  .listDot {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: var(--borderdarker);
  }
  .listContent {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 10px;
    flex: 1;
    p {
      margin-bottom: 0px;
      color: var(--textdark);
      opacity: 0.57;
      font-size: 14px;
      white-space: nowrap;
    }
  }
  .listUnderlined {
    width: 100%;
    border-bottom: 1px solid var(--borderlight);
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 8px;
  }
  .listBoldContent {
    p {
      margin: 0px;
      font-weight: 700;
      font-size: 14px;
    }
  }
  .idoWhiteBtns .CustomButton .nonfilledbtn {
    max-width: 271px;
    height: 60px;
    border-radius: 4px;
    box-shadow: -3px 4px 4px var(--borderlight);
    border: none;
    font-size: 16px;
    font-weight: 600;
    border: 1px solid var(--borderdarkblue);
    line-height: 16px;
  }
  .buttonmain svg {
    margin-top: -3px;
  }
  &.idoindark {
    .idoWhiteBtns .CustomButton .nonfilledbtn {
      box-shadow: none;
      opacity: 1;
    }
    .specialforbordercolor tbody tr {
      border: 1px solid var(--borderdarkblue) !important;
    }
    .specialforbordercolor thead tr {
      border-bottom-color: var(--borderdarkblue) !important;
    }
    .tablestyle tbody tr {
      border-color: var(--borderdarker);
    }
    .tablestyle thead tr {
      border-color: var(--borderdarker);
    }
    .bodybordertoremove tbody tr {
      border: none;
    }
    .chartWrapper {
      border-left: 1px solid var(--borderdarker);
      border-color: var(--borderdarker);
    }
    .oddTrStyle {
      // background-color: var(--textdark);
    }
  }
  .idoWhiteBtns {
    width: 100%;
    .CustomButton {
      flex: 1;
      margin-left: 10px;
    }
  }
  .idoBlueBtns {
    .CustomButton {
      width: 100%;
      margin-left: 10px;
      .buttonmain {
        line-height: 16px;
        height: 60px;
        max-width: 271px;
        height: 60px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  // **************************************
  .tableandBoldtext {
    margin-top: 37px;
  }
  .section2Headtext {
    font-weight: 700;
    font-size: 16px;
    color: var(--textdark);
    line-height: 8px;
  }
  .tablestyle {
    th,
    td {
      color: var(--textdark);
      text-align: center;
      vertical-align: middle;
    }
    tr {
      border: 1px solid var(--borderlight);
      height: 68px;
    }
  }
  .oddTrStyle {
    background-color: var(--bglightgrey);
  }
  // ****************************
  .distribution {
    margin-top: 40px;
    p {
      margin-bottom: 0px;
    }
  }
  .distributionheading {
    font-size: 30px;
    line-height: 52px;
    font-weight: 600;
    color: var(--textdark);
    margin-top: 20px;
  }
  .normalheading {
    font-size: 16px;
    color: var(--textdark);
    line-height: 24px;
  }
  .chartWrapper {
    border: 1px solid var(--borderlight);
    border-left: none;
    height: 100%;
    text-align: center;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .toppie {
    font-size: 14px;
    font-weight: 600;
    color: var(--textdark);
    text-align: center;
  }
  .toptxt {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .pieimg {
    margin-top: 25px;
  }
  .tablecol {
    padding-right: 0px;
  }
  .tablecol2 {
    padding-right: 0px;
    overflow: auto;
  }
  .graphcol {
    padding-left: 0px;
  }
  .texttostart {
    th,
    td {
      text-align: left !important;
      padding: 4px 15px;
    }
  }
  .fontbold {
    font-weight: 600 !important;
  }
  .checkedyear {
    color: var(--primary) !important;
    font-weight: 700;
  }
  .uncheckedyear {
    color: var(--primary);
    font-weight: 700;
  }
  // .tableNchart{
  //     border-collapse: collapse;
  // }
  // section 6*******************
  .tokencybor {
    margin-top: 40px;
    p {
      margin-bottom: 0px;
    }
  }
  .tokengraphforTemp {
    width: 100%;
  }
  // section 8 ***************************
  .bonus {
    margin-top: 40px;
    p {
      margin-bottom: 0px;
    }
  }
  .bonusHeading {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
  @media (max-width: 1500px) {
    .tokencybor {
      .listWrapeer {
        width: 126%;
      }
    }
  }
  @media (max-width: 1399px) {
    .chartWrapper {
      border: 1px solid var(--borderlight);
      border-top: none;
      padding: 30px;
      // display: flex;
      // flex-direction: row;
      // justify-content: center;
    }
    .cyborlogoingraph {
      height: 112px;
      top: 44%;
      left: 49%;
    }
    .graphcol {
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      padding-right: 0px;
    }
    .chartnlogowrap {
      position: relative;
      height: 408px;
      width: 100%;
    }
    .distributionSection5 {
      .labels {
        width: 50%;
        margin: 0px auto;
        margin-top: -30px;
      }
    }
  }
}
