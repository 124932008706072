.SharePopup {
  .modal-dialog {
    max-width: 340px;
  }
  .modal-body {
    padding: 45px 45px 45px 45px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }
  .text {
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;
    color: var(--textdark);
  }

  .detail1 {
    text-align: left;
    margin-bottom: 15px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }

  .label {
    vertical-align: top;
  }

  .label-color {
    color: var(--textdarker);
    font-weight: 600;
    font-size: 16px;
  }

  .value {
    display: inline-block;
    margin-top: 20px;
  }

  .socialicon {
    display: inline-block;
    margin-left: 12px;
  }

  .arrowclass111 {
    width: 35px;
    height: 35px;
  }
  .arrowclass111 {
    path {
      fill: var(--bgwhite);
    }
  }

  .ml {
    margin-left: 0px;
  }

  @media screen and (max-width: 768px) {
    .modal-dialog {
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
    }
    .modal-body {
      padding: 35px;
    }
    .modal-content {
      width: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
    .detail1 {
      text-align: center;
    }
  }

  @media screen and (min-width: 359px) and (max-width: 400px) {
    .socialicon {
      display: inline-block;
      margin-left: 5px;
    }
    .arrowclass111 {
      width: 33px;
      height: 35px;
    }
  }
}
