.qrcode {
  .qrcard {
    width: 100%;
    max-width: 750px;
    // height:100%;
    max-height: 112vh;
    border: 1px solid var(--borderlight);
    border-radius: 6px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 30px;
    margin-top: 8%;
  }
  .options {
    margin: 0 auto;
    width: 275px;
    margin-bottom: 10px;
  }
  .qramountspace {
    width: 200px;
    margin: 0 auto;
  }
  .qrcontentspace {
    margin: 0 auto;
    line-height: 16px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  // .searchbox .innerinputfield{
  //   margin-top: -5px;
  // }
  .deactivationMessage{
    color: var(--red);
  }
  .qramount {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: center;
    opacity: 0.87;
  }
  .qramountcontent {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;
    margin: 0 auto;
    opacity: 0.87;
  }
  .applyingshadow {
    background: var(--bgwhite);
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    margin: 0 auto;
    width: 350px;
  }
  .qrbox {
    padding: 25px;
    margin-bottom: 20px;
    padding-top: 37px;
  }
  .qrname {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: var(--textdark);
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .optiontxtholder1 {
    display: inline-block;
    text-align: center;
    margin-left: 8px;
    margin-right: 8px;
  }
  .comtxt {
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
  .comtxt1 {
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    opacity: 0.54;
  }
  .optiontxtholder1 img {
    cursor: pointer;
  }
  .qrid {
    color: var(--textlight);
    text-align: left;
    word-break: break-word;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
  }
  .qrspace {
    margin-top: 25px;
  }
  // .options{
  //   margin-bottom:10px;
  // }
  @media screen and (min-width: 250px) and (max-width: 991px) {
    .qrcard {
      max-width: 700px;
      margin-top: 10px;
      border: 0;
    }
    .coinlistlayout .coinlistscrollable {
      display: none;
    }
    .applyingshadow {
      max-width: 350px;
    }
    .qrcode .optiontxtholder1 {
      margin-left: 10px;
    }
    .modal-body {
      padding: 0px 0px;
      height: 100vh;
    }
    .modal-dialog {
      max-width: 100%;

      height: 100%;
      margin: 0 auto;
    }
    .modal-content {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
  }
  @media screen and (min-width: 250px) and (max-width: 550px) {
    .qrcard {
      padding-left: 0px;
      padding-right: 0px;
    }
    .qrbox {
      margin-left: 48px;
      /* margin-right: 20px; */
      padding: 47px 21px 21px 21px;
      margin: 0 auto;
      margin-bottom: 20px;
    }
    .options {
      margin-top: -4px;
    }
  }
  @media screen and (max-width: 370px) {
    .applyingshadow {
      max-width: 315px;
    }
  }
}
