.buycyborcard {
  text-align: left;
  border: 0.5px solid var(--borderlight);
  border-radius: 6px;
  height: 100%;
  .buyheading {
    height: 82px;
    display: flex;
    align-items: center;
    padding-left: 77px;
    border-bottom: 0.5px solid var(--borderlight);
  }
  .buyTextStyle {
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;
    color: var(--textdark);
    opacity: 0.87;
  }
  
  .buycardcontent {
    padding: 30px 60px;
  }
  .maindrptoogle {
    display: flex;
    justify-content: space-between;
    padding: 4px 18px;
    border: 0.5px solid var(--borderlight);
    border-radius: 6px;
  }
  .togglecontent {
    p {
      margin-bottom: 0px;
    }
    .boldt {
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.005em;
      line-height: 22px;
      color: var(--textdark);
      opacity: 0.87;
    }
    .lightt {
      font-weight: 400;
      font-size: 11px;
      letter-spacing: 0.005em;
      line-height: 15px;
      color: var(--textdark);
      opacity: 0.55;
    }
  }
  .dropNcontWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .dropdown {
      width: 100%;
      max-width: 269px;
    }
  }
  .fancyDesign {
    display: flex;
    align-items: center;
    margin: 10px 0px;
    margin-left: 10px;
  }
  .emptyline {
    border-bottom: 1px solid var(--borderlight);
    width: 20px;
    margin: 0px 5px;
  }
  .fancyCircle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid var(--borderlight);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: var(--green);
  }
  .iconwithtextinabout {
    text-align: left;
    padding: 10px;
    width: 100%;
  }
  .buttoncontainer {
    .CustomButton {
      .buttonmain {
        width: 130px;
        border-radius: 4px;
        margin: 10px 0px;
      }
    }
  }

  @media (max-width: 992px) {
    .buttoncontainer .CustomButton .buttonmain {
      width: 147px;
      height: 40px;
    }
    .buycardcontent{
      padding: 20px 15px;
    }
    .buyheading{
      padding-left: 15px;
      height: 52px;
    }
  }
}
