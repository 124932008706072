.CoinListBalance {
  width: 100%;
  border: 0.99px solid var(--borderlight);
  border-right: none;
  border-radius: 6px;
  box-sizing: border-box;
  height: 87px;
  font-weight: 600;
  font-size: 16px;
  padding-left: 25px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  &.darkcoinlist {
    background: linear-gradient(90deg, #ffffff, #fafafa);
  }
  &.activeCoinBal {
    border: none;
    box-shadow: 0px 3px 20px var(--borderlight);
  }
  .coinimgwrapper {
    height: 42px;
    width: 42px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
  }
  .coinImageWrapflex {
    // flex: 1 1 10%;
  }
  .coinNameSymbolTag {
    flex: 1 1 40%;
    min-width: 50px;
  }
  .coinlistleftcontent {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .coinnameandsymbol {
    padding-right: 10px;
    flex: 1 1 45%;
    max-width: 25%;
    p {
      overflow: hidden;
      text-overflow: ellipsis !important;
      line-height: 0.8;
      margin: 10px 0px;
    }
    .coinSymbol {
      font-size: 13px;
      color: var(--textlight);
    }
  }
  .tagwrapper {
    margin: 0px 10px;
    border: 1px solid var(--borderlight);
    font-size: 12px;
    color: var(--textdark);
    text-align: center;
    border-radius: 5px;
    display: inline-block;
    padding: 1px 3px;
    // line-height: 15px;
    box-shadow: 0px 0px 2px var(--borderlight);
    p {
      margin: 0px;
      margin-top: 1px;
      line-height: 1.1;
    }
  }
  .coinname {
    margin: 0px 10px;
    font-weight: 600;

    text-overflow: ellipsis;
    overflow: hidden;
  }
  .onhoverimg {
    opacity: 0;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    z-index: 1;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  // .coinlistrightcontent{
  .rightnav {
    position: relative;
    height: 87px;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    min-width: 40px;
    background-color: var(--bglightgrey);
    // border-left: 1px solid var(--borderlight);
    border: 0.99px solid var(--borderlight);
    margin-right: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    // flex: 1 1 40%;
    &:hover {
      background-color: var(--primary);
      .verticledotsicon {
        opacity: 0;
      }
      .onhoverimg {
        opacity: 1;
      }
    }
  }
  .activecoinlist {
    background-color: var(--primary);
    border: 0.99px solid var(--primary);
  }
  // }
  .coinlistrightcontent {
    display: flex;
    justify-content: space-between;
    flex: 3;
  }

  .collapseicon {
    transition: 0.3s;
    path {
      fill: var(--textwhite);
      stroke: var(--textwhite);
    }
  }
  .verticledotsicon {
    transition: 0.3s;
    height: 25px;
    path {
      fill: var(--borderdark);
      stroke: var(--borderdark);
    }
  }
  .logoSize {
    height: 100%;
    width: auto;
  }
  .coinlistrightcontent {
    display: flex;
    align-items: center;
  }
  .smallbuttons {
    display: flex;
    // margin-left: 5%;
    margin-right: 2.5%;
    .optionholder {
      margin-right: 20px;
      margin-left: 5px;
    }
  }
  .coinSymbolbelowname {
    font-size: 13px;
    color: var(--textlight);
    margin: 0px 10px;
  }
  .hidesymbol {
    display: none;
  }
  .tagforlargescreen {
    display: inline-block;
  }
  .maketagabsolute {
    display: none;
  }

  @media (max-width: 600px) {
    .CoinListBalance {
      padding-left: 10px;
      height: 56px;
      .coinimgwrapper {
        height: 29px;
        width: 29px;
        padding: 5px;
      }
      .tagwrapper {
        font-size: 9px;
      }
      .maketagabsolute {
        top: -8px;
        left: -4px;
      }
      .coinnameandsymbol {
        p {
          font-size: 12px;
          font-weight: 600;
        }
        .coinSymbol {
          font-size: 12px;
          font-weight: 600;
        }
      }
      .rightnav {
        height: 56px;
        background-color: unset !important;
        border-left: none;
        border-right: 0.99px solid var(--borderlight);
      }
    }
  }
  @media screen and (max-width: 992px) {
    .CoinListItem {
      width: auto;
    }
    .coinname {
      font-size: 12px;
      font-weight: 400;
      color: var(--textdark);
      opacity: 0.54;
    }
    .coinSymbolbelowname {
      font-size: 14px;
      font-weight: 600;
      color: var(--textdark);
      opacity: 0.87;
    }
    .coinimgwrapper {
      height: 29px;
      width: 29px;
      padding: 5px;
    }
    .rightnav {
      height: 56px;
    }
  }
  @media (max-width: 992px) and (min-width: 530px) {
    .coinnameandsymbol {
      width: 150px;
    }
  }
  @media (max-width: 1314px) {
    .smallbuttons {
      margin-left: 10px;
      .optionholder {
        margin: 0px 5px;
      }
    }
  }
  @media (max-width: 1199px) {
    .smallbuttons {
      display: none;
    }
    .hidesymbol {
      display: block;
    }
    .maketagabsolute {
      display: block;
      position: absolute;
      top: -10px;
      left: -6px;
      background-color: var(--bgwhite);
    }
    .tagforlargescreen {
      display: none;
    }
  }
}

@media (min-width: 992px) and (max-width: 1064px) {
  .CoinListBalance {
    padding-left: 10px;
  }
}
@media screen and (max-width: 992px) {
  .CoinListBalance {
    height: 56px;
  }
}
