.WalletQr {
  padding: 28px;
  .outermost {
    display: inline-block;
    width: calc(100% - 97px);
  }
  .iconwithtextwallet {
    text-align: left;

    width: 100%;
  }
  .iconwallet {
    width: 23px;
    height: 23px;
    display: inline-block;
  }
.iconCopy{
  cursor: pointer;
}
  .graphwallet {
    width: 100%;
    height: 100%;
  }
  .textwallet {
    display: inline-block;
    vertical-align: text-top;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--textdarker);
  }
  .valuefield {
    border-radius: 4px;
    border: 0.5px solid var(--borderlight);
    padding:  5px 10px;
    width: 425px;
    // border-bottom: 1px solid var(--borderdark);
  }

  .address {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    display: inline-block;
    overflow: hidden;
    vertical-align: bottom;
    color: var(--textdarker);
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .outerclass {
    text-align: left;
    margin-top: 8px;
    padding-bottom: 28px;
  }

  .box {
    display: inline-block;
  }

  .copyicon{
    cursor: pointer;
    height: 36px;
    width: 20px;
    path{
      fill: var(--textlight)
    }
  }

  .coin {
    display: inline-block;
    padding-left: 25px;
    vertical-align: bottom;
  }

  .imgright {
    display: inline-block;
    vertical-align: top;
  }

  .imagesection {
    width: 97px;
    height: 97px;
    cursor: pointer;
    position: relative;
    &:hover {
      .zoomiconwrapper {
        opacity: 1;
      }
    }
  }
  .borderimg {
    position: absolute;
    width: 20px;
    height: 20px;
  }
  .borderimg-1 {
    top: -10px;
    left: -10px;
    border-top: 1px solid var(--borderlight);
    border-left: 1px solid var(--borderlight);
  }
  .borderimg-2 {
    right: -10px;
    top: -10px;
    border-top: 1px solid var(--borderlight);
    border-right: 1px solid var(--borderlight);
  }
  .borderimg-3 {
    bottom: -10px;
    right: -10px;
    border-bottom: 1px solid var(--borderlight);
    border-right: 1px solid var(--borderlight);
  }
  .borderimg-4 {
    bottom: -10px;
    left: -10px;
    border-bottom: 1px solid var(--borderlight);
    border-left: 1px solid var(--borderlight);
  }
  .zoomiconwrapper {
    position: absolute;
    opacity: 0;
    transition: 0.3s;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }

  .qrwallet {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1100px){
    .valuefield{
      width: 300px;
    }
  }
  @media (max-width: 991px){
    .valuefield{
      width: 100%;
      height: 37px;
      display: inline-block;
    }
    .outerclass{
      display: flex;
      justify-content: space-between;
    }
    .box{
      width: 85%;
    }
    .textwallet{
      width: 70%;
      margin: 0px auto;
      display: block;
      text-align: center;
    }
    .imgright{
      margin: 20px 0px;
    }
    .imagesection{
      width: 185px ;
      height: 185px ;
    }
    .zoomiconwrapper img{
      width: 100%;
      height: 100%;
    }
    .coin{
      width: 42px;
      height: 37px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 9px;
      margin-left: 5px;
      box-shadow: 0px 1px 4px var(--borderlight);
    }

  }

  // @media (max-width: 1400px) {
  //   .outerclass {
  //     margin-top: 10px;
  //   }

  //   .box {
  //     width: calc(100% - 290px);
  //   }
  // }

  // @media (max-width: 1200px) {
  //   .outerclass {
  //     margin-top: 10px;
  //   }

  //   .box {
  //     width: calc(100% - 290px);
  //   }
  // }
  // @media (max-width: 992px) {
  //   .textwallet {
  //     display: inline-block;
  //     padding-left: 10px;
  //     vertical-align: text-top;
  //     font-weight: 400;
  //     font-size: 16px;
  //     line-height: 24px;
  //     color: var(--textdarker);
  //     width: calc(100% - 24px);
  //   }

  //   .outerclass {
  //     text-align: left;
  //     padding-left: 0px;
  //     margin-top: 16px;
  //     padding-bottom: 0px;
  //   }

  //   .box {
  //     display: inline-block;
  //     width: calc(100% - 140px);
  //     vertical-align: bottom;
  //   }

  //   .coin {
  //     display: inline-block;
  //     padding-top: 0px;
  //     padding-left: 11px;
  //     vertical-align: top;
  //   }
  // }

  // @media (max-width: 767px) {
  //   .coin {
  //     display: block;
  //     padding-top: 15px;
  //     padding-left: 0;
  //     vertical-align: top;
  //   }

  //   .box {
  //     display: inline-block;
  //     width: calc(100% - 24px);
  //     vertical-align: bottom;
  //   }
  // }
}
@media (max-width: 991px){
  .WalletQr{
    padding: 0px;
    text-align: center;
  }
}