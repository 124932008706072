.currencydropdown {
  width: 210px;
  .optiontxt {
    font-size: 12px;
    font-weight: 400;
    margin-left: 10px;
    color: var(--textdarker);
  }
  .currency {
    width: 20px;
    height: 20px;
    path {
      fill: var(--textdark);
    }
  }
  .dropdownlink {
    width: 100%;
    height: 45px;
    box-sizing: border-box;
    border-radius: 2px;
    position: relative;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;
    color: var(--textdark);
    box-shadow: 0px 1px 5px var(--borderlight);
  }
  .dropdown-menu a {
    padding-left: 19px;
    height: 40px;
    line-height: 2;
  }
  .dropdown-menu {
    max-height: 209px;
    overflow-y: scroll;
    width: 100%;
    margin-top: 0px;
    border-top: none;
    background-color: var(--bgwhite);
    padding: 0px;
  }
  ul {
    margin-top: 0;
    margin-bottom: 0px;
  }

  .toogleicon {
    margin-left: 10px;
    height: 8px;
    line-height: 32px;
  }

  .dropdownarrow {
    path {
      fill: var(--textdark);
    }
  }
  .dropdownarrow1 {
    path {
      fill: var(--textdark);
    }
  }
  .dropdown.show {
    border-bottom: none;
    .dropdownarrow1 {
      transform: rotate(180deg);
      path {
        fill: var(--textdark);
      }
    }
  }
}
