.forgotpasswordpopup {
  .modal-body {
    padding: 40px;
  }
  .modal-dialog {
    max-width: 450px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }

  .confirm {
    .buttonmain {
      height: 39px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .cancel {
    .buttonmain {
      background-color: var(--bglightgrey);
      border: 1px solid var(--borderlight);
      color: var(--textdark);
      height: 39px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 16px;
    }
  }
  hr {
    color: var(--borderdark);
  }
  .maintitle {
    font-size: 16px;
    font-weight: 600;
    color: var(--textdark);
    margin-bottom: 20px;
  }
  .titlepass {
    text-align: left;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .txttp {
    width: 63%;
    margin: 0 auto;
    line-height: 18px;
  }
  .passwordtitle {
    /* Password Reset Email Sent */

    border-radius: nullpx;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .titleText {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0 auto;
    // margin-bottom: 10%;
    // margin-top:10%;
  }
  .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  .inner-text {
    font-size: 11px;
    font-weight: 400;
    color: var(--textdark);
    opacity: 0.54;
    text-align: center;
  }
  .input.form-control.input {
    border-radius: 4px;
    height: 33px;
    padding: 0 10px;
  }
  .exchangetext {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  .close-btn {
    margin-top: -20px;
  }
  .inputheader {
    font-size: 14px;
    font-weight: 400;
    color: var(--textdark);
    opacity: 0.87;
    text-align: left;
  }

  .reset-password-btn {
    border-radius: 4px;
    margin: 0 auto;
    width: 250px;
    margin-top: 35px;
  }

  @media screen and (min-width: 300px) and (max-width: 992px) {
    .modal {
      display: block;
      width: 100%;
      margin-left: 2%;
    }
  }
  @media screen and (max-width:410px) {
    .txttp {
      width: 100%;
    }
  }
  @media screen and (max-width:768px) {
    .modal-dialog {
      max-width: 100%;
      width: 100%;

      margin: 0 auto;
    }

    .modal-content {
      width: 100%;

      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
  }
}
