#PayScreen {
  .containerallignm {
    margin-top: 33px;
  }
  .outerbox {
    text-align: left;
    background-color: var(--bgwhite);
    border: 1px solid var(--borderlight);
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    max-width: 582px;
    height: auto;
  }


  .outerbox1 {
    text-align: center;
    margin: 0 auto;
    background-color: var(--bgwhite);
    border: 1px solid var(--borderlight);
    overflow: hidden;
    width: 100%;
    max-width: 582px;
    height: 377px;
    padding: 40px 60px 40px 60px;
  }
  .outerbox1 .sucesstxt{
  line-height: 257px;
}
  .merchantlogo {
    height: 40px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);
    img {
      width: auto;
      height: 100%;
    }
  }
  .payhead {
    padding: 20px;
    border-bottom: 1px solid var(--borderlight);
    position: relative;
  }

  .image {
    display: inline-block;
  }

  .text {
    display: inline-block;
    width: calc(100% - 60px);
    margin-left: 17px;
  }

  .textpayemnt {
    font-weight: 600;
    font-size: 16px;
    vertical-align: middle;
    color: var(--textdark);
  }

  .bottomsection {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .merchant {
    display: inline-block;
  }
  .textmerchant {
    font-weight: 600;
    font-size: 16px;
    color: var(--textdark);
  }

  .merchantext{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--textlight);
  }
  .imgsection {
    display: inline-block;
    width: calc(100% - 100px);
    vertical-align: middle;
  }
  .imgofmerchant {
     

    float: right;
  }
  .payertext{
    text-transform: capitalize;  
  }
  .merchanttext {
    text-transform: capitalize;
  }
  .imgmerchant {
    width: 100%;
    height: 100%;
  }

  .payer {
    display: inline-block;
    text-align: right;
    width: calc(100% - 50px);
  }

  .payertext {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--textlight);
  }
  .payersection {
    margin-top: 30px;
  }

  .amounttopay {
    margin-top: 50px;
    text-align: center;
  }

  .amount {
    padding: 10px;
  }

  .amountvalue {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .amounttopaytxt {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--textdark);
  }

  .valuetopaytxt {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: var(--textdark);
  }

  .outercontainer {
    width: 176px;
    text-align: center;
    margin: 0 auto 0;
  }
  .amountsections{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }
}
