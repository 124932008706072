.withdrawfiat {
  .outercontainer {
    height: 40px;
    margin: 0 auto 0;
    width: 115px;
    margin-top: 45px;
    margin-bottom: 25px;
  }

  .currencytoconvert {
    margin-bottom: 20px;
  }
  
  .box {
    height: auto;
    background: var(--bgwhite);
    box-shadow: 0px 4px 30px var(--borderlight);
    box-sizing: border-box;
    border-radius: 1px;
    padding: 20px;
  }

  .bold {
    font-weight: 600;
  }
  ::-webkit-scrollbar {
    opacity: 0;
    width: 5px;
    margin-left: 40px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey;
    background-color: var(--bglightgrey);
    opacity: 0.54;
    // border-radius: 40px;
    margin-left: 40px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--borderlight);
    border-radius: 10px;
  }
  .maxdropdown {
    .dropdown-menu {
      width: 100%;
    }
  }
  .textleft {
    text-align: left;
  }
  .bottomtext {
    text-align: left;
    margin-top: 10px;
  }
  .withtitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: var(--textdark);
    text-align: center;
    margin-bottom: 60px;
    margin-top: 45px;
    margin-left: 0px;
  }
  .withdrawspace {
    margin-top: 30px;
  }
  .balancespace {
    margin-top: 7px;
    margin-bottom: 7px;
  }
  .banksection{
    .formfield {
      margin: 0 auto;
      border: 1px solid var(--borderdark);
      height: 45px;
      padding-top: 5px;
      padding-bottom: 5px;
      border-radius: 9px;
    }
    .formfieldnew {
      margin: 0 auto;
      /* border: 1px solid var(--borderdark); */
      height: 41px;
      /* padding-top: 5px; */
      padding-bottom: 5px;
      border-radius: 2px;
    }
    .inputfieldholder {
      margin: 0 auto;
      border: 1px solid var(--borderdark);
      height: 45px;
      padding-top: 5px;
      padding-bottom: 5px;
      border-radius: 4px;
      .input {
        background: transparent;
        border: none;
        padding: 5px;
      }
    }
    ::placeholder {
      color: var(--primary);
      //opacity: 0.5;
    }
  
  
    .logomodified {
      width: 40px;
      height: 35px;
      display: inline-block;
      border: none;
      border-left: 1px solid var(--borderdark);
      text-align: center;
      vertical-align: middle;
      position: relative;
      float: right;
      bottom: 44px;
      left: 8px;
      vertical-align: middle;
      cursor: pointer;
    }
    .logoholder {
      width: 26px;
      height: 26px;
      //margin: 0 auto;
      border-radius: 2px;
      // overflow: hidden;
      margin-top: 4px;
      cursor: pointer;
    }
    .logosection{
      position: relative;
      float: right;
      bottom: 38px;
     // left: 8px;
    }
  
  }
  .banksection{
    margin-top: 8%;
   }
  .cointop {
    margin-top: 8px;
  }
  .addrtitle {
    margin-top: 36px;
  }
  .labelfont {
    font-size: 14px;
    font-weight: 400;
  }
  .labelfont1 {
    font-size: 16px;
    font-weight: 400;
  }
  .radiolable {
    font-size: 14px;
    font-weight: 700;
    text-align: left;
  }
  .labelfont3 {
    font-size: 12px;
    font-weight: 300;
    text-align: left;
    margin-left: 30px;
  }
  .dropdownlink {
    border-radius: 4px;
    border-bottom: 1px solid var(--borderdark);
    padding: 5px 16px;
  }
  .input.form-control.input {
    margin-top: -4px;
    border-radius: 4px;
    //border: 1px solid var(--borderdark);
    padding: 10px 18px;
  }
  .errors {
    font-size: 14px;
    font-weight: 400;
    color: var(--textdarker);
  }
  .dropdownsearchcol {
    .DropdownSearch .toogleicon {
      margin-right: 7px;
    }
  }
  .outercontainer1 {
    text-align: left;
    .buttonmain {
      border-radius: 3px;
      box-shadow: 1px 2px 9px var(--borderlight);
      background-color: var(--bgwhite);
      color: var(--primary);
      border: 0.5px solid var(--borderlight);
      width: max-content;
      height: 26px;
      padding: 0px 12px;
    }
  }
  .margintop {
    margin-top: 20px;
  }
  .addmargin {
    margin-top: 30px;
    width: 200px;
  }
  .addressbtnwrapper {
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 4px var(--borderlight);
    border-radius: 9px;
    width: 60px;
    height: 30px;
  }
  .amountmax {
    .linktxt {
      z-index: 3;
      right: -14px;
    }
  }
  .ortext {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    margin-top: 32px;
    color: var(--textdark);
    opacity: 0.54;
  }
  .linktxt {
    margin-top: -3px;
    .addressdropdown {
      margin-top: 0px;
      .btn {
        margin-top: -3px;
        background-color: var(--bgwhite);
        border-color: var(--bgwhite);
      }
    }
  }
  .forhidingradiobtn .form-check-input{
    display: none;
  }
  .allignleft{
    padding-left: 0px;
  }
  .banksection{
    .dropdown-menu{
      max-height: 150px;
    }
    .DropdownSearch .cointext{
      margin-left: 0px;
    }
    .dropdownlink{
      width: 240px;
      border:0px;
      .toogleicon{
        line-height: 24px;
      }
      .cointext{
        width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 2px;
      }
      .search{
        margin-left: 4px;
      }
      
    }
  }
  @media (max-width: 1200px){
    .logosection{
      left: 38px;
    }
    .banksection{
      .dropdownlink{
        width: 250px;
      }
      
    }
  }
  @media (max-width: 992px) {
    .amountmax {
      .linktxt {
        right: 5px;
      }
    }
    .withtitle {
      margin-left: 0px;
    }
    .addr {
      margin-top: 0px;
    }
    .addrtitle {
      margin-top: 10px;
    }
    .outercontainer1 {
      text-align: center;
      margin-top: 25px;
      .buttonmain {
        border-radius: 0px;
        box-shadow: none;
        background-color: unset;
        color: var(--primary);
        border: none;
        width: 100%;
        height: 26px;
      }
    }
    .outercontainer{
      margin-top: 10px;
    }
    .allignleft{
      padding-left: unset;
    }
    .logosection {
      left: unset; 
  }
  .banksection{
    .dropdownlink{
      width: 94%;
    }
  }
  .labelfont {
    font-size: 16px;
    font-weight: 600;
  }
  .labelfont1{
    font-weight: 600;
  }
  .cointop{
    margin-left: -10px;
  }
  .margin-top{
    margin-top: 5px;
  }
  }
  .form-check-input:checked {
    background-color: var(--textlight);
    border-color: var(--textdark);
    box-shadow: unset;
  }
  .form-check-input:focus {
    border-color: unset;
    box-shadow: unset;
  }
  .form-check {
    padding-left: 30px;
    margin-top: 10px;
  }
  .newacc {
    text-decoration: underline;
    font-size: 14px;
    background-color: var(--bgwhite);
    border-color: var(--textdark);
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 400;
    color: var(--textdark);
    opacity: 0.7;
    cursor: pointer;
  }
  .percentage {
    text-align: right;
    color: var(--primary);
  }
  .percentageleftmargin {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 2px;
    border: 1px solid var(--borderlight);
    border-radius: 3px;
    box-shadow: 0px 1px 4px var(--borderlight);
  }
  @media screen and (min-width: 992px) and (max-width: 2500px) {
    .dropdownlink {
      width: 280px;
    }
    .input.form-control.input {
      width: 280px;
      padding: 10px 18px;
    }
    .addressbtnwrapper {
      margin-right: -18px;
    }
    .percentage {
      margin-right: -18px;
      width: 280px;
    }
    .banksection .inputfieldholder{
      width: 280px;
    }
  }
  @media (min-width: 992px) and (max-width: 1198px) {
    .amountmax {
      .linktxt {
        z-index: 3;
        right: -45px;
      }
    }
  }
  @media (min-width: 992px) {
    .hidden-for-desktop {
      display: none;
    }
  }
}
