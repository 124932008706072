.modernBgCard{
    border: 0.8px solid var(--borderlight);
    border-radius: 6px;
    text-align: left;
    overflow: hidden;
    background-color: var(--bgfaintwhite);
    .m-coinnamewrapper{
        height: 82px;
        padding-left: 85px;
        display: flex;
        align-items: center;
        .m-coinwhenfromido{
            color: var(--textdarker);
            opacity: 0.87;
            line-height: 42px;
        }
    }
    .m-bgwrapfromido{
        height: 213px !important;
    }
    .m-coinname{
        font-size: 32px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0px;
        color: var(--textdarker);
        opacity: 0.87;
    }
    .m-bgwraper{
        height: 257px;
        // background-color: violet;
        display: flex;
        align-items: center;
        position: relative;
        // z-index: 1;
    }
    .formainbg{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
        img{
            width: 404px;
            height: 404px;
            position: absolute;
            right: -3.03%;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0.5;
        }
    }
    .m-coininfo{
        display: flex;
        align-items: center;
        margin-left: 50px;
        flex-wrap: wrap;
        z-index: 1;
    }
    .m-smalllogo{
        height: 40px;
        width: 40px;
    }
    .m-current{
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        color: var(--textwhite);
        opacity: 0.54;
    }
    .m-mainamount{
        font-size: 50px;
        font-weight: 700;
        line-height: 54px;
        color: var(--textwhite);
        margin-left: 7px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .m-imgamtwrapper{
        display: flex;
        align-items: center;
        margin-top: 5px;
    }
    .m-currentwrapper{
        padding: 10px 20px 10px 35px;
        border-right: 1px solid var(--white);
        max-width: 280px;
        overflow-x: hidden;
    }
    .m-bonuswrapper{
        border-right: none;
    }
    .coinpercentchange{
        font-size: 11px;
        font-weight: 700;
        margin-left: 3px;
        opacity: 0.87;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .greentext {
        color: var(--green);
        
    }
    .redtext {
        color: var(--red);
    }
    .bonustext{
        font-size: 9px;
        font-weight: 700;
    }
    .marketrategraphwrap{
        display: flex;
        align-items: center;
    }
    .graphincreaseicon{
        width: 19px;
        height: 13px;
    }
    .m-btnwrapper{
        align-self: flex-end;
        border-right: none;
       .nonfilledbtn{
            width: 130px;
            border-radius: 4px;
            opacity: 1;
            height: 40px;
            box-shadow: none;
            background-color: var(--white);
            border-color: var(--white);
        } 
    }
    
    @media (max-width: 1150px) and (min-width: 992px){
        .m-coinnamewrapper{
            padding-left: 15px;
        }
        .m-coininfo{
            margin-left: 15px;
        }
        .m-currentwrapper{
            padding: 10px 10px 10px 10px;
            max-width: 220px;
        }
    }
    @media (max-width: 991px){
        .m-coininfo{
            margin: 10px 0px 13px 17px;
        }
        .m-bgwraper{
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            height: 155px;
        }
        .m-bgwrapfromido{
            height: 145px !important;
        }
        .m-coinnamewrapper{
            height: auto;
            padding-left: 27px;
        }
        .m-coinname{
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.005em;
            color: var(--textwhite);
            font-weight: 700;
        }
        .m-currentwrapper{
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 0px;
            padding-bottom: 0px;
            // max-width: 100px;
        }
        .m-btnwrapper{
            align-self: unset;
            padding-bottom: 0px;
            padding-left: 27px;
            max-width: unset;
            .nonfilledbtn{
                width: 147px;
                height: 40px;
            }
        }
        .m-current{
            font-size: 8px;
            font-weight: 600;
            margin-bottom: 0px;
        }
        .m-smalllogo{
            width: 14px;
            height: 14px;
        }
        .m-mainamount{
            font-size: 16px;
            line-height: 24px;
        }
        .m-marketrate{
            margin-bottom: 0px;
        }
        .graphincreaseicon{
            width: 10px;
            height: 7px;
            margin-left: 10px;
        }
        .coinpercentchange{
            font-size: 8px;
        }
       .formainbg img{
           width: 190px;
           height: 190px;
           right: -17.73%;
           top: 58%;
       }
       .m-currentmarket{
           border-right: none;
       }
       &.cyborModernBgCard{
            .m-bgwraper{
                height: 191px;
            }
            .m-currentwrapper{
                max-width: 95px;
            }
            .m-bonuswrapper{
                max-width: unset;
                border-right: none;
                position: relative;
                overflow: visible;
            }
            .m-btnwrapper{
                max-width: unset;
                z-index: 1;
            }
            .bonustext{
                margin: 0px !important;
                position: absolute;
                left: 50%;
                width: 20vw;
                overflow: visible;
                line-height: 0px;
                margin-left: 5px;
            }
       }
    }
}