#myaccounts {
  .containerallignm {
    margin-top: 33px;
  }
  ::-webkit-scrollbar {
    opacity: 0;
    width: 5px;
    margin-left: 40px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey;
    background-color: var(--bglightgrey);
    opacity: 0.54;
    // border-radius: 40px;
    margin-left: 40px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--borderlight);
    border-radius: 10px;
  }
  .outercontainer {
    background-color: var(--bgwhite);
    overflow: hidden;
    margin-bottom: 25%;
  }
  .trasactiontitle {
    color: var(--textdark);
    font-weight: 600;
    font-size: 18px;
    line-height: 67px;
    text-align: left;
    padding-left: 20px;
  }
  .add {
    cursor: pointer;
  }

  .title {
    margin-left: 8px;
    margin-top: 3px;
    display: inline-block;
    font-weight: 600;
  }

  .myaccountcontent {
    padding: 0% 1%;
    display: flex;
  }
  .myaccountheading {
    display: flex;
    text-align: left;
    margin-top: 5px;
    //max-width: 400px;
  }
  .mainheading {
    font-weight: 700;
    color: var(--textdark);
    opacity: 0.87;
    font-size: 18px;
    margin: 4px 0px 17px 0px;
  }
  .mainheading1 {
    font-weight: 700;
    color: var(--textdark);
    opacity: 0.87;
    font-size: 18px;
    margin: 4px 0px 17px 0px;
  }
  .textbelowmainheading {
    margin: auto;
    font-size: 12px;
    color: var(--textdark);
    font-weight: 400;
    opacity: 0.54;
  }

  .banklogo {
    // position: absolute;

    width: 75px;

    height: 60px;
    border-radius: 9px;
  }

  .fixed {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .boxs {
    justify-content: center;
    align-items: center;
    border: 0.1px solid var(--borderlight);
    box-shadow: 1px 0px 1px var(--borderlight);
    margin-bottom: 10px;
    border-radius: 9px;
    padding: 10px;
    width: 200px;
    margin: 10px;
  }
  .bankaccounts{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .addlogo {
    display: block;
    margin: auto;
    cursor: pointer;
  }
  .decription {
    text-align: left;
    margin-top: 20px;
  }
  .desciptionline {
    color: var(--textdark);
    font-size: 14px;
    font-weight: 400;
    opacity: 0.87;
  }
  .col1 {
    display: flex;
    justify-content: center;
  }

  .addblue {
    //margin: 0px 185px;
    padding-top: 10px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    color: var(--primary);
  }
  .addnew {
    padding-top: 15px;
    padding-bottom: 9px;
    margin: auto;
    width: 10%;
  }
  .addbank {
    margin-left: 30px;
    cursor: pointer;
  }
  .logos-allign{
    display: flex;
    justify-content: space-between;
  }
  .deletebutton {
    height: 30px;
    width: 30px;
    border-radius: 9px;
    margin-left: 10px;
    cursor: pointer;
    rect {
      fill: var(--primary);
      opacity: 0.6;
    }
  }
  @media screen and (min-width: 992px) {
    .addbankcenter {
      margin: 58px 88px 29px 180px;
      // border: 1px solid red;
      cursor: pointer;
    }
  }
  // .addbankcenter {

  //   margin: 58px 88px 29px 180px;
  //   // border: 1px solid red;
  //   cursor: pointer;
  // }

  .col-lg-9 {
    width: 100%;
  }
  .box {
    margin-top: 25px;
    position: absolute;
    width: 510px;
    height: auto;
    overflow: scroll;
    border: 1px solid rgba(33, 33, 33, 0.12);
    box-sizing: border-box;
    border-radius: 10px;
  }
  .boxdark {
    margin-top: 25px;
    overflow: auto;
    width: 510px;
    height: 191px;
    //border: 1px solid rgba(255, 255, 255, 0.12);
    border:1px solid var(--borderlight);
    box-sizing: border-box;
    border-radius: 10px;
  }
  .boxdisplay {
    display: flex;
  }
  .logo {
    display: flex;
    margin: 24px 30px;
  }

  .addbanktext {
    font-size: 12px;
    font-weight: 400;
    color: var(--primary);
  }

  .Addlogo {
    margin: auto;
    text-align: center;
    max-height: 60px;
    max-width: 60px;
  }
  .Arrow {
    transform: rotate(180deg);
    path {
      fill: var(--shadow);
    }
  }

  .innerboxbusiness {
    margin: 16px 0px 0px 34px;
    width: 138px;
    height: 75px;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px var(--borderlight);
    border-radius: 9px;
  }
  .line1business {
    display: flex;
  }
  .cyborlogobusiness {
    margin: 10px 0px 0px 12px;
  }
  .cointitlebusiness {
    margin: 12px 0px 0px 6px;
    font-size: 12px;
    font-weight: 600;
    color: var(--textdark);
    opacity: 0.87;
  }
  .namebusiness {
    margin: 6px 0px 0px 0px;
    font-size: 10px;
    font-weight: 600;
    color: var(--textdark);
    opacity: 0.87;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .idbusiness {
    font-size: 10px;
    margin-top: 5px;
    font-weight: 400;
    color: var(--textdark);
    opacity: 0.5;
  }
  .Addlogobusiness {
    margin: auto;
    text-align: center;
    max-height: 60px;
    max-width: 60px;
  }
  .addnewbusiness {
    padding-top: 15px;
    padding-bottom: 9px;
    margin: auto;
    width: 10%;
  }
  @media screen and (min-width: 992px) {
    .addchange {
      display: none;
    }
    .addbanktext {
      display: none;
    }
    .innerbox {
      display: none;
    }
    .myaccountheading {
      display: none;
    }
  }
  @media screen and (max-width: 410px) {
    .box {
      width: 300px !important;
      overflow: scroll;
    }
    .boxdark {
      width: 320px !important;
      overflow: scroll;
    }
    .banklogo {
      width: 110px;
      height: 65px;
      margin: 15px 15px 16px 15px;
    }
  }

  @media screen and (max-width: 991px) {
    .outercontainer {
      padding: 0px 5%;
      margin-bottom: 0px;
    }
    .decription {
      margin-top: 430px;
    }
    .box {
      min-width: 305px;
      height: 368px;
      padding: 20px;
    }
    .addbankcenter {
     // margin-left: 100px;
    }
    .boxdark {
     // min-width: 354px;
      height: 368px;
      padding: 12px;
    }
    .myaccountcontent {
      display: inline;
    }
    .logo {
      display: inline;
      margin: 0px;
    }
    .col2 {
      padding-top: 20px;
      margin-left: 10px;
      margin-bottom: 165px;
    }

    .banklogo {
      // min-width: 136px;
      min-height: 77px;
      margin: 15px 10px 15px 15px;
    }

    .addbanktext {
      font-size: 12px;
      font-weight: 400;
      color: var(--primary);
      margin-left: 10px;
    }
    .box1 {
      width: 354px;
      display: flex;
      margin: 23px 30px 30px -10px;
      height: 122px;
    }
    .addblue {
      display: none;
    }
    .addchange {
      margin-left: 23px;
      font-size: 12px;
      font-weight: 400;
      color: var(--primary);
    }
    .addlogo {
      padding: 10px;
    }

    /*  .Addlogo {
      margin-left: 230%;
    }*/
    .mainheading {
      font-size: 16px;
      font-weight: 600;
      margin-left: 10px;
    }
    .mainheading1 {
      font-size: 18px;
      font-weight: 700;
      margin-left: 0px;
    }
    .textbelowmainheading {
      font-size: 14px;
      font-weight: 400;
      color: var(--textdark);
    }

    .desciptionline {
      font-size: 12px;
      font-weight: 400;
      color: var(--textdark);
    }
    .innerbox {
      margin: 11px 0px 0px 8px;
      width: 138px;
      height: 75px;
      border: 1px solid var(--borderlight);
      box-sizing: border-box;
      box-shadow: 0px 1px 4px var(--borderlight);
      border-radius: 9px;
    }
    .innerboxbusiness {
      margin: 11px 0px 0px 8px;
    }

    .line1 {
      display: flex;
    }
    .cyborlogo {
      margin: 10px 0px 0px 12px;
    }
    .cointitle {
      margin: 12px 0px 0px 6px;
      font-size: 12px;
      font-weight: 600;
      color: var(--textdark);
      opacity: 0.87;
    }
    .name {
      margin: 6px 0px 0px 12px;
      font-size: 10px;
      font-weight: 600;
      color: var(--textdark);
      opacity: 0.87;
    }
    .id {
      margin-left: 12px;
      font-size: 10px;
      font-weight: 400;
      color: var(--textdark);
      opacity: 0.5;
    }
    .deletebutton{
      margin-top: 15px;
    }
    
  }
  @media screen and (max-width: 1200px) {
    .box {
      width: 285px;
    }
    // .boxdark {
    //   width: 300px;
    //   overflow: auto;
    // }

    .banklogo {
      width: 71px;
      height: 57px;
      margin-left: 6px;
    }
    .boxs{
      width: 102px;
    }
  }
  @media screen and (max-width: 992px) {
    .boxs{
      width: 140px;
    }
  }
  @media screen and (max-width: 1400px) {
    .logo {
      margin: 24px 24px;
    }
    .banklogo {
      width: 75px;
      height: 57px;
    }
  }
  @media screen and (min-width: 992px) and (max-width: 2000px) {
    .box1 {
      margin-top: 20px;
    }
  }
}
#bankaccount-tooltip{
  z-index: 0;
}