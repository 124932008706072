.screenMainWrapper {
  position: relative;
  .sidebarCol {
    padding: 0px !important;
    // -ms-flex: 0 0 298px;
    // flex: 0 0 298px;
    width: 305px;
    position: sticky;
    // position: -webkit-sticky;
    top: 0;
    align-self: flex-start;
    // max-width: 298px !important;
    border-right: 1px solid var(--borderlight);
  }
  .screenMainRow {
    margin: 0px !important;
  }
  .screenContentCol {
    padding: 0px !important;
    width: calc(100% - 298px);
  }
  .screenContainer {
    // padding: 0px 41px;
  }
  .Nav-Wrp {
    padding: 0px 5%;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .screenContentContainer {
    margin-top: 30px;
  }
  @media (min-width: 992px) and (max-width: 1098px) {
    .screenContentCol {
      // padding: 0px !important;
      width: calc(100% - 248px);
    }
  }
  @media (max-width: 1043px) {
    .screenContentCol {
      width: 100%;
    }
    .screenContainer {
      padding: 0px;
    }
    .Nav-Wrp {
      padding: 0px 0px;
    }
  }
}
