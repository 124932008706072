.paymentdetail {
  .modal-body {
    padding: 45px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }
  .number-section {
    text-align: center;
  }
  .text {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: var(--textdarker);
  }
  .qrsection {
    width: 228px;
    height: 224px;
    margin: 0 auto;
    text-align: center;
  }
  .options {
    margin: 0 auto;
    width: 225px;
    margin-bottom: 10px;
    margin-top: 50px;
  }
  .optiontxtholder1 {
    display: inline-block;
    text-align: center;
    margin-left: 8px;
    margin-right: 12px;
    word-wrap: break-word;
  }
  .comtxt1 {
    width: 60%;
    margin-left: 15%;
  }
  .modalPrimaryHeader {
    max-width: 100%;
    margin-bottom: 5%;
    margin-top: -1px;
    height: 29px;
    background-color: var(--primary);
  }
  .qrpng {
    width: 100%;
    height: 100%;
  }
  .detail {
    text-align: left;
    margin-bottom: 15px;
    border-bottom: 1px solid var(--borderdark);
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    margin-top: 30px;
  }
  .label {
    display: inline-block;
  }
  .labeltext {
    color: var(--textdarker);
    font-weight: 600;
    font-size: 16px;
  }
  .value {
    display: inline-block;
    float: right;
  }
  .valuetext {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--textlight);
  }
  .back {
    float: right;
    // width: 9%;
    position: absolute;
    height: 56px;
    width: 96.05px;
    background-color: var(--primary);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    right: 0px;
    top: 42.5px;
    // margin-top: 19px;
  }
  .buttoncontainer {
    width: 160px;
    padding-top: 30px;
    text-align: center;
    margin: 0 auto;
  }
  @media screen and (max-width:992px) {
    .modal-dialog {
      max-width: 100%;

      height: 100%;
      margin: 0 auto;
    }
    .modal-body {
      padding: 0px;
      height: 100vh;
    }

    .modal-content {
      width: 100%;

      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
    .hidden-for-mobile {
      display: none;
    }
    .number-section {
      text-align: left;
      margin-left: 36px;
      margin-top: 31px;
      margin-bottom: 20px;
    }
  }
  @media screen and (min-width:992px) {
    .hidden-for-desktop {
      display: none;
    }
  }
}
