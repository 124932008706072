.Sidebar {
  width: auto;
  background-color: var(--bgwhite);
  padding-bottom: 25px;
  border-radius: 2px 2px 0px 2px;
  height: 100vh;
  overflow-y: auto;
  text-align: left;
  overflow-y: overlay;
  scrollbar-width: 1px;
  scrollbar-color: #c6c6c6;
  &::-webkit-scrollbar {
    width: 1px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #c6c6c6;
    scrollbar-width: 0.5px;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background: #c6c6c6;
    }
  }
  .sidebaricon.open {
    transform: rotate(90deg);
    transition: transform 0.3s ease; 
  }
  .sidebaricon {
    width: 28px;
    height: 25px;
    vertical-align: bottom;
    path {
      fill: var(--textdarker);
    }
  }
  .sidebaricon-admin {
    width: 21px;
    height: 25px;
    vertical-align: bottom;
    margin-right: 2px;
    margin-top: -5px;
    path {
      fill: var(--textdarker);
    }
  }
  .sidebaricon1 {
    width: 24px;
    height: 25px;
    margin-right: 4px;
    vertical-align: bottom;
    path {
      fill: var(--textdarker);
    }
  }
  .homeicon {
    margin-top: -5px;
  }
  .sidebaricon2 {
    width: 24px;
    height: 22px;
    margin-right: 4px;
    margin-top: -5px;
    // vertical-align: bottom;
    path {
      fill: var(--textdarker);
    }
    path :active {
      fill: var(--primary);
    }
  }

  .sidelogo {
    margin-bottom: 32px;
    margin-top: 21px;
    margin-left: 112px;
    width: 65px;
  }
  .item-icon img {
    width: 30px;
    height: 27px;
  }
  .item-icon {
    opacity: 0.87;
    margin-right: 4px;
  }
  .specialcybor {
    display: inline-block;
  }
  .cyborsidebarwrapper {
    display: flex;
    align-items: center;
    width: 90%;
    margin: 0px;
    // margin-bottom: 10px;
    float: left;
    height: auto;
  }
  .toogleicon {
    float: right;
    height: auto;
    width: auto;
  }
  .balances {
    margin-left: 4px;
    overflow-wrap: break-word;
  }
  .hrbelownav {
    color: var(--textwhite);
    margin: 1rem 15%;
  }

  .themeswitchwrapper {
    margin-left: 52px;
    margin-right: 52px;
    align-items: center;
    justify-content: space-between;
    &.textformode {
      width: 100px;
    }
    .modetexts {
      width: 55px;
      font-size: 9px;
      text-align: center;

      color: var(--textwhite);
      opacity: 0.54;
    }
    .toogleicon {
      float: unset;
      svg {
        path {
          fill: var(--primary);
        }
      }
    }
    .filtertext {
      font-weight: 500;
      font-size: 14px;
      color: var(--textdarker);
      text-transform: uppercase;
    }

    .checkedHandleIcon {
      transform: translateX(0px);
      width: 37px !important;
    }
    .uncheckedHandleIcon {
      margin-left: 0px;
    }
    .react-switch-bg {
      width: 100px !important;
      height: 28px !important;
    }
    .react-switch-handle {
      width: 50px !important;
      border-radius: 5px !important;
      height: 28px !important;
      top: 0px !important;
      div {
        height: 28px !important;
        width: 50px !important;
      }
    }
    .toogleon .react-switch-handle {
      transform: translateX(50px) !important;
    }
    .react-switch-bg {
      // background: var(--darkgrey) !important;
      border-radius: 6px !important;
      // opacity: 0.8;
      div {
        height: 28px !important;
        width: 50px !important;
      }
    }
  }
  .switchwrapper {
    margin-bottom: 20px;
    margin-left: 45px;
  }
  .linebelowtoogle {
    color: var(--primarylight2);
    height: 2px;
  }
  .css-16jesut {
    background-color: var(--bgwhite);
  }
  .css-12w9als {
    text-overflow: unset;
    word-break: break-all;
    white-space: unset;
  }
  .css-16jesut > .ps-menu-button {
    background-color: var(--bgwhite);
    opacity: 0.9;
    min-height: 27px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    text-align: left;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    font-style: normal;
    color: var(--textdarker);
    padding-left: 30px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    letter-spacing: 0.5px;
  }
  .css-1t8x7v1 > .ps-menu-button {
    background-color: var(--bgwhite);
    opacity: 0.9;
    min-height: 27px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    text-align: left;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    font-style: normal;
    color: var(--textdarker);
    padding-left: 30px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    letter-spacing: 0.5px;
  }
  .css-ewdv3l {
    background-color: var(--bgwhite);
  }

  .css-honxw6,
  .css-jn69v9 {
    width: 9px;
    height: 9px;
  }
  .css-wx7wi4 {
    margin-right: 0px;
    margin-left: -4px;
  }
  .css-1tqrhto > .ps-menu-button {
    opacity: 0.87;
    font-size: 18px;
    font-weight: 300;
    padding-left: 60px;
    color: var(--textdarker);
  }
  .css-12w9als {
    border-right: none !important;
  }
  .css-1654oxy .ps-active {
    // background: linear-gradient(90deg, #8800c7, #9367cb);
    border-right: 6px solid var(--primary);
    cursor: pointer;
    text-align: left;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    font-style: normal;
    color: var(--primary);
    padding-left: 14px;
    padding-right: 5px;
    // background-color: rgba(136, 0, 199, 0.2);
    .sidebaricon path,
    .item-icon path {
      fill: var(--primary);
    }
    .sidebaricon1 path,
    .item-icon path {
      fill: var(--primary);
    }
    .sidebaricon2 path,
    .item-icon path {
      fill: var(--primary);
    }
  }
  .css-14xmt93 > .ps-menu-button:active {
    // background: linear-gradient(90deg, #8800c7, #9367cb);
    border-right: 6px solid var(--primary);
    cursor: pointer;
    text-align: left;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    font-style: normal;
    color: var(--textdarker);
    // padding-left: 14px;
    // padding-right: 5px;
  }
  .css-14xmt93 > .ps-menu-button {
    padding-left: 60px;
    cursor: pointer;
    text-align: left;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    font-style: normal;
    color: var(--primary);
    border-right: 6px solid var(--primary);
  }

  .css-1654oxy > .ps-menu-button:active {
    border-right: 6px solid var(--primary);
  }
  .css-16jesut > .ps-menu-button:hover,
  .css-14xmt93 > .ps-menu-button:hover,
  .css-1tqrhto > .ps-menu-button:hover,
  .css-1t8x7v1 > .ps-menu-button:hover,
  .css-1654oxy > .ps-menu-button:hover {
    // background: linear-gradient(90deg, #8800c7, #9367cb);
    background-color: rgba(136, 0, 199, 0.2);
    color: var(--primary);
    font-weight: 600;
    .sidebaricon path,
    .item-icon path {
      fill: var(--primary);
    }
    .sidebaricon1 path,
    .item-icon path {
      fill: var(--primary);
    }
    .sidebaricon2 path,
    .item-icon path {
      fill: var(--primary);
    }
  }

  .css-1wvake5 {
    position: relative;
    border-right-width: 0cm;
    border-right-style: none;
    border-color: unset;
    -webkit-transition: width, left, right, 300ms;
    transition: width, left, right, 300ms;
    width: auto;
    min-width: auto;
    background-color: var(--bgwhite);
  }
  @media (max-width: 991px) {
    .switchwrapper {
      .react-switch-bg {
        width: 206px !important;
      }
      .react-switch-handle {
        width: 103px !important;
        border-radius: 5px !important;
        height: 33px !important;
        top: 0px !important;
        div {
          height: 33px !important;
          width: 103px !important;
        }
      }
      .react-switch-bg {
        background: var(--darkgrey) !important;
        border-radius: 5px !important;
        // opacity: 0.8;
        div {
          height: 33px !important;
          width: 103px !important;
        }
      }
      .toogleon .react-switch-handle {
        transform: translateX(103px) !important;
      }
      // .switchwrapper{
      margin-right: 0px;
      margin-top: 0px;
      // }
    }
  }
}
@media (max-width: 1098px) and (min-width: 992px) {
  .sidelogo {
    margin-left: 90px !important;
  }
}

@media (max-width: 1044px) and (min-width: 992px) {
  .Sidebar {
    height: 123vh;
  }
}
