.coinlist {
  // min-width: 150px;
  display: inline-block;
  .coincircle {
    height: 40px;
    width: 40px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    border-radius: 100px;
    text-align: center;
  }
  img.coinimg {
    width: auto;
    height: 25px;
    margin-top: 7px;
  }
  // moving search field
  .innerinputfield{
    margin-left:40px;
    margin-top:-2px;
  }
  .coinlistarea {
    font-weight: 600;
    font-size: 12px !important;
    color: var(--textdark);
    margin-left: 10px;
    display: inline-block;
    // width: calc(100% - 60px);
    max-width: 300px;
    height: auto;
    p{
      margin-bottom: 0px;
      line-height: 1.4;
    }
    .nameclass{
      font-weight: 600;
      color: var(--textdark);
    }
    .symbolclass{
      font-size: 12px;
      color: var(--textlight);
      font-weight: 400;
      white-space: nowrap;
    }
  }

  @media (max-width: 992px) {
    .coinlistarea {
      width: calc(100% - 60px);
    }
    td span {
      line-height: 18px;
    }
  }
}
