#transferhistory {
  padding: 0px 50px;
  .table-responsive-sm {
    overflow-y: hidden;
    overflow-x: auto;
    // border: 1px solid var(--borderlight);
    min-height: 450px;
  }
  .table > :not(caption) > * > * {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .containertp {
    margin-top: 33px;
  }
  // .transfertbl {
  //   position: relative;
  //   width: 100%;
  //   background: var(--bgwhite);
  //   border: var(--borderdark);
  //   border-radius: 2px;
  // }

  .maintdwrapper {
    position: relative;
  }
  .innerTdforprofile {
    padding: 9px;
    border-radius: 0.1px;
    border: 1px solid var(--borderlight);
    border-left: none;
    border-right: none;
    height: 70px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
  }
  .idpr {
    width: 224px;
  }
  .tablestyle {
    width: 100%;
    background: var(--bgwhite);
    border: var(--borderdark);
    border-radius: 6px;
    border-collapse: separate;
    border-spacing: 0px;
  }
  // thead tr {
  //   border-bottom: 2px solid var(--borderlight);
  //   background-color: var(--primarylight);
  // }
  // tr:hover {
  //   background-color: var(--bglightgrey);
  // }
  // table {
  //   border-color: var(--borderdark);
  // }

  .innerTd {
    padding: 9px;
    padding-left: 0px;
    border-radius: 0.1px;
    border: 1px solid var(--borderlight);
    border-left: none;
    border-right: none;
    height: 60px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .tableindark {
    .innerTd,
    tbody tr td:first-child .innerTd,
    tbody tr td:last-child .innerTd {
      border-color: var(--borderdarkblue);
    }
  }
  .rightsection {
    text-align: left;
    width: 100%;
    margin-bottom: 40px;
  }
  .thethreebutton {
    text-align: left;
    margin-right: 0px;
  }
  .buttoncontainersm {
    display: inline-block;
    margin-left: 0px;
    margin-right: 8px;
    // margin-top: 11px;
    // margin-bottom: 20px;

    // margin-left: 11px;
    // margin-right: 11px;
    // margin-top: 11px;
    // margin-bottom: 20px;
    width: auto;
  }

  // tbody tr td:first-child .innerTd {
  //   padding-left: 45px;
  //   border-top-left-radius: 10px;
  //   border-bottom-left-radius: 10px;
  //   border-left: 1px solid var(--borderlight);
  // }
  tbody tr td:first-child .innerTd {
    padding-left: 15px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-left: 1px solid var(--borderlight);
  }
  tbody tr td:last-child .innerTd {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: 1px solid var(--borderlight);
  }
  .colspace.actions {
    line-height: 39px;
  }
  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: var(--borderlight);
  }
  tbody:before {
    content: "-";
    display: block;
    line-height: 7px;
    color: transparent;
  }
  thead tr th {
    border: 0.5px solid var(--borderlight);
    border-left: none;
    border-right: none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    background-color: var(--bglightgrey);
  }
  thead tr th:first-child {
    padding-left: 45px;
    border-left: 1px solid var(--borderlight);
    border-top-left-radius: 10px;
  }
  thead tr th:last-child {
    border-right: 1px solid var(--borderlight);
    border-top-right-radius: 10px;
  }
  tr {
    border-color: var(--borderlight);
  }
  table {
    border-color: var(--borderdark);
  }
  td {
    border: none;
    padding-left: 0px;
    padding-right: 0px;
  }
  th {
    border: none;
    text-align: left;
  }

  .imgstatus {
    margin-top: 3px;
    width: 33px;
    height: 33px;
  }
  // .pfname {
  //   width: 180px;
  // }
  .alignleft {
    text-align: left;
  }
  td,
  th {
    border: none;
  }
  // .trasactiontitle {
  //   color: var(--textdark);
  //   opacity: 0.84;
  //   font-weight: 600;
  //   font-size: 18px;
  //   line-height: 67px;
  //   float: left;
  //   display: inline-block;
  // }
  .trouter {
    height: 65px;
    margin-bottom: 20px;
    width: 100%;
    background: var(--bgwhite);
    border: var(--borderdark);
    border-radius: 2px;
    padding-left: 20px;
    padding-right: 6px;
  }
  .trtbleheading {
    color: var(--shadow);
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    vertical-align: middle;
    height: 68px;
    white-space: nowrap;
  }
  .heading {
    opacity: 0.54;
  }

  .cointag {
    font-size: 9px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 12px;
    color: var(--textdark);
    display: inline-block;
    box-shadow: 0 2px 4px 0 var(--borderlight), 0 2px 4px 0 var(--borderlight);
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 2px;
    margin-top: 5px;
    margin-left: 5px;
  }
  .status {
    font-weight: 600;
    font-size: 12px;
    line-height: 40px;
    text-transform: uppercase;
  }
  .completed {
    color: var(--green);
  }
  .cancel {
    color: var(--red);
  }
  .pending {
    color: var(--yellow);
  }

  // .vertical {
  //   display: inline-block;
  // }
  .threedot {
    background-color: transparent;
    padding: 0px;
    border: none;
    outline: none;
  }
  .arrowclass {
    path {
      fill: var(--textdark);
      stroke: var(--textdark);
    }
  }
  .arrowclass {
    margin-top: 11px;
    width: 19px;
    height: 19px;
  }
  .menu {
    border-radius: 15px;
    background-color: var(--bgwhite);
    border: 1px solid var(--borderlight);
  }
  .threedot.dropdown-toggle::after {
    display: none;
  }
  .dropdown.show {
    display: inline-block;
  }
  .dropdown-item:focus,
  .dropdown-item:hover {
    text-decoration: none;
    cursor: pointer;
  }

  .amt {
    font-weight: 400;
    font-size: 12px;
    vertical-align: middle;
    color: var(--textdark);
    text-transform: capitalize;
    opacity: 0.87;
  }

  .amount {
    font-weight: 600;
    font-size: 12px;
    line-height: 40px;
    color: var(--textdark);
  }
  .btmmargin {
    margin-bottom: 20px;
    text-align: left;
  }
  .coinlistarea {
    line-height: 32px;
    font-size: 12px;
    font-weight: 400;
  }
  .coinlist img.coinimg {
    margin-top: -7px;
  }
  .coinlist {
    text-align: left;
    margin-left: 20px;
  }
  .coinimg {
    height: 10px;
  }
  .coincircle {
    height: 18px;
    width: 18px;
    margin-top: 2px;
  }
  .colwidth {
    width: 150px;
  }
  .pagination {
    justify-content: flex-end;
  }
  .paginationicon {
    path {
      fill: var(--textdark);
    }
  }
  .filtersection {
    display: inline-block;
    float: right;
    position: relative;
    margin-top: 12px;
  }
  .filter .btn-primary {
    text-align: left;
  }
  .filter .dropdown-toggle::after {
    float: right;
    margin-top: 12px;
  }
  .profilelogo {
    border-radius: 50px;
    width: 25px;
    height: 25px;
    margin-right: 7px;
  }
  .realprofileimg {
    border-radius: 50px;
    width: 25px;
    height: 25px;
    object-fit: cover;
    background-color: var(--textwhite);
    margin-right: 7px;
    border: 1px solid var(--borderlight);
  }
  @media (max-width: 600px) {
    .innerTd {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 10px;
    }
  }

  // deposit
  padding: 0 5%;

  .containertp {
    margin-top: 33px;
  }
  .transfertbl {
    // position: relative;
    width: 100%;
    background: var(--bgwhite);
    border: var(--borderdark);
    border-radius: 2px;
  }
  .vertical {
    display: inline-block;
  }
  .threedot {
    background-color: transparent;
    padding: 0px;
    border: none;
    outline: none;
  }
  .arrowclass {
    path {
      fill: var(--textdark);
      stroke: var(--textdark);
    }
  }
  .arrowclass {
    margin-top: 0px;
    width: 19px;
    height: 19px;
  }
  .menu {
    border-radius: 6px;
    background-color: var(--bgwhite);
    border: 1px solid var(--borderlight);
  }
  .threedot.dropdown-toggle::after {
    display: none;
  }
  .dropdown.show {
    display: inline-block;
  }
  .dropdown-item:focus,
  .dropdown-item:hover {
    text-decoration: none;
    cursor: pointer;
  }
  // thead tr {
  //   border-bottom: 2px solid var(--borderlight);
  //   background-color: var(--primarylight);

  //   border-radius:10px;

  // }
  // tr:hover {
  //   background-color: var(--bglightgrey);
  // }
  table {
    border-color: var(--borderdark);
  }

  .imgstatus {
    margin-top: 3px;
  }

  td,
  th {
    border: none;
  }

  .trasactiontitle {
    color: var(--textdarker);
    /* opacity: 0.84; */
    font-weight: 600;
    font-size: 32px;
    line-height: 67px;
    float: left;
    display: inline-block;
  }
  .trouter {
    height: 65px;
    margin-bottom: 20px;
    width: 100%;
    background: var(--bgwhite);
    border: var(--borderdark);
    border-radius: 2px;
    padding-left: 0px;
    padding-right: 6px;
  }

  .status {
    font-weight: 600;
    font-size: 12px;
    line-height: 40px;
    padding-right: 0px;
    text-transform: uppercase;
  }
  .completed {
    color: var(--green);
  }
  .cancel {
    color: var(--red);
  }
  .pending {
    color: var(--yellow);
  }
  .btnwithimg {
    border-radius: 4px;
    line-height: 15px;
    height: 33px;
    width: max-content;
    max-width: unset;
    box-shadow: 0px 1px 4px var(--borderlight);
    opacity: 1;
    line-height: 15px;
  }
  .mleft {
    margin-left: 5px;
  }

  .innerTd {
    padding: 9px;
    border-radius: 0.1px;
    border: 1px solid var(--borderlight);
    border-left: none;
    border-right: none;
    height: 70px;
    text-align: left;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
  .id {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    opacity: 0.87;

    // text-align: right;
  }
  tbody tr td:first-child .innerTd {
    padding-left: 15px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-left: 1px solid var(--borderlight);
  }
  tbody tr td:last-child .innerTd {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-right: 1px solid var(--borderlight);
  }
  .amount {
    font-weight: 600;
    font-size: 12px;
    line-height: 40px;
    color: var(--textdark);
  }
  .btmmargin {
    margin-bottom: 20px;
    text-align: left;
  }
  .coinlistarea {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 5px;
  }
  .coinlist img.coinimg {
    margin-top: -7px;
    // vertical-align: initial;
  }
  .coinlist {
    width: 105px;
    margin-left: 0px;
    text-align: left;
  }

  .coinimg {
    height: 10px;
  }
  .coincircle {
    height: 18px;
    width: 18px;
    margin-top: 2px;
  }
  .pagination {
    justify-content: flex-end;
  }
  .paginationicon {
    path {
      fill: var(--textdark);
    }
  }

  .profilelogo {
    border-radius: 50px;
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
  .colspace.actions {
    line-height: 39px;
  }
  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: var(--borderlight);
  }
  thead tr th {
    border: 0.5px solid var(--borderlight);
    border-left: none;
    border-right: none;
    border-top-right-radius: 0.1px;
    border-top-left-radius: 0.1px;
    background-color: var(--bglightgrey);
  }
  thead tr th:first-child {
    padding-left: 45px;
    border-left: 1px solid var(--borderlight);
    border-top-left-radius: 6px;
  }
  thead tr th:last-child {
    border-right: 1px solid var(--borderlight);
    border-top-right-radius: 6px;
  }
  // tr {
  //   border-color: var(--borderlight);
  // }
  table {
    border-color: var(--borderdark);
  }
  td {
    border: none;
    padding-left: 0px;
    padding-right: 0px;
  }
  th {
    border: none;
    text-align: left;
  }
  .table-responsive-sm {
    overflow-x: auto;
    width: 100%;
    min-height: 450px;
    // border: 1px solid var(--borderlight);
  }
  .centeralign {
    text-align: center;
  }
  @media screen and (min-width: 992px) and (max-width: 1800px) {
    .datewidth {
      width: 200px;
    }
    .idtr {
      min-width: 170px;
    }
  }

  @media (max-width: 450px) {
    .filtersection {
      margin-bottom: 12px;
    }
    .table-responsive-sm {
      // overflow-x: auto;
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    .innerTd {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 10px;
    }
  }
  @media screen and (max-width: 992px) {
    .rightsection {
      margin-top: -15px;
      margin-bottom: 0px;
    }
    .hidden-for-mobile {
      display: none;
    }
    .btnwithimg {
      border-radius: 4px;
    }
    .filter .btn-primary {
      opacity: 0.54;
    }
    .cointag {
      margin-left: 15px;
      position: absolute;
      bottom: 19px;
      line-height: 14px;
    }
    .amttxt {
      margin-right: 70px;
      margin-top: 20px;
    }
    .idtxt {
      margin-right: 96px;
    }
    .filtericon {
      margin-top: -3px;
      width: auto;
    }
    .filter .btn-primary {
      margin-top: 60px;
      height: 33px;
      width: 62px;
      margin-bottom: 10px;
      padding-left: 11px;
      padding-right: 11px;
      border: none;
      border-color: unset;
      box-shadow: 0px 1px 4px var(--borderlight);
      border-radius: 4px;
    }
    .filter .dropdown-toggle::after {
      vertical-align: 0.18em;
    }
    .filteric {
      margin-top: -4px;
    }
    .trasactiontitle {
      font-size: 16px;
    }

    .trouter {
      padding-left: 0px;
      margin-top: -20px;
    }
    .coinlist {
      width: 61px;

      text-align: left;
      vertical-align: -webkit-baseline-middle;
    }
    .thethreebutton {
      text-align: left;
      // margin-right: 0px;
      margin-left: 0px;
    }
    .coinlist img.coinimg {
      margin-top: -8px;
      vertical-align: -webkit-baseline-middle;
    }
    .coinlistarea {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: center;
    }
    .arrow {
      margin-right: 10px;
      path {
        fill: var(--textdark);
      }
    }
    .id {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
    }

    .numbers {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      opacity: 0.87;
    }
    .filter .filtertext {
      display: none;
    }
    .btmmargin {
      display: none;
    }
    .datewidth {
      width: 130px;
    }

    .specialTd {
      padding: 0px;
    }
    .date {
      width: 130px;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      opacity: 0.54;
      margin-bottom: 0px;
    }
    .id {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      opacity: 0.87;
    }

    tbody tr td:first-child .innerTd {
      padding-left: 10px;
      padding-right: 5px;
    }
    .innerTd {
      padding-top: 10px;
    }
  }
  @media screen and (min-width: 992px) {
    .hidden-for-desktop {
      display: none;
    }
  }
  // deposit
}
@media (max-width: 992px) {
  #transferhistory {
    padding: 0px 10px;
  }
}
