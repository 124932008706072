#createpaymentlink {
  padding: 0px 5%;
    .containerallignm {
      margin-top: 33px;
    }
    .topspace {
      margin-top: 20px;
    }
    .innerinputfield {
      font-size: 15px;
      width: 100%;
      max-width: 357px;
      border: none;
      background: none;
      margin-left: -20px;
      margin-bottom: 3px;
      color: var(--textdark);
    }
 
     .button-holder{
       width:95.91px;
     }
     
  //   .buttonmain {
  //     font-weight: 500;
  //     font-size: 14px;
  //     width: 100%;
  //     height: 32px;
  //     margin: 20px 0;
  //     margin-bottom: 10px;
  //     margin-top: 10px;
  //     text-align: center;
  //     border: none;
  //     background-size: 300% 100%;
  //     border-radius: 9px;
  //     transition: all 0.4s ease-in-out;
  //     color: var(--textwhite);
  //     background-color: var(--primary);
  //     box-shadow: 0 7px 6px -6px var(--borderdarker);
  // }

  .commonsection{
    vertical-align: top;
  }
    @media screen and (max-width: 400px) {
    .innerinputfield {
      margin-left: -2px;
    }
  }
  }
  @media (max-width: 556px) {
    #createpaymentlink {
      padding: 0px 5px;
    }
  }
  