.DepositWallet{
    text-align: left;
     .iconwithtextinabout{
         text-align: left;
         padding: 10px 10px 10px 0px;
         width:100%;
         // height:85px;
        
     }
 
     .icon{
         width:23px;
         height:23px;
         display: inline-block;
     }
 
     .graph{
         width: 100%;
         height: 100%;
     }
 
     .abouttext{
         display: inline-block;
         padding-left: 10px;
         vertical-align: text-top;
         font-weight: 600;
         font-size: 16px;
         line-height: 24px;
         color: var(--textdark);
         
        }
 
        .input.form-control.input {
    border-radius:0px;
    border: none;
    border-bottom: 1px solid var(--borderdark);
  }

  .dropdownlink {
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid var(--borderdark);
    padding: 5px;
  }
       .field{
           display: inline-block;
           width:calc(100% - 126px);
       }
        
        .outerlayer{
            display: inline-block;
            width: calc(100% - 112px);
        }
 
       
        .buttoncontainer1{
            margin-top: 6px;
            margin-left: 15px;
            display: inline-block;
            vertical-align: top;
        }

       

        
        .commontext{
            font-weight: 500;
font-size: 12px;
line-height: 20px;
color: var(--textlight);
opacity: 0.8;
        }

        .leftbtn1{
            display: inline-block;
    vertical-align: top;
    margin-left: 5px;
        }

        .right1{
            display: inline-block;
    width: calc(100% - 162px);
        }

        .left1{
            display: inline-block;
    width: calc(100% - 162px);
    margin-right: 20px;
        }

        .formouter1{
            text-align: left;
        }
        
        .mleft {
            margin-left: 15px;
          }
          .iconcolor {
            path {
              fill: var(--primary);
            }
          }
            

          @media (max-width:1400px) {
            .left1 {
                display: inline-block;
                width: calc(100% - 135px);
                margin-right: 20px;
            } 

            .right1 {
                display: inline-block;
                width: calc(100% - 123px);
            }
          }

           @media (max-width: 1200px) {

            .left1 {
                display: inline-block;
                width:100%;
                margin-right: 20px;
            } 

            .right1 {
                display: inline-block;
                width:100%;
            }
            .leftbtn1 {
                display: block;
                vertical-align: top;
                margin-left: 0px; 
                margin-top: 20px;
            }

            .buttoncontainer1 {
                margin-left: 0px; 
                display: block;
                vertical-align: top;
            }  
           }  

          @media (max-width: 992px) {
            .left1 {
                display: inline-block;
                width: 100%;
                margin-right: 20px;
            }

            .right1 {
                display: inline-block;
                width: 100%;
            }

           
          }    
 }