.IssueCardPopup {
    .modal-body {
      padding-top: 30px;
      padding-bottom: 45px;
    }
    .modal-content {
      background-color: var(--bgwhite);
      border-radius: 10px;
    }
    .modal-header {
      box-shadow: 0 4px 6px -6px var(--borderlight);
    }
    .modal-footer {
      display: block;
    }
    .exchangetext {
      font-size: 16px;
      font-weight: 600;
      text-align: left;
    }
    .exchangetitle {
      font-size: 16px;
      font-weight: 600;
      margin-left: 12px;
    }
  
    .titleText {
      font-size: 16px;
      font-weight: 600;
      color: var(--textdark);
      opacity: 0.87;
    }
    .titlepass {
      text-align: left;
      font-size: 16px;
      font-weight: 600;
      margin-top: 15px;
    }
    .inputboxpass {
      .input {
        width: 430px;
        margin-top: 10px;
        border-color: var(--borderlight);
      }
    }
    .InputField .input.form-control.input {
      border-radius: 4px;
      padding: 0 10px;
    }
    .exchangebox {
      border: 1px solid var(--borderlight);
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 15px;
      padding-bottom: 15px;
      margin-top: 20px;
      border-radius: 5px;
    }
    .address {
      font-size: 16px;
      font-weight: 400;
      word-break: break-word;
    }
    .passwordIcon {
      position: absolute;
      margin-top: -33px;
      width: 20px;
      right: 15px;
      cursor: pointer;
    }
    .confirm {
      margin-left: 25%;
      .buttonmain {
        width: 153px;
        height: 34px;
        border-radius: 4px;
        font-weight: 600;
        font-size: 16px;
        box-shadow: 0px 1px 4px var(--borderlight);
      }
    }
    .cancel {
      margin-right: 25%;
      .buttonmain {
        background-color: var(--bgwhite);
        border: 1px solid var(--primary);
        color: var(--primary);
        height: 34px;
        width: 153px;
        border-radius: 4px;
        font-weight: 600;
        font-size: 16px;
        box-shadow: 0px 1px 4px var(--borderlight);
      }
    }
    .closeicon1 {
      position: absolute;
    }
    .closebtn1 {
      position: absolute;
      left: 412px;
      top: -32px;
      cursor: pointer;
      width: 96.35px;
      height: 56.52px;
    }
    .colspace {
      margin-bottom: 50px;
    }
    @media screen and (min-width: 993px) {
      .closeicon1 {
        display: none;
      }
    }
  
  
    @media screen and (max-width: 992px) {
      .titleText {
        font-weight: 400;
        color: var(--shadow);
        opacity: 0.87;
      }
      .closeicon {
        display: none;
      }
      .back {
        height: 56px;
        width: 97px;
        background-color: var(--primary);
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0px;
        top: 30px;
      }
      .modal-header {
        border: none;
        box-shadow: none;
      }
  
      .modal-title {
        margin-top: 26px;
      }
      .modal-dialog {
        max-width: 100%;
        width: 100%;
        height: 100vh;
  
        margin: 0 auto;
      }
  
      .modal-content {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        border-radius: 0px;
        border: none;
      }
      .cancel {
        .buttonmain {
          width: 140px;
        }
      }
      .confirm {
        .buttonmain {
          width: 140px;
        }
      }
    }
    @media screen and (max-width: 410px) {
      .cancel {
        .buttonmain {
          width: 115px;
        }
      }
      .confirm {
        .buttonmain {
          width: 115px;
        }
      }
    }
  }
  