.filterfiat {
  position: relative;
  .fordaterange {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    border-radius: 4px;
    z-index: 1;
    cursor: pointer;
  }
  .dropdown {
    color:var(--textdark);
    background-color: var(--bgwhite);
    border-color: var(--primary);
    border-radius: 4px;
    border:  1px solid var(--primary);
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
}
  .dropdown-menu {
    width: 100%;
    margin-top: -4px;
    border-top: none;
    background-color: var(--bgwhite);
    border: 1px solid var(--borderdark);
  }
  .filtericon {
    path {
      fill: var(--textdark);
    }
    vertical-align: top;
    height: 80%;
    width: 25px;
    display: inline-block;
  }
  .filtertext {
    margin-left: 18px;
    margin-right: 18px;
    display: inline-block;
    line-height: 29px;
    font-size: 14px;
  }

  .dropdownarrow {
    path {
      fill: var(--textdark);
    }
  }
  .dropdown.show {
    border-bottom: none;
    .dropdownarrow {
      transform: rotate(180deg);
    }
  }
  .daterangepicker {
    position: absolute;
    right: 7% !important;
    &:before {
      display: none !important;
    }
  }
  @media (max-width: 991px) {
    .filtertext {
      display: none;
    }
    
  }
}
