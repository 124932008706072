#addnewbank {
  padding: 0px 5%;
  .modal-body {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }
  .modal-header {
    box-shadow: 0 4px 6px -6px var(--borderlight);
  }
  .modal-title {
    margin: auto;
    margin-top: 18px;
    line-height: 1.5;
    text-align: center;
  }
  .deletetext {
    font-size: 15px;
    font-weight: 400;
    text-align: left;
  }
  .buttonmain {
    width: 150px;
  }
  .deletetitle {
    font-size: 16px;
    font-weight: 600;
    margin-left: 12px;
    color: var(--textdark);
    opacity: 0.87;
  }

  .input.form-control.input {
    margin-top: 11px;
    border-radius: 10px;
    height: 31px;
  }
  .titles {
    text-align: left;
    margin-bottom: 18px;
    font-size: 14px;
    font-weight: 600;
    color: var(--textdark);
    width: 40%;
    display: inline-block;
    margin: 10px 10px;
  }
  .dropdownlink {
    border-radius: 10px;
  }
  .cointext {
    margin-top: 5px;
  }
  .outercontainer {
    margin: auto;
  }
  .dropdownlink {
    margin-top: 11px;
    width: 321px;
    height: 31px;
  }
  .modal-header {
    border-bottom: 0px;
  }

  .toogleicon {
    line-height: 23px;
  }
  .buttonmain {
    width: 160px;
    height: 39px;
  }
  .dropdown-menu {
    margin-top: 1px;
  }
  .inputbox1 {
    .dropdownlink {
      height: 31px;
      border-radius: 10px;
      width: 100%;
    }
    .toogleicon {
      line-height: 22px;
    }
    .search{
      left: 4px;
    }
  }
  .addNewText {
    margin-bottom: 0px;
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    color: var(--textdark);
  }
  .arrowicon {
    transform: rotate(180deg);
    margin-right: 10px;
    path {
      fill: var(--borderdarker);
    }
  }
  @media (max-width: 992px) {
    .titles {
      margin: unset;
      display: block;
      width: 100%;
      margin-bottom: 20px;
    }
    .modal-dialog {
      max-width: 100%;
      width: 100%;
      height: 110vh;

      margin: 0 auto;
    }

    .modal-content {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
    .inputbox1 .dropdownlink {
      height: 40px;
      width: 100%;
    }

    .input.form-control.input {
      height: 40px;

      padding: 0 10px;
    }

    .deletetitle {
      margin-left: -75%;
    }
    .modal-body {
      padding-top: 33px;
    }
    .btmbtnoutercontainer {
      margin-top: 61px;
    }
    .toogleicon {
      margin-top: 5px;
    }
  }
  @media (min-width: 992px) {
    .hidefordeskop {
      display: none;
    }
  }
}
@media (max-width: 556px) {
  #addnewbank {
    padding: 0px 10px;
  }
}
