#myprofile {
  .containerallignm {
    margin-top: 52px;
  }
  .outercontainer {
    background-color: var(--bgwhite);
    overflow: hidden;
    padding: 0px;
  }
  .settingtitle {
    color: var(--textdark);
    opacity: 0.84;
    font-weight: 600;
    font-size: 32px;
    line-height: 67px;
    text-align: left;
  }
  .align-left {
    text-align: left;
  }
  .topspace {
    margin-top: 20px;
  }
  .mainorgaisationwrapper {
    margin: 50px 0px 87px 0px;
  }
  .organisationinfo {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  .organisationinfowrapper {
    display: flex;
    flex-wrap: wrap;
    width: 449px;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: 390px) {
    .organisationinfowrapper {
      flex-direction: column;
      align-items: unset;
      justify-content: unset;
      text-align: left;
    }
  }
  .title {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
  }
  .titles {
    text-align: left;
    margin-top: 25px;
    font-size: 16px;
    font-weight: 600;
    color: var(--textdark);
  }
  .phototitle {
    text-align: left;
    margin-top: 40px;
    font-size: 16px;
    font-weight: 600;
    color: var(--textdark);
    opacity: 0.75;
  }

  .titles1 {
    font-size: 18px;
    font-weight: 700;
    color: var(--textdark);
    opacity: 0.87;
  }
  .titleText {
    font-size: 16px;
    font-weight: 600;
    color: var(--textdark);
    opacity: 0.87;
  }
  .titleTextValue {
    font-size: 16px;
    color: var(--textlight);
    opacity: 0.54;
    font-weight: 600;
  }
  .inputbox {
    .input {
      border-radius: 4px;
      width: 431px;
      height: 33px;
      margin-top: 10px;
      border-color: var(--borderlight);
      padding: 0 10px;
    }
  }
  .inputboxpass {
    display: inline-block;
    .input {
      border-radius: 10px;
      width: 431px;
      height: 33px;
      margin-top: 10px;
      border-color: var(--borderlight);
      padding: 0 10px;
    }
  }
  .inputphone {
    .react-tel-input {
      width: 430px;
    }
  }
  .titlepass {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    margin-top: 40px;
  }
  .editicon {
    margin-left: 20px;
    path {
      stroke: var(--primary);
    }
  }
  .outerpass {
    text-align: left;
  }
  .outerspan {
    display: inline-block;
  }
  .resetpass {
    color: var(--primary);
    font-size: 16px;
    font-weight: 400;
    margin-left: 20px;
  }
  .resetpass2 {
    margin-left: 0px;
    margin-top: 20px;
    display: block;
  }
  .editText {
    color: var(--primary);
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
    cursor: pointer;
    text-decoration: underline;
  }

  .topbuttons {
    margin-right: 5px;
    .buttonmain:hover {
      width: 153px;
      height: 34px;
      // box-shadow: 2px 0px 3px var(--borderlight);
    }
  }
  .savebtn {
    .buttonmain:hover {
      // box-shadow: 2px 0px 3px var(--borderlight);
    }
  }
  .bottombuttons {
    margin-left: 15px;
    .buttonmain:hover {
      // box-shadow: 2px 0px 3px var(--borderlight);
    }
  }
  .topbuttons {
    display: inline-block;
    .buttonmain {
      // width: 95px;
      width: 153px;
      height: 34px;
      margin-right: 25px;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      box-shadow: 2px 0px 3px var(--borderlight);
    }
  }
  .savebtn {
    display: inline-block;
    .buttonmain {
      width: 153px;
      height: 34px;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      box-shadow: 2px 0px 3px var(--borderlight);
      // padding-left: 30px;
      // padding-right: 30px;
    }
  }
  .bottombuttons {
    display: inline-block;
    .buttonmain {
      padding-left: 10px;
      padding-right: 10px;
      width: 153px;
      height: 34px;
      margin-right: 0px;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      color: var(--primary);
      background-color: var(--bgwhite);
      box-shadow: 2px 0px 3px var(--borderlight);
    }
  }
  .btmbtnoutercontainer {
    text-align: left;
    margin-top: 20px;
  }
  .textcontainer {
    display: inline-block;
  }

  .react-tel-input .form-control {
    width: 431px !important;
    height: 33px !important;
    border-radius: 4px !important;
    border-left: 1px solid var(--borderlight) !important;
    border-top: 1px solid var(--borderlight) !important;
    border-right: 1px solid var(--borderlight) !important;
    border-bottom: 1px solid var(--borderlight);
  }
  .marginlft {
    margin-left: 30px;
  }
  .outercls {
    text-align: left;
    margin-top: 15px;
  }
  .spanwidth {
    width: 300px;
  }

  .imgsize {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .imgsize2 {
    width: 100%;
    height: 100%;
    // border-radius: 10px;
  }

  .spanmsg {
    font-size: 12px;
    font-weight: 400;
    color: var(--textlight);
    opacity: 0.54;
  }

  .InputField .passwordIcon {
    position: absolute;
    //left: 230px;
    bottom: 5px;
    width: 20px;
    cursor: pointer;
  }
  .display {
    display: inline-block;
    vertical-align: middle;
  }
  .imgouter {
    // border: 1px solid var(--borderlight);
    height: 180px;
    width: 200px;
    .integratedimage {
      object-fit: cover;
    }
  }
  .btntop {
    text-align: center;
    margin-top: 60px;
    vertical-align: top;
  }
  .invalid-feedback {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
  }
  .inputfilebtn {
    width: 0px;
  }
  .inputbuttonmain {
    width: 153px;
    height: 34px;
    font-weight: 600;
    padding-left: 19px;
    padding-right: 19px;
    font-size: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-right: 10px;
    margin: 20px 0;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 4px;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    color: var(--primary);
    background-color: var(--textwhite);
    box-shadow: 2px 0px 3px var(--borderlight);
    border: 0.5px solid rgba(0, 0, 0, 0.12);
    cursor: pointer;
    // &:hover {
    //   box-shadow: 0px 2px 4px var(--borderdarker);
    // }
  }

  .inputbuttonmaindark {
    width: 153px;
    height: 34px;
    font-weight: 600;
    padding-left: 19px;
    padding-right: 19px;
    font-size: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-right: 10px;
    margin: 20px 0;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 4px;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    color: var(--primary);
    background-color: var(--textdark);
    box-shadow: 2px 0px 3px var(--borderlight);
    border: 0.5px solid var(--borderlight);
    cursor: pointer;
    // &:hover {
    //   box-shadow: 0px 2px 4px var(--borderlight);
    // }
  }
  .btnexclude {
    .buttonmain {
      color: var(--primary);
      background-color: var(--textwhite);
      border: 0.5px solid var(--borderlight);
      cursor: pointer;
      // &:hover {
      //   box-shadow: 0px 2px 4px var(--borderlight);
      // }
    }
  }
  .btnexcludedark {
    .buttonmain {
      color: var(--primary);
      background-color: var(--textdark);
      border: 0.5px solid var(--borderlight);
      filter: drop-shadow(2px 0px 3px rgba(0, 0, 0, 0.12));
      border-radius: 4px;
      cursor: pointer;
      // &:hover {
      //   box-shadow: 0px 2px 4px var(--borderlight);
      // }
    }
  }
  .btncancel {
    .buttonmain {
      border: 0.5px solid var(--borderlight);
    }
  }
  .resetpass {
    cursor: pointer;
    text-decoration: none;
  }
  .passwordIcon {
    position: absolute;
    margin-left: 184px;
    margin-top: -35px;
    width: 20px;
    cursor: pointer;
  }

  .Arrow {
    transform: rotate(180deg);
    path {
      fill: var(--shadow);
    }
  }

  @media screen and (max-width: 410px) {
    .imgouter {
      width: 285px;
      height: 250px;
    }
  }

  @media screen and (max-width: 520px) {
    .InputField .passwordIcon {
      right: 20px;
      left: auto;
    }
  }
  @media screen and (max-width: 600px) {
    .inputbox {
      .input {
        width: 100%;
      }
    }
    .inputboxpass {
      width: 100%;
      .input {
        width: 100%;
      }
    }
    .inputphone {
      .react-tel-input .form-control {
        width: 100% !important;
      }
    }
    .inputphone {
      .react-tel-input {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .marginlft {
      margin-left: 0px;
    }
    .btntop {
      margin-top: 0px;
    }
    .outerpass {
      text-align: center;
    }
    .outercls {
      text-align: center;
  }
  }
  @media screen and (max-width: 992px) {
    .outercontainer {
      padding: 0px 5%;
    }
    .settingtitle {
      display: none;
    }
    .topspace {
      display: none;
    }
    .spanwidth {
      display: none;
    }
    .mainorgaisationwrapper {
      display: none;
    }
    .outercls {
      text-align: left;
    }
    .imgouter {
      width: 308px;
      height: 268px;
    }
    .titles {
      font-weight: 700;
    }
    .maintitle {
      margin-top: 0px !important;
    }
    .titleText {
      font-size: 16px;
      font-weight: 400;
    }
    .phototitle {
      font-size: 16px;
      font-weight: 400;
    }
    .leftspace {
      //margin-left: 10px;
    }
    .btmbtnoutercontainer {
      text-align: center;
    }
    .marginlft {
      margin-left: 0px;
    }
    .btntop {
      margin-top: 20px;
    }
    .inputbuttonmain {
      width: 153px;
      height: 40px;
      line-height: 27px;
      padding-top: 5px;
    }
    .btnexclude {
      .buttonmain {
        width: 153px;
        height: 40px;
        padding-top: 2px;
      }
    }
    .savebtn {
      .buttonmain {
        width: 153px;
        height: 40px;
      }
    }
    .bottombuttons {
      .buttonmain {
        width: 153px;
        height: 40px;
      }
    }
  }
  @media screen and (max-width: 540px) {
    .outercls {
      text-align: center;
    }
    .btnexclude {
      .buttonmain {
        margin-right: 0px;
      }
    }
  }

  @media screen and (max-width: 410px) {
    .savebtn {
      .buttonmain {
        width: 125px;
        height: 40px;
      }
    }
    .bottombuttons {
      .buttonmain {
        width: 125px;
        height: 40px;
      }
    }
    .inputbuttonmain {
      width: 125px;
      height: 40px;
      
    }
    .inputbuttonmaindark{
      width: 125px;
      height: 40px;
    }
    .btnexclude {
      .buttonmain {
        width: 125px;
        height: 40px;
      }
    }
    .btnexcludedark {
      .buttonmain {
        margin-right: 0px;
        width: 125px;
        height: 40px;
      }
    }
  }
}
