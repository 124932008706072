.walletcoincard{
    border: 0.5px solid var(--borderlight);
    margin-top: 20px;
    // border: 0.5px solid rgba(33, 33, 33, 0.12);
    box-shadow: 0px 3px 20px var(--borderlight);
    border-radius: 6px;
    .wcc-imgwrapper{
        width: 65px;
        height: 65px;
        border-radius: 50%;
        padding: 15px;
        // margin: 0px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            height: 100%;
            // width: auto;
        }
    }
    // .holdercss{
    //     width: 34px !important;
    //     height: 24px !important;
    //     border-radius: 4px !important;
    // }
    .commtxt{
        font-size: 10px !important;
        margin-top: 0px;
    }
    // .holdercsswrapper{
    //     margin-left: 10px !important;
    //     margin: 3px 10px;
    //     margin-bottom: 0px;
    // }
    .wcc-infowrapper{
        padding: 0px 20px;
        height: 117px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--borderlight);
        position: relative;
    }
    // .wcc-imgandinfo{
    //     display: flex;
    //     align-items: center;
    // }
    // .wcc-imgwrapper{
    //     height: 60px;
    //     width: 60px;
    //     border-radius: 50%;
    //     background-color: turquoise;
    // }
    .wcc-info{
        flex: 3;
        margin-left: 10px;
        p{
            margin-bottom: 1px;
        }
        .wcc-title{
            font-size: 13px;
            color: var(--textdark);
            font-weight: 600;
        }
        .wcc-desc{
            font-size: 12px;
            color: var(--textlight);
            font-weight: 400;
        }
    }
    .eyeiconwalletcard{
        cursor: pointer;
        width: 23px;
    }
    .wcc-coinBalance{
        flex: 3;
        // position: absolute;
        // top: 5px;
        // right: 20px;
        // transform: translate(-50%,-50%);
        .coinAmt{
            font-size: 14px;
            color: var(--textdark);
            font-weight: 600;
        }
        .coinName{
            font-size: 14px;
            color: var(--textlight);
            margin-left: 4px;
            font-weight: 600;
        }
    }
    .wcc-btnwrapper{
        padding: 13px 24px;
        display: flex;
        // align-items: center;
        // justify-content: center;
        .holdercss{
            width: 31px;
            height: 31px;
            border-radius: 4px;
            margin-right: 16px;
            .arrowclass{
                margin-top: 0px;
                width: 17px;
                height: 17px;
            }
        }
    }
    .cointag {
        font-size: 10px;
        font-weight: 600;
        line-height: 16px;
        color: var(--textdark);
        box-shadow: 0 2px 4px 0 var(--borderlight), 0 2px 4px 0 var(--borderlight);
        padding-right: 2px;
        padding-left: 2px;
        border-radius: 6px;
        vertical-align: middle;
        border-radius: 2px;
        text-transform: uppercase;
      }
    @media (max-width: 1362px) and (min-width: 1198px){
        .wcc-coinBalance{
            flex: unset;
            position: absolute;
            top: 20px;
            right: 20px;
            transform: translateY(-50%);
        }
    }
}