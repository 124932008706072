.fiattable {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  text-align: left;
  .completed {
    color: var(--green);
  }
  .cancel {
    color: var(--red);
  }
  .table > :not(caption) > * > * {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  tbody:before {
    content: "-";
    display: block;
    line-height: 7px;
    color: transparent;
  }
  .tableheading {
    color: var(--textdark);
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    padding: 5px;
    opacity: 0.54;
  }
  td span {
    line-height: 50px;
  }
  .coincol {
    width: 200px;
  }
  .tablestyle {
    width: 100%;
    background: var(--bgwhite);
    border: var(--borderdark);
    border-radius: 2px;
    border-collapse: separate;
    border-spacing: 0px;
  }
  .balance {
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    color: var(--textdark);
    line-height: 50px;
  }
  .price {
    font-weight: 600;
    color: var(--textlight);
    font-size: 16px;
    line-height: 50px;
    text-align: left;
  }
  .change {
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    line-height: 50px;
  }
  .colspace {
    padding: 0px;
    padding-top: 13px;
  }
  .innerTd {
    padding: 9px;
    border-radius: 0.1px;
    border: 1px solid var(--borderlight);
    border-left: none;
    border-right: none;
    height: 70px;
  }
  tbody tr td:first-child .innerTd {
    padding-left: 45px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-left: 1px solid var(--borderlight);
  }
  tbody tr td:last-child .innerTd {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-right: 1px solid var(--borderlight);
  }
  .colspace1 {
    padding: 0px;
    padding-top: 13px;
    width: 80px;
  }
  // .colspace1.actions {
  //   padding-left: 8px;
  //   padding-right: 8px;
  // }
  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: var(--borderlight);
  }
  thead tr th {
    border: 0.5px solid var(--borderlight);
    border-left: none;
    border-right: none;
    border-top-right-radius: 0.1px;
    border-top-left-radius: 0.1px;
    background-color: var(--bglightgrey);
  }
  thead tr th:first-child {
    padding-left: 45px;
    border-left: 1px solid var(--borderlight);
    border-top-left-radius: 6px;
  }
  thead tr th:last-child {
    border-right: 1px solid var(--borderlight);
    border-top-right-radius: 6px;
  }

  tr {
    border-color: var(--borderlight);
  }

  table {
    border-color: var(--borderdark);
  }
  td,
  th {
    border: none;
    text-align: left;
  }
  .coinlist {
    min-width: 180px;
  }
  .coincircle {
    vertical-align: middle;
  }
  @media (max-width: 992px) {
    td span {
      line-height: 18px;
    }
  }
  @media (max-width: 992px) {
    .coinlist {
      display: flex;
      align-items: center;
    }
    thead {
      display: none;
    }
    tbody tr td:first-child .innerTd {
      padding-left: 10px;
    }
    .carea {
      width: 125px;
    }
    .mobilescrbal {
      p {
        line-height: 20px;
        margin: 0px;
        font-size: 14px;
        max-width: 14ch;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .mainbal {
        font-weight: 600;
        color: var(--textdark);
      }
      .mainsym {
        font-weight: 400;
        color: var(--textlight);
      }
    }
    .innerTd {
      display: flex;
      align-items: center;
    }
  }
  .smallbuttons {
    display: flex;
    .holdercss {
      width: 39px;
      height: 39px;
    }
    .optionholder {
      margin: 5px 10px 0px 0px;
    }
    .arrowclass {
      margin-top: 10px;
    }
  }
}
