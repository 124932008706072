.onboardingmainwrapper {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1020;
  .nav-item .nav-link {
    padding: 0px;
  }
  .nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar {
    border-radius: 0px;
  }
  .formobilemenu {
    display: flex;
  }
  .apptitle {
    margin-bottom: 0px;
  }
  .cyborbusinesslogo{
   // width : 95px !important;
  }
  .apptitle img {
    margin-left: 50px;
    height: 60px;
    cursor: pointer;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    text-align: center;
  }
  .editmode {
    margin-right: 7px;
  }
  .buttonsearch {
    path {
      stroke: var(--textdark);
    }
  }
  .spacing {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: -5px;
    path {
      fill: var(--textdark);
    }
  }
  .navvl {
    margin-right: 12px;
  }
  .dropdown-menu {
    left: -50%;
    right: auto;
  }
  .navwhitebg {
    padding: 10px 0px;
  }
  .menucolour {
    color: var(--textdark);
    font-weight: 500;
  }
  .navline {
    display: inline-block;
    height: 15px;
    opacity: 0.12;
    border-left: 2px solid var(--borderdarker);
    vertical-align: middle;
  }
  .contentatend {
    justify-content: end;
    margin-left: auto;
  }
  .vertcallycenter {
    line-height: 50px;
    cursor: pointer;
  }
  .dropdown-toggle::after {
    display: none;
    margin-left: none;
    vertical-align: none;
    content: "";
    border-top: none;
    border-right: none;
    border-bottom: 0;
    border-left: none;
  }
  .widthofimgmoon {
    margin-right: 40px;
    margin-left: 15px;
    vertical-align: middle;
    height: 58px;
    width: 17px;
    cursor: pointer;
    path {
      fill: var(--textdarker);
    }
  }
  .widthofimgnotify {
    vertical-align: middle;
    height: 58px;
    width: 22px;
  }
  .widthofimgbell {
    height: 18px;
    width: 14px;
    margin-top: -2px;
    path {
      fill: var(--textdarker);
    }
  }
  .widthofimghelp {
    height: 19px;
    width: 14px;
    path {
      fill: var(--textdarker);
    }
    .svgcircle {
      stroke: var(--textdarker);
      fill: none;
      stroke-width: 1.5;
    }
  }
  .mobilemenuiconstyle {
    .widthofimgbell {
      width: 24px;
      height: 20px;
      margin-top: -6px;
    }
    .yellowcircle {
      top: 0px;
      right: -3px;
      width: 15px;
      height: 15px;
    }
    .languagewrapper {
      margin-left: 20px;
      margin-right: 0px;
    }
    .helpwrapper {
      margin-left: 20px;
      margin-right: 0px;
    }
    .widthofimghelp {
      height: 23px;
      width: 19px;
      margin-top: -5px;
    }
  }
  .helpDrpArrow {
    display: inline-block;
    margin-left: 5px;
    .dropdownarrow path {
      fill: var(--textdarker);
    }
  }
  .notifycircle {
    position: relative;
  }
  .yellowcircle {
    position: absolute;
    right: -3px;
    top: 5px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    border: 1px solid var(--textwhite);
    background-color: var(--yellow);
  }
  .navbar-nav {
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    flex-direction: row;
    list-style: none;
  }
  .pname {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: var(--textdark);
    text-transform: capitalize;
  }
  .helpwrapper {
    margin-right: 74px;
  }
  &.darknavwrapper{
    .switchwrapper.personalbusinessswitch .react-switch-bg{
      background-color: var(--togglebgcolor) !important;
    }
  }
  @media (max-width: 768px) {
    .switchwrapper{
      margin: 0px;
    }
    .contentatend {
      margin-left: unset;
    }
    .modeIcon {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(-50%, -50%);
    }
    .langDrp{
      position: absolute;
      top: 50%;
      left: 52px;
      transform: translate(-50%, -50%);
      .dropdown-menu{
        left: 0%;
        top: 110%;
        position: absolute;
      }
    }
    .circleAndArrow{
      display: flex;
      align-items: center;
    }
    .mobCircleDrp{
      width: 36px;
      height: 36px;
      border-radius: 50%;
      border: 1px solid var(--primary);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 500;
      color: var(--textdarker);
      text-transform: uppercase;
    }
    .mobCircleDrpArrow{
      margin-left: 5px;
      path{
        fill: var(--primary);
      }
    }
    .halfmoon {
      width: 25px;
      height: 25px;
      path {
        fill: var(--primary);
        fill-opacity: 1;
      }
    }
    .modetext {
      font-size: 8px;
      line-height: 14px;
      margin-bottom: 0px;
    }
    .navwhitebg {
      padding: 20px 0px;
    }
    &.darknavwrapper .navbar {
      box-shadow: none;
    }
    .navbar {
      border-radius: 2px;
      border: none;
    }
    .navbarcontainer {
      display: flex;
      justify-content: center;
      max-width: 100%;
      padding-left: 0px;
      padding-right: 0px;
    }
    .navbar {
      position: relative;
      padding-left: 31px;
      padding-right: 31px;
    }
    .helpwrapper {
      margin-right: 10px;
    }
    .pname .verified-or-not {
      display: none !important;
    }
    .profilecircle {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .profilearea .user-name {
      text-align: left;
      white-space: break-spaces;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .profilearea {
      margin-left: 4px;
    }
    .toogleicon svg {
      margin-top: -4px;
      margin-left: 2px;
    }
  }
  @media (max-width: 589px) {
    .spacing {
      margin-left: 8px;
      margin-right: 8px;
    }
  }
  @media (max-width: 400px) {
    .pname .user-name {
      width: 54px;
      line-height: 11px;
      font-size: 10px;
    }
  }

  @media (max-width: 992px) {
    .navbarprofile .profileimg {
      vertical-align: middle;
      width: 32px;
      height: 32px;
    }
    .navbarprofile .profilecircle {
      height: 32px;
      width: 32px;
    }
  }
  @media only screen and (min-width: 360px) and (max-width: 425px) {
    .tempdisable {
      display: none;
    }
  }
  @media (max-width: 370px){
    .modeIcon {
      top: 50%;
      right: -10px;
    }
    .langDrp{
      top: 50%;
      left: 38px;
    }
  }
  .languagewrapper {
    margin-right: 15px;
  }
}
