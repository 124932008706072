#FiatWallet {
  padding: 0px 5%;
  .containertp {
    margin-top: 33px;
  }
  .cryptobalacesection {
    width: 100%;
  }

  .threecards {
    margin-top: 20px;
    text-align: left;
  }
  .filterfiatcurrency .dropdown-menu {
    margin-top: -6px;
  }
  .leftcard {
    display: inline-block;
    margin-right: 32px;
    vertical-align: top;
    width: calc(100% - 498px);
  }

  .cardtopwallet {
    width: 100%;
    padding: 20px;
    height: 100%;
    background-color: var(--bgwhite);
    border: 1px solid var(--borderlight);
  }

  .cardbottomwallet {
    margin-top: 22px;
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: var(--bgwhite);
    border: 1px solid var(--borderlight);
  }

  .rightcardswallet {
    width: 100%;
    padding: 20px;
    height: 100%;
    background-color: var(--bgwhite);
    border: 1px solid var(--borderlight);
  }

  .rightcard {
    display: inline-block;
    width: calc(100% - 500px);
  }

  .topspace {
    margin-top: 20px;
    text-align: right;
  }

  .spacearea {
    margin-top: 22px;
  }

  .buttonmain {
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    height: 40px;
    margin: 20px 0;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    border-radius: 9px;
    color: var(--textwhite);
    background-color: var(--primary);
    box-shadow: 0px 2px 4px var(--borderdark);
  }

  .nonfilledbtn {
    border-radius: 2px;

    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    padding: 8px;
    width: 100px;
    height: 40px;
    background-color: var(--bgwhite);
    border: none;
    color: var(--primary);
    box-shadow: 0px 2px 4px var(--borderlight);
    opacity: 0.7;
    border-radius: 2px;
  }
  .nonfilledbtn:hover {
    box-shadow: 0px 1px 2px var(--borderdark);
    opacity: 1;
  }

  .btnwithimg {
    border-radius: 2px;

    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    padding: 8px 10px;
    width: 100%;
    max-width: 89px;
    color: var(--primary);
    height: 40px;
    background-color: var(--bgwhite);
    border: none;
    /* color: var(--textdark); */
    box-shadow: 0px 2px 4px var(--borderdark);
    opacity: 0.7;
  }
  .CoinListBalance {
    height: 87px;
    .rightnav {
      height: 87px;
    }
    .coinimgwrapper {
      width: 42px;
      height: 42px;
    }
    .coinSymbolbelowname {
      margin: 0px 19px;
    }
    .coinname {
      margin: 0px 19px;
    }
  }
  .tokensection {
    margin-bottom: 91px;
  }
  .legend {
    display: inline;
    margin-right: 20px;
  }
  .right {
    display: block;
  }
  .FiatBalance{
    padding: 1px;
    padding-top: 15px;
    .mainbuttonswrapper{
      padding-right: 43.5px;
    }
  }
  @media (max-width: 1400px) {
    .leftcard {
      display: inline-block;
      margin-right: 32px;
      vertical-align: top;
      width: calc(100% - 448px);
    }

    .rightcard {
      display: inline-block;
      width: calc(100% - 425px);
    }
  }

  @media (max-width: 1200px) {
    .leftcard {
      display: inline-block;
      margin-right: 32px;
      vertical-align: top;
      width: calc(100% - 385px);
    }

    .rightcard {
      display: inline-block;
      width: calc(100% - 353px);
    }

    .field {
      display: inline-block;
      width: 100%;
    }

    .leftbtn {
      display: inline-block;
      vertical-align: top;
      margin-left: 0px;
      margin-top: 10px;
    }

    .buttoncontainer {
      margin-left: 0px;
      display: inline-block;
      vertical-align: top;
    }
  }

  @media (max-width: 992px) {
    .nonfilledbtn {
      display: none;
    }
    .filterfiatcurrency .dropdown-menu {
      margin-top: 1px;
      margin-left: 6px;
    }
    .leftcard {
      display: inline-block;
      margin-right: 32px;
      vertical-align: top;
      width: 100%;
    }

    .rightcard {
      display: inline-block;
      width: 100%;
      margin-top: 20px;
    }

    .rightcardswallet {
      width: 100%;
      padding: 20px;
      height: 100%;
      background-color: var(--bgwhite);
      border: 1px solid var(--borderlight);
    }

    .cardtop {
      margin-left: 0px;
      margin-top: 22px;
      width: 100%;
      padding: 20px;
      height: 100%;
      background-color: var(--bgwhite);
      border: 1px solid var(--borderlight);
    }

    .cardbottom {
      margin-left: 0px;
      margin-top: 22px;
      width: 100%;
      height: 100%;
      background-color: var(--bgwhite);
      border: 1px solid var(--borderlight);
    }
    .FiatBalance .mainbuttonswrapper{
      padding-right: 0px;
    }
  }
}
