.banklistlayout {
  /* width */
  ::-webkit-scrollbar {
    opacity: 0;
    width: 5px;
    margin-left: 40px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey;
    background-color: var(--bglightgrey);
    opacity: 0.54;
    // border-radius: 40px;
    margin-left: 40px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--borderlight);
    border-radius: 10px;
  }
  .BankListItem {
    margin-top: 8px;
  }
  .activeList{
    border: 2px solid var(--primary);
  }
  .topspace {
    margin-top: 10px;
    // max-height: 800px;
    // overflow-y: scroll;
  }
  .topspacese {
    margin-bottom: 25px;
    margin-left: 0px;
  }
  .innerinputfield {
    margin-left: -2px;
    margin-top: 2px;
  }
  .searchbox {
    max-width: 395px;
    height: 39px;
    margin-left: 0;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 5px;
  }
  .searchinside {
    top: 50%;
  }
  .bankimg {
    height: 70%;
  }
  @media (max-width: 991px) {
    .searchbox {
      max-width: 690px;
    }
    .innerinputfield {
      margin-left: -1px;
      max-width: 690px;
    }
  }
}
