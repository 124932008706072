.deleteconfirmationpopup {
  .modal-body {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }
  .modal-header {
    box-shadow: 0 4px 6px -6px var(--borderlight);
  }
  .modal-footer {
    display: block;
  }
  .deletetext {
    font-size: 15px;
    font-weight: 400;
    text-align: left;
  }
  .deletetitle {
    font-size: 20px;
    font-weight: 600;
    margin-left: 12px;
  }
  .confirm {
    .buttonmain {
      height: 39px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .errortext {
    width: 100%;
   
    color: #dc3545;
   
    font-size: 12px;
  font-weight: 400;
  text-align: left;
  }
  .cancel {
    .buttonmain {
      background-color: var(--bglightgrey);
      border: 1px solid var(--borderlight);
      color: var(--textdark);
      height: 39px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 16px;
    }
  }
  @media screen and (max-width:768px) {
    .modal-dialog {
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
    }

    .modal-content {
      width: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
  }
}
