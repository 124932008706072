#RequestLoan {
  padding: 0px 25%;

  .top-btn {
    text-align: right;

    .buttonmain {
      font-weight: 600;
      font-size: 16px;
      width: 208px;
      height: 34px;
      margin: 0px;
      text-align: center;
      border: none;
      border-radius: 4px;
      color: var(--textwhite);
      background-color: var(--primary);
    }
  }
  .transactiontitle {
    text-align: left;
    padding-left: 3px;
  }
  .trouter {
    height: 65px;
    margin-bottom: 20px;
    width: 100%;
    background: var(--bgwhite);
    border: var(--borderdark);
    border-radius: 2px;
  }
  .cointag {
    font-size: 8px;
    font-weight: 600;
    line-height: 16px;
    color: var(--textdark);
    display: inline-block;
    box-shadow: 0 2px 4px 0 var(--borderlight), 0 2px 4px 0 var(--borderlight);
    padding-right: 2px;
    padding-left: 2px;
    border-radius: 2px;
    vertical-align: middle;
    border-radius: 2px;
    width: 30px;
    text-transform: uppercase;
    margin-left: 10px;
    margin-top: -2px;
  }
  .title {
    color: var(--textdarker);
    font-weight: 600;
    font-size: 32px;
    line-height: 67px;
    float: left;
  }
  .inputboxpass {
    position: relative;
    .input {
      margin-top: 10px;
      border-color: var(--borderlight);
    }
     textarea{
      height: 100px !important;
    }
    
    .slider {
      width: 100%;
    }
    
    .slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #4a90e2; /* Set initial color */
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;
    }
    
    .slider::-moz-range-thumb {
      width: 12px;
      height: 12px;
      border: none;
      border-radius: 50%;
      background: #4a90e2; /* Set initial color */
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;
    }
    
    .slider::-webkit-slider-runnable-track {
      width: 100%;
      height: 4px;
      border-radius: 2px;
    }
    
    .slider::-moz-range-track {
      width: 100%;
      height: 4px;
      background: #e1e1e1; /* Set initial track color */
      border-radius: 2px;
    }
    
  }
  .spincolor {
    color: var(--primary);
  }
  .input-dropdown {
    width: 194px;
    position: absolute;
    top: 0px;
    right: 0px;

    .DropdownSearch .dropdownlink {
      border: 0px;
      height: 33px;
    }
 
    .search {
      top: 2px;
    }

    .DropdownSearch .dropdown-menu {
      margin-top: 0px;
      right: 0px;
      top: 44px;
    }
  }

  .titleText {
    font-size: 16px;
    font-weight: 600;
    color: var(--textdark);
    opacity: 0.87;
  }
  .titlepass {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
  }
  .colspace {
    margin-bottom: 10px;
    width: 100%;
  }
  .section {
    display: flex;
    justify-content: space-between;
    padding: 8px 0px;
    align-items: center;
    &.left {
      flex: 1;
      text-align: left;
    }
    &.right {
      text-align: right;
    }
  }
  .InputField .input.form-control.input {
    border-radius: 4px;
  }
  .form-check {
    display: flex;

    padding-left: 12px;
    // align-items: center;
    // justify-content: flex-start;
    margin: 16px 0px;

    .form-check-label {
      padding-left: 8px;
    }
    .form-check-label a{
      text-decoration: none;
      cursor: pointer;
      color: #4a90e2;
    }
  }
  .DropdownSearch .cointext {
    margin-left: 17px;
    display: inline-block;
    line-height: 40px;
  }

  .input-drop-down {
    width: max-content;
    padding: 0px 12px;
    position: absolute;
    top: 2px;
    right: 0px;

    .coinicon .coin-img {
      height: 25.59px;
      margin-right: 17px;
    }
    .cointext {
      line-height: 40px;
      font-weight: 400;
      font-size: 13px;
      color: var(--textdark);
    }
  }

  //   .input-dropdown .coinicon {
  //     height: 50%;
  //     /* width: 25px; */
  //     /* display: inline-block; */
  //     position: absolute;
  //     line-height: 40px;
  //   }
  //   .input-dropdown .coinicon img {
  //     height: 100%;
  //     width: auto;
  //   }
  //   .input-dropdown .cointext{
  //     display: inline-block;
  // /* right: 0; */
  // line-height: 40px;
  // margin-right: 0px;
  // width: 100px;
  //   }
  .errortext {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;
    display: block;
    text-align: center;
  }
}
