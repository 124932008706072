.Withdrawal {
  .modalPrimaryHeader {
    height: 29px;
    background-color: var(--primary);
  }
  .colborder {
    border-left: 1px solid var(--borderlight);
  }
  .modal-dialog {
    max-width: 1100px;
  }
  .modal-body {
    padding: 45px 25px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }
  .innerinputfield {
    margin-left: 2px !important;
  }
  .searchbox{
    max-width: 395px!important;
  }
  .searchinside .pointer {
    margin-top: 5px !important;
}
  .tgspace {
    text-align: right;
    margin-bottom: 20px;
  }
  .searcharea.searchinside {
    top: 52%;
  }
  .search{
    top: 2px;
  }
  .dropdown-menu {
    width: 334px;
  }

  @media only screen and (min-width: 992px) {
    .hidetoggle {
      display: none;
    }
    .back {
      display: none;
    }
  }
  @media (max-width: 991px) {
    .colborder {
      border-left: none;
    }
    .hidecls {
      display: none;
    }
    .dropdown-menu {
      width: 100%;
    }
    .modal-body {
      padding: 30px 0px 65px 0px;
      min-height: 82vh;
    }
    .modal-dialog {
      max-width: 100%;
      width: 100%;
      min-height: 100vh;

      margin: 0 auto;
    }
    .modal-content {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
    .tgspace {
      text-align: center;
      margin: 0 auto;
    }
    .back {
      float: right;
      width: 9%;
      height: 56px;
      width: 41px;
      background-color: var(--primary);
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0px;
      top: 12px;
      margin-top: 18px;
    }
    .modalPrimaryHeader {
      height: 29px;
      background-color: var(--primary);
    }
  }
}
.mod-exchangebackdrop {
  opacity: 0 !important;
}
.Withdrawal.hidethisexchangemodal {
  display: none !important;
}
