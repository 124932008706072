.paymentSuccess {
  .modal-dialog {
    max-width: 500px;
  }
  .modal-body {
    padding: 45px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }
  .text {
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;
    color: var(--textdark);
    text-align: center;
    margin: 0 auto;
    max-width: 202px;
  }
  .box {
    margin-top: 28px;
    height: auto;
    background: var(--bgwhite);
    // border: 1px solid var(--borderlight);
    box-shadow: 0px 3px 20px var(--borderlight);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 8px;
  }
  .link {
    text-align: center;
  }
  .linktxt {
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
    color: var(--primary);
  }
  .commonsection {
    text-align: center;
    margin-top: 15px;
  }
  .textline1 {
    font-size: 14px;
    font-weight: 600;
    color: var(--textdark);
  }
  .textline2 {
    font-size: 14px;
    font-weight: 400;
    color: var(--textlight);
    display: block;
  }
  .status {
    text-align: center;
  }
  .textofstatus {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: var(--textlight);
  }
  .completed {
    text-align: center;
    margin-left: 8px;
  }
  .textofcomplete {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: var(--green);
    text-transform: uppercase;
  }
  .greenicon,
  .completed {
    display: inline-block;
  }
  .dotclass {
    margin-top: 13px;
    width: 17px;
    height: 17px;
    vertical-align: text-bottom;
    path {
      fill: var(--green);
      stroke: var(--green);
    }
  }
  /*only use if png*/
  .qrcode-section {
    width: 228px;
    height: 224px;
    text-align: center;
    margin: 0 auto;
    margin-top: 20px;
  }
  .transectionid {
    display: inline-block;
    width: 100%;
    max-width: 260px;
    word-wrap: break-word;
  }
  .detail {
    text-align: left;
    margin-bottom: 15px;
    border-bottom: 1px solid var(--borderdark);
    width: 100%;
    max-width: 325px;
    margin: 0 auto;
    margin-top: 30px;
  }
  .label {
    display: inline-block;
  }
  .labeltext {
    color: var(--textdarker);
    font-weight: 400;
    font-size: 14px;
  }
  .value {
    display: inline-block;
    float: right;
  }
  .valuetext {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding-right: 2px;
    color: var(--textlight);
  }
  .tcolor {
    color: var(--primary);
  }
  .currencytoconvert {
    margin-bottom: 20px;
    width: 100%;
    max-width: 280px;
    margin: 0 auto;
    margin-top: 30px;
  }
  .arrowclass11 {
    width: 25px;
    height: 25px;
  }
  .imgcontainer {
    width: 17px;
    height: 17px;
    display: inline-block;
  }
  .copy {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .detail1 {
    text-align: left;
    margin-bottom: 15px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    margin-top: 30px;
  }
  .textclass {
    display: inline-block;
    width: calc(100% - 17px);
  }
  .socialicon {
    display: inline-block;
    margin-left: 3px;
  }
  .buttonsection {
    margin-top: 30px;
  }
  .buttoncontainer {
    width: 192px;
    display: inline-block;
    padding: 8px;
  }

  .pl {
    padding-left: 0px;
  }

  .pr {
    padding-right: 0px;
  }

  .imgsection {
    width: 100;
    height: 100%;
  }

  .down {
    path {
      fill: var(--primary);
      stroke: var(--primary);
    }
  }

  .imgsection {
    width: 191px;
    height: 27px;
    text-align: center;
    margin: 0 auto;
  }

  .imglogo {
    width: 100%;
    height: 100%;
  }

  .text {
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: var(--textdarker);
    opacity: 0.54;
  }

  .exchangetitle {
    font-size: 16px;
    font-weight: 700;
    margin-left: 12px;
  }

  .detail2 {
    max-width: 325px;
    text-align: left;
    margin-bottom: 15px;
    border-bottom: 1px solid var(--borderdark);
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
  }

  .line1 {
    text-align: right;
    display: inline-block;
    width: calc(100% - 63px);
  }

  .textcolor2 {
    color: var(--textdarker);
    width: 100%;
    max-width: 105px;
    word-wrap: break-word;
    opacity: 0.54;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
  }

  .line2 {
    text-align: right;
    display: inline-block;
    width: calc(100% - 97px);
  }

  .line3 {
    text-align: right;
    display: inline-block;
    width: calc(100% - 39px);
  }
  .split {
    height: 100%;
    width: 50%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    padding-top: 100px;
  }
  
  /* Control the left side */
  .left {
    left: 0;
  }
  
  /* Control the right side */
  .right {
    right: 0;
    margin-top: -15px;
  }

  @media (max-width: 520px) {
    .buttoncontainer {
      width: 115px;
    }
  }
  @media screen and (max-width:992px) {
    .modal-dialog {
      max-width: 100%;

      height: 100%;
      margin: 0 auto;
    }
    .modal-body {
      padding: 0px;
      max-height: 150vh;
    }
    .modal-content {
      width: 100%;

      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
  }
}
