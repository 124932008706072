.forgotpasswordemailsuccess {
  .modal-body {
    padding: 40px;
  }
  .modal-dialog {
    max-width: 400px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }
  .exchangetext {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 30px;
    margin: 0 auto;
  }
  .confirm {
    .buttonmain {
      height: 39px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .cancel {
    .buttonmain {
      background-color: var(--bglightgrey);
      border: 1px solid var(--borderlight);
      color: var(--textdark);
      height: 39px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .passcontent {
    width: 75%;
    margin: 0 auto;
    line-height: 18px;
    margin-bottom: 25px;
  }
  .passwordtitle {
    /* Password Reset Email Sent */

    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
  }
  .titleText {
    font-size: 13px;

    font-weight: 400;

    text-align: center;
    margin: 0 auto;
    color: var(--textdark);
    opacity: 0.54;
  }

  .close-btn {
    margin-top: -20px;
  }

  .ok-btn {
    height: 40px;
    width: 147px;
    left: 132px;
    border-radius: 10px;
    margin: 0 auto;
  }
  hr {
    color: var(--borderdark);
  }
  @media screen and (max-width:768px) {
    .modal-dialog {
      max-width: 100%;

      height: 100%;
      margin: 0 auto;
    }

    .modal-content {
      width: 100%;

      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
  }
}
