#paymentreport {
  .outercontainer {
    padding: 0px 5%;
  }
  .containertp {
    margin-top: 33px;
  }
  .form-check-input:focus {
    border-color: unset;
    outline: 0;
    box-shadow: unset;
  }
  .margintop {
    margin-top: 40px;
  }
  .btnwithimg {
    width: max-content;
    max-width: unset;
    min-width: 94px;
    height: 43px;
    line-height: 15px;
  }
  .paginationsectionforidotable {
    float: right;
    margin-top: 30px;
  }
  .rightsection {
    text-align: center;
    width: 100%;
    margin-bottom: 30px;
  }
  .thethreebutton {
    text-align: center;
    margin-right: 0px;
  }
  .buttoncontainersm {
    display: inline-block;
    margin-left: 0px;
    margin-right: 8px;
    width: auto;
  }
  .DropdownSearch {
    max-width: 334px;
  }
  .coinicon {
    line-height: 22px;
    margin-right: 8px;
  }
  .mleft {
    margin-left: 5px;
  }
  .trasactiontitle {
    color: var(--textdark);

    font-weight: 600;
    font-size: 18px;
    line-height: 67px;
    text-align: left;
    padding-left: 20px;
  }
  .trouter {
    height: 85px;
    width: 100%;
    background: var(--bgwhite);
    border: 0.5px solid var(--borderlight);
    border-bottom: none;
    box-sizing: border-box;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    align-items: center;
  }
  .reporthederwrapper {
    margin-left: 8px;
  }
  .dropdownlink {
    height: 32px;
    box-sizing: border-box;
    border: 1px solid var(--borderlight);
    border-radius: 4px;
    padding-top: 2px;
  }
  .dropdown-menu {
    border: 1px solid var(--borderlight);
  }
  .toogleicon {
    line-height: 26px;
  }
  .cointag {
    line-height: 14px;
  }
  .DropdownSearch .cointext {
    margin-left: 0px;
    line-height: 0px;
  }
  .input.form-control.roundedborderfield {
    height: 32px;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .title {
    margin-left: 8px;
    display: inline-block;
    font-weight: 600;
    font-size: 32px;
  }
  .paymentreportcustombutton {
    font-size: 16px;
    font-weight: 600;
  }
  .InputField {
    max-width: 334px;
    height: 32px;
  }
  .othersIcon {
    top: 50%;
  }

  .paymentreportcustombutton {
    font-size: 12px;
    font-weight: 600;
    width: 271px;
    height: 60px;
    box-shadow: unset;
  }

  .paymentreportwrapper {
    padding: 50px 70px;
    background-color: var(--bgwhite);
    border: 0.5px solid var(--borderlight);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    .formfieldholder {
      margin: 0px 20px 35px 20px;
    }
    .input.form-control.input {
      font-weight: 400;
    }
    .formfieldholder .label {
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      margin-bottom: 5px;
    }
    .calendericon {
      width: 28px;
      height: 25px;
      path {
        fill: var(--textdark);
      }
    }
    .form-check-input:not(:checked) {
      background-color: var(--bgwhite);
    }
    .search {
      left: 4px;
    }

    .checkboxholder {
      text-align: left;
      padding-top: 17px;

      .form-check-input {
        height: 1.1em;
        width: 1.1em;
        //margin-right: 15px;
        cursor: pointer;
        border: 0.5px solid var(--borderlight);
        border-radius: 2px;
      }
      .form-check-label {
        font-size: 12px;
        font-weight: 400;
        cursor: pointer;
      }
    }
  }
  @media screen and (max-width: 400px) {
    .paymentreportcustombutton {
      width: 100%;
    }
  }
  @media screen and (max-width: 991px) {
    .hidden {
      display: none;
    }
    .trouter {
      height: 20px;
      border: 0px;
    }
    .paymentreportwrapper {
      border: 0px;
    }

    .title {
      font-size: 16px;
      margin-left: 0px;
    }
    .paymentreportwrapper {
      padding: 32px 18px;
    }
    .formfieldholder .label {
      font-size: 14px;
    }
    .paymentreportcustombutton {
      height: 34px;
    }
    .paymentreportwrapper .formfieldholder {
      margin: 24px 0px 0px 0px;
    }
    .outercontainer {
      padding: 0px;
    }
    .justify-content-md-center {
      margin: auto;
      width: 88%;
    }
    .reporthederwrapper {
      margin: 0px 0px 0px -6px;
    }
  }
  @media (max-width: 575px) {
    .paymentreportwrapper {
      padding: 0px 15px 40px;
    }

    .paymentreportwrapper .checkboxholder {
      .form-check-input {
        margin-right: 5px;
        .form-check-label {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
    .form-check-label {
      font-size: 12px;
      font-weight: 400;
    }
  }
  @media (max-width: 365px) {
    .paymentreportwrapper .checkboxholder .form-check-label {
      font-size: 11px;
      font-weight: 400;
      cursor: pointer;
    }
  }
  @media (max-width: 576px) {
    .InputField {
      max-width: unset;
    }
    .DropdownSearch {
      max-width: unset;
    }
  }
}
