.withdrawfiattoggle {
  .topspaceformodal {
    text-align: right;
  }
  @media (max-width: 991px) {
    .topspaceformodal {
      text-align: left;
      margin-top: 15px;
    }
    .hidetgl1{
      display: none;
    }
  }
  .formtpspace {
    margin-top: 30px;
  }

}
