.ExchangeFrom {
  width: 100%;
  .outercontainerpopup {
    height: 40px;
    margin: 0 auto 0;
    width: 115px;
    margin-top: 20px;
    margin-bottom: 25px;
  }
  .exchangetitle {
    font-weight: 700;
    font-size: 16px;
    color: var(--textdark);
    text-align: left;
    margin-bottom: 15px;
  }
  .margintp {
    margin-top: 10px;
  }
  .textinput {
    width: 100%;
    height: 39px;
    border: 1px solid var(--borderlight);
    border-radius: 4px;
    cursor: text;
    position: relative;
  }

  .usdtclass {
    font-size: 14px;
    text-decoration: none;
    color: var(--textdark);

    font-weight: 600;
    font-size: 14px;
    position: absolute;
    top: 23%;
    left: 5%;
  }
  .toogleicon {
    line-height: 25px;
  }
  .coinicon {
    line-height: 25px;
  }
  .outerinput {
    width: 70%;
    position: absolute;
    right: 5px;
  }
  .dropdownlink {
    border-radius: 4px;
    border: 1px solid var(--borderlight);
    padding: 5px 15px;
    height: 39px;
  }
}
