.RoundForm {
  .outercontainerforbtn {
    height: 70px;
  }
  .tabarea {
    width: auto;
    display: inline-block;
  }
  .outerconforbtn1 {
    width: 109px;
    height: auto;
    display: inline-block;
    margin-right: 20px;
  }
  .outerconforbtn1:last-of-type {
    margin-right: 0px;
  }
  .defaultext {
    text-align: center;
  }
  .text {

    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: var(--textlight);
  }
  .fieldssection {
    position: relative;
  }
  .fieldssectionmargin {
    margin-top: 15px;
  }
  .logosection {
    width: 40px;
    height: 35px;
    display: inline-block;
    border: none;
    border-left: 1px solid var(--borderdark);
    text-align: center;
    vertical-align: middle;
  }
  .formfield {
    margin: 0 auto;
    position: relative;
    border: 1px solid var(--borderdark);
    height: 45px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 2px;
  }
  .inputfieldholder {
    display: inline-block;
    width: calc(100% - 40px);
    height: 100%;
    vertical-align: top;
    .input {
      background: transparent;
      border: none;
      padding: 5px;
    }
  }
  .dropdondholder {
    max-width: 364px;
    margin: 0 auto;
  }
  .logoholder {
    width: 26px;
    height: 26px;
    margin: 0 auto;
    border-radius: 2px;
    overflow: hidden;
    margin-top: 4px;
  }
  .coinimg {
    width: 100%;
    max-width: 65%;
    height: auto;
  }
  .tickmark {
    width: 26px;
    height: 26px;
  }
  .label {

    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--textlight);
  }
  .labeloffield {
    text-align: left;
    margin: 0 auto;
    padding-bottom: 5px;
  }
  .outerconforbtn {
    width: 196px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 29px;
    padding-bottom: 15px;
  }
  .logoholder.address {
    opacity: 0.5;
    background: var(--green);
    .tickmark {
      width: 10px;
      path {
        fill: var(--textwhite);
      }
    }
  }
  .logoholder.address.valid {
    opacity: 1;
  }
  .tabbtn {
    .buttonmain {
      opacity: 0.7;
      background-color: var(--bgwhite);
      border: 1px solid var(--textlight);
      color: var(--textlight);
      height: 40px;
      border-radius: 3px;
    }
    .buttonmain.active {
      opacity: 1;
      background-color: var(--primary);
      border: 1px solid var(--textlight);
      color: var(--textwhite);
    }
  }
}
