.animatedBanner{
    .anim-circle {
        position: absolute;
        top: 63%;
        left: 50%;
        -webkit-transform: translate(-50%, 50%);
        transform: translate(-50%, 50%);
    }

    @media screen and (max-width: 1199px){
        .anim-circle {
            transform: scale(0.9);
        }    
    }
    @media screen and (max-width: 992px){
        .anim-circle {
            top: 50%;
            transform: scale(0.8);
        }    
    }
    @media screen and (max-width: 768px){
        .anim-circle {
            top: 50%;
            transform: scale(0.75);
        } 
    }
    @media screen and (max-width: 514px) {
        .anim-circle {
            top: 50%;
            transform: scale(0.57);
        } 
    }
    @media screen and (max-width: 400px) {
        .anim-circle {
            top: 50%;
            transform: scale(0.5);
        } 
    }

    .circle__item1 img {
        -webkit-animation-name: rotatus-revers;
        animation-name: rotatus-revers;
        -webkit-animation-duration: 20s;
        animation-duration: 20s;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }

    .circle__item2 img {
        -webkit-animation-name: rotatus-revers;
        animation-name: rotatus-revers;
        -webkit-animation-duration: 30s;
        animation-duration: 30s;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        z-index: -1;
    }

    .circle__item3 img {
        -webkit-animation-name: rotatus-revers;
        animation-name: rotatus-revers;
        -webkit-animation-duration: 40s;
        animation-duration: 40s;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        z-index: -1;
    }

    .circle__item4 img {
        -webkit-animation-name: rotatus-revers;
        animation-name: rotatus-revers;
        -webkit-animation-duration: 50s;
        animation-duration: 50s;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        z-index: -1;
    }

    .circle1 {
        position: absolute;
        top: 0px;
        left: 127px;
    }

    .circle21 {
        position: absolute;
        top: 32px;
        left: 88px;
    }

    .circle22 {
        position: absolute;
        top: 45px;
        left: 323px;
    }

    .circle23 {
        position: absolute;
        top: 288px;
        left: 372px;
    }

    .circle24 {
        position: absolute;
        top: 321px;
        left: 42px;
    }

    .circle31 {
        position: absolute;
        top: 393px;
        left: 503px;
    }

    .circle32 {
        position: absolute;
        top: 125px;
        left: 41px;
    }

    .circle33 {
        position: absolute;
        top: 526px;
        left: 215px;
    }

    .circle34 {
        position: absolute;
        top: 60px;
        left: 435px;
    }

    .circle41 {
        position: absolute;
        top: 125px;
        right: 24px;
    }

    .circle42 {
        position: absolute;
        top: 206px;
        right: -8px;
    }

    .circle43 {
        position: absolute;
        top: 453px;
        right: -9px;
    }

    .circle44 {
        position: absolute;
        bottom: 12px;
        right: 141px;
    }

    .circle45 {
        position: absolute;
        bottom: 71px;
        left: 66px;
    }

    .circle46 {
        position: absolute;
        top: 288px;
        left: 0px;
    }

    .circle47 {
        position: absolute;
        top: 155px;
        left: 50px;
    }

    .circle__item1 {
        width: 256px;
        height: 256px;
        border-radius: 100%;
        border: 1px solid var(--borderlight);
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-animation-name: rotatus;
        animation-name: rotatus;
        -webkit-animation-duration: 20s;
        animation-duration: 20s;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }

    .circle__item2 {
        width: 396px;
        height: 396px;
        border-radius: 100%;
        border: 1px solid var(--borderlight);
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-animation-name: rotatus;
        animation-name: rotatus;
        -webkit-animation-duration: 30s;
        animation-duration: 30s;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }

    .circle__item3 {
        width: 536px;
        height: 536px;
        border-radius: 100%;
        border: 1px solid var(--borderlight);
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-animation-name: rotatus;
        animation-name: rotatus;
        -webkit-animation-duration: 40s;
        animation-duration: 40s;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }

    .circle__item4 {
        width: 668px;
        height: 668px;
        border-radius: 100%;
        border: 1px solid var(--borderlight);
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-animation-name: rotatus;
        animation-name: rotatus;
        -webkit-animation-duration: 50s;
        animation-duration: 50s;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }

    @-webkit-keyframes rotatus {
        0% {
            -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
        }
        100% {
            -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }

    @keyframes rotatus {
        0% {
            -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
        }
        100% {
            -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }

    @-webkit-keyframes rotatus-revers {
        0% {
            -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
        }
        100% {
            -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
        }
    }

    @keyframes rotatus-revers {
        0% {
            -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
        }
        100% {
            -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
        }
    }
}