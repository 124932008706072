.marketratess {
  width: 100%;
  height: 106px;
  border-radius: 6px;
  border: 0.5px solid var(--borderlight);
  padding: 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  margin-bottom: 23px;
  padding: 0px 25px;
  p {
    margin: 0px;
    line-height: 2;
  }
  .coindetailsrow {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .coindetailssection {
    display: flex;
    align-items: center;
    flex: 1 1 30%;
    min-width: 30%;
    // width: 100%;
  }
  .rateandpercentchange {
    flex: 1 1 30%;
    min-width: 30%;
  }
  .coingraphsection {
    flex: 1 1 30%;
    min-width: 30%;
  }
  .eyeflexwrapper {
    flex: 1 1 9%;
    min-width: 9%;
    text-align: center;
  }


  .coingraphsection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 66px;
  }
  .coinimagewrapper {
    height: 63px;
    width: 63px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 15px;
    img {
      height: 35px;
      width: auto;
    }
  }
  .coinnameandsymbol {
    margin: 0px;
    margin-left: 17px;
    .coinname {
      font-weight: 700;
      font-size: 16px;
      width: calc(100% - -22px);
      word-break: break-word;
      letter-spacing: 0.005em;
      opacity: 0.87;
      line-height: 22px;
    }
    .coinsymbol {
      font-size: 12px;
      color: var(--textdark);
      font-weight: 400;
      opacity: 0.54;
      letter-spacing: 0.005em;
      line-height: 16px;
    }
  }
  .rateandpercentchange {
    .coinrate {
      font-weight: 700;
      font-size: 20px;
      width: calc(100% - -30px);
      word-break: break-word;
      line-height: 26px;
      opacity: 0.87;
    }
    .coinpercentchange {
      font-size: 14px;
      margin: 0px 10px;
      font-weight: 400;
    }
    .greentext {
      color: var(--green);
    }
    .redtext {
      color: var(--red);
    }
    .graphincreaseicon {
      path {
      }
    }
    .thisweek {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
    }
    .percentchangedetails {
      line-height: 1.3;
    }
  }
  .graphcolumn {
    padding: 10px 0px;
  }
  .graphwrapper {
    margin-right: 20px;
    img {
      width: 100%;
    }
  }
  .cointag {
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 18px;
    color: var(--textdark);
    display: inline-block;
    box-shadow: 0 2px 4px 0 var(--borderlight), 0 2px 4px 0 var(--borderlight);
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 2px;
    margin-right: 2px;
    // float: right;
    margin-top: 2px;
  }
  .colpadding {
    padding: 10px 13px;
    align-items: center;
    display: flex;
  }
  .eyeiconwrapper {
    margin-left: 20px;
    // border:2px solid yellow;
  }

  .redgraph svg g path {
    stroke: var(--red);
    stroke-width: 1;
  }
  .redgraph svg g g path {
    stroke: none;
    stroke-width: 0;
    fill: var(--red);
    opacity: 0.3;
  }
  .greengraph svg g path {
    stroke: var(--green);
    stroke-width: 1;
  }
  .greengraph svg g g path {
    stroke: none;
    stroke-width: 0;
    fill: var(--green);
    opacity: 0.3;
  }
}
@media (max-width: 382px) {
  .marketratess .coinnameandsymbol .coinname {
    min-width: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media (max-width: 992px) {
  .marketratess {
    height: 65px;
    padding: 5px 12px;
    margin-bottom: 10px;
    .coinimagewrapper {
      height: 33px;
      width: 33px;
      img {
        height: 21px;
      }
    }
    .coinnameandsymbol {
      margin-left: 9px;
      .coinname {
        font-size: 12px;
      }
    }
    .coindetailssection {
      display: flex;
      align-items: center;
      flex: 1 1 30%;
      min-width: 30%;
      order: 1;
      // width: 100%;
    }
    .rateandpercentchange {
      flex: 1 1 30%;
      min-width: 30%;
      order: 3;
    }
    .coingraphsection {
      flex: 1 1 30%;
      min-width: 30%;
      order: 2;
      padding: 0px 20px 0px 20px;
      // height: 63px;
      overflow: hidden;
      svg {
        height: 50px;
        path {
          stroke-width: 5;
        }
      }
    }
    .eyeflexwrapper {
      flex: 1 1 9%;
      // min-width: 9%;
      text-align: end;
      order: 4;
      // height:fit-content;
      // border:2px solid red;
    }
    .rateandpercentchange {
      .coinpercentchange {
        font-size: 8px;
        line-height: 11px;
        margin: 0px 5px 0px 1px;
      }
      .graphincreaseicon {
        width: 9px;
        height: 6px;
        vertical-align: baseline;
      }
      .percentchangedetails {
        line-height: 0;
      }
      .thisweek {
        font-size: 8px;
        line-height: 11px;
      }
      .coinrate {
        line-height: 25px;
        font-size: 12px;
        font-weight: 600;
        width: 100%;
      }
      svg {
        path {
          stroke-width: 4;
        }
      }
    }
    .eyeiconwrapper {
      margin-left: 0px;
    }
  }
}
