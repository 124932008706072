.depositpopup {
  .modal-body {
    padding-bottom: 35px;
    padding-top: 15px;
  }

  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }
  .modal-header {
    border-bottom: none;
  }
  .modal-footer {
    display: block;
  }
  .copy {
    width: 441px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 20px;
  }

  .exchangetext {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: var(--textdark);
    opacity: 0.54;
    text-align: left;
  }
  .exchangetitle {
    font-size: 20px;
    font-weight: 600;
    margin-left: 12px;
  }
  .exchangebox {
    border: 1px solid var(--borderlight);
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 8px;
    border-radius: 5px;
  }
  .address {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--textdark);
    opacity: 0.87;
    word-break: break-word;
  }
  .copy {
    .buttonmain {
      height: 39px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 16px;
    }
  }
  @media screen and (max-width:768px) {
    .modal-dialog {
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
    }

    .modal-content {
      width: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
    .copy {
      .buttonmain {
        width: 200px;
      }
    }
  }
}
