#cyborprotection {
  padding: 0px 5%;
  .containertp {
    margin-top: 33px;
    
  }
  .dwnldChrAtag {
    text-decoration: none;
  }
  .trasactiontitle {
    color: var(--textdark);

    font-weight: 600;
    font-size: 18px;
    line-height: 67px;
    text-align: left;
    padding-left: 20px;
  }
  .trouter {
    height: 85px;
    width: 100%;
    background: var(--bgwhite);
    display: flex;
    align-items: center;
  }
  .cyborprotectionheadwrapper {
    margin-left: 8px;
  }
  .title {
    margin-left: 8px;
    margin-top: 3px;
    display: inline-block;
    font-weight: 600;
  }
  .aboutIcon {
    display: inline-block;
  }
  .cyborprotectionwrapper {
    padding: 20px 30px;
    background-color: var(--bgwhite);
    border-top: none;
  }
  .logo-and-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  // .cyborholdinglogo {
  //   width: 180px;
  // }
  .protectioncontent {
    padding: 0% 7%;
  }
  .protectionheading {
    text-align: center;
    width: 50%;
    margin: auto;
    margin-top: 40px;
  }
  .mainheading {
    font-weight: 700;

    color: var(--textdark);
    // opacity: 0.8;
    font-size: 24px;
    margin-bottom: 15px;
    letter-spacing: -1px;
  }
  .textbelowmainheading {
    margin: auto;
    font-size: 14px;
    color: var(--textlight);
    font-weight: 500;
  }
  .mobilescreenimages {
    margin-top: 50px;
  }
  .appscreenwrapper {
    padding: 20px;
  }
  .appscreenwrapper img {
    width: 100%;
    border-radius: 10px;
  }
  .protectiondecription {
    text-align: left;
    margin-top: 50px;
  }
  .protectiondesciptionline {
    color: var(--textdark);
    font-size: 14px;
    opacity: 0.87;
    text-align: justify;
  }
  .protectiondownloadcontent {
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .protectiondownloadlines {
    line-height: 1;
    color: var(--textlight);
    margin-bottom: 10px;
  }
  .protectiondownloadlines b {
    color: var(--textdark);
    opacity: 0.6;
  }
  .appstoreswrapper {
    margin-bottom: 50px;
  }
  .singleappstorewrapper {
    padding-bottom: 10px;
  }

  .btnwithlefticon {
    border-radius: 4px;
    width: 370px;
    height: 80px;
    justify-content: center;
    box-shadow: none;
  }
  .bottombtn {
    .btnwithlefticon {
      border-radius: 4px;
      width: 360px;
      height: 80px;
      justify-content: center;
      box-shadow: none;
      margin: 0 auto;
    }
  }
  .col-lg-9 {
    width: 100%;
  }
  #downloadapp {
    width: 151px;
    height: 41px;
    margin: 0 auto;
  }
  .buttontext2 {
    font-size: 16px;
    font-weight: 600;
  }
  .lightborder {
    #downloadapp {
      border: 1px solid var(--textwhite);
      .getfrom {
        color: var(--textwhite);
      }
      .platformname {
        color: var(--textwhite);
      }
    }
  }
  .darkborder {
    #downloadapp {
      border: 1px solid var(--borderlight);
      .getfrom {
        color: var(--textdark);
      }
      .platformname {
        color: var(--textdark);
      }
    }
  }
  .CustomButton .lefticonimg {
    height: 31px;
    width: 31px;
    margin: 13px 10px;
  }
  @media screen and (max-width: 1200px) {
    .protectionheading {
      width: 60%;
    }
  }
  @media screen and (max-width: 992px) {
    .containertp {
      padding-left: 0px;
      padding-right: 0px;
    }
    .cyborprotectionwrapper {
      padding: 20px 0px;
    }
    .protectioncontent {
      padding: 0px 0px;
    }
    .cyborholdinglogo {
      margin: auto;
      // width: 50%;
    }
    .protectionheading {
      width: 100%;
    }
    .dlbtn {
      display: none;
    }
    .mainheading {
      font-size: 16px;
    }
    .textbelowmainheading {
      font-size: 14px;
      font-weight: 400;
      color: var(--shadow);
    }
    .protectiondesciptionline {
      font-size: 12px;
      font-weight: 400;
      color: var(--shadow);
    }
    .mobilescreenimages {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      width: auto;
    }
  }

  @media (max-width: 975px) {
    .CustomButton .lefticonimg {
      height: 31px;
      width: 31px;
      margin: 13px 10px;
    }
  }
  @media screen and (max-width: 475px) {
    // .cyborholdinglogo {
    //   width: 120px;
    // }
    .cyborprotectionwrapper {
      padding: 20px 10px;
    }
    .protectioncontent {
      padding: 0px 8px;
    }
    .appscreenwrapper {
      padding: 10px;
    }
    .protectiondecription {
      margin-top: 0px;
    }
    .protectiondesciptionline {
      padding: 10px;
      margin-bottom: 10px;
    }
    .singleappstorewrapper {
      padding: 10px 0px;
      display: flex;
      justify-content: center;
    }
  }
  @media screen and (max-width: 430px) {
    .bottombtn {
      .btnwithlefticon {
        width: 300px;
      }
    }
  }
  @media screen and (max-width: 383px) {
    // .cyborholdinglogo {
    //   width: 100px;
    // }
    .mainheading {
      font-size: 22px;
    }
  }
}
@media (max-width: 556px) {
  #cyborprotection {
    padding: 0px 10px;
  }
}
