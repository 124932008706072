.loginconfirmationpopup {
  .modal-dialog {
    max-width: 570px;
  }
  .modal-body {
    padding-top: 45px;
    padding-bottom: 45px;
    padding-left: 60px;
    padding-right: 60px;
  }
  .modal-content {
    border-radius: 10px;
    background-color: var(--bgwhite);
  }

  .modal-footer {
    display: block;
  }
  .leftalign {
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .securitylogo{
  height: 70px;
  width: 70px;

  }

  .subtimer{
    text-align: right;
    float: right;
 
    font-weight: 600;
    font-size: 11px;
    color: var(--textdark);
    opacity: 0.54;
  }
  .hidden{
    display: none;
  }
  .bluetxt {
    margin-top: 25px;
    margin-bottom: 30px;
    color: var(--primary);
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }
  .code {
    display: inline-block;
    .input.form-control.input {
      width: 55px;
      height: 55px;
      margin-left: 24px;
      border-radius: 10px;
    }
  }
  .code1 {
    display: inline-block;
    .input.form-control.input {
      width: 55px;
      height: 55px;
      margin-right: 24px;
      border-radius: 10px;
    }
  }
  .code2 {
    display: inline-block;
    .input.form-control.input {
      width: 55px;
      height: 55px;
      border-radius: 10px;
    }
  }
  .getcode {
    margin-top: 45px;
    .input.form-control.input {
      border-radius: 4px;
      padding-left: 25px;
    }
  }

  .titleText {
    font-size: 16px;
    font-weight: 600;
  }
  .titletop {
    font-weight: 600;
    font-size: 16px;
    color: var(--textdark);
    opacity: 0.87;
  }
  .subtitle {
    font-weight: 600;
    font-size: 11px;
    color: var(--textdark);
    opacity: 0.54;
    margin-top: 15px;
  }
  .btmtxt {
    text-align: left;
    margin-left: 25px;
    font-size: 9px;
    font-weight: 400;
    color: var(--textdark);
    opacity: 0.75;
    margin-top: 6px;
  }

  .subtitle1 {
    font-weight: 400;
    font-size: 12px;
    color: var(--textdark);
    opacity: 0.54;
    margin-top: 15px;
    text-align: left;
    margin-left: 25px;
  }
  .linktxt {
    position: relative;
    top: -22px;
    float: right;
    right: 25px;
  }
  .rightmargin {
    width: 10px;
    display: block;
  }
  .backbtn {
    position: absolute;
    left: 50px;
    top: 120px;
    cursor: pointer;
  }

  .confirm {
    .buttonmain {
      width: 130px;
      height: 40px;
      border-radius: 4px;
      font-weight: 600;
      font-size: 12px;
    }
  }
  .otparea {
    display: block !important;
    width: 100%;
    text-align: center;
  }
  .otpinput {
    width: 20%;
    height: 45px;
    justify-content: center;
    display: inline-block !important;
    width: 16.25%;

    input {
      width: calc(100% - 10px) !important;
      height: 100%;
      border-radius: 4px;
      color: var(--textdark) !important;
      border-color: var(--borderlight) !important;
      background-color: var(--bgwhite) !important;
      border: 1px solid;
      border-width: 1px;
    }
  }

  .centeralign {
    text-align: center;
  }
  .img {
    width: 120px;
    height: 50px;
    margin-bottom: 20px;
  }
  @media (max-width: 460px) {
    .linktxt {
      // top: 49%;
      right: 8px;
    }
    .getcode {
      .input.form-control.input {
        padding-left: 8px;
      }
    }
  }
  @media screen and (max-width: 450px) {
    .modal-body {
      padding-left: 15px;
      padding-right: 15px;
    }
    .backbtn {
      left: 10px;
    }
  }
  @media screen and (max-width: 768px) {
    .modal-dialog {
      max-width: 100%;
      width: 100%;
      height: 150vh;
      margin: 0 auto;
    }

    .modal-content {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
    .hidebtn {
      display: none;
    }
    .titleText {
      font-size: 14px;
      font-weight: 700;
      text-align: left;
    }
    .titletop {
      text-align: left;
    }
    .subtitle {
      margin-top: 48px;
      text-align: left;
    }
    .getcode {
      margin-top: 20px;
    }
    .confirm {
      .buttonmain {
        width: 250px;
      }
    }
  }
  @media screen and (min-width: 768px) {
    .backbtn {
      display: none;
    }
    .hide {
      display: none;
    }
  }
  @media screen and (min-width: 350px) and (max-width: 460px) {
    .linktxt {
      font-size: 10px;
    }
    .getcode {
      .input.form-control.input {
        font-size: 10px;
      }
    }
  }
}
