.noacess {
  .modal-dialog {
    max-width: 500px;
  }
  .modal-body {
    padding-top: 40px;
    padding-bottom: 45px;
    padding-left: 60px;
    padding-right: 60px;
  }
  .modal-content {
    border-radius: 10px;
    background-color: var(--bgwhite);
  }
  .modal-footer {
    display: block;
  }
  .outercls {
    text-align: center;
  }
  .labelcls {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    color: var(--textdark);
  }

  .rowspace {
    margin-top: 35px;
  }
  @media screen and (max-width: 992px) {
    .modal-dialog {
      max-width: 100%;
      width: 100%;
      height: 100%;
      margin: 0 auto;
    }

    .modal-content {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
  }
}
