.idocard {
  width: 100%;
  .cardoutersection {
    width: 100%;
    //max-width: 212px;
    height: auto;
    background: var(--bgwhite);
    box-shadow: 0px 1px 10px var(--borderlight);
    border-radius: 6px;
    padding-left: 5px;
    padding-right: 5px;
    border-bottom: none;
    margin-bottom: 37px;
  }
  .labelwithicon {
    display: flex;
    padding: 5px;
    width: 100%;
    //max-width: 212px;
    height: auto;
    background: var(--bgwhite);
    padding-top: 7px;
    padding-bottom: 7px;
    //eborder-bottom: 1px solid var(--borderlight);
    border-radius: 6px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    text-align: left;
    box-shadow: 0px 1.4px 0.7px 0px var(--borderlight);
  }
  .coincircle {
    min-width: 30px;
    height: 30px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;
    float: left;
  }
  img.coinimg {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
  .coinlistarea {
   
    position: relative;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: var(--textdark);
    margin-left: 10px;
  }
  .idovalue {
  
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: var(--textdark);
  }
  .valuesection {
    padding: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: default;
  }
}
