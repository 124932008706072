.EditLoanTypePopup {
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 40px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 40px;
    margin-left: 40px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    // background: var(--borderlight);
    border-radius: 10px;
  }

  /* Handle on hover */
  // ::-webkit-scrollbar-thumb:hover {
  //   background: #b30000;
  // }
  .modal-dialog {
    max-width: 700px;
  }
  .modal-body {
    padding: 45px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }
  .fileErrorCustom {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 12px;
    color: #dc3545;
  }

  .classscroll {
    max-height: 755px;
    overflow-y: scroll;
  }
  .containerallignm {
    margin-top: 33px;
  }
  .outercontainer {
    background-color: var(--bgwhite);
    overflow: hidden;
  }
  .margintop {
    margin-top: 65px;
  }
  .input.form-control.input {
    height: 31px;
    border-radius: 10px;
    padding-left: 12px;
  }
  .titles {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    margin-top: 40px;
  }
  .inputbox {
    .input {
      border-radius: 2px;
      width: 430px;
      margin-top: 10px;
      border-color: var(--borderlight);
    }
  }
  .inputphone {
    .react-tel-input {
      width: 100%;
      height: 31px;
    }
  }
  .titlepass {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    margin-top: 40px;
  }
  .errortext {
    width: 100%;
   
    color: #dc3545;
   
    font-size: 12px;
  font-weight: 400;
  text-align: left;
  }
  .errortext1 {
    width: 100%;
   
    color: #dc3545;
   
    font-size: 12px;
  font-weight: 400;
  text-align: center;
  }
  .inputboxpass {
    display: 100%;
    .input {
      border-radius: 2px;
      width: 430px;
      margin-top: 10px;
      border-color: var(--borderlight);
    }
  }
  .outerpass {
    text-align: left;
  }
  .outerspan {
    display: inline-block;
  }
  .resetpass {
    color: var(--primary);
    font-size: 16px;
    font-weight: 400;
    margin-left: 20px;
  }

  // .topbuttons {
  //   .buttonmain:hover {
  //     box-shadow: 0px 2px 4px var(--borderdarker);
  //   }
  // }

  // .bottombuttons {
  //   .buttonmain:hover {
  //     box-shadow: 0px 2px 4px var(--borderdarker);
  //   }
  // }
  .topbuttons {
    display: inline-block;
    .buttonmain {
      width: 153px;
      height: 34px;
      margin-right: 15px;
      border-radius: 9px;
      font-weight: 600;
      font-size: 16px;
      box-shadow: 2px 0px 3px var(--borderlight);
    }
  }
  .bottombuttons {
    display: inline-block;
    .buttonmain {
      width: 153px;
      height: 34px;
      margin-right: 15px;
      border-radius: 9px;
      font-weight: 600;
      font-size: 16px;
      color: var(--primary);
      background-color: var(--bgwhite);
      box-shadow: 2px 0px 3px var(--borderlight);
      border: 0.5px solid var(--borderlight);
    }
  }
  .btmbtnoutercontainer {
    margin-top: 20px;
  }
  .textcontainer {
    display: inline-block;
  }

  .react-tel-input .form-control {
    margin-top: 10px !important;
    width: 100% !important;
    height: 31px !important;
    border-radius: 10px !important;
    border-left: 1px solid var(--borderdark) !important;
    border-top: 1px solid var(--borderdark) !important;
    border-right: 1px solid var(--borderdark) !important;
    border-bottom: 1px solid var(--borderdark);
  }
  .marginlft {
    margin-left: 18px;
  }
  .outercls {
    text-align: left;
    margin-top: 15px;
  }
  .spanwidth {
    width: 200px;
  }

  .imgsize {
    width: 100%;
    height: 100%;
  }

  .spanmsg {
    font-size: 12px;
    font-weight: 400;
    color: var(--textlight);
    opacity: 0.9;
  }

  .InputField .passwordIcon {
    position: absolute;
    right: 17px;
    width: 20px;
    cursor: pointer;
  }
  .display {
    display: inline-block;
    vertical-align: middle;
  }
  .imgouter {
    // border: 1px solid var(--borderlight);
    height: 180px;
    width: 200px;
    .integratedimage {
      object-fit: cover;
    }
  }
  .btntop {
    margin-top: 0px;
  }
  .invalid-feedback {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
  }
  .inputfilebtn {
    width: 0px;
  }
  .editspan {
    margin-left: 10px;
  }
  .back {
    path {
      fill: var(--textlight);
    }
  }
  .inputbuttonmain {
    font-weight: 600;
    font-size: 16px;
    padding: 4px 14px;
    margin-right: 10px;
    margin: 20px 0;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 9px;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    color: var(--primary);
    background-color: var(--bgwhite);
    box-shadow: 2px 0px 3px var(--borderlight);
    border: 0.5px solid var(--borderlight);
    width: 153px;
    height: 34px;
    text-align: center;
    cursor: pointer;
    // &:hover {
    //   box-shadow: 0px 2px 4px var(--borderdarker);
    // }
  }

  .displayasinline {
    display: inline-block;
    vertical-align: top;
  }

  .displayasinline2 {
    display: inline-block;
    width: calc(100% - 200px);
  }

  .error-msg{
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 0px;
    color: var(--textdark);
  }
 
  
  @media (max-width: 992px) {
    .modal-body {
      padding: 45px 25px;
    }
    .closebtn {
      display: none;
    }
    .margintop {
      margin-top: 15px;
    }

    .modalPrimaryHeader {
      height: 29px;
      background-color: var(--primary);
    }
    .modal-body {
      padding: 0px;
      min-height: 100vh;
    }
    .modal-dialog {
      max-width: 100%;
      width: 100%;

      margin: 0 auto;
    }

    .modal-content {
      width: 100%;
      min-height: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
    .titles {
      font-weight: 400;
      margin-top: 28px;
    }
    .edittitle {
      font-size: 16px;
      font-weight: 700;
      color: var(--textdark);
      opacity: 0.87;
      margin-top: 25px;
      text-align: left;
    }
   
    .spanwidth{
      display: none;
    }
    .imgouter {
      width: 308px;
      height: 268px;
  }
  .input.form-control.input{
    width: 430px;
    height: 40px;
  }
  .react-tel-input .form-control{
    width: 430px !important;
    height: 40px !important;
  }
  .btntop{
    margin-top: 20px;
  }
  .outercontainer{
    padding: 0px 5%;
  }
  .marginlft{
    margin-left: 0px;
  }
  }
  @media (max-width: 586px) {
    .displayasinline2 {
      display: block;
      width: 100%;
    }

    .marginlft {
      margin-left: 4%;
    }
  }
  @media (max-width: 510px){
    .input.form-control.input{
      width: 305px;
    }
    .react-tel-input .form-control{
      width: 305px !important;
    }
  }
  @media (max-width: 380px) {
    .topbuttons {
      .buttonmain {
        width: 135px;
      }
    }
    .bottombuttons {
      .buttonmain {
        width: 135px;
      }
    }
    .btntop {
      .buttonmain {
        width: 125px;
        height: 40px;
      }
    }
    .inputbuttonmain {
      width: 125px;
      height: 40px;
    }
    .EditBranchpopup{
      padding: 0px 3%;
    }
    .btmbtnoutercontainer{
      width: 114%;
    }
  }
  @media (min-width: 992px) {
    .hidden {
      display: none;
    }
  }
}
