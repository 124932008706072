#setnewpassword {
  .outercls{
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding-top: 30px;
  }
  .red {
    color: var(--red);
  }
  .exchangetitle {
    font-size: 16px;
    font-weight: 600;
    margin-left: 12px;
  }
  .titleText {
    font-size: 16px;
    font-weight: 600;
    color: var(textdark);
    opacity: 0.87;
  }
  .titlepass {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
  }
  .inputboxpass {
    .input {
      margin-top: 10px;
      border-color: var(--borderlight);
    }
  }
  .InputField .input.form-control.input {
    border-radius: 10px;
    padding: 0 10px;
  }
  .exchangebox {
    border: 1px solid var(--borderlight);
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 20px;
    border-radius: 5px;
  }
  .address {
    font-size: 16px;
    font-weight: 400;
    word-break: break-word;
  }
  .passwordIcon {
    position: absolute;
    margin-top: -33px;
    width: 20px;
    right: 15px;
    cursor: pointer;
  }
  .confirm {
    margin-left: 25%;
    .buttonmain {
      width: 153px;
      height: 34px;
      border-radius: 10px;
      font-weight: 600;
      font-size: 16px;
      box-shadow: 0px 1px 4px var(--borderlight);
    }
  }
  .cancel {
    margin-right: 25%;
    .buttonmain {
      background-color: var(--bgwhite);
      border: 1px solid var(--primary);
      color: var(--primary);
      height: 34px;
      width: 153px;
      border-radius: 10px;
      font-weight: 600;
      font-size: 16px;
      box-shadow: 0px 1px 4px var(--borderlight);
    }
  }

  .colspace {
    margin-bottom: 50px;
  }
  @media screen and (min-width: 993px) {
  }

  @media screen and (max-width: 992px) {
    .titleText {
      font-weight: 400;
      color: var(--shadow);
      opacity: 0.87;
    }

    .cancel {
      .buttonmain {
        width: 140px;
      }
    }
    .confirm {
      .buttonmain {
        width: 140px;
      }
    }
  }
  @media screen and (max-width: 410px) {
    .cancel {
      .buttonmain {
        width: 115px;
      }
    }
    .confirm {
      .buttonmain {
        width: 115px;
      }
    }
  }
}
