.roundlist {
  .tabholder {
    padding-bottom: 25px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 37px;
  }
  .table-responsive-sm {
    overflow-x: auto;
  }
  .transfertbl {
    width: 100%;
    background: var(--bgwhite);
    border: var(--borderdark);
    border-radius: 2px;
  }
  thead tr {
    border: 1px solid var(--borderlight);
    background-color: var(--bglightgrey);
  }
  tr {
    border-color: var(--borderlight);
    cursor: pointer;
    border-bottom: 1px solid var(--borderlight);
  }
  tr:hover {
    background-color: var(--bglightgrey);
  }
  table {
    border-color: var(--borderdark);
  }
  td {
    img {
      margin-top: 3px;
    }
  }
  td,
  th {
    border: none;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
  }
  .trtbleheading {
    color: var(--textdark);
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .values {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: var(--textdark);
  }
  .textallign {
    text-align: center;
  }
  .outercontainerforbtn {
    margin-bottom: 43px;
    height: 70px;
  }
  .outerconforbtn1 {
    width: 109px;
    height: 28px;
    display: inline-block;
    margin-right: 20px;
  }
}
