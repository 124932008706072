.SigninqrPopup {
  .modal-dialog {
    max-width: 480px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .modal-body {
    padding: 45px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }

  .two-factor {
    text-align: center;
    margin: 0 auto;
  }

  .text-twofact {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--textdark);
    opacity: 0.87;
  }

  .descriptiontext {
    margin: 0 auto;
    text-align: center;
    max-width: 291px;
    padding-top: 10px;
    padding-bottom: 28px;
    line-height: 18px;
  }

  .text-description {
    font-weight: 400;
    font-size: 11px;

    text-align: center;
    color: var(--textdark);
    opacity: 0.54;
  }

  .qrcode-section {
    // width: 280px;
    height: 200px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    max-width: 280px;
  }

  .imagewh {
    width: 100%;
    height: 100%;
  }

  .addressofqr {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    max-width: 209px;
    padding-top: 50px;
    padding-bottom: 30px;
  }

  .addressqrtext {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.08px;
    color: var(--textdark);
    opacity: 0.87;
    word-break: break-all;
  }

  .allignleft {
    text-align: left;
  }

  .allignright {
    text-align: right;
  }
  .confirm {
    .buttonmain {
      background-color: var(--primary);
      color: var(--textwhite);
      height: 40px;
      width: 130px;
      border-radius: 4px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  }
  .cancel {
    .buttonmain {
      background-color: var(--bgwhite);
      height: 40px;
      width: 130px;
      border-radius: 4px;
      border: 1px solid var(--borderlight);
      color: var(--primary);
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.08px;
      line-height: 16px;
    }
  }
  .borderimg {
    position: absolute;
    width: 20px;
    height: 20px;
  }
  .borderimg-1 {
    top: -25px;
    left: -1px;
    border-top: 1px solid var(--borderlight);
    border-left: 1px solid var(--borderlight);
  }
  .borderimg-2 {
    right: -1px;
    top: -25px;
    border-top: 1px solid var(--borderlight);
    border-right: 1px solid var(--borderlight);
  }
  .borderimg-3 {
    bottom: -25px;
    right: -1px;
    border-bottom: 1px solid var(--borderlight);
    border-right: 1px solid var(--borderlight);
  }
  .borderimg-4 {
    bottom: -25px;
    left: -1px;
    border-bottom: 1px solid var(--borderlight);
    border-left: 1px solid var(--borderlight);
  }
  @media (max-width: 400px) {
    .modal-body {
      padding: 45px 25px 45px 25px;
    }
    .cancel {
      .buttonmain {
        background-color: var(--bgwhite);
        height: 40px;
        width: 100%;
        border-radius: 9px;
        border: 1px solid var(--borderlight);
        color: var(--primary);
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.08px;
        line-height: 16px;
      }
    }

    .confirm {
      .buttonmain {
        background-color: var(--primary);
        color: var(--textwhite);
        height: 40px;
        width: 100%;
        border-radius: 9px;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  @media (max-width: 768px) {
    .hidebtn {
      display: none;
    }
    .modal-dialog {
      max-width: 100%;
      width: 100%;

      margin: 0 auto;
    }

    .modal-content {
      width: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
    .descriptiontext {
      text-align: left;
      max-width: 100%;
    }
    .two-factor {
      text-align: left;
    }
  }
}
