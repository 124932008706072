#VerificationSignUp {
  .outerbox {
    border-radius: 2px;
    padding: 50px 25px 50px 25px;
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 99;
  }
  .image {
    width: 153px;
    //height: 50px;
    margin-top: 0px;
    margin-bottom: 25px;
  }
  .imglogo{
    width: 100px;
  }
  .outerconforbtn {
    height: 40px;
    margin: 0 auto 0;
    width: 115px;
  }
  .outerconttxt {
    margin-top: 24px;
  }
  .txtclass {
    font-size: 14px;
  }
  .formfieldholder {
    margin-bottom: 25px;
  }
  .whitebg {
    background-color: var(--bgwhite);
  }
  .spanmsg{
    line-height: 25px;
    font-size: 16px;
    font-weight: 500;
    color: var(--textdark);
  }

  .text{
    padding-bottom: 8px;
  }

  .reverttext{
   color:var(--primary);
   cursor: pointer;
  }
}
