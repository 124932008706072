.filter {
  .btn-primary {
    color: var(--textdark);
    background-color: var(--bgwhite);
    border-color: var(--primary);
    border: 1px solid var(--primary);
    border-radius: 4px;
    height: 44px;
    width: 256px;
  }
  .btn-check:active + .btn-primary,
  .btn-check:checked + .btn-primary,
  .btn-primary.active,
  .btn-primary:active,
  .show > .btn-primary.dropdown-toggle {
    color: var(--textdark);
    background-color: var(--bgwhite);
    border-color: var(--primary);
    border: 1px solid var(--primary);
    border-radius: 4px;
  }
  .btn-check:focus + .btn-primary,
  .btn-primary:focus,
  .btn-check:active + .btn-primary:focus,
  .btn-check:checked + .btn-primary:focus,
  .btn-primary.active:focus,
  .btn-primary:active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    color: var(--textdark);
    background-color: var(--bgwhite);
    border-color: var(--primary);
    border: 1px solid var(--primary);
    border-radius: 4px;
  }
  .dropdown-menu {
    width: 100%;
    margin-top: -4px;
    border-top: none;
    background-color: var(--bgwhite);
    border: 1px solid var(--borderdark);
  }
  .dropdown-toggle::after {
    vertical-align: 0;
  }
  .filtericon {
    path {
      fill: var(--textdark);
    }
    vertical-align: top;
    height: 80%;
    width: 25px;
    display: inline-block;
  }
  .filtertext {
    margin-left: 18px;
    display: inline-block;
    line-height: 29px;
    opacity: 0.84;
    font-size: 14px;
    font-weight: 600;
    vertical-align: top;
    width: 170px;
    text-align: left;
  }

  .dropdownarrow {
    path {
      fill: var(--textdark);
    }
  }
  .dropdown.show {
    border-bottom: none;
    .dropdownarrow {
      transform: rotate(180deg);
    }
  }

  @media  (max-width:992px) {
    .dropdown-toggle::after {
      vertical-align: 0.255em;
    }
  }

  @media (max-width: 430px) {
    .filtericon {
      width: 20px;
    }
    .filtertext {
      margin-left: 8px;
      margin-right: 5px;
    }
  }
}
.daterangepicker {
  right: 75px !important;
  &:before {
    display: none !important;
  }
}
