.addbankconfirmationpopup {
  .modal-body {
    padding-top: 45px;
    padding-bottom: 10px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }
  .modal-header {
    box-shadow: 0 4px 6px -6px var(--borderlight);
  }
  .addbanktext {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: var(--textdark);
    opacity: 0.87;
  }
  .BankListItem .bankimgs {
    width: 40px;
}
  .subtxt {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: var(--textdark);
    opacity: 0.54;
    margin-top: 12px;
    margin-bottom: 20px;
  }
  .bankname {
    margin-top: 0px;
  }
  .bankimg {
    margin-top: 0px;
    height: 60%;
  }
  .addbanktitle {
    font-size: 16px;
    font-weight: 600;
    margin-left: 12px;
  }
  .addbanksymbol {
    width: 60px;
    margin-top: 50px;
    cursor: pointer;
  }
  .textblue {
    display: block;
    padding-top: 10px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    color: var(--primary);
  }
  .confirm {
    .buttonmain {
      height: 39px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .cancel {
    .buttonmain {
      background-color: var(--primary);
      border: 1px solid var(--primary);
      color: var(--textwhite);
      height: 39px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 16px;
      width: max-content;
      min-width: 116px;
      margin-bottom: 50px;
    }
  }
  .BankListItem {
    height: auto;
    box-sizing: border-box;
    padding: 20px 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    line-height: 20px;
    text-align: left;
    margin-bottom: 12px;
    white-space: nowrap;
    overflow: hidden;
  }
  .textallign {
    line-height: 24px;
  }
  .AddBankImage{
    margin: auto;
    width: 200px;
    height: 200px;
    border: 1px solid var(--borderlight);
    border-radius: 9px;
    margin-bottom: 15px;
  }
  @media (max-width: 992px) {
    .modal-dialog {
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
    }

    .modal-content {
      width: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
  }
}
