.LoginWelcomePopup {
  .modal-dialog {
    max-width: 650px;
    width: 100%;
  }
  .modal-body {
    padding: 45px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }
  .transectiontype {
    text-align: left;
    margin-bottom: 20px;
  }
  .imgonsignup {
    width: 85px;
    //height: 50px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .imagesection {
    text-align: center;
    margin: 0 auto;
  }

  .textsection {
    padding: 10px 0px 10px 0px;
  }

  .lineone {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    max-width: 250px;
  }

  .text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--textdarker);
    opacity: 0.87;
  }

  .text1 {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    opacity: 0.87;
    color: var(--textdarker);
  }
  .linetwo {
    text-align: center;
    width: 100%;
    margin: 27px auto;
    max-width: 250px;
  }

  .outerconforbtn {
    margin: 0 auto 0;
    width: 160px;
  }
  .buttonmain {
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    height: 39px;
    margin: 20px 0;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    border-radius: 4px;
    transition: all 0.4s ease-in-out;
    color: var(--textwhite);
    background-color: var(--primary);
  }
  @media (max-width: 992px) {
    .modal-dialog {
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
    }

    .modal-content {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
  }
}
