.Withdrawalfiat {
  .modalPrimaryHeader {
    height: 29px;
    background-color: var(--primary);
  }
  .colborder {
    border-left: 1px solid var(--borderlight);
  }


  .currencytoconvert {
    margin-bottom: 20px;
  }

  .box {
    height: auto;
    background: var(--bgwhite);
    box-shadow: 0px 4px 30px var(--borderlight);
    box-sizing: border-box;
    border-radius: 1px;
    padding: 20px;
    text-align: left;
    width: 80%;
    margin: auto;
    margin-top: 10px;
  }

  .bold {
    font-weight: 600;
  }

  .boxtitle {
    text-align: center;
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .boxtitletext {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--textdarker);
  }

  .dropdown-menu {
    width:280px;
  }
  .search {
    top: 2px;
    z-index: 0;
  }
  .modal-dialog {
    max-width: 1100px;
  }
  .modal-body {
    padding: 45px 20px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }
  .topspace {
    max-height: 600px;
    overflow-y: auto;
    padding-right: 15px;
  }
  .btmspacefottoggle {
    text-align: right;
    margin-bottom: 20px;
  }
  @media only screen and (min-width: 992px) {
    .hidetoggle1 {
      display: none;
    }
    .hide-for-desktop {
      display: none;
    }
    .desk {
      display: none;
    }
  }
  @media (max-width: 991px) {
    .colborder {
      border-left: none;
    }
    .dropdown-menu {
      width: 100%;
    }
    .modal-body {
      padding: 30px 0px 65px 0px;
    }
    .modal-dialog {
      max-width: 100%;
      width: 100%;
      height: auto;

      margin: 0 auto;
    }

    .modal-content {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
    .closebtn {
      display: none;
    }
    .rightnav {
      float: right;
      width: 9%;
      height: 56px;
      width: 41px;
      background-color: var(--primary);
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0px;
      top: 30px;
    }

    .banklistlayout {
      display: none;
    }
    .btmspacefottoggle {
      text-align: center;
    }
    .modalPrimaryHeader {
      height: 29px;
      background-color: var(--primary);
    }
  }
}
.mod-exchangebackdrop {
  opacity: 0 !important;
}
.Withdrawalfiat.hidethisexchangemodal {
  display: none !important;
}
