.addbankadmin {
    .modal-body {
      padding-top: 45px;
      padding-bottom: 45px;
    }
    .modal-content {
      background-color: var(--bgwhite);
      border-radius: 10px;
    }
    .modal-header {
      box-shadow: 0 4px 6px -6px var(--borderlight);
    }
    .inputfilebtn {
      width: 0px;
    }
    .inputbuttonmain {
      width: 153px;
      height: 34px;
      font-weight: 600;
      padding-left: 19px;
      padding-right: 19px;
      font-size: 16px;
      padding-top: 4px;
      padding-bottom: 4px;
      margin-right: 10px;
      margin: 20px 0;
      margin-bottom: 10px;
      margin-top: 10px;
      margin-right: 10px;
      border-radius: 9px;
      -o-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
      color: var(--primary);
      background-color: var(--bgwhite);
      box-shadow: 2px 0px 3px var(--borderlight);
      border: 0.5px solid var(--primary);
      cursor: pointer;
      // &:hover {
      //   box-shadow: 0px 2px 4px var(--borderdarker);
      // }
    }
  
    .inputbuttonmaindark {
      width: 153px;
      height: 34px;
      font-weight: 600;
      padding-left: 19px;
      padding-right: 19px;
      font-size: 16px;
      padding-top: 4px;
      padding-bottom: 4px;
      margin-right: 10px;
      margin: 20px 0;
      margin-bottom: 10px;
      margin-top: 10px;
      margin-right: 10px;
      border-radius: 9px;
      -o-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
      color: var(--primary);
      background-color: var(--bgwhite);
      box-shadow: 2px 0px 3px var(--borderlight);
      border: 0.5px solid var(--primary);
      cursor: pointer;
      // &:hover {
      //   box-shadow: 0px 2px 4px var(--borderlight);
      // }
    }
    .logoErrorMessages{
      display: block;
      margin-top: -10px;
      font-size: 11px;
      font-weight: 600;
      text-align: left;
      color: #dc3545;
    }
    .btnexclude {
      .buttonmain {
        color: var(--primary);
        background-color: var(--bgwhite);
        border: 0.5px solid var(--primary);
        cursor: pointer;
        // &:hover {
        //   box-shadow: 0px 2px 4px var(--borderlight);
        // }
      }
    }
    .btnexcludedark {
      .buttonmain {
        color: var(--primary);
        background-color: var(--bgwhite);
        border: 0.5px solid var(--primary);
        filter: drop-shadow(2px 0px 3px rgba(0, 0, 0, 0.12));
        border-radius: 10px;
        cursor: pointer;
        // &:hover {
        //   box-shadow: 0px 2px 4px var(--borderlight);
        // }
      }
    }
    .topbuttons {
      display: inline-block;
      .buttonmain {
        // width: 95px;
        width: 153px;
        height: 34px;
       // margin-right: 25px;
        border-radius: 9px;
        font-weight: 600;
        font-size: 16px;
        box-shadow: 2px 0px 3px var(--borderlight);
      }
    }
    .editButtons{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    // .btntop {
    //   text-align: center;
    //   margin-top: 72px;
    //   vertical-align: top;
    // }
  
    .modal-title {
      margin: auto;
      margin-top: 18px;
      line-height: 1.5;
      text-align: center;
    }
    .spantext {
      vertical-align: middle;
    }
    .deletetext {
      font-size: 15px;
      font-weight: 400;
      text-align: left;
    }
    .outerbx {
      border: 1px solid var(--borderlight);
      border-radius: 10px;
      text-align: center;
      padding: 10px;
      margin-top: 15px;
    }
    .iconImage{
      max-height: 126px;
      max-width: 310px;
    }
    .addlogo{
      cursor: pointer;
    }
    .addtext {
      margin-top: 5px;
      margin-bottom: 2px;
      font-size: 12px;
      font-weight: 400;
      color: var(--textdark);
      opacity: 0.54;
    }
    .addtext1 {
      margin-top: 5px;
      margin-bottom: 2px;
      font-size: 10px;
      font-weight: 400;
      color: var(--textdark);
      opacity: 0.54;
    }
    .check {
      display: inline-block;
      margin-top: 5px;
      // width: 3px;
    }
    .displaytoo {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .curimg {
      width: 20px;
      height: 20px;
      margin-top: -1px;
    }
    .checkdiv {
      display: inline-block;
      float: right;
      width: 226px;
    }
    .checkdiv1 {
      display: inline-block;
    }
    .lispace {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .buttonmain {
      width: 150px;
    }
    .tpspace {
      margin-top: 10px;
    }
    .deletetitle {
      font-size: 16px;
      font-weight: 600;
      margin-left: 12px;
      color: var(--textdark);
      opacity: 0.87;
    }
  
    .input.form-control.input {
      border-radius: 10px;
      height: 31px;
      padding: 0 12px;
    }
    .form-check-input:invalid {
      background-color: var(--bgwhite);
    }
    .form-check-input[type="checkbox"] {
      border-radius: 2px;
      margin: auto 0;
      height: 15px;
      cursor: pointer;
      width: 15px;
      margin-right: 10px;
      border: 1px solid var(--borderlight);
      box-shadow: none;
      margin-top: 2px;
    }
    .form-check-input:active {
      border: transparent;
    }
    .form-check-input:focus {
      border-color: #86b7fe;
      outline: 0;
      box-shadow: none;
    }
    .form-check {
      padding-left: 0;
    }
    .titles {
      text-align: left;
      margin-bottom: 18px;
      font-size: 12px;
      font-weight: 600;
      color: var(--textdark);
    }
    .dropdownlink {
      border-radius: 10px;
    }
  
    .cointext {
      margin-top: 2px;
      margin-left: unset;
    }
    .outercontainer {
      margin: auto;
      width: 321px;
    }
    .dropdownlink {
      margin-top: 11px;
      //width: 321px;
      height: 31px;
    }
    .modal-header {
      border-bottom: 0px;
    }
  
    .toogleicon {
      line-height: 23px;
    }
    .buttonmain {
      width: 160px;
      height: 39px;
    }
    .dropdown-menu.show {
      margin-top: 1px;
    }
    .inputbox1 {
      .dropdownlink {
        height: 31px;
        border-radius: 10px;
      }
      .toogleicon {
        line-height: 22px;
      }
      .search {
        left: 4px;
        height: 24px;
      }
    }
    .btmbtnoutercontainer {
      margin-top: 56px;
    }
    .addNewText {
      margin-bottom: 0px;
      text-align: left;
      font-weight: 600;
      font-size: 16px;
      color: var(--textdark);
    }
    .arrowicon {
      transform: rotate(180deg);
      margin-right: 10px;
      path {
        fill: var(--borderdarker);
      }
    }
    .container {
      background-color: var(--bgwhite);
    }
    .footercommon {
      background-color: var(--bgwhite);
    }
    .errormessage {
      margin-top: 0.25rem;
      font-size: 0.875em;
      color: #dc3545;
    }
    @media (max-width: 992px) {
      .modal-dialog {
        max-width: 100%;
        width: 100%;
        //height: 130vh;
  
        margin: 0 auto;
      }
  
      .modal-content {
        width: 100%;
        //height: 160vh;
        margin: 0 auto;
        border-radius: 0px;
        border: none;
      }
      .inputbox1 .dropdownlink {
        height: 40px;
      }
  
      .input.form-control.input {
        height: 40px;
      }
      .checkdiv {
        float: unset;
        margin-left: 20px;
        min-width: 226px;
        width: 100%;
      }
      .checkdiv1{
        display: flex;
      }
  
      .deletetitle {
        margin-left: -75%;
      }
      .modal-body {
        padding-top: 33px;
      }
      .btmbtnoutercontainer {
        margin-top: 61px;
      }
      .toogleicon {
        margin-top: 5px;
      }
      .outercontainer {
        width: 100%;
      }
      .modal-header {
        display: none;
      }
      .deletetitle {
        display: none;
      }
      .cointext {
        margin-top: 6px;
      }
    }
    @media (min-width: 992px) {
      .hidefordeskop {
        display: none;
      }
      .hide {
        display: none;
      }
    }
  }
  