.UploadPopup {
  .modal-dialog {
    max-width: 1156px;
    width: 100%;
  }
  .modal-body {
    padding: 25px 0px 50px 0px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }
  .text {
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;
    color: var(--textdark);
  }
  .exchangetitle {
    font-size: 16px;
    font-weight: 700;
    margin-left: 12px;
  }
  .box {
    margin-top: 28px;
    height: auto;
    background: var(--bgwhite);
    // border: 1px solid var(--borderlight);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px;
    box-shadow: 0px 3px 20px var(--borderlight);
  }
  .link {
    text-align: center;
  }
  .linktxt {
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    color: var(--primary);
  }
  .linktxt1 {
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    color: var(--primary);
  }
  .textline1 {
    display: block;
    font-size: 11px;
    font-weight: 600;
    color: var(--textdark);
  }

  .textline2 {
    display: block;
    font-size: 11px;
    font-weight: 600;
    color: var(--textlight);
  }

  .imgsection {
    width: 191px;
    height: 27px;
    text-align: center;
    margin: 0 auto;
  }
  .imgsection1 {
    width: 260px;
    text-align: center;
  }

  .imglogo {
    width: 100%;
    height: 100%;
  }

  .text {
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: var(--textdarker);
    opacity: 0.54;
    margin-bottom: 20px;
  }

  /*only use if png*/
  .qrcode-section {
    width: 310px;
    height: 305px;
    margin: 0 auto;
    text-align: center;
  }
  .transectionid {
    display: inline-block;
    width: 100%;
    max-width: 65%;
    word-wrap: break-word;
  }
  .detail {
    //display: inline-flex;
    text-align: left;
    margin-bottom: 15px;
    border-bottom: 1px solid var(--borderdark);
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    margin-top: 30px;
  }
  .label {
    display: inline-block;
    vertical-align: top;
  }
  .labeltext {
    color: var(--textdarker);
    font-weight: 400;
    font-size: 16px;
  }
  .labeltext1 {
    color: var(--textdarker);
    font-weight: 400;
    font-size: 12px;
  }
  .line4 {
    text-align: right;
    display: inline-block;
    width: calc(100% - 85px);
  }
  .value {
    display: inline-block;
    float: right;
  }
  .valuetext {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--textlight);
  }
  .align {
    width: calc(100% + 10px);
  }
  .valuetext1 {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  .longvalue {
    display: inline-block;
    width: calc(100% - 40px);
    overflow: hidden;
    vertical-align: bottom;
    word-wrap: break-word;
    text-align: right;
  }

  .currencytoconvert {
    margin-bottom: 20px;
    width: 100%;
    max-width: 272px;
    margin: 0 auto;
    margin-top: 30px;
  }
  .arrowclass11 {
    width: 17px;
    height: 17px;
  }
  .arrowclass11 {
    path {
      fill: var(--primary);
    }
  }

  .detail2 {
    width: 250px;
    text-align: left;
    margin-bottom: 15px;
    border-bottom: 1px solid var(--borderdark);
    //width: 100%;
    margin: 0 auto;
    margin-top: 30px;
  }
  .detail3 {
    width: 250px;

    //width: 100%;
    margin: 0 auto;
    margin-top: 20px;

  }
  .status {
    text-align: center;
  }
  .textofstatus {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: var(--green);
  }
  .copy {
    width: 20px;
    height: 24px;
    margin-left: 10px;
    cursor: pointer;
  }
  .textofstatus2{
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color:#FDD205
  }
  .progresses{
    width: 50px;
    height: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .textcolor2 {
    color: var(--textdarker);
    width: 100%;
    max-width: 105px;
    word-wrap: break-word;
  }

  .line1 {
    text-align: right;
    display: inline-block;
    width: calc(100% - 48px);
  }

  .line2 {
    text-align: right;
    display: inline-block;
    width: calc(100% - 70px);
  }

  .line3 {
    text-align: right;
    display: inline-block;
    width: calc(100% - 30px);
  }

  .classtoholdlongtext {
    text-align: right;
    display: inline-block;
    width: calc(100% - 87px);
  }
  @media screen and (min-width:993px) {
    .linktxt {
      display: none;
    }
    .qrcode-section-mobile {
      display: none;
    }
  }

  @media screen and (max-width:992px) {
    .modal-header {
      margin-top: 17px;
      margin-bottom: 28px;
    }
    .modal-dialog {
      max-width: 100%;

      height: 100%;
      margin: 0 auto;
    }
    .modal-body {
      padding: 0px;
      max-height: 150vh;
      background-color: var(--bgwhite);
    }
    .modal-content {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
    .linktxt1 {
      display: none;
    }
    .arrowclass11 {
      display: none;
    }
    .box {
      width: 261px;
    }
    .detail2 {
      display: flex;
      width: 342px;
    }
    .detail3 {
      width: 342px;

      //display: flex;
    }
    .detail {
      //display: flex;
      max-width: 342px;
    }
    .labeltext {
      font-size: 14px;
    }
    .labeltext1 {
      font-size: 14px;
    }
    .textcolor2 {
      color: var(--textlight);
    }
    .qrcode-section {
      display: none;
    }
    .qrcode-section-mobile {
      margin-bottom: 35px;
    }
    .classtoholdlongtext {
      width: calc(100% - 77px);
    }
    .longvalue {
      width: calc(100% - 32px);
    }
    .valuetext {
      font-size: 12px;
    }
    .valuetext1 {
      font-weight: 400;
    }
  }
}
