
.Paypopup {
    .modal-dialog {
      max-width: 650px;
      width: 100%;
    }
    .modal-body {
      padding: 45px;
    }
    .modal-content {
      background-color: var(--bgwhite);
      border-radius: 20px;
    }
    .transectiontype {
      text-align: left;
      margin-bottom: 20px;
    }
    .transectiontext {
      font-size: 20px;
      color: var(--textdark);
      font-weight: 600;
    }
    .detail {
      text-align: left;
      margin-bottom: 15px;
    }
    .label {
      display: inline-block;
      vertical-align: top;
    }
    .labeltext {
      color: var(--textlight);
      font-weight: 600;
      font-size: 16px;
    }
    .name {
        display: inline-block;
        text-align: right;
        width: calc(100% - 155px);
    }
  
    .valuetransid{
      display: inline-block;
      text-align: right;
      width: calc(100% - 131px);
    }
  
    .valueamout{
      display: inline-block;
      text-align: right;
      width: calc(100% - 63px);
    }
    .longvalue {
      display: inline-block;
      width: calc(100% - 40px);
      overflow: hidden;
      vertical-align: bottom;
      word-wrap: break-word;
    }
   
    .valuetext {
      color: var(--textdark);
      font-weight: 600;
      font-size: 16px;
    }
    .confirm {
        .buttonmain {
          height: 39px;
          border-radius: 5px;
          font-weight: 600;
          font-size: 16px;
        }
      }
      .cancel {
        .buttonmain {
          background-color: var(--bglightgrey);
          border: 1px solid var(--borderlight);
          color: var(--textdark);
          height: 39px;
          border-radius: 5px;
          font-weight: 600;
          font-size: 16px;
        }
      }
  }
  