.CurrencyTab {
  position: relative;
  text-align: left;
  display: inline;
  .fordisplay {
    width: auto;
    background: var(--bglightgrey);
    height: 34px;
    display: inline-block;
    border-radius: 6px;
    margin-right: -14px;
  }
  .algign-left {
    position: absolute;
    left: 0;
  }
  // .fordisplay{
  //   display: inline !important;
  // }
  .nav-item {
    width: 140px;
    height: 100%;
    cursor: pointer;
    display: inline-block;
  }
  .nav-link {
    color: var(--textlight);
    font-weight: 400;
    font-size: 16px;
    opacity: 0.5;
    line-height: 32px;
    padding: unset;
    height: 100%;
    text-align: center;
    width: 100%;
  }
  .nav-link.active {
    background-color: var(--bgwhite);
    color: var(--primary);
    opacity: 0.8;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 600;
    border: 0.1px solid var(--borderlight);
    box-shadow: 2px 0px 3px var(--borderlight);
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: var(--textwhite);
    background-color: var(--primary);
  }
  .outernavholder {
    background-color: var(--primary);
  }
  .profilenavbar .active {
    color: var(--textwhite);
  }
  .profilenavbar a {
    color: var(--textdark);
  }

 
  @media (max-width: 480px) {
    .nav-item {
      width: 108px;
    }
  }
  @media (max-width: 405px) {
    .nav-item {
      width: 90px;
    }
  }

 
}
