.Branchitem {
  width: 100%;
  border: 1px solid var(--borderlight);
  border-radius: 10px;
  box-sizing: border-box;
  height: auto;
  padding: 15px 15px 10px 15px;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 10px;

  .outer {
    display: inline-block;
    width: calc(100% - 180px);
  }
  .maintext {
    font-size: 16px;
    font-weight: 700;
    color: var(--textdark);
    line-height: 18px;
  }

  .subtext {
    font-size: 10px;
    font-weight: 400;
    color: var(--textlight);
    opacity: 0.54;
    margin-top: 4px;
  }

  .coinbalance {
    float: right;
    font-size: 16px;
    font-weight: 600;
    line-height: 50px;
    height: 100%;
  }
  @media (max-width: 992px) {
    .coinbalance {
      line-height: 35px;
    }
  }
  @media (max-width: 440px) {
    .outer {
      width: calc(100% - 120px);
    }
  }
}
