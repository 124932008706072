.depositmodal {
  .modal-dialog {
    max-width: 1100px;
  }
  .modal-body {
    padding: 50px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }
  // adding modal border
  .modalPrimaryHeader {
    max-width: 100%;
    margin-bottom: 5%;
    margin-top: -1px;
  }
  .togglespace {
    text-align: right;
    margin-bottom: 20px;
  }
  .searchbox {
    border-radius: 4px;
    padding: 5px;
  }
  .innerinputfield {
    margin-left: -64px;
  }
  .searchinside {
    margin-top: 1px;
  }
  .dropdowndownmobiledepositmodal .dropdownlink {
    border-radius: 4px;
  }
  .footercommon{
    background-color: var(--bgwhite);
  }
  @media (max-width: 440px) {
    .modal-body {
      padding: 18px;
    }
  }
  @media (min-width: 992px) {
    .modal-body {
      padding: 45px 25px;
    }
  }
  @media screen and (min-width: 992px) {
    .hidetoggle {
      display: none;
    }
  }
  @media screen and (max-width: 991px) {
    .coinlistlayout {
      display: none;
    }
    .togglespace {
      margin: 0 auto;
      text-align: center;
    }
    .textleft {
      display: none;
    }
    .closebtn {
      display: none;
    }
    .back {
      float: right;
      width: 9%;
      height: 56px;
      width: 41px;
      background-color: var(--primary);
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0px;
      top: 30px;
      margin-top: 19px;
    }
    .modalPrimaryHeader {
      height: 29px;
      background-color: var(--primary);
    }
    .modal-body {
      padding: 0px;
      min-height: 100vh;
    }
    .modal-dialog {
      max-width: 100%;
      width: 100%;
      min-height: 100vh;

      margin: 0 auto;
    }

    .modal-content {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
  }
  @media screen and (min-width: 992px) {
    .hidden-for-desktop {
      display: none;
    }
  }
}
