.coinlistlayout {
  /* width */
  ::-webkit-scrollbar {
    opacity: 0;
    width: 5px;
    margin-left: 40px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey;
    background-color: var(--bglightgrey);
    opacity: 0.54;
    // border-radius: 40px;
    margin-left: 40px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--borderlight);
    border-radius: 10px;
  }

  /* Handle on hover */
  // ::-webkit-scrollbar-thumb:hover {
  //   background: #b30000;
  // }
  .topspace {
    margin-top: 10px;
  }
  .topspacese {
    margin-bottom: 25px;
    margin-left: -5px;
  }
  .coinlistscrollable {
    overflow-x: hidden;
    overflow-y: visible;
    // height: 455px;
    // max-height: 550px;
    padding-right: 15px;
    height: 600px;
  }
  .searcharea{
    padding-right: 25px;
  }
  .innerinputfield {
    margin-left: -30px;
    margin-bottom: 3px;
    margin-top: 2px;
  }
  .searchbox {
    max-width: 475px;
    border-radius: 4px;
    padding: 5px;
    margin-left: 1%;
  }

  .searchinside {
    .pointer {
      margin-top: 3px;
    }
  }
  .search-align-for-deposit {
    margin-left: -5px;
  }
  @media (max-width: 991px) {
    .searchbox {
      max-width: 690px;
      max-height: 100px;
    }
    .innerinputfield {
      margin-left: -1px;
      max-width: 690px;
    }
  }
}
