.fiatdepositform {
  .currencytoconvert {
    margin-bottom: 20px;
  }
  .transectionid {
    display: inline-block;
    width: 100%;
    word-wrap: break-word;
  }
  .detail {
    text-align: left;
    margin-bottom: 15px;
    border-bottom: 1px solid var(--borderdark);
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
  }
  .errors {
    font-size: 14px;
    font-weight: 400;
    color: var(--textdarker);
  }
  .label {
    display: inline-block;
  }
  .labeltext {
    color: var(--textdarker);
    font-weight: 500;
    font-size: 14px;
  }
  .value {
    display: inline-block;
    float: right;
  }
  .valuetext {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--textlight);
  }
  .box {
    height: auto;
    background: var(--bgwhite);
    box-shadow: 0px 4px 30px var(--borderlight);
    box-sizing: border-box;
    border-radius: 1px;
    padding: 20px;
  }
  .buttoncontainer {
    width: 250px;
    display: inline-block;
    padding: 8px;
  }
  .fieldssectionmargin {
    margin-top: 15px;
  }
  .labeloffield {
    text-align: left;
    padding-bottom: 5px;
  }
  .radiolable {
    // font-size: 14px;
    // font-weight: 700;
    // text-align: left;
    margin: 0 auto;
    border: 1px solid var(--borderdark);
    height: 45px;
    padding-top: 5px;
    // padding-top: 0px;
    padding-bottom: 5px;
    border-radius: 9px;
  }

  .label {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--textdarker);
  }

  .formfield {
    margin: 0 auto;
    border: 1px solid var(--borderdark);
    height: 45px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 9px;
  }
  .currencydropdown {
    position: relative;
    width: 118px;
    height: 34px;
    left: 75%;
    bottom: 56px;
    border: 1px solid var(--borderdark);
    border-radius: 6px;
    box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.12);
    .dropdownlink {
      height: 32px;
      padding: 8px;
      border-radius: 6px;
    }
    .toogleicon {
      line-height: 16px;
      margin-right: 12px;
    }
    .dropdown-menu {
      width: 100%;
      min-width: 118px;
      margin-top: 1px;
      overflow: hidden;
    }
    .search {
      height: 28px;
      padding-left: 5px;
      margin-left: 10px;
      width: 65%;
    }
    .dropdown-menu a {
      height: 34px;
      padding-left: 12px;
      .cointext {
        margin-left: 24px;
        margin-top: 0px;
      }
      .coinicon img {
        height: 16px;
        width: 16px;
        margin-bottom: 4px;
      }
    }
    ul{
      overflow-y: scroll;
    }
  }

  .banklogo {
    width: 200px;
    text-align: left;
    margin-left: 0px;
  }
  .newbankname {
    color: var(--textdark);
    font-size: 14px;
    font-weight: 600;
    vertical-align: middle;
    margin-left: 10px;
  }
  .bankimg {
    width: auto;
    height: 30px;
  }
  .infotext {
    margin-top: 8px;
    padding: 0 8px;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
  }
  .formfieldnew {
    margin: 0 auto;
    /* border: 1px solid var(--borderdark); */
    height: 41px;
    /* padding-top: 5px; */
    padding-bottom: 5px;
    border-radius: 2px;
    .input.form-control.withoutleftborder {
      padding-bottom: 3px;
    }
  }
  .inputfieldholder {
    display: inline-block;
    width: calc(100% - 40px);
    height: 100%;
    vertical-align: top;
    .input {
      background: transparent;
      border: none;
      padding: 5px;
    }
  }
  ::placeholder {
    color: var(--primary);
    //opacity: 0.5;
  }
  .logosection {
    width: 40px;
    height: 35px;
    display: inline-block;
    border: none;
    border-left: 1px solid var(--borderdark);
    text-align: center;
    vertical-align: middle;
  }
  .copysection {
    display: inline-block;
    border: none;
    text-align: center;
    vertical-align: middle;
  }
  .comtxt {
    width: 33px;
    cursor: pointer;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .logomodified {
    width: 40px;
    height: 35px;
    display: inline-block;
    border: none;
    border-left: 1px solid var(--borderdark);
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
  }
  .logoholder {
    width: 26px;
    height: 26px;
    margin: 0 auto;
    border-radius: 2px;
    // overflow: hidden;
    margin-top: 4px;
    cursor: pointer;
  }
  .dropdown {
    path {
      fill: var(--textdark);
    }
  }
  ul {
    text-align: left;
  }
  li {
    color: var(--textdarker);
    padding-bottom: 10px;
  }
  .amtrighttext {
    font-size: 12px;
    font-weight: 400;
    color: var(--primary);
    position: absolute;
    right: 50px;
  }
  .boxtitle {
    text-align: center;
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .boxtitletext {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--textdarker);
  }
  .amtleft {
    display: inline-block;
    text-align: left;
    // width: calc(100% - 44px);
  }
  .amtright {
    display: inline-block;
    float: right;
  }
  .lira {
    font-size: 13px;
    line-height: 20px;
    color: var(--primary);
  }
  .tl {
    font-weight: 600;
    color: var(--textdark);
  }
  .red {
    color: var(--red);
  }
  .mar {
    margin-top: 10px;
  }
  .m-btm {
    margin-bottom: 20px;
  }
  .bold {
    font-weight: 600;
  }

  .allignleft {
    text-align: left;
  }

  .logoholder .btn-primary {
    color: var(--textdark);
    background-color: transparent;
    border-color: none;
    border-radius: 50px;
    border: none;
    position: relative;
    top: -3px;
    left: -11px;
    padding: 0px;
  }
  .logoholder .btn-primary:hover {
    color: var(--textdark);
    background-color: transparent;
    border-color: unset;
  }

  .btn-check:focus + .btn-primary,
  .btn-primary:focus {
    color: var(--textdark);
    background-color: transparent;
    border-color: unset;
    box-shadow: unset;
  }
  .btn-check:active + .btn-primary,
  .btn-check:checked + .btn-primary,
  .btn-primary.active,
  .btn-primary:active,
  .show > .btn-primary.dropdown-toggle {
    color: var(--textdark);
    background-color: transparent;
    border-color: none;
    border-radius: 50px;
    border: none;
  }
  .dropdown-menu {
    max-height: 209px;
    // overflow-y: scroll;
    width: 100%;
    margin-top: -4px;
    border-top: none;
    background-color: var(--bgwhite);
    border: 1px solid var(--borderdark);
  }

  .filtericon {
    path {
      fill: var(--textdark);
    }
    vertical-align: top;
    height: 80%;
    width: 25px;
    display: inline-block;
  }
  .droparrow {
    margin-left: 10px;
  }
  .filtertext {
    display: inline-block;
    line-height: 29px;
    font-size: 12px;
    font-weight: 600;
    //margin-left: 6px;
    // margin-right: -9px;
  }

  .dropdownarrow {
    path {
      fill: var(--textdark);
    }
  }
  .dropdown.show {
    border-bottom: none;

    .dropdownarrow {
      transform: rotate(180deg);
    }
  }
  .search {
    top: 0px;
  }
  .DropdownSearch .dropdown.show .search {
    top: -2px;
  }
  .dropdownlink {
    border-radius: 0px;
    border: none;
    padding-top: 3px;
  }
  .toogleicon {
    line-height: 24px;
  }
  .DropdownSearch .cointext {
    font-size: 12px;
    font-weight: 500;
    margin-top: 4px;
    //width: 54%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: unset;
  }
  .currencydropdown {
    .DropdownSearch {
      .dropdown.show .search {
        top: 2px;
      }
      .cointext {
        overflow: unset;
        width: 40%;
        font-size: 14px;
        margin-left: 15px;
        margin-top: 8px;
      }
      .coinicon {
        width: 8px;
      }
      .selecteditem {
        position: relative;
        bottom: 10px;
        left: 12px;
        overflow: unset;
        width: 54%;
        // margin-left: 18px;
        overflow: unset;
        width: 40%;
        font-size: 14px;
        margin-left: 15px;
      }
      .coinicon {
        width: 8px;
      }
      .selecteditem {
        position: relative;
        bottom: 10px;
        left: 12px;
        overflow: unset;
        width: 54%;
        margin-left: 0px;
      }
      .coinicon {
        width: 8px;
      }
      .selecteditem {
        position: relative;
        bottom: 10px;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .currencydropdown {
      left: 83%;
    }
  }
  @media screen and (max-width: 767px) {
    .currencydropdown {
      float: right;
      left: unset;
    }
  }
}
