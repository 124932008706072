.BigCoinCard {
  background-color: var(--bgwhite);
  padding: 15px;
  overflow: hidden;
  .loaderWrap{
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .spincolor{
    background-color: var(--primary);
  }
  .hideTag {
    font-size: 16px;
    font-weight: 600;
    color: var(--textlight);
    background: linear-gradient(
      92.43deg,
      var(--bgwhite) 0%,
      var(--bglightgrey) 100%
    );
    border: 1px solid var(--borderlight);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0px 5px;
    height: 32px;
    line-height: 1.8;
    align-items: center;
    vertical-align: bottom;
    white-space: pre;
    cursor: pointer;
  }
  .hideeye {
    width: 16px;
    height: 14px;
    margin: -2px 5px 0px 0px;
    path {
      stroke: var(--textlight);
    }
  }
  .mybalinpie {
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    overflow: hidden;
  }
  // .maindashrow{
  //   padding: 10px 0px;
  // }
  .actions {
    position: relative;
    height: auto;
    padding-right: 0px;
  }
  &.businessbigcoincard {
    padding: 25px;
  }
  &.businessbigcoincard .actions {
    height: 90px;
  }
  .balance {
    color: var(--textdark);
    font-weight: 600;
    font-size: 32px;
    line-height: 34px;
    // margin-top: 60px;
    text-align: left;
    margin-right: 20px;
  }
  &.businessbigcoincard .balance {
    margin-top: 20px;
  }
  .options {
    width: auto;
    height: 100%;

    // display: inline-block;
  }
  .optionposition {
    // position: absolute;
    // right: 0;
    // top: 0;
  }
  .businessoptionposition {
    // right: 30px;
  }
  .mainbuttonswrapper {
    margin-left: auto;
    padding-right: 5px;
    width: 280px;
    display: flex;
    justify-content: flex-end;
  }

  .mainbuttonswrapper .optionholder {
    margin-left: 25px;
  }

  .arrowclass1 {
    path {
      fill: var(--textdark);
    }
  }
  .displayedcurrencies {
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: flex-end;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .displycurr {
    color: var(--textdark);

    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    opacity: 0.87;
  }
  .rightspace {
    margin-right: 8px;
  }
  .downbtn {
    width: 62px;
    height: 32px;
    border-radius: 4px;
    display: inline-block;
    padding: 6px;
    margin: 15px;
    margin-left: 15px;
    border: 1px solid var(--borderlight);
    margin-right: 0px;
  }
  .downbtn:hover {
    box-shadow: 0px 1px 10px var(--borderlight);
    cursor: pointer;
  }
  .setting {
    box-shadow: var(--bglightgrey);
    display: inline-block;
    padding: 6px;
    margin-left: 0px;
  }
  .text {
    display: inline-block;
    padding: 6px;
  }
  .text1 {
    display: inline-block;
  }
  .piechartsection {
    width: 100%;
    max-width: 467px;
    height: 350px;
    display: inline-block;
    position: relative;
  }
  .pietxt {
    font-size: 38px;
    font-weight: 700;
    text-align: center;
    display: block;
    line-height: 38px;
    color: var(--textdark);
    width: calc(100% - 4px);
    overflow: hidden;
    vertical-align: bottom;
    word-wrap: break-word;
  }
  .pietxt1 {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: var(--textdark);
  }
  .legendarea {
    width: 100%;
    margin-top: -50px;
    text-align: left;
    margin-bottom: 10px;
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
  }
  .legend {
    // width: 86px;
    margin-right: 20px;
    display: inline-block;
    text-align: left;
  }
  .legendcolor {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 2px;
    vertical-align: middle;
  }
  .legendname {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    margin-left: 4px;
    color: var(--textdark);
    vertical-align: middle;
    line-height: 3px;
  }
  .btn-primary {
    background-color: unset;
    border-color: unset;
    border: unset;
    margin-top: -11px;
    margin-left: -4px;
  }
  .btn-check:active + .btn-primary,
  .btn-check:checked + .btn-primary,
  .btn-primary.active,
  .btn-primary:active,
  .show > .btn-primary.dropdown-toggle {
    color: unset;
    background-color: unset;
    border-color: unset;
    border: unset;
  }
  .dropdown-toggle::after {
    display: none;
  }

  .droparrow {
    path {
      fill: var(--textdark);
      opacity: 0.54;
    }
  }
  .droparrowss {
    path {
      fill: var(--textdark);
    }
  }

  // .col-lg-4{
  //   width: 36%;
  // }
  // .col-lg-8{
  //   width: 64%;
  // }
  .CoinListBalance {
    padding-left: 12px;
    height: 65px;
    border-radius: 6px;
    .rightnav {
      height: 65px;
    }
    .coinname {
      font-weight: 700;
    }
    // .coinlistleftcontent{
    //   width: 50px;
    // }
    .coinimgwrapper {
      height: 41px;
      width: 41px;
    }
    // .coinnameandsymbol{
    //   width: 62px;
    // }
  }
  .dashCoinListColMain {
    padding-left: 30px;
  }
  .dropdown.show {
    .droparrow {
      transform: rotate(180deg);
    }
  }
  .coinListCol {
    padding-right: 0px;
  }
  .coinListWrapperD {
    margin-bottom: 9px;
  }

  @media (max-width: 992px) {
    .formobflex {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
    }
    .balance {
      font-size: 16px;
      margin-right: 10px;
    }
    &.businessbigcoincard .balance {
      margin-top: 0px;
    }
    .hideTag {
      font-size: 14px;
      display: flex;
      align-items: center;
    }
    .options {
      position: relative;
      margin-top: 10px;
    }
    .mainbuttonswrapper {
      margin: 0px auto;
      // padding-right: 5px;
      // width: 280px;
      // display: flex;
      justify-content: center;
    }
    .mainbuttonswrapper {
      .optionholder {
        margin: 15px 10px 15px 10px;
      }
      .holdercss {
        box-shadow: 0px 1px 4px var(--borderdark);
        border-radius: 6px;
        width: 45px;
      }
    }
    .displayedcurrencies {
      justify-content: center;
      margin-top: 0px;
    }
    .setting {
      margin-left: 0px;
    }
    &.businessbigcoincard .actions {
      height: unset;
    }
  }
}
