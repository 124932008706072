.exchangemodal {
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }
  .modal-dialog {
    max-width: 850px;
  }

  .exchangepopup .DropdownSearch .dropdown-menu{
    max-height: 170px;
  }
  @media (max-width: 768px) {
    .modal-dialog {
      max-width: 100%;
      width: 100%;
      height: 191vh;

      margin: 0 auto;
    }

    .modal-content {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
    .hideclose {
      display: none;
    }
    .rightnav {
      height: 56px;
      width: 41px;
      background-color: var(--primary);
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0px;
      top: 30px;
    }
    .modalPrimaryHeader {
      height: 29px;
      background-color: var(--primary);
    }
    .exchangetoptitle {
      margin-top: 48px;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .exchangeamt {
      margin-bottom: 50px;
    }
    .exchangetitle {
      margin-bottom: 25px;
    }
    .exchangepopup .DropdownSearch .dropdown-menu{
      max-height: 210px;
    }
  }
  @media (min-width: 768px) {
    .titlehide {
      display: none;
    }
  }
}

.mod-exchangebackdrop {
  opacity: 0 !important;
}
.exchangemodal.hidethisexchangemodal {
  display: none !important;
}
