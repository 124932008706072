.CryptoBalance {
    background-color: var(--bgwhite);
    padding: 15px;
    border: 1px solid var(--borderlight);
    overflow: hidden;
    .mybalinpie {
     text-align: left; 
    }
    .allign{
        text-align: left;
    }
    
    .outerclass{
        padding-left: 25px;
        padding-top: 17px;
    }
    .balance {
      color: var(--textdark);
      
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      margin-top: 60px;
    }
  
    
  .paddingright{
    padding-right: 15px;
  }
   
    
    .displayedcurrencies {
     text-align: right;
    }
    
    .downbtn {
      width: 40px;
      height: 40px;
      border-radius: 2px;
      display: inline-block;
      padding: 6px;
      margin: 15px;
      margin-left: 25px;
      border: 1px solid var(--borderlight);
      text-align: center;
    }
    .downbtn:hover {
      box-shadow: 0px 1px 10px var(--borderlight);
      cursor: pointer;
    }
    .setting {
      box-shadow: var(--bglightgrey);
      display: inline-block;
      padding: 6px;
     
    }
    .textclass {
      display: inline-block;
      padding: 6px;
    }
    .piechartsection {
      width: 100%;
      max-width: 250px;
      height: 250px;
      display: inline-block;
    }

   
    .pieandlable{
        text-align: left;
        position: relative;
        top:4%;
    }
    .pietxt {
      font-size: 48px;
      font-weight: 400;
      color: var(--textdark);
      width: calc(100% - 1px);
    overflow: hidden;
    vertical-align: bottom;
    word-wrap: break-word;
    line-height: 46px;
    }

    .left{
        display: inline-block;
        width: 100%;
        max-width: 225px;
    }

    .right{
        display: inline-block;
        vertical-align: middle;
    }
   
    .legendarea {
      width: 100%;
      text-align: left;
    }
    .legend {
      width: auto;
      height: auto;
    }
    .legendcolor {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 2px;
      vertical-align: middle;
    }
    .legendname {
      display: inline-block;
      font-size: 12px;
      margin-left: 4px;
      color: var(--textdark);
      vertical-align: middle;
    }
    
    .displycurr {
      color: var(--textdark);
      opacity: 0.85;
  
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }

    .btn-primary {
      background-color: unset;
      border-color: unset;
      border: unset;
      margin-top: -6px;
      margin-left: -6px;
    }
    .btn-check:active + .btn-primary,
    .btn-check:checked + .btn-primary,
    .btn-primary.active,
    .btn-primary:active,
    .show > .btn-primary.dropdown-toggle {
      color: unset;
      background-color: unset;
      border-color: unset;
      border: unset;
    }
    .dropdown-toggle::after {
      display: none;
    }

    .droparrow {
      path {
        fill: var(--textdark);
      }
    }
  
    @media (max-width: 1199px) {
      .right{
          display: inline-block;
          vertical-align: middle;
          margin-bottom: 50px;
      }

      .legend {
          width: auto;
          height: auto;
          display: inline-block;
          margin-right: 20px;

        }

        .legendarea {
          width: 100%;
          text-align: left;
          margin-left: 25px;
        }

        .pietxt{
          padding-top: 15px;
          font-size: 28px;
    line-height: 24px;
    font-weight: 400;
    display: inline-block;
    width: calc(100% + -1px);
    overflow: hidden;
    vertical-align: bottom;
    word-wrap: break-word;
    color: var(--textdarker);
        }
    }
    
    @media (max-width: 992px) {

      .left{
        display: inline-block;
        width: 100%;
        max-width: 100%;
    }

    .mybalinpie {
      text-align: center; 
     }
     .allign{
         text-align: center;
     }


      .options {
        position: relative;
        margin-top: 10px;
      }

      .outerclass{
        padding-left: 10px;
        padding-top: 0px;
    }

    .pieandlable{
        text-align: left;
        position: relative;
        top:4%;
        right:2%;
    }
    }

    
  }
  