.dropdowncurrency {
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 40px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 40px;
    margin-left: 40px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    // background: var(--borderlight);
    border-radius: 10px;
  }

  /* Handle on hover */
  // ::-webkit-scrollbar-thumb:hover {
  //   background: #b30000;
  // }
  .check {
    display: inline-block;
    margin-top: 5px;
    // width: 3px;
  }
  .innerbutton {
    color: var(--textdark);
  }
  .form-check-inpu {
    color: var(--textdark);
  }
  .innnermenu {
    height: 100px;
  }
  .tophead {
    background: var(--bglightgrey);
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -10px;
    color: var(--textdark);
    width: 210px;
  }

  .form-check-input[type="checkbox"] {
    border-radius: 1px;
    margin: auto 0;
    height: 16px;
    cursor: pointer;
    width: 16px;
    margin-right: 16px;
    border: 1px solid var(--borderlight);
    box-shadow: none;
  }
  .form-check-input:active {
    border: 1px solid var(--primary);
  }
  .form-check-input:focus{
    border: 1px solid var(--primary);
  }
  // .form-check-input:checked {
  //   background-color: var(--bgwhite);
  // }
  form-check-input:checked {
    background-color: var(--bgwhite);
    border-color: var(--primary);
  }
  

  .coincircle {
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    border-radius: 100px;
  }
  img.coinimg {
    width: auto;
    height: 17px;
  }
  .coinlistarea {
    font-weight: 400;
    font-size: 14px;
    color: var(--textdark);
    margin-left: 10px;
    width: calc(100% - 90px);
  }

  .dropdownmenu {
    position: absolute;
    inset: 0px auto auto 0px;
    transform: translate3d(-169px, 40px, 0px);
    width: 210px;
    max-height: 350px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 0px;
    background-color: var(--bgwhite);
    border: none;
    box-shadow: 0px 2px 5px var(--borderdark);
    margin-top: 5px;
  }
  .outer {
    display: inline-block;
    width: 100%;
  }
  .cname {
    margin-left: 8px;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 400;
    color: var(--textdark);
  }
  .lispace {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .currencyscrollable {
    max-height: 200px;
overflow-y: scroll;
  }
}
