.BusinessTransactionPopup {
  .modal-dialog {
    max-width: 850px;
  }
  .modal-body {
    padding: 45px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }
  .number-section {
    text-align: center;
    margin-left: 5px;
    width: 50%;
  }
  .text {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: var(--textdarker);
    opacity: 0.87;
  }
  .qrsection {
    width: 304px;
    height: 300px;
    margin: 0 auto;
    text-align: center;
  }
  .options {
    margin: 0 auto;
    width: 225px;
    margin-bottom: 10px;
    margin-top: 50px;
  }
  .optiontxtholder1 {
    display: inline-block;
    text-align: center;
    margin-left: 8px;
    margin-right: 12px;
    word-wrap: break-word;
  }
  .comtxt1 {
    width: 60%;
    margin-left: 15%;
  }
  .modalPrimaryHeader {
    max-width: 100%;
    margin-bottom: 5%;
    margin-top: -1px;
    height: 29px;
    background-color: var(--primary);
  }
  .qrpng {
    width: 100%;
    height: 100%;
  }
  .detail {
    text-align: left;
    margin-bottom: 15px;
    border-bottom: 1px solid var(--borderdark);
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    margin-top: 25px;
  }
  .label {
    display: inline-block;
    width: 180px;
  }
  .labeltext {
    color: var(--textdarker);
    font-weight: 500;
    // font-size: 16px;
    font-size: 14px;
  }
  .value {
    display: inline-block;
    float: right;
  }
  .copyiconinmoal {
    width: 15px;
    height: 18px;
    margin-top: -5px;
    cursor: pointer;
  }
  .valuetext {
    font-weight: 600;
    font-size: 14px;
    // font-size: 16px;
    line-height: 16px;
    color: var(--textdarker);
    max-width: 100px;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &.withlink {
      max-width: 100px;
      color: var(--primary);
      font-size: 12px;
    }
  }
  .top{
    margin-top: 24px;
  }
  .detailsSection {
    padding-top: 10px;
    margin-right: 15px;
  }
  .back {
    float: right;
    // width: 9%;
    position: absolute;
    height: 56px;
    width: 96.05px;
    background-color: var(--primary);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    right: 0px;
    top: 42.5px;
    // margin-top: 19px;
  }
  .buttoncontainer {
    width: 160px;
    padding-top: 38px;
    text-align: center;
    margin: 0 auto;
  }
  @media screen and (max-width:991px) {
    .text{
      width: max-content;
    }
    .modalPrimaryHeader {
      margin-bottom: 35px;
    }
    .modal-dialog {
      max-width: 100%;

      min-height: 100%;
      margin: 0 auto;
    }
    .modal-body {
      padding: 0px;
      min-height: 100vh;
    }

    .modal-content {
      width: 100%;

      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
    .hidden-for-mobile {
      display: none;
    }
    .number-section {
      text-align: left;
      margin: 0;
      padding-left: 36px;
      padding-top: 0px;
      padding-bottom: 30px;
      width: 100%;
    }
    .hrline {
      position: relative;
      left: -50%;
      right: -50%;
      border-bottom: 1px solid var(--borderlight);
      width: 200%;
      height: 1px;
    }
    .titleM {
      flex: 1;
      font-size: 16px;
      font-weight: 600;
      color: var(--textdarker);
      opacity: 0.87;
    }
    .valueM {
      flex: 1;
      font-size: 16px;
      font-weight: normal;
      color: var(--textdarker);
      opacity: 0.54;
    }
    .detailWrapM {
      display: flex;
      text-align: left;
    }
    .detailWrapTableM {
      padding: 10px 20px;
    }
    .labeltextM {
      font-size: 12px;
      font-weight: 600;
      color: var(--textdarker);
      opacity: 0.87;
      margin-top: 30px;
      margin-bottom: 5px;
    }
    .Paid {
      color: var(--green);
    }
    .Cancelled {
      color: var(--red);
    }
    .Pending {
      color: var(--yellow);
    }
    .statusTextM {
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
    }
    .valueM2 {
      flex: 1;
      font-size: 24px;
      font-weight: 600;
      color: var(--textdarker);
      opacity: 0.54;
      line-height: 24px;
    }
    .valueMforcoin {
      flex: 1;
    }
    .coinlist {
      display: flex;
      align-items: center;
      .coincircle {
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .coinimg {
        height: 12px;
        margin-top: 0px;
      }
      .coinlistarea {
        opacity: 0.54;
      }
    }
    .modalbuttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      div {
        width: 50px;
        height: 50px;
        border-radius: 9px;
        background-color: var(--primary);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .iconfill {
        path {
          fill: var(--white);
        }
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: var(--textdarker);
        opacity: 0.54;
        white-space: pre-wrap;
      }
    }
  }
  @media screen and (min-width:992px) {
    .hidden-for-desktop {
      display: none;
    }
  }
}
