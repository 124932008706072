.withdraw {
  .outercontainer {
    // height: 135px;
    margin: 0 auto 0;
    // width: 115px;
    margin-top: 10px;
    // margin-bottom: 25px;
    .CustomButton {
      width: 115px;
      margin: 0px auto;
      margin-top: 20px;
      margin-right: 158px;
    }
  }
  .textleft {
    text-align: left;
  }
  .DropdownSearch .toogleicon {
    margin-right: 8px;
  }
  .withtitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: var(--textdark);
    text-align: center;
    margin-bottom: 60px;
    margin-top: 45px;
    margin-left: 16px;
  }
  .withdrawspace {
    margin-top: 30px;
  }
  .balancespace {
    margin-top: 7px;
    margin-bottom: 7px;
  }
  .cointop {
    margin-top: 10px;
  }
  .addr {
    margin-top: 16px;
  }
  .addrtitle {
    margin-top: 18px;
  }
  .insufficientbal {
    color: var(--red);
    margin-left: -28px;
    font-size: 17px;
    text-align: center;
    width: 198px;
    margin: 0 auto;
    margin-top: 40px;
  }
  .recievebal {
    overflow-wrap: break-word;
  }
  .insufficientbalerr {
    color: var(--red);
    font-size: 14px;
    text-align: center;
  }
  .labelfont {
    font-size: 14px;
    font-weight: 400;
  }
  .balancetext {
    font-size: 14px;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .withdrawallimit {
    margin-top: 8px;
    line-height: 1;
  }
  .withdrawallimit span {
    font-size: 13px !important;
    line-height: 10px;
  }
  .withdrawallimitred span {
    font-size: 13px !important;
    color: var(--red);
    line-height: 10px;
  }
  .feesandrecieve {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // font-size: 14px;
    p {
      // margin-bottom: 0px;
      text-align: left;
    }
    // .fr-fees{
    //   text-align: left;
    // }
    // .fr-recieve{
    //   text-align: right;
    // }
  }
  .dropdownlink {
    border: 1px solid var(--borderlight);
    border-radius: 4px;
    padding: 5px;

  }
  .DropdownSearch .dropdown-menu {
    width: 334px;
  }
  .input.form-control.input {
    border: 1px solid var(--borderlight);
    border-radius: 4px;
    padding: 10px 18px;
  }
  .percentage {
    text-align: right;
    color: var(--primary);
  }
  .addressbtnwrapper {
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 4px var(--borderlight);
    border-radius: 4px;
    width: 60px;
    height: 33px;
  }
  .selecteditem {
    margin-left: 10px;
  }
  .amountmax {
    .linktxt {
      z-index: 3;
    }
  }
  .linktxt {
    .btn {
      background-color: var(--bgwhite);
      border-color: var(--bgwhite);
    }
    .addressdropdown {
      margin-top: 0px;
    }
  }

  .percentageleftmargin {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 2px;
    border: 1px solid var(--borderlight);
    border-radius: 3px;
    box-shadow: 0px 1px 4px var(--borderlight);
  }
  .linktxt {
    position: absolute;
    top: 50%;
    right: 1px;
  }
  .container{
    background-color: var(--bgwhite);
  }
  @media (max-width: 991px) {
    .addr {
      margin-top: 0px;
    }
    .withtitle {
      font-size: 20px;
      margin-left: 0px;
    }

    .addrtitle {
      margin-top: 10px;
    }
    .labelfont {
      font-size: 16px;
      font-weight: 600;
    }
    .lablesmallfont {
      display: inline-block;
      margin-bottom: 15px;
      margin-top: 3px;
    }
    .outercontainer {
      .CustomButton {
        margin: 0px auto;
        margin-top: 20px;
      }
    }
  }
  @media (min-width: 992px) {
    .hidden {
      display: none;
    }
  }
  @media screen and (min-width: 992px) and (max-width: 2500px) {
    .dropdownlink {
      width: 334px;
    }
    .area1 {
      width: 334px;
    }
    .area2 {
      width: 334px;
    }
    .addrtitle {
      margin-top: 20px;
    }
    .percentage {
      margin-right: -18px;
      width: 334px;
    }
  }
}
