#downloadapp {
  min-height: 40px;
  width: 100%;
  padding: 5px;
  border: 1px solid var(--borderlight);
  display: flex;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  .platformimg img {
    width: 30px;
    margin-right: 4px;
  }
  .platform {
    text-align: left;
  }
  .getfrom {
    font-size: 8px;
    font-weight: 600;
  }
  .platformname {
    font-size: 14px;
    font-weight: 600;
  }
  .platform p {
    line-height: 1.3;
    margin: 0px;
  }
  @media screen and (max-width: 475px) {
    #downloadapp {
      width: 100%;
      .platformimg img {
        width: 20px;
      }
      .getfrom {
        font-size: 8px;
      }
      .platformname {
        font-size: 14px;
      }
    }
  }
  @media screen and (max-width: 998px) {
    #downloadapp {
      width: 100%;
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1200px) {
    #downloadapp {
      min-height: 60px;
    }
  }
}
