#mobilesettings {
  text-align: left;
  .container {
    width: 100%;
    border-top: none;
    padding: 0px;
  }
  .container1 {
    border-top: none;
  }
  .title {
    font-weight: 600;
    font-size: 16px;
  }
  .optiontitle {
    width: 100%;
    height: 24px;
  }
a{
  text-decoration: none;
}
  .optioncontainer {
    margin-top: 10px;
    margin-left: 10px;
  }

  .box {
    padding: 10px;
    display: flex;
  }
  .optiontext {
    font-weight: 400;
    font-size: 16px;
  }
  .stroke {
    border-top: 1px solid rgba(33, 33, 33, 0.12);
  }

  .termstext {
    font-weight: 600;
    font-size: 16px;
    color: var(--primary);
  }
  .logout {
    margin: auto;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 60px;
  }
  .text {
    font-size: 16;
    font-weight: 400;
    padding-top: 5px;
    text-align: center;
  }

  .icon {
    text-align: center;
    padding: 5px;
  }
  .icon2 {
    text-align: right;
  }
  .backicon {
    width: 15px;
    height: 10px;
    display: inline-block;
  }
  
  .Arrow{
    path {
      fill: var(--shadow);
    }
  }
}
