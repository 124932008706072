.head{
    .headerContainer  {
       text-align: center;

       font-weight: 500;
       font-size: 16px;
       line-height: 23px;
       height: 24px;
    } 
}



