.addbank {
  .modal-body {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }
  .modal-header {
    box-shadow: 0 4px 6px -6px var(--borderlight);
  }
  .modal-title {
    margin: auto;
    margin-top: 18px;
    line-height: 1.5;
    text-align: center;
  }
  .spantext {
    vertical-align: middle;
  }
  .deletetext {
    font-size: 15px;
    font-weight: 400;
    text-align: left;
  }
  .outerbx {
    border: 1px solid var(--borderlight);
    border-radius: 10px;
    text-align: center;
    padding: 10px;
    margin-top: 15px;
  }
  .addtext {
    margin-top: 5px;
    margin-bottom: 2px;
    font-size: 12px;
    font-weight: 400;
    color: var(--textdark);
    opacity: 0.54;
  }
  .addtext1 {
    margin-top: 5px;
    margin-bottom: 2px;
    font-size: 10px;
    font-weight: 400;
    color: var(--textdark);
    opacity: 0.54;
  }
  .check {
    display: inline-block;
    margin-top: 5px;
    // width: 3px;
  }
  .displaytoo {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .curimg {
    width: 20px;
    height: 20px;
    margin-top: -1px;
  }
  .checkdiv {
    display: inline-block;
    float: right;
  }
  .checkdiv1 {
    display: inline-block;
  }
  .lispace {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .buttonmain {
    width: 150px;
  }
  .tpspace {
    margin-top: 10px;
  }
  .deletetitle {
    font-size: 16px;
    font-weight: 600;
    margin-left: 12px;
    color: var(--textdark);
    opacity: 0.87;
  }

  .input.form-control.input {
    border-radius: 4px;
    height: 31px;
    padding: 0 12px;
  }
  .form-check-input:invalid {
    background-color: var(--bgwhite);
  }
  .form-check-input[type="checkbox"] {
    border-radius: 2px;
    margin: auto 0;
    height: 15px;
    cursor: pointer;
    width: 15px;
    margin-right: 10px;
    border: 1px solid var(--borderlight);
    box-shadow: none;
    margin-top: 2px;
  }
  .form-check-input:active {
    border: transparent;
  }
  .form-check-input:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none;
  }
  .form-check {
    padding-left: 0;
  }
  .titles {
    text-align: left;
    margin-bottom: 18px;
    font-size: 12px;
    font-weight: 600;
    color: var(--textdark);
  }
  .dropdownlink {
    border-radius: 10px;
  }

  .cointext {
    margin-top: 2px;
    margin-left: unset;
  }
  .outercontainer {
    margin: auto;
    width: 321px;
  }
  .dropdownlink {
    margin-top: 11px;
    //width: 321px;
    height: 31px;
  }
  .modal-header {
    border-bottom: 0px;
  }

  .toogleicon {
    line-height: 23px;
  }
  .buttonmain {
    width: 160px;
    height: 39px;
  }
  .dropdown-menu.show {
    margin-top: 1px;
  }
  .inputbox1 {
    .dropdownlink {
      height: 31px;
      border-radius: 6px;
    }
    .toogleicon {
      line-height: 22px;
    }
    .search {
      left: 4px;
      height: 24px;
    }
  }
  .btmbtnoutercontainer {
    margin-top: 56px;
  }
  .addNewText {
    margin-bottom: 0px;
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    color: var(--textdark);
  }
  .arrowicon {
    transform: rotate(180deg);
    margin-right: 10px;
    path {
      fill: var(--borderdarker);
    }
  }
  .container {
    background-color: var(--bgwhite);
  }
  .footercommon {
    background-color: var(--bgwhite);
  }
  .errormessage {
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;
  }

  @media (min-width: 992px) {
    .hidefordeskop {
      display: none;
    }
    .hide {
      display: none;
    }
  }

  
  @media (max-width: 992px) {
    .modal-dialog {
      max-width: 100%;
      width: 100%;
      height: 130vh;

      margin: 0 auto;
    }

    .modal-content {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
    .inputbox1 .dropdownlink {
      height: 40px;
    }

    .input.form-control.input {
      height: 40px;
    }
    .checkdiv {
      float: unset;
      margin-left: 20px;
    }

    .deletetitle {
      margin-left: -75%;
    }
    .modal-body {
      padding-top: 33px;
    }
    .btmbtnoutercontainer {
      margin-top: 61px;
    }
    .toogleicon {
      margin-top: 5px;
    }
    .outercontainer {
      width: 100%;
    }
    .modal-header {
      display: none;
    }
    .deletetitle {
      display: none;
    }
    .cointext {
      margin-top: 6px;
    }
  }

}
