.profilemenu {
  box-shadow: 0px 4px 7px var(--borderlight);
  position: relative;
  right: 0%;
  .pfname {
    font-size: 12px;
    font-weight: 600;
    margin-left: 8px;
  }
  .mainbuttonswrapper {
    display: flex;
    margin-left: 15px;
    margin-right: 15px;
  }
  .tophr {
    border: 5px solid var(--primary);
    margin: 0 auto;
    margin-top: -9px;
  }
  .optionlist {
    margin-top: 43px;
    width: 240px;
    background-color: var(--bgwhite);
    border-radius: 6px;
    border-top: 8px solid var(--primary);
    box-shadow: 0px 2px 5px var(--borderlight);
    border-left: none;
    border-right: none;
    border-bottom: none;
  }
  .optionholder {
    text-align: center;
    margin: 0 auto;
  }
  .amtusd {
    color: var(--primary);
    margin-top: 10px;
    margin-bottom: 25px;
  }
  .count {
    font-size: 14px;
    font-weight: 600;
  }
  .symbol {
    font-size: 9px;
    font-weight: 400;
  }
  .mybal {
    color: var(--textdark);
    font-size: 12px;
    font-weight: 600;
  }
  .amount {
    font-size: 10px;
    font-weight: 600;
    color: var(--primary);
  }
  .commtxt {
    font-size: 10px;
    font-weight: 400;
  }
  .aligncenter {
    text-align: left;
    margin-left: 28px;
  }
  .amtbox {
    box-shadow: 0px 0px 6px var(--borderlight);
    display: inline-block;
    float: right;
    margin-top: 4px;
    margin-right: 28px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 5px;
  }
  .normspace {
    margin-right: 13px;
  }
  .normspace1 {
    margin-right: 20px;
  }
  .credotxt {
    color: var(--textdark);
    font-size: 9px;
  }
  .normaltxt {
    font-weight: 400;
  }
  .credovalue {
    font-weight: 900;
    margin-right: 3px;
  }
  .dropdownspace {
    margin-top: 5px;
    margin-bottom: 5px;
    &:hover .myaccountdarkicon path {
      fill: var(--textwhite) !important;
    }
    &:hover .myaccounticon path {
      fill: var(--black) !important;
    }
  }
  .dropdownspace1 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .dropdown-item {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .logtxt {
    font-size: 12px;
    font-weight: 400;
    margin-left: 8px;
  }
  .inlineblock {
    display: inline-block;
  }
  .dpitem {
    height: auto;
  }
  .seticon {
    margin-left: 3px;
    width: 25px;
    height: 18px;
  }
  .logicon {
    margin-left: 3px;
    width: 25px;
    height: 18px;
  }
  .profileimgicon {
    path {
      fill: var(--textdark);
    }
  }
  .profile {
    display: flex;
    align-items: center;
  }
  .profilea {
    margin-left: -50px;
  }
  .myaccounticon {
    circle {
      fill: var(--textdark);
    }
    path {
      fill: var(--black);
    }
  }
  .myaccountdarkicon {
    circle {
      fill: var(--textdark);
    }
    path {
      fill: var(--textwhite);
    }
  }

  .profilearea {
    display: flex;
    align-items: center;
  }
  .profilearea p {
    margin-bottom: 0px;
  }
  .profilename {
    margin-top: -1px;
    margin-left: 6px;
  }
  .profilecircle {
    margin-top: 0px;
  }
  .nextarr {
    float: right;
  }
  .hrcolor {
    color: var(--borderdark);
    height: 0.1px;
  }
  .dropdown-item:hover {
    path {
      fill: var(--textdark);
    }
  }
  .CurrencyTab .tablist {
    display: unset;
  }
  @media screen and (max-width: 992px) {
    .aligncenter {
      text-align: center;
    }
  }
}
@media (max-width: 2000px) {
  .profilemenu {
    position: absolute;
    right: 0%;
  }
}
@media (max-width: 999px) {
  .profilemenu {
    position: absolute;
    right: 0%;
  }
  .drpprofile .profileimg {
    vertical-align: middle;
    width: 64px;
    height: 64px;
  }
  .drpprofile .profilecircle {
    height: 64px;
    width: 64px;
  }
}
