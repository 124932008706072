.optionholder {
  text-align: center;
}
.holdercss {
  text-align: center;
  box-sizing: border-box;
  border-radius: 4px;
  width: 45px;
  max-width: 100px;
  height: 45px;
  border: 1px solid var(--borderlight);
  display: inline-block;
  background-color: var(--bgwhite);
  transition: .5s;
}
.holdercss:hover {
  cursor: pointer;
  border: 1px solid var(--borderlight);
  box-shadow: 0px 1px 10px var(--borderlight);
  path {
    fill: var(--primary);
    opacity: 0.7;
  }
}
.iconholder1 {
  position: relative;
  right: 12%;
}
.iconholder2 {
  position: relative;
}
.iconholder3 {
  position: relative;
  left: 12%;
}
.commtxt {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.005em;
  margin-top: 5px;
  color: var(--textdark);
  opacity: 0.54;
}
.arrowclass {
  margin-top: 13px;
  width: 17px;
  height: 17px;
  path {
    fill: var(--primary);
    opacity: 0.7;
  }
}
