.sendpopup {
  .modal-body {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 20px;
  }
  .modal-header {
    box-shadow: 0 4px 6px -6px var(--borderlight);
  }
  .modal-footer {
    display: block;
  }
  .exchangetext {
    font-size: 15px;
    font-weight: 400;
    text-align: left;
  }
  .exchangetitle {
    font-size: 20px;
    font-weight: 600;
    margin-left: 12px;
  }
  .exchangebox {
    border: 1px solid var(--borderlight);
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 20px;
    border-radius: 5px;
  }
  .address {
    font-size: 16px;
    font-weight: 400;
    word-break: break-word;
  }

  .confirm {
    .buttonmain {
      height: 39px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .cancel {
    .buttonmain {
      background-color: var(--bglightgrey);
      border: 1px solid var(--borderlight);
      color: var(--textdark);
      height: 39px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 16px;
    }
  }
}
