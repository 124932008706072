.buypopup {
  .modal-dialog {
    max-width: 600px;
  }
  .modal-body {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 120px;
    padding-right: 120px;
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }
  .modal-header {
    box-shadow: 0 4px 6px -6px var(--borderlight);
  }
  .cointag {
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 18px;
    color: var(--textdark);
    display: inline-block;
    box-shadow: 0 2px 4px 0 var(--borderlight), 0 2px 4px 0 var(--borderlight);
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 2px;
    margin-right: 2px;
    margin-top: 2px;
    margin-left: 14px;
  }
  .spincolor {
    color: var(--primary);
  }
  .icons {
    width: 20px;
    height: 25px;
    margin-right: 10px;
  }
  .buytitle {
    font-size: 16px;
    font-weight: 600;
    color: var(--textdarker);
    margin-left: 25px;
  }
  .btext {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: var(--textdark);
    margin-top: 20px;
  }
  .buy {
    .buttonmain {
      height: 40px;
      width: 154px;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .bottomtext {
    font-size: 10px;
    line-height: 15px;
    font-weight: 400;
    color: var(--textlight);
  }
  .buytext {
    font-size: 12px;
    font-weight: 400;
    color: var(--textdark);
    text-align: left;
  }
  .dropdownlink {
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid var(--borderdark);
    padding: 5px;
  }
  .input.form-control.input {
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid var(--borderdark);
  }
  .txtmargin {
    margin-top: 20px;
  }
  .buytxtalign {
    text-align: left;
    margin-top: 10px;
    margin-left: 5px;
  }

  .dropdown-menu {
    overflow-y: scroll;
    width: 100%;
    margin-top: -4px;
    border-top: none;
    background-color: var(--bgwhite);
    border: 1px solid var(--borderdark);
  }
  @media (max-width: 700px) {
    .modal-body {
      padding-left: 60px;
      padding-right: 60px;
    }
  }
  @media (max-width: 992px) {
    .modal-dialog {
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
    }

    .modal-content {
      width: 100%;
      margin: 0 auto;
      border-radius: 0px;
      border: none;
    }
  }
}
