.Loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  .message {
    margin-bottom: 10px;
    width: 98%;
    max-width: 700px;
  }
  .load {
    position: relative;
    background-color: var(--bgwhite);
    opacity: 0.8;
    width: 100%;
    height: 100%;
  }
  .loader1 {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  .loadercolour {
    color: var(--primarydark);
  }
}
