#signup {
  position: relative;
  &.fordarkbg{
    background-color: var(--blakish);
    .onboardingmainwrapper{
      background-color: var(--blakish);
    }
  }
  &.forlightbg{
    background-color: var(--white);
    .onboardingmainwrapper{
      background-color: var(--white);
    }
  }
  .outerbox {
    border-radius: 2px;
    padding: 25px 25px;
    width: 80%;
    overflow: hidden;
  }
  .banner-image{
    width: 400px;
    height: 100%;
    position: absolute;
    top:55%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .imageCol{
    position: relative;
    height: 545px;
  }
  // .animatedBanner .inner-animatedBanner{
  //   margin-top: 45px;
  //   transform: scale(0.65);
  // }
  .personalImg .banner-image{
    width: 700px !important;
    left: 48%;
  }
  .buttonmain{
    width: 150px;
  }
  .imgonsignup {
    width: 120px;
    height: 50px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .outerconforbtn {
    height: 40px;
    width: 115px;
    
  }
  .outerconttxt {
    margin-top: 24px;
  }
  .txtclass {
    font-size: 14px;
  }
  .formfieldholder {
    margin-bottom: 15px;
  }
  .whitebg {
    background-color: var(--bgwhite);
  }
  .checktxt {
    text-decoration: none;
    color: var(--primary);
    font-size: 11px;
    font-weight: 400;
  }
  .checktext {
    font-size: 11px;
    font-weight: 400;
    color: var(--textlight);
    text-align: left;
  }
  .form-check-input:invalid {
    background-color: var(--bgwhite);
  }
  .form-check-input:checked {
    // background-color: #0d6efd;
    background-color: var(--primary); 
    border-color: #0d6efd;
}
  .form-check-input[type="checkbox"] {
    border-radius: 2px;
    margin: auto 0;
    height: 15px;
    cursor: pointer;
    width: 15px;
    margin-right: 10px;
    border: 1px solid var(--borderlight);
    box-shadow: none;
  }
  .form-check-input:active {
    border: transparent;
  }
  .form-check {
    padding-left: 0;
  }
  .checkspace {
    padding-top: 15px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .form-check-label {
    cursor: pointer;
    width: 90%;
  }
  .link-to-signin {
    font-size: 11px;
    font-weight: 400;
    text-align: left;
    color: var(--textdark);
  }
  .signin-register-link {
    font-size: 11px;
    font-weight: 400;
    text-align: left;
    color: var(--primary);
    margin-left: 15px;
  }
  .terms {
    font-size: 11px;
    font-weight: 400;
    text-align: left;
    color: var(--primary);
    margin-left: 5px;
  }
  .invalid-feedback {
    text-align: left;
  }
  .centeralign {
    text-align: center;
  }
  .topheader {
    font-size: 22px;
    font-weight: 600;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 35px;
  }
  .topheader1 {
    color: var(--textdark);
  }
  .topheader2 {
    color: var(--primary);
    margin-left: 8px;
  }
  .input.form-control.input {
    background-color: transparent;
    padding: 0px 0px;
    opacity: 0.87;
  }
  .react-tel-input .form-control{
    background-color: transparent !important;
  }
  .react-tel-input .flag-dropdown{
    background-color: transparent !important;
  }
  .heading {
    font-size: 18px;
    font-weight: 600;
    color: var(--textdark);
    opacity: 0.87;
    text-align: left;
    margin-bottom: -2px;
  }
  .passwordIcon {
    margin-top: -26px;
    width: 14px;
    right: 10px;
  }
  .logoimg {
    width: 120px;
    height: 50px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .topspace {
    margin-top: 30px;
  }
  .signupfooter {
    margin: 62px 0px 0px 0px;
    padding-bottom: 20px;
  }
  .displayblock {
    display: inline-block;
  }
  .signuptxt {
    font-size: 14px;
    font-weight: 700;
    color: var(--primary);
    margin-left: 80px;
  }
  @media screen and (min-width: 360px) and (max-width: 396px) {
    .checktext {
      font-size: 9px;
      font-weight: 400;
      color: var(--textlight);
      text-align: left;
    }
  }
  // @media screen and (min-width: 1200px) and (max-width: 1400px) {
  //   .outerbox {
  //     margin-left: 10%;
  //   }
  // }
  @media screen and (min-width: 768px) {
    .hide {
      display: none;
    }
  }
  
  @media screen and (min-width: 992px) {
    .hidesignup {
      display: none;
    }
    .displayblock {
      display: block;
    }
  }
  @media screen and (max-width: 992px) {
    .ban-img {
      width: 100%;
      height: 100%;
    }
    .content{
      overflow: hidden;
    }
    .outerbox {
      width: 100%;
    }
    .forgotpasslink {
      text-align: center;
    }
    .topheader {
      text-align: center;
    }
    .hidetop {
      display: none;
    }
    .outerconforbtn {
      width: 100px;
    }
    .topspace {
      margin-top: 0px;
    }
    .link-to-signin {
      display: none;
    }
    .banner-image{
      width: 255px;
      height: 100%;
      position: absolute;
      top:51%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .imageCol{
      height: 574px;
    }
    // .animatedBanner .inner-animatedBanner{
    //   margin-top: 60px;
    //   transform: scale(0.55);
    // }
    .personalImg .banner-image{
      width: 520px !important;
      left: 48%;
    }
  }
  @media screen and (max-width: 768px) {
    .topheader {
      font-size: 18px;
      margin-bottom: 25px;
    }
    .heading {
      font-size: 16px;
    }
    .banner-image{
      width: 233px;
      height: 100%;
      position: absolute;
      top:47%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .imageCol{
      height: 490px;
    }
    // .animatedBanner .inner-animatedBanner{
    //   margin-top: 55px;
    //   transform: scale(0.45);
    // }
    .personalImg .banner-image{
      width: 410px !important;
      left: 48%;
    }
  }
  @media screen and (max-width: 514px) {
    .banner-image{
      width: 184px;
      height: 100%;
      position: absolute;
      top:48%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .personalImg .banner-image{
      width: 360px !important;
      left: 48%;
    }
    .imageCol{
      position: relative;
      height: 360px;
    }
    // .animatedBanner .inner-animatedBanner{
    //   margin-top: 35px;
    //   transform: scale(0.35);
    // }
  }
}