.createreport {
  .modalheaderwrapper {
    padding: 20px 30px;
    border-bottom: 1px solid var(--borderlight);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .createreporttitle {
      font-size: 16px;
      font-weight: 700;
      margin: 0px;
    }
    .sidebaricon {
      height: 18px;
      width: 18px;
      color: var(--textdark);
      cursor: pointer;
    }
  }
  .modal-body {
    padding: 35px 10px;
    .yourreporttext {
      margin-top: 10px;
      margin-bottom: 70px;
      font-size: 16px;
      font-weight: 600;
    }
    .downloadfiletext {
      color: var(--textlight);
      font-size: 15px;
      margin-bottom: 8px;
      margin-top: 30px;
    }
    .buttoncontainersm {
      display: inline-block;
      margin-left: 11px;
      margin-right: 11px;
      margin-bottom: 20px;
    }
    .mleft {
      margin-left: 3px;
    }
  }
  .paginationsectionforidotable{
    // margin
  }
  .modal-content {
    background-color: var(--bgwhite);
    border-radius: 10px;
  }
  .searchbox {
    max-width: 380px;
  }
  .buttontext {
    vertical-align: middle;
    line-height: 14px;
    font-size: 12px;
    font-weight: 600;
  }
  .reporttable{
    height: 50vh;
    overflow-y: auto;
  }
  @media (max-width: 576px){
    .modal-dialog{
      margin: 1.8rem;
    }
  }
}
.btn-close {
  color: var(--textdark);
}
